import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import {
  switchMap,
  mergeMap,
  catchError,
  withLatestFrom,
  map,
} from 'rxjs/operators';
import { LifeStyleReport } from 'src/app/data/LifeStyleReport';
import { LifeStyleReportsService } from 'src/app/Services/life-style-reports.service';
import {
  LifeStyleAction,
  LifeStyleActionTypes,
  LoadLifeStyleComplete,
} from '../Actions/life-style.actions';
import { AppState } from '../reducers';
import { selectLifeStyle } from '../selectors/life-style.selectors';

@Injectable()
export class LifeStyleEffects {
  @Effect()
  loadLifeStyle$ = this.actions$.pipe(
    ofType<LifeStyleAction>(LifeStyleActionTypes.LoadLifeStyle),
    withLatestFrom(this.store$.select(selectLifeStyle)),
    switchMap(([action, savedLifeStyle]) => {
      if (!savedLifeStyle) {
        return this.lifeStyleReportsService
          .getByPatientId(action.patientId)
          .pipe(
            mergeMap((lifeStyle) => [
              LoadLifeStyleComplete({ lifeStyle: lifeStyle }),
            ])
            /* catchError((errorMessage) =>
            of(new ProductError({ error: errorMessage }))
          ) */
          );
      } else {
        return of(LoadLifeStyleComplete({ lifeStyle: savedLifeStyle }));
      }
    })
  );

  @Effect()
  updateLifeStyle$ = this.actions$.pipe(
    ofType<LifeStyleAction>(LifeStyleActionTypes.UpdateLifeStyle),
    withLatestFrom(this.store$.select(selectLifeStyle)),
    switchMap(([action, savedLifeStyle]) => {
      if (savedLifeStyle == null) {
        return this.lifeStyleReportsService.post(action.lifeStyle).pipe(
          map((lifeStyle) => LoadLifeStyleComplete({ lifeStyle: lifeStyle }))
          /* catchError((errorMessage) =>
            of(new ProductError({ error: errorMessage }))
          ) */
        );
      }
      return this.lifeStyleReportsService.put(action.lifeStyle).pipe(
        map((lifeStyle: LifeStyleReport) =>
          LoadLifeStyleComplete({ lifeStyle: lifeStyle })
        )
        /* catchError((errorMessage) =>
          of(new ProductError({ error: errorMessage }))
        )*/
      );
    })
  );

  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private lifeStyleReportsService: LifeStyleReportsService
  ) {}
}
