import {
  AfterContentChecked,
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Observable } from 'rxjs';
import { Subscription } from 'rxjs/internal/Subscription';
import { ChatMsg } from 'src/app/data/chat-msg';
import { GuidedQuestion } from 'src/app/data/GuidedQuestion';
import { IndicatorMsg } from 'src/app/data/indicator-msg';
import { RegularUser } from 'src/app/data/RegularUser';
import { RequestForTableMin } from 'src/app/data/RequestForTableMin';
import { GuidedQuestionActionId } from 'src/app/Enum/StatusesEnum';
import {
  SubmitGuidedQuestionAnswer,
  UnSelectServiceRequest,
  UpdateServiceRequestComplete,
} from 'src/app/Redux/Actions/service-request.actions';
import { AppState } from 'src/app/Redux/reducers';
import { selectProductId } from 'src/app/Redux/selectors/product.selectors';
import {
  selectActiveRequest,
  selectGuidedQuestion,
  selectGuidedQuestionMode,
} from 'src/app/Redux/selectors/service-request.selectors';
import {
  selectGuidedQuestionData,
  selectGuidedQuestionTree,
  selectUser,
} from 'src/app/Redux/selectors/user.selectors';
import { ChatHoursService } from 'src/app/Services/chat-hours.service';
import { DesignService } from 'src/app/Services/design.service';
import { FirebaseService } from 'src/app/Services/firebase.service';
import { GlobalParamsService } from 'src/app/Services/global-params.service';
import { RegularUsersService } from 'src/app/Services/regular-users.service';
import { ServiceRequestService } from 'src/app/Services/service-request.service';
import { getDay } from 'src/app/Utils/dateUtils';
import { iOS, isMobile } from 'src/app/Utils/userAgent';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent implements OnInit, OnDestroy, AfterContentChecked {
  outOfService: boolean = true;
  @ViewChild('chatScroller') chatScroller;
  @ViewChild('chatInput') chatInput: ElementRef;
  @Output('displayName') displayName: EventEmitter<string> = new EventEmitter();

  serviceRequest$: Observable<RequestForTableMin> =
    this.store.select(selectActiveRequest);
  selectedActiveRequest$: Observable<RequestForTableMin> =
    this.store.select(selectActiveRequest);
  guidedQuestionTree$: Observable<string> = this.store.select(
    selectGuidedQuestionTree
  );
  guidedQuestionData$: Observable<string> = this.store.select(
    selectGuidedQuestionData
  );
  guidedQuestion$: Observable<string> = this.store.select(selectGuidedQuestion);
  guidedQuestionMode$: Observable<boolean> = this.store.select(
    selectGuidedQuestionMode
  );
  productId$: Observable<number> = this.store.select(selectProductId);
  user$: Observable<RegularUser> = this.store.select(selectUser);
  user: RegularUser;
  item$: any;
  chatRoom: any;
  currentUser: RegularUser;
  patient: any;
  guidedQuestion: GuidedQuestion;
  serviceRequest: RequestForTableMin;
  guidedQuestionMode: boolean;
  activeRequest: RequestForTableMin;
  chatMessage: any = '';
  emojis: boolean = false;
  chatLength: number = 0;
  config: PerfectScrollbarConfigInterface = {
    wheelSpeed: 0.5,
  };
  chatRoomDates: any[];
  activeAgent: any;

  treeData: any;
  treeObjects: any;
  guidedQuestionTree: any;
  guidedQuestionData: any;
  chatAnimation: boolean = false;
  loadGQ: boolean = false;
  chatIndicator: IndicatorMsg;
  chatIndicatorSubscription: Subscription;
  chatroomSubscription: Subscription;
  srcResult;
  isChatRoomListened: boolean;
  height: string;
  heightSubject: number;
  docId: string;
  chatRating: number;
  isAlreadyRate: boolean;
  loadGQApi: boolean;
  chatRatingComplete: boolean = false;
  productId: any;
  constructor(
    private regularUsersService: RegularUsersService,
    private firestore: AngularFirestore,
    private firebaseService: FirebaseService,
    private store: Store<AppState>,
    private globalParamsService: GlobalParamsService,
    private designService: DesignService,
    private serviceRequestService: ServiceRequestService,
    private chatHoursService: ChatHoursService
  ) {
    this.regularUsersService.currentUser.subscribe((user) => {
      this.currentUser = user;
      this.patient = user.patient;
    });
  }

  ngOnDestroy(): void {
    //console.log('ngOnDestroy');

    //this.setChatActivity(false);
    if (this.chatIndicatorSubscription)
      this.chatIndicatorSubscription.unsubscribe();

    if (this.chatroomSubscription) {
      //console.log('ngOnDestroy_chatroom');
      this.chatroomSubscription.unsubscribe();
    }
  }
  ios() {
    return iOS();
  }
  isMobile() {
    return isMobile();
  }
  ngAfterContentChecked() {
    this.height = this.heightSubject - 114 + '.px';
  }

  ngAfterViewChecked() {
    if (this.chatRoom && this.chatLength != this.chatRoom.length) {
      this.chatLength = this.chatRoom.length;
      this.scrollToBottom();
    }
  }

  ngOnInit(): void {
    this.serviceTime();
    this.designService.chatWrapperSubject.subscribe((data) => {
      this.heightSubject = data;
      //console.log('ChatComponent: ', data);
    });
    this.productId$.subscribe((productId) => (this.productId = productId));
    this.guidedQuestionTree$.subscribe(
      (data) => (this.guidedQuestionTree = JSON.parse(data))
    );
    this.guidedQuestionData$.subscribe(
      (data) => (this.guidedQuestionData = JSON.parse(data))
    );
    this.guidedQuestion$.subscribe((res) => {
      //console.log('guidedQuestion Active', res);
      var guidedQuesId = res;
      if (this.guidedQuestionTree) {
        var item = this.searchInTree(guidedQuesId, this.guidedQuestionTree);
        var children = item?.children;
        if (this.guidedQuestionData && this.guidedQuestionData[guidedQuesId]) {
          var obj = this.guidedQuestionData[guidedQuesId] as GuidedQuestion;

          var gq = new GuidedQuestion();
          gq.nextQuestionTxt = obj.nextQuestionTxt;
          gq.answerTxt = obj.answerTxt;
          if (children)
            gq.answers = children.map((x) => {
              var obj1 = this.guidedQuestionData[x.item] as GuidedQuestion;
              var gq1 = new GuidedQuestion();
              gq1.guidedQuestionId = x.item;
              gq1.actionId = obj1.actionId;
              gq1.answerTxt = obj1.answerTxt;
              gq1.nextQuestionTxt = obj1.nextQuestionTxt;
              return gq1;
            });

          this.guidedQuestion = gq;
          this.loadGQApi = false;
        }
      }
    });
    this.guidedQuestionMode$.subscribe((res) => {
      //console.log('guidedQuestion', res);
      this.guidedQuestionMode = res;
    });
    this.serviceRequest$.subscribe((res) => {
      //console.log('serviceRequest', res);
      this.serviceRequest = res;
    });
    this.user$.subscribe((res) => {
      this.user = res;
    });

    this.selectedActiveRequest$.subscribe((data) => {
      this.activeRequest = data;
      if (this.activeRequest && this.activeRequest.reviewScore > -1) {
        this.chatRating = this.activeRequest.reviewScore;
      }
      if (this.activeRequest && this.activeRequest.serviceRequestId) {
        if (!this.isChatRoomListened) {
          //console.log('isChatRoomListened');
          this.isChatRoomListened = true;

          this.setChatIndicator();
          this.readChat();
        }
      }
    });
  }
  serviceTime() {
    this.chatHoursService.getAll().subscribe((data) => {
      var now = new Date();
      var nowTime = now.getTime();
      var date = now.getDate();
      var nowDay = now.getDay();

      data.forEach((day) => {
        var chatHourStart = +day.hourStart?.split(':')[0];
        var chatHourEnd = +day.hourEnd?.split(':')[0];
        var chatDay = day.dayInWeek - 1;

        var chatDateStart = new Date(
          now.getFullYear(),
          now.getMonth(),
          date,
          chatHourStart,
          now.getMinutes()
        ).getTime();

        var chatDateEnd = new Date(
          now.getFullYear(),
          now.getMonth(),
          date,
          chatHourEnd,
          now.getMinutes()
        ).getTime();

        if (
          nowDay == chatDay &&
          nowTime >= chatDateStart &&
          nowTime <= chatDateEnd
        ) {
          this.outOfService = false;
        }
      });
    });
  }
  /* setChatActivity(isActive: boolean) {
    this.firebaseService.updateChatIndicator('chatIndicator', this.docId, {
      isActive: isActive,
    });
    console.log('setChatActivity');
  } */
  scrollToBottom() {
    var chatScrollerEl = (
      this.chatScroller.directiveRef.elementRef as ElementRef
    ).nativeElement;
    chatScrollerEl.scrollTop = chatScrollerEl.scrollHeight;
  }
  setChatIndicator() {
    this.docId = this.activeRequest.serviceRequestId.toString();
    this.chatIndicatorSubscription = this.firestore
      .collection('chatIndicator')
      .doc(`${this.activeRequest.serviceRequestId}`)
      .valueChanges()
      //.pipe(take(1))
      .subscribe((res: any) => {
        if (res) {
          this.chatIndicator = res;
          if (this.chatIndicator.countCrm == NaN) {
            this.chatIndicator.countCrm = 0;
          }
          if (this.chatIndicator.countSite == NaN) {
            this.chatIndicator.countSite = 0;
          }

          if (this.chatIndicator.countSite !== 0) {
            this.firebaseService.updateChatIndicator(
              'chatIndicator',
              this.docId,
              {
                countSite: 0,
                seenBy: [1, 2], //from site
                //isActive: true,
                /*
              countCrm: this.chatIndicator.countCrm ?? 0,
              msg: this.chatIndicator.msg ?? '',
              createdDate: this.chatIndicator.createdDate ?? null,
              displayName: this.chatIndicator.displayName ?? '',
              clientName: this.chatIndicator.clientName ?? '', 
              sentFrom: this.chatIndicator.sentFrom ?? 0, //from site
              isGQ: this.chatIndicator.isGQ,
              isClosed: this.chatIndicator.isClosed ?? false,
              avatarId: '' + this.chatIndicator.avatarId ?? '0',
              subjectId: this.activeRequest.subjectId ?? null, */
              }
            );
          } else {
            /*  if (!this.chatIndicator.isActive) {
              this.setChatActivity(true);
            } */
          }
          this.displayName.emit(this.chatIndicator.displayName);
        } else {
          //this.chatIndicator = new IndicatorMsg();
          //this.chatIndicator.countCrm = 0;
        }
      });
  }
  getfullName(): string {
    if (this.user.firstName && this.user.lastName)
      return this.user.firstName + ' ' + this.user.lastName;

    if (this.user.firstName && !this.user.lastName) return this.user.firstName;

    if (!this.user.firstName && this.user.lastName) return this.user.lastName;
  }
  readChat() {
    this.chatMessage = '';
    if (this.activeRequest && this.activeRequest.serviceRequestId) {
      this.chatroomSubscription = this.firestore
        .collection(`chatroom_${this.activeRequest.serviceRequestId}`)
        .snapshotChanges()
        .subscribe((res) => {
          //console.log('chatroom_listen', this.activeRequest);

          var res1 = res.map((x) => {
            var key = x.payload.doc.id;
            var val: any = x.payload.doc.data();
            val.key = key;
            return val;
            // this.chatIndicatorCrm[x.payload.doc.id] = x.payload.doc.data();
          });
          this.processChatRoom(res1);
        });
    }
  }
  searchInTree(search: string, directories: any[]) {
    for (let directory of directories) {
      if (directory.item === search) {
        return directory;
      }
      if (directory.children !== undefined && directory.children.length > 0) {
        let childsearch = this.searchInTree(search, directory.children);
        if (childsearch !== undefined) {
          return childsearch;
        }
      }
    }
    return undefined;
  }
  getActiveAgent() {
    // agent name
    var agentsMsges = this.chatRoom
      .filter((chat) => chat.sentFrom == 2)
      .sort((a, b) => {
        var x = new Date(a.createdDate);
        var y = new Date(b.createdDate);
        return x.getTime() > y.getTime() ? 1 : -1;
      });
    if (agentsMsges.length > 0) {
      this.activeAgent = agentsMsges[0].displayName;
    } else {
      this.activeAgent = 'My Genes';
    }
  }

  showAgentAvatar(avatarId) {
    var avatarIdToInt = avatarId;
    if (avatarIdToInt === '0') avatarIdToInt = +avatarIdToInt;

    var a =
      avatarIdToInt > 0 || avatarIdToInt == NaN ? '' + avatarIdToInt : 'bot';
    return a;
  }
  sortChatRoomByDates() {
    this.chatRoomDates.sort((a, b) => {
      var x = new Date(a[0].createdDate);
      var y = new Date(b[0].createdDate);
      return x.getTime() > y.getTime() ? 1 : -1;
    });
    this.chatRoomDates.forEach((x) => {
      x.forEach((y) => {
        y.createdDate = moment(y.createdDate, 'DD/MM/YYYY HH:mm:ss').format(
          'YYYY-MM-DD[T]HH:mm:ss'
        );
      });
      x.sort((a, b) => {
        var x = new Date(a.createdDate);
        var y = new Date(b.createdDate);
        return x.getTime() > y.getTime() ? 1 : -1;
      });
    });
  }
  processChatRoom(res) {
    res = res.filter(
      (x) =>
        (x as ChatMsg).regularUserId == this.user.regularUserId ||
        (x as ChatMsg).sentFrom == 2
    );
    /*  
    var msg=(x as ChatMsg);
      console.log(msg);
      
      console.log(msg.regularUserId ," ",this.user.regularUserId," ",msg.sentFrom)
      if ((x as ChatMsg).regularUserId==this.user.regularUserId || (x as ChatMsg).sentFrom==2)
      */

    if (res.length == 0 && this.guidedQuestion) {
      this.sendMessageToFirebase(this.guidedQuestion.answerTxt, true, false);
      setTimeout(() => {
        this.sendMessageToFirebase(
          this.guidedQuestion.nextQuestionTxt,
          true,
          false
        );
      }, 500);
    }
    res.forEach((msg: ChatMsg) => {
      if (!msg.seen && msg.sentFrom == 2) {
        // set msg to seen if comes from crm
        var key = msg.key;
        delete msg.key; // remove the key from the msg object,
        // we neded the key in order to know what doc key is
        // so we created the object with the key inside
        // but we dont want to store the key in the firebase object
        msg.seen = true;
        this.firestore
          .collection(`chatroom_${this.activeRequest.serviceRequestId}`)
          .doc(key)
          .set(msg);
      }
    });

    this.chatRoom = res;

    this.getActiveAgent();

    if (!this.chatRoom || this.chatRoom.length != res.length) {
      var chatScrollerEl = (
        this.chatScroller.directiveRef.elementRef as ElementRef
      ).nativeElement;
      chatScrollerEl.scrollTop = chatScrollerEl.scrollHeight;
    }
    var chatRoomDatesObj = {};
    this.chatRoomDates = [];
    this.chatRoom.forEach((x) => {
      var createdDay = getDay(x.createdDate);
      if (!chatRoomDatesObj[createdDay]) {
        chatRoomDatesObj[createdDay] = [];
      }
    });
    var chatRoomDatesObj = {};
    this.chatRoomDates = [];
    this.chatRoom.forEach((x) => {
      var createdDay = getDay(x.createdDate);
      if (!chatRoomDatesObj[createdDay]) {
        chatRoomDatesObj[createdDay] = [];
      }
      chatRoomDatesObj[createdDay].push(x);
    });

    Object.values(chatRoomDatesObj).forEach((x) => {
      this.chatRoomDates.push(x);
    });

    this.sortChatRoomByDates();
  }

  closeEmotion() {
    if (this.emojis) {
      this.emojis = !this.emojis;
    }
  }

  preventCloseEmoticons(e) {
    e.stopPropagation();
    e.preventDefault();
  }

  getGuidedQuestionTxt() {
    if (this.guidedQuestion) {
      //var a = this.guidedQuestion.answers[0].answerTxt;
      return this.guidedQuestion.nextQuestionTxt;
    }
  }

  unSelectServiceRequest() {
    this.store.dispatch(UnSelectServiceRequest());
  }

  openFileInput(fileInput: HTMLElement) {
    fileInput.click();
  }
  onFileSelected() {
    const inputNode: any = document.querySelector('#file');

    if (typeof FileReader !== 'undefined') {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        this.srcResult = e.target.result;
      };

      reader.readAsArrayBuffer(inputNode.files[0]);
    }
  }

  onAnswerSelect(answer: GuidedQuestion) {
    this.loadGQ = true;
    var isGQ = this.guidedQuestionMode;
    if (answer.actionId == GuidedQuestionActionId.SwitchToGuidedQuestionsMode) {
      isGQ = false;
    }
    this.sendMessageToFirebase(answer.answerTxt, true);
    this.chatAnimation = true;

    setTimeout(() => {
      this.chatAnimation = false;

      if (answer.nextQuestionTxt)
        this.sendMessageToFirebase(answer.nextQuestionTxt, isGQ, false);
    }, 1000);
    this.loadGQApi = true;
    this.store.dispatch(
      SubmitGuidedQuestionAnswer({
        serviceRequestId: this.serviceRequest.serviceRequestId,
        answerId: answer.guidedQuestionId,
        actionId: answer.actionId,
      })
    );
    setTimeout(() => (this.loadGQ = false), 2000);
  }

  addEmoji(event) {
    this.chatMessage += event.emoji.native;
  }

  openEmoticons(e) {
    e.preventDefault();
    e.stopPropagation();

    this.emojis = !this.emojis;
  }
  sendMessageToFirebase(_message, isGQ = false, isMe = true) {
    var sentFrom = isMe ? 1 : 2;
    var regularUserId = isMe ? this.user.regularUserId : 1;
    var avatarId = isMe ? '' + this.user.avatarId : '0';
    var now = moment(new Date().toUTCString()).format('DD/MM/YYYY HH:mm:ss');

    if (!this.chatIndicator) {
      this.chatIndicator = new IndicatorMsg();
      this.chatIndicator.countCrm = 0;
      this.firebaseService.setChatIndicator(
        'chatIndicator',
        `${this.activeRequest.serviceRequestId}`,
        {
          countCrm: isGQ
            ? this.chatIndicator.countCrm
            : ++this.chatIndicator.countCrm,
          countSite: 0,
          createdDate: now,
          msg: _message,
          displayName: '',
          clientName: this.getfullName(),
          seenBy: [1], //from site
          sentFrom: 1, //from site
          isGQ,
          avatarClientId: avatarId,
          avatarAgentId: this.chatIndicator.avatarAgentId ?? '0',
          subjectId: this.activeRequest.subjectId,
          isClosed: false,
          productId: this.productId,
          //isActive: true,
        }
      );
    } else {
      this.firebaseService.updateChatIndicator(
        'chatIndicator',
        `${this.activeRequest.serviceRequestId}`,
        {
          countCrm: isGQ
            ? this.chatIndicator.countCrm
            : ++this.chatIndicator.countCrm,
          countSite: 0,
          createdDate: now,
          msg: _message,
          subjectId: this.activeRequest.subjectId,
          clientName: this.getfullName(),
          isGQ,
          avatarClientId: avatarId,
        }
      );
    }

    this.firebaseService.sendMessage(
      `chatroom_${this.activeRequest.serviceRequestId}`,
      {
        key: `${this.activeRequest.serviceRequestId}`,
        displayName: this.getfullName(),
        massage: _message,
        regularUserId: regularUserId,
        sentFrom: sentFrom, //site-user,
        createdDate: now,
        seen: false,
        avatarId,
      }
    );
  }
  sendMessage(e) {
    this.chatMessage = this.chatMessage.trim();
    if (!this.chatMessage) {
      e.preventDefault();
      e.stopPropagation();
      return;
    }
    this.sendMessageToFirebase(this.chatMessage);
    this.chatMessage = '';
    /*  setTimeout(() => {
      this.chatInput.nativeElement.rows = '1';
      this.chatInput.nativeElement.style.height = '15px';
    }, 100); */
    this.emojis = false;
    //console.log(this.chatInput);
  }

  isChatActive() {
    if (this.chatIndicator && this.chatIndicator.isClosed) {
      this.scrollToBottom();
      return false;
    }
    return true;
  }
  getIsSeenTxt(chat) {
    if (chat.regularUserId == this.user.regularUserId) {
      return !chat.seenBy || chat.seenBy.length <= 1
        ? '<span>✓</span>'
        : '<span>✓</span><span>✓</span>';
    } else return '';
  }

  onSelectRate(rate) {
    this.chatRating = rate;
  }

  isGoodRating() {
    return this.activeRequest.reviewScore >= 4;
  }

  onSendReview(comment: string) {
    if (comment === '') comment = 'NoContent';
    this.serviceRequestService
      .rateFromSite(
        this.activeRequest.serviceRequestId,
        this.chatRating,
        comment
      )
      .subscribe((data) => {
        var serviceRequest = {
          ...this.activeRequest,
          reviewScore: this.chatRating,
        };
        this.store.dispatch(
          UpdateServiceRequestComplete({
            isComplete: true,
            serviceRequest: serviceRequest,
          })
        );
        //this.chatRatingComplete = true;
      });
  }
}
