import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogEnum } from 'src/app/Enum/ConfirmationDialogEnum';
import { MeetingType, ResetType } from 'src/app/Enum/StatusesEnum';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent implements OnInit {
  //confirmationType: ConfirmDialogEnum;
  MeetingType = MeetingType;
  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    //console.log(data.type);
  }

  ngOnInit(): void {}
  onNoClick() {
    this.dialogRef.close({ event: 'close', data: { answer: 'no' } });
  }
  onYesClick() {
    this.dialogRef.close({ event: 'close', data: { answer: 'yes' } });
  }

  pageTitleSendTo() {
    var updateParam: string;
    var sendTo: string;
    switch (this.data.resetType) {
      case ResetType.password:
        updateParam = 'סיסמה';
        sendTo = ' SMS';
        break;
      case ResetType.email:
        updateParam = 'דוא"ל';
        sendTo = ' SMS';
        break;
      case ResetType.phone:
        updateParam = 'מספר טלפון';
        sendTo = 'מייל';
        break;
    }

    return `לצורך עדכון ${updateParam} נבקש לשלוח לך קוד אימות ב${sendTo}`;
  }

  get confirmationType() {
    return ConfirmDialogEnum;
  }

  chosenCallType(type: number) {
    this.dialogRef.close({ event: 'close', data: { answer: type } });
  }
}
