import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { Product } from 'src/app/data/Product';
import { ProductTypeEnum } from 'src/app/Enum/StatusesEnum';
import { AppState } from 'src/app/Redux/reducers';
import { selectProduct } from 'src/app/Redux/selectors/product.selectors';
import { PatientsService } from 'src/app/Services/patients.service';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';
import { PALevel } from 'src/app/Utils/userHelper';

@Component({
  selector: 'app-home-alerts',
  templateUrl: './home-alerts.component.html',
  styleUrls: ['./home-alerts.component.scss'],
})
export class HomeAlertsComponent implements OnInit {
  @Output('alertsCount') alertsCount: EventEmitter<number> = new EventEmitter();
  product$: Observable<Product> = this.store.select(selectProduct);
  product: Product;
  userActivityLevel: string;
  dataExpired: {
    bloodTestExpired: boolean;
    bloodTestDate: string;
    lifeStyleExpired: boolean;
    lifeStyleDate: string;
  };
  constructor(
    private store: Store<AppState>,
    public translate: TranslateService,
    private router: Router,
    private patientsService: PatientsService,
    private popupHandlerService: PopupHandlerService
  ) {}

  ngOnInit(): void {
    this.product$.subscribe((res) => {
      if (res) {
        this.product = res;
        this.ifUserTestDataExpired();
        this.userActivityLevel = PALevel(
          this.product?.sportActivityData?.activityLevel
        );
      }
    });
  }

  ifUserTestDataExpired() {
    this.patientsService
      .ifUserTestDataExpired(this.product.productId)
      .subscribe((res) => {
        this.dataExpired = res;
        this.alertsCount.emit(this.getAlertsCount());
      });
  }

  getAlertsCount() {
    let count = 0;
    if (this.dataExpired.bloodTestExpired) {
      count++;
    }
    if (this.dataExpired.lifeStyleExpired) {
      count++;
    }
    return count;
  }
  isPremium() {
    return this.product.productTypeId == ProductTypeEnum.NutrogenicsPlus;
  }
  goToActivityLifestyle() {
    if (this.isPremium()) {
      this.router.navigate(['lifestyle-report-new'], {
        queryParams: { locationId: 1 },
      });
    } else {
      this.popupHandlerService.openUpgradePreviewDialog();
    }
  }
}
