import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BloodTestResult } from '../data/BloodTestResult';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BloodTestResultsService {
  constructor(private http: HttpClient) {}
  getByPatientId(clientId) {
    return this.http.get<BloodTestResult[]>(
      `${environment.baseUrl}BloodTestResults/${clientId}`
    );
  }
  post(results: BloodTestResult[]) {
    return this.http.post<BloodTestResult[]>(
      `${environment.baseUrl}BloodTestResults/`,
      results
    );
  }
  put(results: BloodTestResult[]) {
    return this.http.put<BloodTestResult[]>(
      `${environment.baseUrl}BloodTestResults/`,
      results
    );
  }
  saveSingle(patientId, bloodTestTypeId, value) {
    return this.http.get<any>(
      `${environment.baseUrl}BloodTestResults/SaveSingle/${patientId}/${bloodTestTypeId}/${value}`
    );
  }
}
