<div style="direction: ltr" *ngIf="product$ | async">
  <div class="statusTitle">
    {{ getStatusTitle() }}
  </div>
  <div class="status-bar">
    <div
      class="status-bar__item"
      *ngFor="let item of barStatuses; let index = index; let first = first"
    >
      <div
        *ngIf="!first"
        class="status-bar__item__line"
        [class.active]="isActiveStep(item.id)"
        [class.complete]="item.isComplete"
      ></div>
      <div
        class="status-bar__item__number"
        [class.active]="isActiveStep(item.id)"
        [class.complete]="item.isComplete"
      >
        <ng-container *ngIf="!item.isComplete; else isComplete">
          {{ index + 1 }}
        </ng-container>
        <ng-template #isComplete>
          <mat-icon>check</mat-icon>
        </ng-template>
      </div>
    </div>
  </div>

  <!--  <div class="img-bar">
    <div class="img-bar__img">
      <img src="../../../assets/tube-bar.svg" />
    </div>
    <div class="img-bar__img"><img src="../../../assets/truck-bar.svg" /></div>
    <div class="img-bar__img">
      <img
        style="position: relative; left: -3px"
        src="../../../assets/test-bar.svg"
      />
    </div>
    <div class="img-bar__img">
      <img src="../../../assets/results-bar.svg" />
    </div>
  </div>
  <div class="status-bar">
    <div class="status-bar__item status-1" #coloredBar1>
      <div class="load-bar" #loadBar1></div>
      <div class="status-bar__item__indicator" #indicator1>
        <img src="../../../assets/check.svg" />
        <span>1</span>
      </div>
    </div>
    <div class="status-bar__item status-2" #coloredBar2>
      <div class="load-bar" #loadBar2></div>
      <div class="status-bar__item__indicator" #indicator2>
        <img src="../../../assets/check.svg" />
        <span>2</span>
      </div>
    </div>
    <div class="status-bar__item status-3" #coloredBar3>
      <div #loadBar3 class="load-bar"></div>
      <div class="status-bar__item__indicator" #indicator3>
        <img src="../../../assets/check.svg" />
        <span>3</span>
      </div>
    </div>
    <div class="status-bar__item status-4">
      <div class="status-bar__item__indicator" #indicator4>
        <img src="../../../assets/check.svg" />
        <span>4</span>
      </div>
    </div>
  </div>
  <div class="text-bar">
    <div
      class="text-bar__text"
      [class.active]="status == ProductStatusEnum.EnteredBarcode"
      [class.colored]="
        status == ProductStatusEnum.WaitingToPickUp ||
        status == ProductStatusEnum.ArrivedToLab ||
        status == ProductStatusEnum.LaboratoryProcess ||
        status == ProductStatusEnum.RetestOnLab ||
        status == ProductStatusEnum.Completed
      "
    >
      הגיעה <br />
      הבדיקה
    </div>
    <div
      class="text-bar__text"
      [class.active]="status == ProductStatusEnum.WaitingToPickUp"
      [class.colored]="
        status == ProductStatusEnum.ArrivedToLab ||
        status == ProductStatusEnum.LaboratoryProcess ||
        status == ProductStatusEnum.RetestOnLab ||
        status == ProductStatusEnum.Completed
      "
    >
      איסוף <br />הבדיקה
    </div>
    <div
      class="text-bar__text"
      [class.active]="
        status == ProductStatusEnum.ArrivedToLab ||
        status == ProductStatusEnum.LaboratoryProcess ||
        status == ProductStatusEnum.RetestOnLab
      "
      [class.colored]="status == ProductStatusEnum.Completed"
    >
      הבדיקה <br />במעבדה
    </div>
    <div
      class="text-bar__text"
      [class.active]="status == ProductStatusEnum.Completed"
    >
      התקבלו <br />תוצאות
    </div>
  </div> -->
</div>
