import { Component, OnInit } from '@angular/core';
import { RegularUsersService } from 'src/app/Services/regular-users.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent implements OnInit {
  constructor(private regularUsersService: RegularUsersService) {}

  ngOnInit(): void {}
  doLogout() {
    this.regularUsersService.logout();
  }
}
