<mat-sidenav-container autosize class="sidenav-full" *ngIf="!isMobile()">
  <mat-sidenav
    opened
    #catNav
    mode="side"
    position="end"
    role="navigation"
    class="main-sidenav"
    [disableClose]="true"
    [opened]="showSideNav && !isNotSidenavPage()"
  >
    <app-side-nav [isExapleUser]="isExapleUser"></app-side-nav>
  </mat-sidenav>
  <mat-sidenav-content>
    <div [class.mobile-fix]="lastUrl !== '/chat'">
      <div class="top-bar">
        <img (click)="goToHome()" src="../assets/logo.svg" alt="" />
        <button
          *ngIf="(user$ | async) && isInnerPage() && !isNotSidenavPage()"
          class="top-bar__back"
          (click)="navBack()"
          mat-icon-button
        >
          <mat-icon>keyboard_arrow_right</mat-icon>
        </button>
        <button
          *ngIf="isNotSidenavPage() && (user$ | async)"
          class="top-bar__logout"
          (click)="doLogout()"
          mat-button
        >
          התנתק
        </button>
      </div>
      <router-outlet (activate)="onActivate($event)"></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
<div class="mobile" *ngIf="isMobile()">
  <img class="logo" src="../assets/logo.svg" alt="" />
  <div class="store">
    <iframe
      *ngIf="isRegisterRoute()"
      width="100%"
      height="210"
      src="https://www.youtube.com/embed/fGdbdWDBXhI"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
    <strong>נא לשים לב:</strong>
    <p style="text-align: center">
      ניתן להיכנס לאיזור האישי דרך האפליקציה בלבד
    </p>
    <div>Download from</div>
    <a href="https://apps.apple.com/us/app/mygenes/id6443790685" target="_top">
      <img *ngIf="isIOS()" src="../assets/app-store.svg" alt="" />
    </a>
    <a
      href="https://play.google.com/store/apps/details?id=com.mygenes.myapp"
      target="_top"
    >
      <img
        (click)="goToPlayStore()"
        *ngIf="!isIOS()"
        src="../assets/play-store.svg"
        alt=""
    /></a>
  </div>
</div>

<!-- <a [routerLink]="['/app-redirect']">
  <img *ngIf="isIOS()" src="../assets/app-store.svg" alt="app-store" />
  <img
    (click)="goToPlayStore()"
    *ngIf="!isIOS()"
    src="../assets/play-store.svg"
    alt="play-store"
  />
</a> -->
