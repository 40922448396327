import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { RequestForTableMin } from '../data/RequestForTableMin';
import { ServiceRequest } from '../data/ServiceRequest';

@Injectable({
  providedIn: 'root',
})
export class ServiceRequestService {
  constructor(private http: HttpClient, public datepipe: DatePipe) {}

  getByProductId(patientId: number): Observable<RequestForTableMin[]> {
    return this.http.get<RequestForTableMin[]>(
      `${environment.baseUrl}ServiceRequests/ForProductSite/${patientId}`
    );
  }
  createNewRequest(patientId: number): Observable<RequestForTableMin> {
    return this.http.get<RequestForTableMin>(
      `${environment.baseUrl}ServiceRequests/OpenFromSite/${patientId}`
    );
  }
  closeServiceRequest(serviceRequestId: number): Observable<any> {
    return this.http.get<any>(
      `${environment.baseUrl}ServiceRequests/CloseServiceRequest/${serviceRequestId}`
    );
  }
  submitGuidedQuestionAnswer(
    serviceRequestId: number,
    answerId: string,
    actionId: number
  ) {
    return this.http.get<any>(
      `${environment.baseUrl}ServiceRequests/SubmitGuidedQuestionAnswer/${serviceRequestId}/${answerId}/${actionId}`
    );
  }

  rateFromSite(serviceRequestId: number, rating: number, comment: string) {
    return this.http.get<any>(
      `${environment.baseUrl}ServiceRequests/RateFromSite/${serviceRequestId}/${rating}/${comment}`
    );
  }
}
