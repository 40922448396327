import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { Product } from 'src/app/data/Product';
import { Video } from 'src/app/data/Video';
import { VideoCategory } from 'src/app/data/VideoCategory';
import { VideoForSite } from 'src/app/data/VideoForSite';
import { AppState } from 'src/app/Redux/reducers';
import { selectProduct } from 'src/app/Redux/selectors/product.selectors';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';
import { VideoCategoriesService } from 'src/app/Services/video-categories.service';
import { VideosService } from 'src/app/Services/videos.service';

@Component({
  selector: 'app-vod',
  templateUrl: './vod.component.html',
  styleUrls: ['./vod.component.scss'],
})
export class VodComponent implements OnInit {
  @ViewChild('videoPlayer', { static: false }) videoPlayer: ElementRef;
  selectedVideo: Video;
  videoList: Video[];
  filteredVideos: Video[];
  categoriesList: VideoCategory[];
  selectedCategory: number = 0;
  showPlayBtn: boolean = true;
  videoBigClicked: boolean = false;
  showIframe: boolean = true;
  autoplay: boolean = false;
  langId: number = 1;
  product$: Observable<Product> = this.store.select(selectProduct);
  productId: number;
  categoryId: number = 1;
  constructor(
    public translate: TranslateService,
    private videoCategoriesService: VideoCategoriesService,
    private videosService: VideosService,
    private store: Store<AppState>
  ) {
    this.product$.subscribe((product) => {
      if (product) {
        this.productId = product.productId;
        this.getVideos();
      }
    });
  }

  ngOnInit(): void {
    //this.getCategories();
  }

  getCategories() {
    this.videoCategoriesService.getAll().subscribe((data) => {
      this.categoriesList = data;
      var all = new VideoCategory();
      all.videoCategoryId = 0;
      all.name = 'All';
      this.categoriesList.unshift(all);
    });
  }

  getVideos() {
    this.videosService
      .getVideosForProductByCategory(
        this.productId,
        this.categoryId,
        this.langId
      )
      .subscribe((data) => {
        this.videoList = data;
        this.selectedVideo = this.videoList[0];
        //this.filteredVideos = [...this.videoList];
      });
  }

  /* filterBy(categoryId: number) {
    this.selectedCategory = categoryId;
    if (categoryId == 0) {
      this.filteredVideos = this.videoList;
    } else {
      this.filteredVideos = this.videoList.filter(
        (x) => x.categoryId == categoryId
      );
    }
  } */
  onVideoClick(video: Video) {
    this.showIframe = false;
    setTimeout(() => {
      this.selectedVideo = video;
      this.showIframe = true;
    }, 100);
    setTimeout(() => {
      this.videoPlayer.nativeElement.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      });
    }, 200);
  }
  productFinishWatchVideo() {
    //this.videosService.productFinishWatchVideo()
  }
  onVideoEnded(video: Video) {
    const index = this.videoList.findIndex((x) => x.videoId == video.videoId);
    this.showIframe = false;
    if (video.isDisclaimer) {
      this.getVideos();
    } else {
      setTimeout(() => {
        this.selectedVideo = this.videoList[index + 1];
        this.showIframe = true;
      }, 100);
    }
  }
  onVideoBigClick() {
    this.videoBigClicked = true;
  }
}
