import { Client } from './Client';
import { Deal } from './Deal';
import { ProductForDealBundle } from './ProductForDealBundle';

import { Status } from './Status';

export class DealBundle {
  dealBundleId: number;
  clientId: number;
  client: Client;
  agentId: number;
  agent: any;
  dateCreated: string;
  dateUpdated: string;
  isCancelDeal: boolean;
  deals: Deal[];
  productForDealBundles: ProductForDealBundle[];
  dealsMin: any[];
  amount: number;
  leftToPay: number;
  statusId: number | null;
  status: Status;
  originalCancelationRequests: any[];
  cancelCancelationRequests: any[];
}
