import { Patient } from './Patients';

export class LifeStyleReport {
  lifeStyleReportId: number | null;
  patientId: number | null;
  patient: Patient | null;
  value: string | null;
  isComplete: boolean | null;
  stage: number | null;
  valueNew: string | null;
  isCompleteNew: boolean | null;
  stageNew: number | null;
  isOldActive: boolean | null;

  constructor(lifeStyleReport: LifeStyleReport = null) {
    if (lifeStyleReport) {
      this.lifeStyleReportId = lifeStyleReport.lifeStyleReportId;
      this.patientId = lifeStyleReport.patientId;
      this.patient = lifeStyleReport.patient;
      this.value = lifeStyleReport.value;
      this.isComplete = lifeStyleReport.isComplete;
      this.stage = lifeStyleReport.stage;
      this.valueNew = lifeStyleReport.valueNew;
      this.isCompleteNew = lifeStyleReport.isCompleteNew;
      this.stageNew = lifeStyleReport.stageNew;
      this.isOldActive = lifeStyleReport.isOldActive;
    }
  }
}
