import { Client } from './Client';
import { DealBundle } from './DealBundle';
import { FileUpload } from './FileUpload';
import { FutureProduct } from './FutureProduct';
import { Product } from './Product';
import { Status } from './Status';
import { TranzillaTransaction } from './TranzillaTransaction';

export class Deal {
  dealId: number;
  //bundleAmount: number | null;
  isAditionPayment: boolean;
  dealBundleId: number | null;
  dealBundle: DealBundle;
  // clientId: number;
  //client: Client;
  //agentId: number;
  //agent: Agent;
  amount: number;
  numPayments: number;
  paymentMethodId: number | null;
  paymentMethod: any;
  statusId: number;
  status: Status;
  dateUpdated: string;
  dealHistories: any[];
  cardTokenId: number | null;
  cardToken: any;
  isTokenDeal: boolean;
  //products: Product[];
  //cancelProducts: Product[];
  futureProducts: FutureProduct[];
  tranzillaTransaction: TranzillaTransaction;
  confirmationNumber: string;
  originalCancelationRequests: any[];
  cancelCancelationRequests: any[];
  wiredTransfer: any;
  manualCreditCard: any;
  coupon: any;
  fileUploads: any[];
  isCancelDeal: boolean;
  productsIds: number[];
}
