import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormArray,
  Validators,
} from '@angular/forms';
import { LifeStyleExercise } from '../../data/LifestyleExercise.model';
import { LifeStyleReportLocalOld } from '../../data/LifeStyleReportLocal-old';
//import { LifeStyleDisease } from '../../data/LifeStyleDisease';

import { LifeStyleReportsService } from 'src/app/Services/life-style-reports.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LifeStyleReport } from 'src/app/data/LifeStyleReport';
import { RegularUsersService } from 'src/app/Services/regular-users.service';
import { ProductService } from 'src/app/Services/product.service';
import { Product } from 'src/app/data/Product';
import { Status } from 'src/app/data/Status';

@Component({
  selector: 'app-life-style-report',
  templateUrl: './life-style-report.component.html',
  styleUrls: ['./life-style-report.component.scss'],
})
export class LifeStyleReportComponent implements OnInit {
  showPrevBtn: boolean = false;
  lifestyle: LifeStyleReport;
  lifestyleLocal: LifeStyleReportLocalOld;
  lifestyleDisease: FormArray;
  lifestyleForm1: FormGroup;
  lifestyleForm2: FormGroup;
  lifestyleForm3: FormGroup;
  lifestyleForm4: FormGroup;
  lifestyleForm5: FormGroup;
  lifestyleForm6: FormGroup;
  lifestyleForm7: FormGroup;
  isNewReport: boolean = true;
  showActivitiesFlag;
  showMedicineFlag;
  showSmokingFlag;
  step = 1;
  //@Input() patientId;
  patientId: number;
  product: Product;
  productStatus: Status;

  nutritionData: any = [
    { value: 1, name: 'רגילה' },
    { value: 2, name: 'ללא חלב' },
    { value: 3, name: 'טבעוני' },
    { value: 4, name: 'צמחוני' },
    { value: 5, name: 'ללא גלוטן' },
    { value: 6, name: 'אחר' },
  ];
  digestionData: any = [
    { value: 1, name: 'צרבות' },
    { value: 2, name: 'שלשולים' },
    { value: 3, name: 'עצירויות' },
    { value: 4, name: 'מעי רגיש' },
    { value: 6, name: 'אחר' },
  ];

  LifeStyleDiseaseLabels: any = [
    'מחלות לב וכלי דם, דפיקות לב, הפרעות בקצב לב, לחץ דם גבוה, נפיחות ברגליים, התקף לב מסוג כלשהו',
    'הפרעות בבליעה, צרבת, בחילות, כיב קיבה או תריסריון, מחלות מעיים, דימומים מדרכי העיכול, מחלות כיס מרה, כבד, הקאות חוזרות, צהבת נגיפית מסוג A/B/C',
    'רגישות יתר (אלרגיה) לסוגי מזונות / תרופות / גורמים מגרים אחרים',
    'מחלות או זיהומים בדרכי השתן, אבנים בכליות או בדרכי השתן, דם/סוכר/חלבון בשתן',
    'מחלות חילוף חומרים או בלוטות, סוכרת, שומנים בדם, מחלות בלוטת המגן, שיגדון',
    'דלקות מפרקים, ראומטיזם, מחלות עצם, ארטריטיס',
    'אנמיה, מחלות קרישה',
    'הפרעות אחרות שמקורן במחלות אחרות שלא פורטו לעיל או מגבלות בריאותיות אחרות',
  ];

  healthStatusData = ['טוב', 'לא טוב', 'די טוב'];
  eatingPatternLabels = [
    'אכילה מהירה',
    'תאווה למתוק',
    'take away / אוכל מהיר יותר מפעם בשבוע',
    'אכילה מסיבות שאינן רעב',
    'אני אוכל על הדרך (בעמידה/הליכה/נהיגה/ליד המקרר/תוך כדי הכנת האוכל',
    'אכילה תוך כדי צפייה בטלוויזיה / קריאה/ דיבור או משחק בטלפון/מול המחשב',
    'אני אוכל עד כדי תחושה של מלאות יתר',
    'אכילת יתר כתוצאה מרגשות לא נעימים (מתח/עצבים/פיצוי/שעמום/עייפות)',
    'אני אוכל גם כשאני לא רעב מסיבות שונות (חזרה הביתה/לפי השעון/מפגשים חברתיים)',
    'אני אוהב לבשל',
  ];
  eatingPatternData = [
    'אף פעם',
    'לעיתים רחוקות',
    'לפעמים',
    'לעיתים קרובות',
    'תמיד',
  ];
  lifestyleData: any = [{ name: '', duration: '', numInWeek: '' }];
  diseaseError: boolean;
  eatingError: boolean;
  isTakingDrugsError: boolean;
  isSmokingError: boolean;
  isifActivitiesError: boolean;
  ishealthStatusError: boolean;

  constructor(
    private fb: FormBuilder,
    private lifeStyleReportsService: LifeStyleReportsService,
    private route: ActivatedRoute,
    private regularUsersService: RegularUsersService,
    private productService: ProductService,
    private router: Router
  ) {
    regularUsersService.currentUser.subscribe((user) => {
      if (user === undefined) return;
      if (!user) {
        this.router.navigate(['login']);
        return;
      }

      this.patientId = user.patient.patientId;
      this.productService
        .getByPatientId(this.patientId)
        .subscribe((product) => {
          this.product = product as Product;
          this.productStatus = this.product.status;
        });
    });
    this.lifestyleLocal = new LifeStyleReportLocalOld();
    this.lifestyle = new LifeStyleReport();
    //this.lifestyleDisease = new LifeStyleDisease();

    this.createForm();
  }
  goBack() {
    this.router.navigate(['home']);
  }

  ngOnInit() {
    var productId = this.route.snapshot.queryParamMap.get('productId');
    var patientId = this.route.snapshot.queryParamMap.get('patientId');
    this.lifeStyleReportsService
      .getByPatientId(this.patientId)
      .subscribe((data) => {
        if (data == null) {
          this.lifestyle = new LifeStyleReport();
          this.lifestyleLocal = new LifeStyleReportLocalOld();
        } else {
          this.asignReport(data);

          //console.log(this.lifestyleLocal);
          //console.log(this.step);
          this.showActivities();
          this.showMedcine();
          this.showSmoking();
          this.isNewReport = false;
        }
        this.createForm();
      });
  }
  asignReport(data) {
    this.lifestyle = data;
    this.step = this.lifestyle.stage == null ? 1 : this.lifestyle.stage;
    this.lifestyleLocal = JSON.parse(this.lifestyle.value);
  }

  emitActivity(e) {
    this.lifestyleLocal.lifeStyleExercises[e.index] = e;
  }

  addActivity() {
    if (!this.lifestyleLocal.lifeStyleExercises)
      this.lifestyleLocal.lifeStyleExercises = [];
    var lifestyleExercise = new LifeStyleExercise();
    lifestyleExercise.index = this.lifestyleLocal.lifeStyleExercises.length;
    this.lifestyleLocal.lifeStyleExercises.push(lifestyleExercise);
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    //console.log(this.step);
    this.step--;
    // console.log(this.step);
  }

  showActivities() {
    if (this.lifestyleLocal.ifActivities === '1') {
      this.showActivitiesFlag = true;
    } else {
      this.showActivitiesFlag = false;
    }
  }
  showSmoking() {
    if (this.lifestyleLocal.isSmoking === '1') {
      this.showSmokingFlag = true;
    } else {
      this.showSmokingFlag = false;
    }
  }
  showDiseasesFields(inx) {
    var obj = this.lifestyleForm6.controls;
    var result = Object.keys(obj).map((key) => [obj[key]]);

    if (result[inx][0].value.ifActive === '1') {
      return true;
    } else {
      return false;
    }
  }
  showMedcine() {
    if (this.lifestyleLocal.isTakingDrugs === '1') {
      this.showMedicineFlag = true;
    } else {
      this.showMedicineFlag = false;
    }
  }

  ifActivities(e) {
    if (e.value == 1) {
      this.showActivitiesFlag = true;
    } else {
      this.showActivitiesFlag = false;
    }
  }
  ifMedicine(e) {
    if (e.value == 1) {
      this.showMedicineFlag = true;
    } else {
      this.showMedicineFlag = false;
    }
  }
  ifSmoking(e) {
    if (e.value == 1) {
      this.showSmokingFlag = true;
    } else {
      this.showSmokingFlag = false;
    }
  }
  get ifActivitiesYes() {
    return this.lifestyleForm5.get('ifActivities').value;
  }

  createForm() {
    this.lifestyleForm1 = new FormGroup({
      fullName: new FormControl(this.lifestyleLocal.fullName, [
        Validators.required,
      ]),
      email: new FormControl(this.lifestyleLocal.email, [Validators.required]),

      socialSecurityNum: new FormControl(
        this.lifestyleLocal.socialSecurityNum,
        [Validators.required]
      ),
      sexId: new FormControl(this.lifestyleLocal.sexId, [Validators.required]),

      age: new FormControl(this.lifestyleLocal.age, [Validators.required]),
      height: new FormControl(this.lifestyleLocal.height, [
        Validators.required,
      ]),
      weight: new FormControl(this.lifestyleLocal.weight, [
        Validators.required,
      ]),
    });
    this.lifestyleForm2 = new FormGroup({
      nutritionTypes: this.createNutritionCheckboxes(this.nutritionData),
      sensitivityToFood: new FormControl(
        this.lifestyleLocal.sensitivityToFood,
        [Validators.required]
      ),
      foodToAvoid: new FormControl(this.lifestyleLocal.foodToAvoid, [
        Validators.required,
      ]),
      digestionProblems: this.createDigestionProblemsCheckboxes(
        this.digestionData
      ),
    });
    this.lifestyleForm3 = new FormGroup({
      waterNumGlassesPerDay: new FormControl(
        this.lifestyleLocal.waterNumGlassesPerDay,
        [Validators.required]
      ),
      sweetNumGlassesPerDay: new FormControl(
        this.lifestyleLocal.sweetNumGlassesPerDay,
        [Validators.required]
      ),
      dietNumGlassesPerDay: new FormControl(
        this.lifestyleLocal.dietNumGlassesPerDay,
        [Validators.required]
      ),
      coffeePerDay: new FormControl(this.lifestyleLocal.coffeePerDay, [
        Validators.required,
      ]),
      teaPerDay: new FormControl(this.lifestyleLocal.teaPerDay, [
        Validators.required,
      ]),
      alcoholPerDay: new FormControl(this.lifestyleLocal.alcoholPerDay, [
        Validators.required,
      ]),
    });
    this.lifestyleForm4 = new FormGroup({
      eatingHabitsMorning: new FormControl(
        this.lifestyleLocal.eatingHabitsMorning,
        [Validators.required]
      ),
      eatingHabitsMiddle1: new FormControl(
        this.lifestyleLocal.eatingHabitsMiddle1,
        [Validators.required]
      ),
      eatingHabitsLunch: new FormControl(
        this.lifestyleLocal.eatingHabitsLunch,
        [Validators.required]
      ),
      eatingHabitsMiddle2: new FormControl(
        this.lifestyleLocal.eatingHabitsMiddle2,
        [Validators.required]
      ),
      eatingHabitsSupper: new FormControl(
        this.lifestyleLocal.eatingHabitsSupper,
        [Validators.required]
      ),
    });
    this.lifestyleForm5 = new FormGroup({
      ifActivities: new FormControl(this.lifestyleLocal.ifActivities, [
        Validators.required,
      ]),
      activitiesArray: new FormArray([]),
      isTakingDrugs: new FormControl(this.lifestyleLocal.isTakingDrugs, [
        Validators.required,
      ]),
      drugsTakenText: new FormControl(this.lifestyleLocal.drugsTakenText),
      isSmoking: new FormControl(this.lifestyleLocal.isSmoking, [
        Validators.required,
      ]),
      smokingTextNum: new FormControl(this.lifestyleLocal.smokingTextNum),
      smokingTextYears: new FormControl(this.lifestyleLocal.smokingTextYears),
      smokingTextQuit: new FormControl(this.lifestyleLocal.smokingTextQuit),
      /* isExercising: new FormControl(this.lifestyleLocal.isExercising, [
        Validators.required,
      ]),
      lifeStyleExercises: new FormControl(
        this.lifestyleLocal.lifeStyleExercises,
        [Validators.required]
      ), */
      relaxingActivity: new FormControl(this.lifestyleLocal.relaxingActivity, [
        Validators.required,
      ]),
      sleepHours: new FormControl(this.lifestyleLocal.sleepHours, [
        Validators.required,
      ]),
      areYouInStress: new FormControl(this.lifestyleLocal.areYouInStress, [
        Validators.required,
      ]),
      whatToAprrove: new FormControl(this.lifestyleLocal.whatToAprrove, [
        Validators.required,
      ]),
      healthStatus: new FormControl(this.lifestyleLocal.healthStatus, [
        Validators.required,
      ]),
    });
    //this.lifestyleForm5.value.isTakingDrugs;
    this.lifestyleForm6 = new FormGroup({
      d0: this.createDieseasesFields(0),
      d1: this.createDieseasesFields(1),
      d2: this.createDieseasesFields(2),
      d3: this.createDieseasesFields(3),
      d4: this.createDieseasesFields(4),
      d5: this.createDieseasesFields(5),
      d6: this.createDieseasesFields(6),
      d7: this.createDieseasesFields(7),
    });
    this.lifestyleForm7 = new FormGroup({
      0: this.createRadioPattern(0),
      1: this.createRadioPattern(1),
      2: this.createRadioPattern(2),
      3: this.createRadioPattern(3),
      4: this.createRadioPattern(4),
      5: this.createRadioPattern(5),
      6: this.createRadioPattern(6),
      7: this.createRadioPattern(7),
      8: this.createRadioPattern(8),
      9: this.createRadioPattern(9),
    });
  }
  get lifeStyleDisease(): FormArray {
    return this.lifestyleForm6.get('lifeStyleDisease') as FormArray;
  }

  createNutritionCheckboxes(nutritionCheckboxes) {
    if (this.lifestyleLocal.nutritionTypes) {
      const arr = this.lifestyleLocal.nutritionTypes.map((item) => {
        return new FormControl(item);
      });
      return new FormArray(arr);
    } else {
      const arr = nutritionCheckboxes.map((item) => {
        return new FormControl(item.selected || false);
      });
      return new FormArray(arr);
    }
  }

  createDigestionProblemsCheckboxes(digestionData) {
    if (this.lifestyleLocal.digestionProblems) {
      const arr = this.lifestyleLocal.digestionProblems.map((item) => {
        return new FormControl(item);
      });
      return new FormArray(arr);
    } else {
      const arr = digestionData.map((item) => {
        return new FormControl(item.selected || false);
      });
      return new FormArray(arr);
    }
  }

  createDieseasesFields(i) {
    if (this.lifestyleLocal.lifeStyleDisease) {
      var obj = this.lifestyleLocal.lifeStyleDisease;
      var result = Object.keys(obj).map((key) => [obj[key]]);
    }
    return new FormGroup({
      diseaseLabel: new FormControl(this.LifeStyleDiseaseLabels[i]),
      ifActive: new FormControl(result ? result[i][0].ifActive : '', [
        Validators.required,
      ]),
      diseaseName: new FormControl(result ? result[i][0].diseaseName : ''),
      whenStarted: new FormControl(result ? result[i][0].whenStarted : ''),
      medCare: new FormControl(result ? result[i][0].medCare : ''),
    });
  }
  createRadioPattern(inx) {
    if (this.lifestyleLocal.lifeStyleEatingPaterns) {
      var obj = this.lifestyleLocal.lifeStyleEatingPaterns;
      var result = Object.keys(obj).map((key) => [obj[key]]);
    }
    return new FormControl(result ? result[inx][0] : '', [Validators.required]);
  }

  canOpen(e) {}

  saveThis() {
    this.lifestyle.patientId = this.patientId;
    if (this.step == 0 && this.lifestyleForm1.valid) {
      this.lifestyleLocal.fullName = this.lifestyleForm1.value.fullName;
      this.lifestyleLocal.email = this.lifestyleForm1.value.email;
      this.lifestyleLocal.socialSecurityNum =
        this.lifestyleForm1.value.socialSecurityNum;
      this.lifestyleLocal.age = this.lifestyleForm1.value.age;
      this.lifestyleLocal.sexId = this.lifestyleForm1.value.sexId;
      this.lifestyleLocal.height = this.lifestyleForm1.value.height;
      this.lifestyleLocal.weight = this.lifestyleForm1.value.weight;
      //this.lifestyleLocal.lifeStyleExercises = this.lifestyleForm1.value.activitiesArray;

      this.lifestyle.value = JSON.stringify(this.lifestyleLocal);

      if (this.isNewReport) {
        this.lifeStyleReportsService.post(this.lifestyle).subscribe((data) => {
          this.asignReport(data);
          this.isNewReport = false;
          this.nextStep();
        });
      } else {
        this.lifeStyleReportsService.put(this.lifestyle).subscribe((data) => {
          this.nextStep();
        });
      }
    }
    if (this.step == 1 && this.lifestyleForm2.valid) {
      this.lifestyleLocal.nutritionTypes =
        this.lifestyleForm2.value.nutritionTypes;
      this.lifestyleLocal.sensitivityToFood =
        this.lifestyleForm2.value.sensitivityToFood;
      this.lifestyleLocal.foodToAvoid = this.lifestyleForm2.value.foodToAvoid;
      this.lifestyleLocal.digestionProblems =
        this.lifestyleForm2.value.digestionProblems;
      this.lifestyle.stage = 2;
      this.lifestyle.value = JSON.stringify(this.lifestyleLocal);
      if (this.isNewReport) {
        this.lifeStyleReportsService.post(this.lifestyle).subscribe((data) => {
          this.asignReport(data);
          this.isNewReport = false;
          this.nextStep();
        });
      } else {
        this.lifeStyleReportsService.put(this.lifestyle).subscribe((data) => {
          this.nextStep();
        });
      }
    }
    if (this.step == 2 && this.lifestyleForm3.valid) {
      this.lifestyleLocal.waterNumGlassesPerDay =
        this.lifestyleForm3.value.waterNumGlassesPerDay;
      this.lifestyleLocal.sweetNumGlassesPerDay =
        this.lifestyleForm3.value.sweetNumGlassesPerDay;
      this.lifestyleLocal.dietNumGlassesPerDay =
        this.lifestyleForm3.value.dietNumGlassesPerDay;
      this.lifestyleLocal.coffeePerDay = this.lifestyleForm3.value.coffeePerDay;
      this.lifestyleLocal.teaPerDay = this.lifestyleForm3.value.teaPerDay;
      this.lifestyleLocal.alcoholPerDay =
        this.lifestyleForm3.value.alcoholPerDay;
      this.lifestyle.stage = 3;
      this.lifestyle.value = JSON.stringify(this.lifestyleLocal);
      if (this.isNewReport) {
        this.lifeStyleReportsService.post(this.lifestyle).subscribe((data) => {
          this.asignReport(data);
          this.isNewReport = false;
          this.nextStep();
        });
      } else {
        this.lifeStyleReportsService.put(this.lifestyle).subscribe((data) => {
          this.nextStep();
        });
      }
    }
    if (this.step == 3 && this.lifestyleForm4.valid) {
      this.lifestyleLocal.eatingHabitsMorning =
        this.lifestyleForm4.value.eatingHabitsMorning;
      this.lifestyleLocal.eatingHabitsMiddle1 =
        this.lifestyleForm4.value.eatingHabitsMiddle1;
      this.lifestyleLocal.eatingHabitsLunch =
        this.lifestyleForm4.value.eatingHabitsLunch;
      this.lifestyleLocal.eatingHabitsMiddle2 =
        this.lifestyleForm4.value.eatingHabitsMiddle2;
      this.lifestyleLocal.eatingHabitsSupper =
        this.lifestyleForm4.value.eatingHabitsSupper;
      this.lifestyle.stage = 4;
      this.lifestyle.value = JSON.stringify(this.lifestyleLocal);
      if (this.isNewReport) {
        this.lifeStyleReportsService.post(this.lifestyle).subscribe((data) => {
          this.asignReport(data);
          this.isNewReport = false;
          this.nextStep();
        });
      } else {
        this.lifeStyleReportsService.put(this.lifestyle).subscribe((data) => {
          this.nextStep();
        });
      }
    }
    if (this.step == 4 && this.lifestyleForm5.valid) {
      this.lifestyleLocal.ifActivities = this.lifestyleForm5.value.ifActivities;
      this.lifestyleLocal.isTakingDrugs =
        this.lifestyleForm5.value.isTakingDrugs;
      this.lifestyleLocal.drugsTakenText =
        this.lifestyleForm5.value.drugsTakenText;
      this.lifestyleLocal.isSmoking = this.lifestyleForm5.value.isSmoking;
      this.lifestyleLocal.smokingTextNum =
        this.lifestyleForm5.value.smokingTextNum;
      this.lifestyleLocal.smokingTextYears =
        this.lifestyleForm5.value.smokingTextYears;
      this.lifestyleLocal.smokingTextQuit =
        this.lifestyleForm5.value.smokingTextQuit;
      this.lifestyleLocal.relaxingActivity =
        this.lifestyleForm5.value.relaxingActivity;
      this.lifestyleLocal.sleepHours = this.lifestyleForm5.value.sleepHours;
      this.lifestyleLocal.areYouInStress =
        this.lifestyleForm5.value.areYouInStress;
      this.lifestyleLocal.whatToAprrove =
        this.lifestyleForm5.value.whatToAprrove;
      this.lifestyleLocal.healthStatus = this.lifestyleForm5.value.healthStatus;
      this.lifestyle.stage = 5;
      this.lifestyle.value = JSON.stringify(this.lifestyleLocal);
      if (this.isNewReport) {
        this.lifeStyleReportsService.post(this.lifestyle).subscribe((data) => {
          this.asignReport(data);
          this.isNewReport = false;
          this.nextStep();
        });
      } else {
        this.lifeStyleReportsService.put(this.lifestyle).subscribe((data) => {
          this.nextStep();
        });
      }
    } else {
      if (
        this.lifestyleForm5.controls.isTakingDrugs.errors &&
        this.lifestyleForm5.controls.isTakingDrugs.errors.required
      ) {
        this.isTakingDrugsError = true;
      } else {
        this.isTakingDrugsError = false;
      }
      if (
        this.lifestyleForm5.controls.isSmoking.errors &&
        this.lifestyleForm5.controls.isSmoking.errors.required
      ) {
        this.isSmokingError = true;
      } else {
        this.isSmokingError = false;
      }
      if (
        this.lifestyleForm5.controls.ifActivities.errors &&
        this.lifestyleForm5.controls.ifActivities.errors.required
      ) {
        this.isifActivitiesError = true;
      } else {
        this.isifActivitiesError = false;
      }
      if (
        this.lifestyleForm5.controls.healthStatus.errors &&
        this.lifestyleForm5.controls.healthStatus.errors.required
      ) {
        this.ishealthStatusError = true;
      } else {
        this.ishealthStatusError = false;
      }
    }
    if (this.step == 5 && this.lifestyleForm6.valid) {
      this.lifestyleLocal.lifeStyleDisease = this.lifestyleForm6.value;
      this.lifestyle.stage = 6;
      this.lifestyle.value = JSON.stringify(this.lifestyleLocal);
      if (this.isNewReport) {
        this.lifeStyleReportsService.post(this.lifestyle).subscribe((data) => {
          this.asignReport(data);
          this.isNewReport = false;
          this.nextStep();
        });
      } else {
        this.lifeStyleReportsService.put(this.lifestyle).subscribe((data) => {
          this.nextStep();
        });
      }
    } else {
      var formArrayD = [
        this.lifestyleForm6.controls.d0,
        this.lifestyleForm6.controls.d1,
        this.lifestyleForm6.controls.d2,
        this.lifestyleForm6.controls.d3,
        this.lifestyleForm6.controls.d4,
        this.lifestyleForm6.controls.d5,
        this.lifestyleForm6.controls.d6,
        this.lifestyleForm6.controls.d7,
      ];
      formArrayD.forEach((element) => {
        if (
          (element as FormGroup).controls.ifActive.errors &&
          (element as FormGroup).controls.ifActive.errors.required
        ) {
          this.diseaseError = true;
        } else {
          this.diseaseError = false;
        }
      });
    }
    if (this.step == 6 && this.lifestyleForm7.valid) {
      this.lifestyleLocal.lifeStyleEatingPaterns = this.lifestyleForm7.value;
      this.lifestyle.stage = 7;
      this.lifestyle.value = JSON.stringify(this.lifestyleLocal);
      this.lifestyle.isComplete = true;
      if (this.isNewReport) {
        this.lifeStyleReportsService.post(this.lifestyle).subscribe((data) => {
          this.asignReport(data);
          this.isNewReport = false;
          //this.nextStep();
          this.router.navigate(['home']);
        });
      } else {
        this.lifeStyleReportsService.put(this.lifestyle).subscribe((data) => {
          //this.nextStep();
          this.router.navigate(['home']);
        });
      }
    } else {
      for (let i = 0; i < 10; i++) {
        if (
          this.lifestyleForm7.controls[i].errors &&
          this.lifestyleForm7.controls[i].errors.required
        ) {
          this.eatingError = true;
        }
      }
    }
    if (this.step == 2 && !this.lifestyleForm3.valid) {
      const invalid = [];
      const controls = this.lifestyleForm3.controls;
      for (const name in controls) {
        if (controls[name].invalid) {
          invalid.push(name);
        }
      }
      var a2 = invalid;
    }
    if (this.step == 3 && !this.lifestyleForm4.valid) {
      const invalid = [];
      const controls = this.lifestyleForm4.controls;
      for (const name in controls) {
        if (controls[name].invalid) {
          invalid.push(name);
        }
      }
      var a3 = invalid;
    }
    if (this.step == 4 && !this.lifestyleForm5.valid) {
      const invalid = [];
      const controls = this.lifestyleForm5.controls;
      for (const name in controls) {
        if (controls[name].invalid) {
          invalid.push(name);
        }
      }
      var a4 = invalid;
    }
    if (this.step == 5 && !this.lifestyleForm6.valid) {
      const invalid = [];
      const controls = this.lifestyleForm6.controls;
      for (const name in controls) {
        if (controls[name].invalid) {
          invalid.push(name);
        }
      }
      var a5 = invalid;
    }
  }

  mangeDrugsValidators(e) {
    if (e.value == 1) {
      this.lifestyleForm5.controls['drugsTakenText'].setValidators(
        Validators.required
      );
    } else {
      //console.log('clear');

      this.lifestyleForm5.controls['drugsTakenText'].clearValidators();
      this.lifestyleForm5.controls['drugsTakenText'].updateValueAndValidity();
    }
  }
  mangeSmokingValidators(e) {
    if (e.value == 1) {
      this.lifestyleForm5.controls['smokingTextNum'].setValidators(
        Validators.required
      );
      this.lifestyleForm5.controls['smokingTextYears'].setValidators(
        Validators.required
      );
      this.lifestyleForm5.controls['smokingTextQuit'].setValidators(
        Validators.required
      );
    } else {
      this.lifestyleForm5.controls['smokingTextNum'].clearValidators();
      this.lifestyleForm5.controls['smokingTextYears'].clearValidators();
      this.lifestyleForm5.controls['smokingTextQuit'].clearValidators();

      this.lifestyleForm5.controls['smokingTextNum'].updateValueAndValidity();
      this.lifestyleForm5.controls['smokingTextYears'].updateValueAndValidity();
      this.lifestyleForm5.controls['smokingTextQuit'].updateValueAndValidity();
    }
  }

  manageDiseasesValidators(e, inx) {
    //console.log(inx);

    if (e.value == 1 && inx == 0) {
      this.lifestyleForm6['d0'].setValidators(Validators.required);
      // console.log(this.lifestyleForm6['d0']);
    } else {
      this.lifestyleForm6.controls['d0'].clearValidators();
    }
    this.lifestyleForm6.controls['d0'].updateValueAndValidity();
  }

  getDiseasesErrors() {}
}
