export enum ProductStatusEnum {
  FilledDetails = -1, // => need to fill details
  NotActive = 90, // => login error

  NotRegistered = 6, // => scan-barcode, todo: make error screen "reach to support"
  Missingdetails = 14, // => scan-barcode, todo: make error screen "reach to support"
  SendToCustomer = 81, // => scan-barcode, todo: make error screen "reach to support"

  SentToCustomer = 9, // => scan-barcode
  EnteredBarcode = 64, // => scan-barcode
  ResendToPatient = 127, // => scan-barcode
  ArrivedToCostumer = 61, // => scan-barcode

  WaitingToPickUp = 7, // => scan-barcode || home

  ArrivedToLab = 62, // => home
  Completed = 63, // => home
  LaboratoryProcess = 8, // => home
  ResultAccepted = 194, // => home
  ArrivedToOffice = 93, // => home
  RetestOnLab = 126, // => home
}

export const enum PaymentMethodEnum {
  CreditCard = 1,
  ManualCreditCard = 2,
  Cash = 3,
  Check = 4,
  WiredTransfer = 5,
}

export const enum LeadStatusEnum {
  NewLead = 2,
  RenewedLead = 3,
  Attempted1 = 5,
  Attempted2 = 6,
  Attempted3 = 7,
  Attempted4 = 8,
  Attempted5 = 9,
  Attempted6 = 10,
  Attempted7 = 11,
  ContantCreated = 13,
  Relevant = 14,
  Active = 16,
  NotActive = 15,
  DidntAnswerSeveralTimes = 18,
  WrongNumber = 19,
  NotInterested = 20,
}

export const enum DeliveryTypeEnum {
  Delivery = 65,
  Pickup = 66,
}
export const enum DeliveryEnum {
  SenttoBar = 67,
  Completed = 68,
}
export const enum TubeTestEnum {
  SentToLab = 69,
  RecievedbyLab = 70,
  Completed = 71,
}
export const enum DealEnum {
  Done = 3,
  WaitingForPayment = 4,
  Canceled = 5,
  Rejected = 50,
  WaitingForAproval = 88,
}
export const enum TaskEnum {
  Open = 10,
  OverDue = 11,
  Completed = 12,
  Canceled = 13,
}
export const enum RequestEnum {
  Open = 112,
  InProgress = 113,
  Done = 114,
  Closed = 115,
}
export const enum TubePackageEnum {
  Draft = 58,
  Final = 59,
  Closed = 60,
}
export const enum FileUploadTypeEnum {
  PatientNutritionReport = 1,
  PatientDnaReport = 2,
  LabProcessData = 3,
}
export const enum ProductTypeEnum {
  NutrogenicsPlus = 1,
  Nutrogenics = 2,
  Farma = 3,
  Upgrage = 7,
}

export const enum GuidedQuestionActionId {
  SwitchToGuidedQuestionsMode = 172,
  assignToCostumerService = 173,
  assignToLogistics = 174,
  assignToNutrition = 175,
  assignToFinance = 176,
}
export const enum ZameretNutrients {
  ahuz_ibud_nozlim = 464,
  alcohol = 465,
  cholesterol = 476,
  food_energy = 486,
  total_fat = 526,
  total_dietary_fiber = 525,
  trans_fatty_acids = 528,
  satured_fat = 517,
  total_sugars = 527,
  protein = 515,
  carbohydrates = 474,
  vitamin_b12 = 534,
  vitamin_b6 = 535,
  vitamin_c = 536,
  vitamin_d = 537,
  vitamin_e = 538,
  vitamin_k = 539,
  iron = 491,
  calcium = 470,
  zinc = 540,
  magnesium = 498,
  folate = 484,
  geneticRate = 541,
}

export const enum GlobalParams {
  GuidedQuestionTree = 5,
  GuidedQuestionData = 6,
  GeneratePdf = 14,
  GeneratePdfBullets = 12,
}
export const enum QuestionDesignType {
  header = 232,
  headerCenter = 238,
  checkboxBig = 233,
  inputText = 234,
  radioRegular = 235,
  nextbutton = 236,
  radioButtonBig = 237,
  successThumb = 241,
}

export const enum QuestionContainerDesignType {
  flex = 260,
  block = 261,
  langDirection = 262,
  center = 263,
  flexCenter = 264,
}
export const enum LifeStyleInputType {
  checkbox = 1,
  radio = 2,
  text = 3,
  slider = 4,
}

export enum ResetType {
  email = 283,
  phone = 284,
  password = 285,
}

export const enum FileUploadType {
  consent_1 = 11,
  consent_2 = 12,
  consent_3 = 13,
  profile = 20,
  weight = 22,
  VideoPoster = 28,
  VodVideo = 42,
}

export enum EnteredBarcodeInnerStatus {
  BeforeSpitting = 1,
  AfterSpitting = 2,
  ConfirmAdress = 3,
  ChangeAdress = 4,
}

export enum AddressType {
  main = 1,
  shipping = 2,
  billing = 3,
}

export enum PALevelType {
  sedentary = 1,
  lowActive = 2,
  active = 3,
  veryActive = 4,
}

export enum VideoType {
  zoom = 1,
  tv = 2,
  social = 3,
}
export enum MeetingScheduleStepType {
  meetingSettingType = 1,
  meetingSchedule = 2,
  meetingApproval = 3,
}

export enum MeetingType {
  phone = 1,
  zoom = 2,
}

export enum AlgoImageType {
  AlgoCatSmallIcon = 1,
  AlgoCatBigIcon = 2,
  AlgoSubCatSmallIcon = 3,
  AlgoSubCatBigIcon = 4,
  PrototypeCatSmallIcon = 5,
  PrototypeCatBigIcon = 6,
  PrototypeSubCatSmallIcon = 7,
  PrototypeSubCatBigIcon = 8,
  AlgoResultTypePass = 9, //(required langId)
  AlgoPdfTemplate = 10,
  LabResult = 11,
  AlgoCatDnaReport = 12,
  AlgoCatPdfBorderBottom = 13,
  AlgoCatPdfBorderMiddle = 14,
  PrototypeCatDnaReport = 15,
  PrototypeCatPdfBorderBottom = 16,
  PrototypeCatPdfBorderMiddle = 17,
  AlgoSubCatPdfBorderTop = 18,
  PrototypeSubCatPdfBorderTop = 19,
  PrototypeResultTypePass = 20, //(required langId)
  AlgoPageTitleImage = 22,
  PrototypePageTitleImage = 23,
  AlgoAppCatIcon = 24,
  PrototypeAppCatIcon = 25,
  AlgoAppPageTitleImage = 26,
  PrototypeAppPageTitleImage = 27,
  VideoPoster = 28,
}

export enum ResultOrderTypeEnum {
  VeryLow = 0,
  Low = 1,
  Medium = 2,
  High = 3,
  VeryHigh = 4,
}

export enum PersonalDetailsStepEnum {
  PersonalDetails = 1,
  Goals = 2,
}
