<ng-container *ngIf="languages">
  <button class="selected-lang" mat-icon-button [matMenuTriggerFor]="langMenu">
    <img src="../../assets/lang-icon-{{ selectedLangId }}.svg" />
  </button>

  <mat-menu #langMenu="matMenu">
    <button
      (click)="onChangeLang(lang)"
      class="menu-lang"
      mat-menu-item
      *ngFor="let lang of languages"
    >
      <img src="../../assets/lang-icon-{{ lang.languageId }}.svg" />
    </button>
  </mat-menu>
</ng-container>
