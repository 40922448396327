import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Patient } from 'src/app/data/Patients';
import { Product } from 'src/app/data/Product';
import { AppState } from 'src/app/Redux/reducers';
import { selectProduct } from 'src/app/Redux/selectors/product.selectors';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';

@Component({
  selector: 'app-weight-info',
  templateUrl: './weight-info.component.html',
  styleUrls: ['./weight-info.component.scss'],
})
export class WeightInfoComponent implements OnInit {
  product$: Observable<Product> = this.store.select(selectProduct);
  @Input() patient: Patient;
  @Output('restartGoalSession') restartGoalSession: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  startWeight;
  totalWeightLoss;
  currentBmi;
  weightGoal;
  prevWeight;
  currentWeight;
  product: Product;
  age: number;

  daysInProcess: number;
  weightUnit: string;
  weightLose: number;

  a: number[] = [];
  constructor(
    private store: Store<AppState>,
    private popupHandlerService: PopupHandlerService
  ) {}

  ngOnInit(): void {
    //console.log(this.patient);
    this.product$.subscribe((res) => {
      if (res) {
        this.product = res;
        const { birthDate } = this.product.patient.regularUser;
        const birthDateYear = new Date(birthDate).getFullYear();
        const d = new Date();
        const n = d.getFullYear();
        this.age = n - birthDateYear;
        //this.calculateBmi();
        //this.calculateGoalBmi();
        //this.rullerRanks = this.isElder() ? this.bmiElderRank : this.bmiRank;
      }
    });
  }
  gotoUpdateGoalPage() {}
  restartGoal() {
    this.restartGoalSession.emit(true);
  }
  lossBarValue() {
    var value =
      ((this.patient.currentWeight - this.patient.goalWeight) /
        (this.patient.startWeight - this.patient.goalWeight)) *
      100;

    return value ? Math.round(value) : 0;
  }

  getWeightProcess() {
    const isWeightStart = this.product.patient.patientWeightHistories
      .filter((x) => !x.isGoal)
      .find((x) => x.isWeightStart);

    const isFirstWeight = [...this.product.patient.patientWeightHistories]
      .filter((x) => !x.isGoal)
      .sort((a, b) => {
        const aDate = new Date(a.dateUpdated);
        const bDate = new Date(b.dateUpdated);
        return aDate.getTime() - bDate.getTime();
      })[0];

    this.startWeight = isFirstWeight;

    this.daysInProcess =
      (new Date().getTime() -
        new Date(this.startWeight?.dateUpdated).getTime()) /
      (1000 * 3600 * 24);

    const tempWeightLose =
      this.startWeight?.value - this.product.patient.currentWeight;

    if (tempWeightLose > 0 && tempWeightLose < 1) {
      this.weightUnit = 'גרם';
      this.weightLose = tempWeightLose * 1000;
    } else if (tempWeightLose >= 1 || tempWeightLose < 0) {
      this.weightUnit = 'ק"ג';
      this.weightLose = tempWeightLose;
    }
    let text = '';
    if (this.weightLose < 0) {
      text = '+';
    } else {
      text = '-';
    }
    if (tempWeightLose !== 0)
      return `(${
        Math.round((Math.abs(this.weightLose) + Number.EPSILON) * 100) / 100
      }${text} ${this.weightUnit})`;
  }
}
