<app-top-bar-mobile
  title="תיאום שיחה עם דיאטן / יועץ תזונה"
  [isPage]="true"
  [isLongTitle]="true"
></app-top-bar-mobile>
<div class="meeting-schedule">
  <div class="mt-4"></div>
  <div [ngSwitch]="scheduleStep">
    <div *ngSwitchCase="MeetingScheduleStepType.meetingSettingType">
      <div class="meeting-schedule__select-type">
        <div class="meeting-schedule__select-type__label">
          בחר/י את סוג השיחה
        </div>
        <mat-form-field
          class="meeting-schedule__select-type__field"
          appearance="fill"
        >
          <mat-select
            placeholder="בחר/י את סוג השיחה"
            panelClass="call-type"
            [(ngModel)]="meetingSchedule.meetingSettingTypeId"
          >
            <mat-option *ngFor="let item of callTypes" [value]="item.id">{{
              item.name
            }}</mat-option>
          </mat-select>
          <mat-icon style="font-size: 3rem" matSuffix
            >keyboard_arrow_down</mat-icon
          >
        </mat-form-field>
        <ng-container *ngFor="let item of callTypes">
          <div
            class="meeting-schedule__select-type__desc"
            *ngIf="item.id == meetingSchedule.meetingSettingTypeId"
          >
            <strong>"{{ item.name }}" </strong>{{ item.desc }}
          </div>
        </ng-container>
      </div>
      <div class="mt-3"></div>
      <button
        (click)="goToNextStep()"
        mat-button
        class="btn btn--fill btn--big"
        [disabled]="!meetingSchedule.meetingSettingTypeId"
      >
        תיאום
      </button>
    </div>
    <div *ngSwitchCase="MeetingScheduleStepType.meetingSchedule">
      <div class="meeting-schedule__sub-title">
        בחרת לתאם שיחה ראשונית
        <br />
        באמצעות {{ getMeetingType() }} עם דיאטן/ית
      </div>
      <div class="mt-3"></div>
      <div class="meeting-schedule__title">מתי נוח לך?</div>
      <div class="mt-2"></div>
      <mat-calendar
        [selected]="selectedDate"
        [headerComponent]="schedualCalendarHeader"
        [dateFilter]="myDateFilter"
        (selectedChange)="onSelectDate($event)"
      ></mat-calendar>
      <div class="meeting-schedule__time">
        <div class="meeting-schedule__time__date">
          {{ getFullSelectedDate() }}
        </div>
        <div class="meeting-schedule__time__row">
          <button
            mat-button
            class="meeting-schedule__time__row__item"
            *ngFor="let item of activeHours"
            (click)="onSelectTime(item)"
          >
            {{ item }}
          </button>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="MeetingScheduleStepType.meetingApproval">
      <div class="meeting-schedule__sub-title">
        בחרת לתאם שיחה ראשונית
        <br />
        באמצעות {{ getMeetingType() }} עם דיאטן/ית
      </div>
      <div class="mt-3"></div>
      <div class="meeting-schedule__title">
        נא לאשר את השיחה המתואמת
        <br />
        לתאריך {{ meetingSchedule.meetingDate | date: "dd/MM/yy" }} בשעה
        {{ meetingSchedule.meetingTime }}
      </div>
      <div class="mt-4"></div>
      <button
        (click)="goToNextStep()"
        mat-button
        class="btn btn--big btn--fill"
      >
        אישור
      </button>
      <div class="mt-2"></div>
      <button
        (click)="scheduleStep = scheduleStep - 1"
        mat-button
        class="btn btn--big btn--outline"
      >
        שינוי
      </button>
    </div>
  </div>
</div>
