<div
  style="
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  "
>
  <img src="../../../assets/scales.svg" />
  <div class="weight-cards__item__title" style="margin-top: 30px">
    משקלך היום
  </div>
  <input
    style="
      margin-top: 30px;
      height: 40px;
      width: 100px;
      border: 2px solid #00babf;
      outline: none !important;
      text-align: center;
      font-size: 25px;
    "
    mask="00.00"
    [(ngModel)]="data.weight"
  />
  <div class="weight-cards__item__result">
    <!--
    <span class="weight-cards__item__text">ק"ג</span>
    -->
    <button class="updateWeightBtn" (click)="submit()">עדכן משקל</button>
  </div>
</div>
