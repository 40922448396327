import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FirebaseAnonimousAuthService {
  isFireLogged = new BehaviorSubject<any>(null);
  readonly isFireLogged$ = this.isFireLogged.asObservable();
  authState: any = null;

  constructor(private afAuth: AngularFireAuth) {
    this.afAuth.authState.subscribe((auth) => {
      this.authState = auth;
    });
  }

  get isUserAnonymousLoggedIn(): boolean {
    return this.authState !== null ? this.authState.isAnonymous : false;
  }

  get currentUserId(): string {
    return this.authState !== null ? this.authState.uid : '';
  }

  anonymousLogin() {
    return this.afAuth
      .signInAnonymously()
      .then((user) => {
        this.authState = user;
        this.isFireLogged.next(this.authState);
      })
      .catch((error) => console.log(error));
  }

  signOut(): void {
    this.afAuth.signOut();
  }
}
