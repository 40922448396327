import { AdressType } from './AdressType';
import { BarCity } from './BarCity';
import { BarStreet } from './BarStreet';
import { RegularUser } from './RegularUser';

export class UserAdress {
  userAdressId: number;
  adressTypeId: number;
  adressType: AdressType;
  regularUserId: number;
  regularUser: RegularUser;
  streetNum: number;
  barCityCode: number | null;
  barCity: BarCity;
  barStreetCode: number | null;
  barStreet: BarStreet;
  country: string;
  entrance: string;
  apartment: string;
  shipNotes: string;
}
