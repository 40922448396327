import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RequestForTableMin } from 'src/app/data/RequestForTableMin';
import { AppState } from '../reducers';

export const selectServiceRequests = (state: AppState) =>
  state.serviceRequests.serviceRequests;

export const selectGuidedQuestion = (state: AppState) => {
  if (state.serviceRequests.activeRequestId) {
    return state.serviceRequests.serviceRequests.find(
      (x) => x.serviceRequestId == state.serviceRequests.activeRequestId
    ).guidedQuestionId;
  } else return null;
};
export const selectGuidedQuestionMode = (state: AppState) => {
  if (state.serviceRequests.activeRequestId) {
    return state.serviceRequests.serviceRequests.find(
      (x) => x.serviceRequestId == state.serviceRequests.activeRequestId
    ).guidedQuestionMode;
  } else return null;
};
export const selectActiveRequest = (state: AppState) => {
  if (state.serviceRequests.activeRequestId) {
    return state.serviceRequests.serviceRequests.find(
      (x) => x.serviceRequestId == state.serviceRequests.activeRequestId
    );
  } else return null;
};

export const selectActiveRequestId = (state: AppState) => {
  return state.serviceRequests.activeRequestId;
};

export const selectIsLoading = (state: AppState) => {
  return state.serviceRequests.isLoading;
};
