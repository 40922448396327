import { Component, OnInit } from '@angular/core';
//import { CanvasWhiteboardComponent } from 'ng2-canvas-whiteboard/lib/canvas-whiteboard.component';
//import { CanvasWhiteboardOptions } from 'ng2-canvas-whiteboard/public-api';

@Component({
  selector: 'app-canvas-whiteboard-thumb',
  templateUrl: './canvas-whiteboard-thumb.component.html',
  styleUrls: ['./canvas-whiteboard-thumb.component.scss'],
  // viewProviders: [CanvasWhiteboardComponent],
})
export class CanvasWhiteboardThumbComponent implements OnInit {
  /*
  canvasOptions: CanvasWhiteboardOptions = {
    drawButtonEnabled: true,
    drawButtonClass: 'drawButtonClass',
    drawButtonText: 'Draw',
    clearButtonEnabled: true,
    clearButtonClass: 'clearButtonClass',
    clearButtonText: 'Clear',
    undoButtonText: 'Undo',
    undoButtonEnabled: true,
    redoButtonText: 'Redo',
    redoButtonEnabled: true,
    colorPickerEnabled: true,
    fillColorPickerText: 'Fill',
    strokeColorPickerText: 'Stroke',
    saveDataButtonEnabled: true,
    saveDataButtonText: 'Save',
    lineWidth: 5,
    strokeColor: 'rgb(0,0,0)',
    shouldDownloadDrawing: true,
  };
  */
  constructor() {}

  ngOnInit(): void {}
}
