<div class="page-title">VOD</div>
<div class="vod" *ngIf="videoList">
  <!-- <div class="filter" *ngIf="categoriesList.length > 1">
    <span
      class="filter__item"
      *ngFor="let item of categoriesList"
      (click)="filterBy(item.videoCategoryId)"
      [class.active]="selectedCategory == item.videoCategoryId"
    >
      {{ item.name | translate }}
    </span>
  </div> -->
  <div class="video-big">
    <!-- <div class="video-big__poster" *ngIf="!videoBigClicked">
      <img
        class="video-big__poster__img"
        [src]="selectedVideo.posterUrl"
        [alt]="selectedVideo.title"
      />
      <div class="video-big__poster__play-btn-wrapper"></div>
      <button
        (click)="onVideoBigClick()"
        mat-icon-button
        class="video-big__poster__play-btn"
      >
        <mat-icon>play_circle_outline</mat-icon>
      </button>
      <div class="video-big__info">
        <div class="video-big__info__title">{{ selectedVideo.title }}</div>
        <div class="video-big__info__description">
          {{ selectedVideo.title }}
        </div>
      </div>
    </div> -->
    <div #videoPlayer>
      <app-video-player
        *ngIf="showIframe && productId"
        [video]="selectedVideo"
        [productId]="productId"
        (videoEnded)="onVideoEnded($event)"
      ></app-video-player>
    </div>
    <!-- <cloudflare-stream
      *ngIf="showIframe"
      [poster]="selectedVideo.posterUrl"
      [controls]="true"
      [src]="selectedVideo.videoUrl"
      (ended)="onVideoEnded()"
      #videoPlayer
    ></cloudflare-stream> -->
  </div>
  <div class="chapters">פרקים</div>
  <div class="video-list" *ngIf="videoList.length > 0">
    <app-video-item
      *ngFor="let video of videoList; let i = index"
      [video]="video"
      [isSelected]="selectedVideo.videoId == video.videoId"
      [index]="i"
      (videoClick)="onVideoClick($event)"
    ></app-video-item>
  </div>
</div>
