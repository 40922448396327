import { BarCity } from './BarCity';
import { BarStreet } from './BarStreet';
import { Client } from './Client';
import { Language } from './Language';
import { Patient } from './Patients';
import { RoleType } from './RoleType';
import { UserAdress } from './UserAdress';

export class RegularUser {
  regularUserId: number;
  avatarId: number;
  firstName: string;
  lastName: string;
  displayName: string;
  phone: string;
  email: string;
  userName: string;
  password: string;
  salt: string;
  streetNum: number;
  city: string;
  street: string;
  barCityCode: number | null;
  barCity: BarCity;
  barStreetCode: number | null;
  barStreet: BarStreet;
  country: string;
  socialSecurityNum: string;
  birthDate: string | null;
  birthDateObj: Date | null;
  gender: string;
  height: number | null;
  weight: number | null;
  pALevel: number | null;
  age: number | null;
  roleTypeId: number | null;
  role: RoleType;
  agent: any;
  client: Client;
  patient: Patient;
  createdDate: string;
  entrance: string;
  apartment: string;
  shipNotes: string;
  resetPasswordToken: string;
  token: string;
  phoneVerificationCode: string;
  isFarLogin: boolean | null;
  langId: number | null;
  language: Language;
  userAdresses: UserAdress[];
  passwordStrengthId: number | null;
  isActive: boolean | null;
}
