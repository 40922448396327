import { Injectable } from '@angular/core';
import { Actions, createEffect, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { of } from 'rxjs';
import {
  withLatestFrom,
  switchMap,
  mergeMap,
  catchError,
  map,
} from 'rxjs/operators';
import { RequestForTableMin } from 'src/app/data/RequestForTableMin';
import { FirebaseService } from 'src/app/Services/firebase.service';
import { ServiceRequestService } from 'src/app/Services/service-request.service';

import {
  LoadServiceRequestsComplete,
  LoadServiceRequestsError,
  OpenServiceRequestComplete,
  SelectServiceRequest,
  ServiceRequestsAction,
  ServiceRequestsActionTypes,
  UpdateServiceRequestComplete,
} from '../Actions/service-request.actions';
import { AppState } from '../reducers';

@Injectable()
export class ServiceRequestEffects {
  /*
  loadMovies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ServiceRequestsActionTypes.LoadServiceRequests),
      mergeMap(() =>
        this.serviceRequestService.getByProductId().pipe(
          map((movies) => ({
            type: ServiceRequestsActionTypes.LoadServiceRequestsComplete,
            payload: movies,
          })),
          catchError(() => EMPTY)
        )
      )
    )
  );
*/
  @Effect()
  getServiceRequests$ = this.actions$.pipe(
    ofType(ServiceRequestsActionTypes.LoadServiceRequests),
    switchMap((action: ServiceRequestsAction) =>
      this.serviceRequestService.getByProductId(action.productId).pipe(
        map((serviceRequests: any) =>
          LoadServiceRequestsComplete({ serviceRequests: serviceRequests })
        ),
        catchError((error) => of(LoadServiceRequestsError(error)))
      )
    )
  );

  @Effect()
  openServiceRequest$ = this.actions$.pipe(
    ofType(ServiceRequestsActionTypes.OpenServiceRequest),
    switchMap((action: ServiceRequestsAction) =>
      this.serviceRequestService.createNewRequest(action.patientId).pipe(
        switchMap((ans) => [
          OpenServiceRequestComplete({ serviceRequest: ans }),
          SelectServiceRequest({
            serviceRequestId: ans.serviceRequestId,
          }),
        ]),
        catchError((error) => of(LoadServiceRequestsError(error)))
      )
    )
  );

  @Effect()
  submitGuidedQuestionAnswer$ = this.actions$.pipe(
    ofType(ServiceRequestsActionTypes.SubmitGuidedQuestionAnswer),
    switchMap((action: ServiceRequestsAction) =>
      this.serviceRequestService
        .submitGuidedQuestionAnswer(
          action.serviceRequestId,
          action.answerId,
          action.actionId
        )
        .pipe(
          switchMap((ans) => [
            UpdateServiceRequestComplete({
              isComplete: true,
              serviceRequest: ans,
            }),
            SelectServiceRequest({
              serviceRequestId: ans.serviceRequestId,
            }),
          ]),
          catchError((error) => of(LoadServiceRequestsError(error)))
        )
    )
  );
  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private serviceRequestService: ServiceRequestService,
    private firebaseService: FirebaseService
  ) {}
}
