import { Component, Inject, NgModule, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { bubbleData } from '../data/bubbleData';
import { PatientWeightHistory } from '../data/PatientWeightHistory';
import { isMobile } from '../Utils/userAgent';

@Component({
  selector: 'app-weight-history-dialog',
  templateUrl: './weight-history-dialog.component.html',
  styleUrls: ['./weight-history-dialog.component.scss'],
})
export class WeightHistoryDialogComponent implements OnInit {
  bubbleData: any[];
  view: any[] = [700, 400];
  //weightHistory;

  // options
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = false;
  showLegend: boolean = true;
  showXAxisLabel: boolean = true;
  yAxisLabel: string = 'משקל';
  showYAxisLabel: boolean = true;
  xAxisLabel: string = 'Years';
  maxRadius: number = 5;
  minRadius: number = 5;
  yScaleMin: number = 70;
  yScaleMax: number = 85;
  xScaleMin: any = 70;
  xScaleMax: any = 70;

  colorScheme = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA'],
  };

  constructor(
    public dialogRef: MatDialogRef<WeightHistoryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    Object.assign(this, { bubbleData });
  }

  onSelect(data): void {
    // console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivate(data): void {
    // console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    // console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }

  ngOnInit(): void {
    if (isMobile()) {
      this.view = [250, 250];
    }
    var weightHistory = [];

    for (var i = 0; i < this.data.weightHistory.length; i++) {
      if (!this.data.weightHistory[i].isGoal)
        weightHistory.push({ ...this.data.weightHistory[i] });
    }

    //var { weightHistory } = this.data;
    var len = weightHistory.length;
    //var len=3;
    var minWeight = Number.POSITIVE_INFINITY;
    var maxWeight = Number.NEGATIVE_INFINITY;

    var tmp;

    for (var i = len - 1; i >= 0; i--) {
      tmp = weightHistory[i].value;
      if (tmp < minWeight) minWeight = tmp;
      if (tmp > maxWeight) maxWeight = tmp;
    }
    this.yScaleMin = minWeight - 5;
    this.yScaleMax = maxWeight + 5;
    //console.log(maxWeight, minWeight);

    var startDate = new Date(
      (weightHistory[0] as PatientWeightHistory).dateUpdated
    );
    //this.xScaleMin=this.dateTickFormatting(startDate.getDate())
    this.xScaleMin = 0;
    var endDate = new Date(
      (weightHistory[len - 1] as PatientWeightHistory).dateUpdated
    );
    this.xScaleMax =
      Math.round((endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60)) +
      200;
    //this.xScaleMax=this.dateTickFormatting(endDate.getDate())

    for (var i = 0; i < len; i++) {
      var patientWeightHistory: PatientWeightHistory = weightHistory[
        i
      ] as PatientWeightHistory;
      var date = new Date(patientWeightHistory.dateUpdated);
      var normalizedDate =
        Math.round((date.getTime() - startDate.getTime()) / (1000 * 60 * 60)) +
        100;
      var normalizedWeight = patientWeightHistory.value;
      // weightHistory[i].y = weightHistory[i]['value'];
      // weightHistory[i].x = weightHistory[i]['dateUpdated'];
      // weightHistory[i].x = this.dateTickFormatting(date.getTime());
      var weightHistory1 = weightHistory[i];
      weightHistory[i].x = normalizedDate;
      weightHistory[i].y = normalizedWeight;
      weightHistory[i].name = patientWeightHistory.dateUpdated;
      weightHistory[i].r = 60;
    }
    // weightHistory.splice(3,10);
    this.bubbleData[0].name = 'היסטורית משקלים';
    this.bubbleData[0].series = weightHistory;
    //var x = this.weightHistory;
  }

  dateTickFormatting(val: any): string {
    if (val instanceof Date) {
      return (<Date>val).toLocaleString('de-DE');
    }
  }
}
