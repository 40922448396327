import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RegularUsersService } from 'src/app/Services/regular-users.service';
import { ProductService } from 'src/app/Services/product.service';

@Component({
  selector: 'app-loading-page',
  templateUrl: './loading-page.component.html',
  styleUrls: ['./loading-page.component.scss'],
})
export class LoadingPageComponent implements OnInit {
  constructor(
    private regularUsersService: RegularUsersService,
    private productService: ProductService,
    private router: Router
  ) {}

  ngOnInit(): void {}
}
