<div class="bar">
  <mat-progress-bar dir="rtl" [value]="persent"> </mat-progress-bar>
  <div
    class="bar__mask"
    [ngStyle]="{ right: persent + '%', width: 'calc(100% - ' + persent + '%)' }"
  ></div>
  <div class="bar__value" [ngStyle]="{ left: 'calc(100% - ' + persent + '%)' }">
    {{ persent | number: "1.0-0" }}%
  </div>
</div>
