<!--<h1 *ngIf="data.type == 'agent'">
  The Lead Agent Was Changed
  <h1 *ngIf="data.type == 'status'">
    The Lead Status Was Changed
  </h1>
  <h1 *ngIf="data.type == 'bulkAgents'">
    The Leads Agents Were Changed
  </h1>
  <h1 *ngIf="data.type == 'purchaseSuccess'">
    The Purchase is Completed Successfully
  </h1>
  <h1 *ngIf="data.type == 'purchaseRejected'">
    The Purchase Was Rejected
  </h1>
  <h1 *ngIf="data.type == 'fill_all_fields'">
    Please Fill All Fields
  </h1>
  <h1 *ngIf="data.type == 'clientAlreadyExists'">
  
    A Client with The Same Phone Already Exists
  </h1>-->
<div [ngSwitch]="data.type">
  <div *ngSwitchCase="DisapearingTextDialogEnum.infoChangedSMS">
    <div class="disapearing-box">
      <mat-icon class="disapearing-box__check">check_circle_outline</mat-icon>
      <div class="disapearing-box__text">סיסמתך שונתה בהצלחה!</div>
    </div>
  </div>
  <div *ngSwitchCase="DisapearingTextDialogEnum.infoChangedMail">
    <div class="disapearing-box">
      <mat-icon class="disapearing-box__check">check_circle_outline</mat-icon>
      <div class="disapearing-box__text">כתובת הדוא"ל שונתה בהצלחה!</div>
    </div>
  </div>
  <div *ngSwitchCase="DisapearingTextDialogEnum.infoChangedPhone">
    <div class="disapearing-box">
      <mat-icon class="disapearing-box__check">check_circle_outline</mat-icon>
      <div class="disapearing-box__text">מספר הטלפון שונה בהצלחה!</div>
    </div>
  </div>
  <div *ngSwitchDefault><h1 [innerHtml]="getText()"></h1></div>
</div>
