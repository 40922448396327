<!-- [class.height-full]="!(guidedQuestionMode$ | async)"-->
<div (click)="closeEmotion()" class="chat">
  <perfect-scrollbar
    #chatScroller
    class="chat-scroll pb-100"
    [ngStyle]="{ height: !isMobile() ? height : '' }"
    [config]="config"
  >
    <!-- [class.pb-50]="!(guidedQuestionMode$ | async) && isChatActive()"
    [class.pb-100]="isMobile() && ios()" -->
    <mat-list #chatList>
      <ng-container *ngFor="let dates of chatRoomDates">
        <div class="dateDivider">
          {{ dates[0].createdDate | date: "dd/MM/yyyy" }}
        </div>
        <ng-container *ngFor="let chat of dates">
          <div class="chat__wrapper">
            <img
              *ngIf="chat.sentFrom == 1"
              src="../../../assets/avatar-{{
                (user$ | async).avatarId > 0 ? (user$ | async).avatarId : 'bot'
              }}.svg"
              alt="avatar"
            />
            <mat-list-item
              #chatItem
              class="chat__item"
              [class.right-bubble]="chat.sentFrom == 1"
              [class.left-bubble]="chat.sentFrom == 2"
            >
              <div
                style="
                  white-space: pre-wrap;
                  word-break: break-word;
                  direction: rtl;
                  margin-left: 20px;
                "
                [innerHtml]="chat.massage"
              ></div>
              <div class="chat-meta">
                <span
                  class="chat-meta__hour"
                  [class.bot-color]="chat.sentFrom == 2"
                  >{{ chat.createdDate | date: "HH:mm" }}</span
                >
                <div class="seen_noti" [innerHtml]="getIsSeenTxt(chat)"></div>
              </div>
            </mat-list-item>
            <ng-container *ngIf="chat.sentFrom == 2">
              <img
                *ngIf="guidedQuestionMode$ | async"
                src="../../../assets/avatar-bot.svg"
                alt="avatar"
              />
              <img
                *ngIf="!(guidedQuestionMode$ | async)"
                src="../../../assets/avatar-{{ chat.avatarId }}.svg"
                alt="avatar"
              />
            </ng-container>
          </div>
        </ng-container>
      </ng-container>

      <ng-container
        *ngIf="(guidedQuestionMode$ | async) && !loadGQ && !loadGQApi"
      >
        <div class="guided-question">
          <div class="guided-question__title">בחר את נושא הפנייה</div>
          <div class="guided-question__q" *ngIf="guidedQuestion?.answers">
            <ng-container *ngFor="let answer of guidedQuestion.answers">
              <button
                mat-button
                (click)="onAnswerSelect(answer)"
                class="guidedAnswerBtn"
                [disabled]="loadGQ"
              >
                {{ answer.answerTxt }}
              </button>
              <br />
            </ng-container>
          </div>
          <div *ngIf="chatAnimation">
            <div id="wave">
              <span class="dot"></span>
              <span class="dot"></span>
              <span class="dot"></span>
            </div>
          </div>
        </div>
      </ng-container>

      <div class="chat-review" *ngIf="!isChatActive()">
        <ng-container *ngIf="activeRequest.reviewScore == -1">
          <div class="chat-review__title">דרג את שיחתך</div>
          <div class="chat-review__emojies">
            <button
              mat-icon-button
              *ngFor="let e of [].constructor(5); let i = index"
              (click)="onSelectRate(i + 1)"
            >
              <img
                src="../../../assets/emoji-rate-{{ i + 1 }}.png"
                [class.active]="chatRating == i + 1"
              />
            </button>
          </div>
          <div class="chat-review__text">
            <mat-form-field
              appearance="fill"
              style="width: 100%; text-align: right"
            >
              <input #ratingText matInput placeholder="ספר לנו עוד.." />
              <button
                mat-icon-button
                matSuffix
                [disabled]="chatRating == 0"
                (click)="onSendReview(ratingText.value)"
              >
                <mat-icon
                  style="
                    transform: rotate(180deg);
                    margin-top: -2px;
                    color: #00babf;
                  "
                  [ngStyle]="{ color: chatRating > 0 ? '#00babf' : '#bfbfbf' }"
                  >send</mat-icon
                >
              </button>
            </mat-form-field>
          </div>
        </ng-container>
        <ng-container *ngIf="activeRequest.reviewScore > -1">
          <ng-container *ngIf="isGoodRating()">
            <div class="chat-review__title" style="padding-bottom: 1rem">
              תודה ששיתפת, <br />
              אנחנו כאן בשבילך!
            </div>
            <div>
              <div class="text-center" style="padding-bottom: 1rem">
                <img src="../../../assets/good-review.png" alt="" />
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="!isGoodRating()">
            <div class="chat-review__title" style="padding-bottom: 1rem">
              מצטערים שהייתה לך <br />
              חוויה לא טובה! <br />
              פנייתך התקבלה ותטופל בהתאם!
            </div>
            <div>
              <div class="text-center" style="padding-bottom: 1rem">
                <img src="../../../assets/bad-review.png" alt="" />
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </mat-list>
  </perfect-scrollbar>

  <div
    class="chat__input"
    *ngIf="!(guidedQuestionMode$ | async) && isChatActive()"
  >
    <emoji-mart
      *ngIf="emojis"
      title=""
      (click)="preventCloseEmoticons($event)"
      (emojiClick)="addEmoji($event)"
    >
    </emoji-mart>
    <input
      hidden
      (change)="onFileSelected()"
      #fileInput
      type="file"
      id="file"
    />
    <mat-form-field
      (click)="preventCloseEmoticons($event)"
      dir="rtl"
      style="text-align: right; width: 100%"
      appearance="fill"
    >
      <mat-label>{{
        outOfService
          ? "הצ'אט לא פעיל כרגע, אנא חזור בשעות הפעילות"
          : "הקלד כאן.."
      }}</mat-label>
      <textarea
        #chatInput
        (keydown.enter)="sendMessage($event)"
        (keypress.enter)="$event.preventDefault(); $event.stopPropagation()"
        [(ngModel)]="chatMessage"
        matTextareaAutosize
        matAutosizeMinRows="1"
        matAutosizeMaxRows="3"
        style="resize: none; box-sizing: content-box"
        matInput
        [disabled]="outOfService"
        [class.fs-ios]="isMobile() && ios()"
      ></textarea>
      <div style="display: inline-flex" matSuffix>
        <!-- <button
          type="button"
          mat-icon-button
          matSuffix
          (click)="openFileInput(fileInput)"
        >
          <mat-icon style="color: #bfbfbf; transform: rotate(40deg)">
            attach_file</mat-icon
          >
        </button> -->

        <button
          [disabled]="outOfService"
          mat-icon-button
          matSuffix
          (click)="openEmoticons($event)"
        >
          <mat-icon style="color: #bfbfbf">tag_faces</mat-icon>
        </button>

        <button
          (click)="sendMessage($event)"
          [disabled]="outOfService"
          mat-icon-button
          matSuffix
        >
          <mat-icon
            style="transform: rotate(180deg); margin-top: -2px"
            [ngStyle]="{ color: !outOfService ? '#00babf' : '#bfbfbf' }"
            >send</mat-icon
          >
        </button>
      </div>
    </mat-form-field>
  </div>
  <!-- <div
    id="showClosedRequestMsg"
    style="
      bottom: 5px;
      position: absolute;
      border: 1px solid black;
      width: 100%;
      padding: 5px;
    "
    *ngIf="!isChatActive()"
  >
    הפנייה סגורה.יש לפתוח פנייה חדשה
  </div> -->
</div>
