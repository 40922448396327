import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MeetingScheduleService {
  activeHours = [
    {
      date: '2022/01/02',
      hours: ['09:00', '11:30', '12:00', '13:00', '14:00', '15:00'],
    },
    { date: '2022/01/03', hours: ['13:00', '14:30', '15:00'] },
    { date: '2022/01/04', hours: ['16:00', '17:30', '18:00'] },
    { date: '2022/01/11', hours: ['16:00', '17:30', '18:00'] },
  ];
  constructor(private http: HttpClient) {}

  getActiveHours(date: string) {
    /* return this.http.get<any>(
      `${environment.baseUrl}`
    ); */
    for (let i = 0; i < this.activeHours.length; i++) {
      const element = this.activeHours[i];
      if (element.date == date) {
        return element.hours;
      }
    }
  }
}
