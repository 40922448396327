import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/Fragments/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-upgrade-preview-dialog',
  templateUrl: './upgrade-preview-dialog.component.html',
  styleUrls: ['./upgrade-preview-dialog.component.scss'],
})
export class UpgradePreviewDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {}
  onBtnClick() {
    this.dialogRef.close({ event: 'close', data: { answer: 'yes' } });
  }
}
