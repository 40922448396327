<div class="container">
  <div style="margin-right: auto" (click)="goBack()" class="backBtn">
    < חזרה
  </div>
  <div *ngIf="bloodTestTypes && bloodTestResults && bloodTestTypes.length > 0">
    {{ bloodTestTypes[inx].name }}
    <br />
    <mat-form-field appearance="fill">
      <mat-label></mat-label>
      <input
        matInput
        class="input_no_arrows"
        type="number"
        [(ngModel)]="bloodTestResults[inx].result"
      />
      <span matSuffix *ngIf="!bloodTestTypes[inx].altUnits">
        {{ bloodTestTypes[inx].units }}
      </span>
    </mat-form-field>
    <p>Value Should Be between {{ getMinVal() }} and {{ getMaxVal() }}</p>
    <mat-button-toggle-group
      *ngIf="bloodTestTypes[inx].altUnits"
      name="fontStyle"
      aria-label="Font Style"
      value="units"
      (change)="onValChange($event.value)"
    >
      <mat-button-toggle value="alt">{{
        bloodTestTypes[inx].altUnits
      }}</mat-button-toggle>
      <mat-button-toggle value="units">{{
        bloodTestTypes[inx].units
      }}</mat-button-toggle>
    </mat-button-toggle-group>

    <!--
    [bloodTestType]="bloodTestTypes[inx]"
  [bloodTestResult]="bloodTestResults[inx]"
-->
    <br />
    <button class="bloodTestBtn" (click)="goToPrev()" *ngIf="inx > 0">
      הקודם
    </button>
    <button
      class="bloodTestBtn"
      (click)="goToNext()"
      *ngIf="inx < bloodTestTypes.length - 1"
    >
      הבא
    </button>
    <button
      class="bloodTestBtn"
      (click)="finishReport()"
      *ngIf="inx == bloodTestTypes.length - 1"
    >
      סיים
    </button>
  </div>
</div>
