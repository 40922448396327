import { DealBundle } from './DealBundle';
import { Product } from './Product';
import { Status } from './Status';

export class ProductForDealBundle {
  productForDealBundleId: number;
  productId: number;
  product: Product;
  dealBundleId: number;
  dealBundle: DealBundle;
  operationId: number | null;
  status: Status;
}
