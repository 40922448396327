import { Component, OnInit } from '@angular/core';
import { android, iOS } from 'src/app/Utils/userAgent';

@Component({
  selector: 'app-app-redirect',
  templateUrl: './app-redirect.component.html',
  styleUrls: ['./app-redirect.component.scss'],
})
export class AppRedirectComponent implements OnInit {
  isAndroid = this.android();
  isIos = this.ios();

  constructor() {}

  ngOnInit(): void {
    if (this.isAndroid) {
      // const url =
      //   'intent://mygenes.co.il/#Intent;scheme=https;package=com.mygenes.myapp;end';

      window.location.replace(
        'https://play.google.com/store/apps/details?id=com.mygenes.myapp'
      );
    } else if (this.isIos) {
      window.location.replace(
        'https://apps.apple.com/us/app/mygenes/id6443790685'
      );
    } else {
      window.location.replace('https://mygenes.co.il');
    }
  }

  android() {
    return android();
  }
  ios() {
    return iOS();
  }
}
