import { Action, createAction, props } from '@ngrx/store';
import { DnaReport } from 'src/app/data/DnaReport';
import { RegularUser } from 'src/app/data/RegularUser';
import { TubeTestCategoryResult } from 'src/app/data/TubeTestCategoryResult';
import { TubeTestResult } from 'src/app/data/TubeTestResult';
import { TubeTestSubCategoryResult } from 'src/app/data/TubeTestSubCategoryResult';

export const loadDnaReports = createAction('[DnaReport] Load DnaReports');

export enum DnaReportActionTypes {
  LoadDnaReport = '[Dna Report] Load Dna Report',
  LoadDnaReportComplete = '[Dna Report] Load Dna Report Complete',
  DnaReportError = '[Dna Report] Dna Report Error',
  LoadCategories = '[Dna Report] Load Categories',
  LoadCategoriesComplete = '[Dna Report] Load Categories Complete',
  CategoriesError = '[Dna Report] Categories Error',
}

export class DnaReportAction implements Action {
  type: string;
  payload: {
    dnaReport: DnaReport;
    categories: TubeTestCategoryResult[];
    error: string;
    barcode: number;
  };
}

export class LoadDnaReport implements Action {
  readonly type = DnaReportActionTypes.LoadDnaReport;

  constructor(readonly payload: { patientId: number }) {}
}

export class LoadDnaReportComplete implements Action {
  readonly type = DnaReportActionTypes.LoadDnaReportComplete;
  constructor(
    readonly payload: {
      dnaReport: DnaReport;
    }
  ) {}
}
export class LoadDnaReportError implements Action {
  readonly type = DnaReportActionTypes.DnaReportError;

  constructor(readonly payload: { error: string }) {}
}

export class LoadCategories implements Action {
  readonly type = DnaReportActionTypes.LoadCategories;

  constructor(readonly payload: { patientId: number }) {}
}

export class LoadCategoriesComplete implements Action {
  readonly type = DnaReportActionTypes.LoadCategoriesComplete;
  constructor(
    readonly payload: {
      categories: TubeTestCategoryResult[];
    }
  ) {}
}
export class LoadCategoriesError implements Action {
  readonly type = DnaReportActionTypes.CategoriesError;

  constructor(readonly payload: { error: string }) {}
}
