import { Action, createAction, props } from '@ngrx/store';
import { LifeStyleReport } from 'src/app/data/LifeStyleReport';

export enum LifeStyleActionTypes {
  LoadLifeStyle = '[Lifestyle] Load Lifestyles',
  UpdateLifeStyle = '[Lifestyle] Update Lifestyles',
  LoadLifeStyleComplete = '[Lifestyle] Load Lifestyle Complete',
  LifeStyleExist = '[Lifestyle] LifeStyle Exist',
}

export class LifeStyleAction implements Action {
  type: string;

  lifeStyle: LifeStyleReport;
  isComplete: boolean;
  patientId: number;
  error: string;
}
export const LoadLifeStyle = createAction(
  LifeStyleActionTypes.LoadLifeStyle,
  props<{ patientId: number }>()
);
export const UpdateLifeStyle = createAction(
  LifeStyleActionTypes.UpdateLifeStyle,
  props<{ lifeStyle: LifeStyleReport }>()
);
export const LoadLifeStyleComplete = createAction(
  LifeStyleActionTypes.LoadLifeStyleComplete,
  props<{ lifeStyle: LifeStyleReport }>()
);
/*
export const LifeStyleExist = createAction(
  LifeStyleActionTypes.LoadLifeStyle,
  props<{ patientId: number }>()
);
*/
/*
export class LoadLifeStyle implements Action {
  readonly type = LifeStyleActionTypes.LoadLifeStyle;

  constructor(readonly payload: { patientId: number }) {}
}
export class UpdateLifeStyle implements Action {
  readonly type = LifeStyleActionTypes.UpdateLifeStyle;

  constructor(readonly payload: { lifeStyle: LifeStyleReport }) {}
}
export class LoadLifeStyleComplete implements Action {
  readonly type = LifeStyleActionTypes.LoadLifeStyleComplete;

  constructor(readonly payload: { lifeStyle: LifeStyleReport }) {}
}
export class LifeStyleExist implements Action {
  readonly type = LifeStyleActionTypes.LifeStyleExist;
}
*/
/*
export type LifeStyleActions =
  | LoadLifeStyle
  | UpdateLifeStyle
  | LoadLifeStyleComplete
  |*/
