import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { VideoForSite } from 'src/app/data/VideoForSite';
import { CloudflareStreamComponent } from '@cloudflare/stream-angular';
import { Video } from 'src/app/data/Video';
import { FileUploadType } from 'src/app/Enum/StatusesEnum';
import { environment } from 'src/environments/environment';
import { VideosService } from 'src/app/Services/videos.service';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
})
export class VideoPlayerComponent implements OnInit, OnDestroy {
  @ViewChild('videoPlayer', { static: false })
  videoPlayer: CloudflareStreamComponent;
  @Input() video: Video;
  @Input() autoplay: boolean;
  @Input() productId: number;

  @Output('videoEnded') videoEnded = new EventEmitter<Video>();

  constructor(private videosService: VideosService) {}
  ngOnDestroy(): void {
    this.video = null;
  }

  onVideoEnded() {
    this.videoEnded.emit(this.video);
    const currentTime = this.videoPlayer.streamEl.nativeElement.currentTime;
    this.videosService
      .productFinishWatchVideo(this.productId, this.video.videoId, currentTime)
      .subscribe((data) => {});
  }
  replaceWebPath(image: string) {
    return image.replace('{picdomain}', environment.picsDomain);
  }
  getPoster() {
    if (!this.video) return;
    const poster = this.video.files?.find(
      (x) => x.fileType == FileUploadType.VideoPoster
    );
    if (poster) {
      const imagePath = `${poster.webPath}/${poster.fileName}`;
      return this.replaceWebPath(imagePath);
    }
  }
  getVideoFile() {
    if (!this.video) return;
    const video = this.video.files?.find(
      (x) => x.fileType == FileUploadType.VodVideo
    );
    if (video) return video.fileName;
  }
  playVideo() {
    //this.videoPlayer.streamEl.nativeElement.play();
  }
  onVideoPause() {
    const currentTime = this.videoPlayer.streamEl.nativeElement.currentTime;

    if (currentTime == this.videoPlayer.streamEl.nativeElement.duration) {
      this.onVideoEnded();
      console.log('onVideoPause ended');
    } else {
      console.log('onVideoPause');
      this.video.progress = currentTime;
      this.videosService
        .productUpdateProgressVideo(
          this.productId,
          this.video.videoId,
          currentTime
        )
        .subscribe((data) => {});
    }
  }

  ngOnInit(): void {}
}
