import {
  AfterContentChecked,
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { RegularUser } from 'src/app/data/RegularUser';
import { RequestForTableMin } from 'src/app/data/RequestForTableMin';
import { UnSelectServiceRequest } from 'src/app/Redux/Actions/service-request.actions';
import {
  selectActiveRequest,
  selectActiveRequestId,
  selectIsLoading,
} from 'src/app/Redux/selectors/service-request.selectors';
import { selectUser } from 'src/app/Redux/selectors/user.selectors';
import { ChatHoursService } from 'src/app/Services/chat-hours.service';
import { DesignService } from 'src/app/Services/design.service';

@Component({
  selector: 'app-chat-wrapper',
  templateUrl: './chat-wrapper.component.html',
  styleUrls: ['./chat-wrapper.component.scss'],
})
export class ChatWrapperComponent
  implements OnInit, AfterContentChecked, AfterViewInit
{
  @ViewChild('chatWrapper', { read: ElementRef, static: false })
  chatWrapper: ElementRef;
  chatWrapperHeight: number;
  @Input() isOpen: boolean = true;
  @Input() openChatId: any;
  @Output('closeChatWrapper')
  closeChatWrapper: EventEmitter<boolean> = new EventEmitter<boolean>();

  selectedActiveRequestId$: Observable<number> = this.store.select(
    selectActiveRequestId
  );
  user$: Observable<RegularUser> = this.store.select(selectUser);
  activeRequestId: number;
  isLoading$: Observable<boolean> = this.store.select(selectIsLoading);

  isLoading: boolean;
  displayName: string = 'My Genes';
  user: RegularUser;
  heightLoaded: boolean;
  fromScanBarcode: string;

  //openChatId: any;

  constructor(
    private store: Store,
    private designService: DesignService,
    private route: ActivatedRoute
  ) {
    this.fromScanBarcode =
      this.route.snapshot.queryParamMap.get('fromScanBarcode');
  }

  ngAfterContentChecked() {
    this.isLoading$.pipe(take(1)).subscribe((data) => {
      this.isLoading = data;
    });
    //this.setChatWrapperHeight();
  }
  ngAfterViewInit() {
    this.setChatWrapperHeight();
  }

  setChatWrapperHeight() {
    this.chatWrapperHeight = this.chatWrapper.nativeElement.offsetHeight;
    this.designService.getChatHeight(this.chatWrapperHeight);
    //console.log('setChatWrapperHeight: ', this.chatWrapperHeight);
  }

  ngOnInit(): void {
    window.addEventListener('resize', () => this.setChatWrapperHeight());
    if (this.chatWrapper) {
      this.setChatWrapperHeight();
    }
    this.designService.getChatHeight(this.chatWrapperHeight);
    /*  this.selectedActiveRequestId$.subscribe((data) => {
      if (data) this.activeRequestId = data.serviceRequestId;
    }) */

    this.user$.subscribe((data) => {
      if (data) this.user = data;
    });
  }

  getfullName(): string {
    var firstName = '';
    var lastName = '';
    if (this.user.firstName) firstName = this.user.firstName;
    if (this.user.lastName) lastName = this.user.lastName;
    if (lastName === '') return firstName;
    if (firstName === '') return lastName;
    return `${firstName} ${lastName}`;
  }

  getDisplayName() {
    if (this.getfullName() !== this.displayName) {
      return this.displayName;
    } else {
      return 'My Genes';
    }
  }
  setDisplayName(e) {
    if (e == '') {
      this.displayName = 'My Genes';
    } else {
      this.displayName = e;
    }
  }

  closeChat() {
    this.closeChatWrapper.emit(true);
  }

  unSelectServiceRequest() {
    this.store.dispatch(UnSelectServiceRequest());
    this.displayName = 'My Genes';
  }
  back(event) {
    if (event) {
      this.unSelectServiceRequest();
    }
  }
}
