import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Product } from 'src/app/data/Product';
import {
  FileUploadTypeEnum,
  ProductStatusEnum,
} from 'src/app/Enum/StatusesEnum';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';
import { isPremium } from 'src/app/Utils/userHelper';

@Component({
  selector: 'app-navbar-mobile',
  templateUrl: './navbar-mobile.component.html',
  styleUrls: ['./navbar-mobile.component.scss'],
})
export class NavbarMobileComponent implements OnInit {
  showMore: boolean;
  showAdd: boolean;
  @Input() product: Product;
  constructor(
    private popupHandlerService: PopupHandlerService,
    private router: Router
  ) {
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.showAdd = false;
      }
    });
  }

  ngOnInit(): void {}

  toggleShowMore() {
    this.showMore = !this.showMore;
  }
  toggleShowAdd() {
    this.showAdd = !this.showAdd;
  }

  dnaTestCompleted() {
    if (this.product && this.product.statusId == ProductStatusEnum.Completed) {
      return false;
    }
    return true;
  }

  isPremium() {
    return isPremium(this.product.productTypeId);
  }

  isDietMenusExist() {
    if (!this.product) return false;
    if (this.product.fileUploads && this.product.fileUploads.length > 0) {
      if (
        this.product.fileUploads.filter(
          (x) => x.fileUploadTypeId == FileUploadTypeEnum.PatientNutritionReport
        ).length > 0
      )
        return true;
    }

    if (this.product.menus && this.product.menus.length > 0) {
      for (var i = 0; i < this.product.menus.length; i++) {
        var menu = this.product.menus[i];
        if (menu.isComplete) {
          return true;
        }
      }
    }
  }

  goToRecomendations() {
    if (!this.product.bloodTestForBulletsFilled) {
      this.popupHandlerService.openHasNotBloodTest();
    } else {
      this.router.navigate(['/report-recomendations']);
    }
  }
}
