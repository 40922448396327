<div class="flex-container">
  <mat-card class="flex-item">
    <mat-card-title class="flex-item__title"
      >הוראות לביצוע הבדיקה</mat-card-title
    >
    <div class="guideGrid">
      <div class="guidGrid__item">
        <div><img src="../../../assets/num1.svg" alt="" /></div>
        <div><img src="../../../assets/guide1.png" alt="" /></div>
      </div>
      <div class="guidGrid__item">
        <div><img src="../../../assets/num2.svg" alt="" /></div>
        <div><img src="../../../assets/guide2.png" alt="" /></div>
      </div>
      <div class="guidGrid__item">
        <div><img src="../../../assets/num3.svg" alt="" /></div>
        <div><img src="../../../assets/guide3.png" alt="" /></div>
      </div>
      <div class="guidGrid__item">
        <div><img src="../../../assets/num4.svg" alt="" /></div>
        <div><img src="../../../assets/guide4.png" alt="" /></div>
      </div>
      <div class="guidGrid__item">
        <div><img src="../../../assets/num5.svg" alt="" /></div>
        <div><img src="../../../assets/guide5.png" alt="" /></div>
      </div>
    </div>
    <div class="guideVideo">
      <div class="guideVideo__title">יש גם וידאו</div>
      <div class="guideVideo__iframe">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/9XBuaXPYeeU"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  </mat-card>
</div>
