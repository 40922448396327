import { AppState } from '../reducers';

export const selectProduct = (state: AppState) => state.product.product;

export const selectProductId = (state: AppState) =>
  state.product.product?.productId;

export const selectPatient = (state: AppState) =>
  state.product.product && state.product.product.patient;
export const selectPatientId = (state: AppState) =>
  state.user.user && state.user.user.patient.patientId;
export const selectProductIsLoading = (state: AppState) =>
  state.product.isLoading;
export const selectProductError = (state: AppState) => state.product.error;
export const selectUpdateWeightSuccess = (state: AppState) =>
  state.product.updateWeightSuccess;
export const selectWeightHistory = (state: AppState) =>
  state.product.product.patient.patientWeightHistories;
export const selectFillDetails = (state: AppState) =>
  state.product.product.patient.filledDetails;
export const lifeStyleReportComplete = (state: AppState) =>
  state.product.product && state.product.product.lifeStyleReportComplete;

export const selectHasFieldBloodTest = (state: AppState) =>
  state.product.product.bloodTestForBulletsFilled;

/*
const selectIsLoadingFromProduct = (product: ProductState) => product.isLoading;
export const  selectProductIsLoading = createSelector(
    selectProduct,
    selectIsLoadingFromProduct,

) */
