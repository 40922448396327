import { Location } from '@angular/common';
import {
  Component,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, Scroll } from '@angular/router';
import {
  AlertController,
  IonRouterOutlet,
  NavController,
  Platform,
} from '@ionic/angular';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import {
  FileUploadTypeEnum,
  ProductStatusEnum,
  ProductTypeEnum,
} from 'src/app/Enum/StatusesEnum';
import { environment } from 'src/environments/environment';
import { Product } from './data/Product';
import { RegularUser } from './data/RegularUser';
import { RequestForTableMin } from './data/RequestForTableMin';
import {
  LoginUserFromStorage,
  LogoutUser,
} from './Redux/Actions/regular-user.actions';
import { AppState } from './Redux/reducers';
import { selectProduct } from './Redux/selectors/product.selectors';
import { selectServiceRequests } from './Redux/selectors/service-request.selectors';
import { selectUser, selectUserError } from './Redux/selectors/user.selectors';
import { FirebaseAnonimousAuthService } from './Services/firebase-anonimous-auth.service';
import { FirebaseService } from './Services/firebase.service';
import { PopupHandlerService } from './Services/popup-handler.service';
import { RegularUsersService } from './Services/regular-users.service';
import { android, iOS, isMobileAndTable } from './Utils/userAgent';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChildren(IonRouterOutlet) routerOutlets: QueryList<IonRouterOutlet>;
  lastTimeBackPress = 0;
  timePeriodToExit = 2000;
  isChatOpen: boolean = false;
  offSiteUrls = ['/callreview', 'lifestyle-app'];
  title = 'mygenesSite1';
  isOutDated = false;
  mobileLinks = [
    { name: 'personal-details', disable: false },
    { name: 'service-requests', disable: true },
    { name: 'eating-diary', disable: true },
    { name: 'diet-menu', disable: true },
    { name: 'dna-report', disable: false },
  ];
  regularUser: RegularUser;
  product: Product;
  patientId: number;
  ProductStatusEnum = ProductStatusEnum;
  fakeStatusId: any = ProductStatusEnum.ArrivedToCostumer;
  personalDetailsFlag: boolean = false;
  afterSpitting: boolean = false;
  status: number;
  indicatorMsg: number;
  showChatFromUrl: boolean = false;
  serviceRequests$: Observable<RequestForTableMin[]> = this.store.select(
    selectServiceRequests
  );
  product$: Observable<Product> = this.store.select(selectProduct);
  user$: Observable<RegularUser> = this.store.select(selectUser);
  userError$: Observable<RegularUser> = this.store.select(selectUserError);
  serviceRequests: RequestForTableMin[];
  productStatusIdsToHome: any[] = [
    ProductStatusEnum.ArrivedToLab,
    ProductStatusEnum.Completed,
    ProductStatusEnum.LaboratoryProcess,
    ProductStatusEnum.ArrivedToOffice,
    ProductStatusEnum.RetestOnLab,
  ];
  productStatusIdsToScanBarcode: any[] = [
    ProductStatusEnum.SentToCustomer,
    ProductStatusEnum.EnteredBarcode,
    ProductStatusEnum.ResendToPatient,
    ProductStatusEnum.ArrivedToCostumer,
    ProductStatusEnum.WaitingToPickUp,
    ProductStatusEnum.SendToCustomer,
  ];
  urlsToExitApp: string[] = ['/home', '/login', '/register'];
  watingForProduct: boolean = false;
  lastUrl: string;
  private subscriptions: { [key: string]: any } = {};
  exampleReport: boolean;
  openChatId: any;
  shiwPdfBullets: boolean;
  showPdfBullets: boolean;
  watingForNavEnd: boolean;
  isHome: boolean;
  innerPage: boolean;
  showSideNav: boolean;
  allowRoutsToMobile = [
    '/login',
    //'/register',
    '/forgot-password',
    '/personal-details',
    '/scan-barcode',
    '/callreview',
    '/lifestyle-app',
    '/app-redirect',
  ];
  isExapleUser: boolean;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private regularUsersService: RegularUsersService,
    private location1: Location,
    private anonAuthService: FirebaseAnonimousAuthService,
    private firebaseService: FirebaseService,
    private store: Store<AppState>,
    private platform: Platform,
    private _location: Location,
    private alertController: AlertController,
    public navCtrl: NavController,
    translate: TranslateService,
    private popupHandlerService: PopupHandlerService
  ) {
    translate.setDefaultLang('he');
    if (!environment.isApp)
      setInterval(
        () => {
          this.checkVersions();
        },
        60 * 60 * 1000
        // 10 * 1000
      );
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.lastUrl = val.url.split('?')[0];

        if (this.lastUrl === '/') {
          this.router.navigate(['/home']);
        }

        if (!this.watingForProduct) {
          if (this.product) {
            this.redirectByProductStatus();
          } else {
            this.navigateWithoutProduct();
          }
        } else {
          if (
            this.watingForNavEnd &&
            this.urlNotInAuth(this.lastUrl) &&
            this.regularUsersService.getUserStorage()
          ) {
            //console.log('from router.events');
            this.store.dispatch(
              LoginUserFromStorage({
                userName: this.regularUsersService.getUserStorage().userName,
                password: this.regularUsersService.getUserStorage().password,
              })
            );
          }
        }
        this.watingForNavEnd = false;
      } else {
        if (!(val instanceof Scroll)) {
          this.watingForNavEnd = true;
        }
      }
    });

    this.product$.subscribe((res) => {
      this.product = res;
      if (this.watingForNavEnd) return;
      if (this.product) {
        this.showSideNav = this.showSideNavFn();
        router.events.subscribe((val) => {
          if (val instanceof NavigationEnd) {
            this.showSideNav = this.showSideNavFn();
          }
        });
        this.watingForProduct = false;
        this.redirectByProductStatus(true);

        this.serviceRequests$.subscribe((res) => {
          this.serviceRequests = res;

          /* this.anonAuthService.isFireLogged$.subscribe((data) => {
            if (!data) return;
            this.firebaseService.getChatIndicator(this.product?.productId);

            this.firebaseService.indicatorMsg$.subscribe((indicator) => {
              if (!indicator) return;
              this.indicatorMsg = 0;

              indicator.map((item, inx) => {
                this.serviceRequests.map((sr) => {
                  if (sr.serviceRequestId == inx) {
                    this.indicatorMsg += item.countSite;
                  }
                });
              });
            });
          }); */
        });
      } else {
        //this.navigateWithoutProduct();
        /*  
        this.redirectByProductStatus(true); */
      }

      /* if (!res) {
        //this.router.navigate(['login']);
      } else if (res) {
        this.product = res;
        this.redirectByProductStatus(this.lastUrl, this.product, true);
        this.watingForProduct = false;
      } */
    });
  }
  isInnerPage() {
    if (this.lastUrl !== '/home') {
      return true;
    }
  }
  /*   ngOnChanges(changes: SimpleChanges): void {
    const { currentValue } = changes.status;
    if(this.showIndicatorBar()){
      this.setAnimation(currentValue);
    }
      
  } */

  isRegisterRoute() {
    if (this.lastUrl === '/register') {
      return true;
    }
  }

  urlNotInAuth(url: string) {
    switch (url) {
      case '/login':
      case '/register':
      case '/app-redirect':
      case '/forgot-password':
      case '/callreview':
      case '/lifestyle-app':
      case '/example-report':
      case '/example-report-inner':
      case '/example-diet-menu':
      case '/example-diet-menu-meals':
        return true;
      default:
        return false;
    }
  }

  navigateWithoutProduct() {
    if (this.urlNotInAuth(this.lastUrl)) {
      if (this.regularUsersService.getUserStorage() && !this.watingForProduct) {
        this.watingForProduct = true;
      }
    } else {
      this.router.navigate(['login']);
    }
    /* switch (this.lastUrl) {
      case '/login':
      case '/register':
      case '/forgot-password':
        if (
          this.regularUsersService.currentUserValue &&
          !this.watingForProduct
        ) {
          this.watingForProduct = true;
        }
        break;

      default:
        this.router.navigate(['login']);
        break;
    } */
  }

  getReturnUrl(url: string) {
    if (url.indexOf('returnUrl') !== -1) {
      var decodeUrl = decodeURIComponent(url);
      return decodeUrl.split('returnUrl=')[1];
    } else {
      return url; //.split('?')[0];
    }
  }

  backButtonEvent() {
    this.platform.backButton.subscribeWithPriority(0, () => {
      this.routerOutlets.forEach(async (outlet: IonRouterOutlet) => {
        if (this.router.url != '/home') {
          // await this.router.navigate(['/']);
          // console.log('back', this.router.url);

          await this._location.back();
        } else if (this.router.url === '/home') {
          //console.log('back', this.router.url);
          if (
            new Date().getTime() - this.lastTimeBackPress >=
            this.timePeriodToExit
          ) {
            this.lastTimeBackPress = new Date().getTime();
            this.presentAlertConfirm();
          } else {
            navigator['app'].exitApp();
          }
        }
      });
    });
  }

  async presentAlertConfirm() {
    const alert = await this.alertController.create({
      // header: 'Confirm!',
      message: 'Are you sure you want to exit the app?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {},
        },
        {
          text: 'Close App',
          handler: () => {
            navigator['app'].exitApp();
          },
        },
      ],
    });

    await alert.present();
  }

  ngOnInit(): void {
    /* this.backgroundMode.enable();
    this.platform.backButton.subscribeWithPriority(9999, () => {
      console.log('backButton');

      this.appMinimize.minimize();
    });
    this.platform.pause.subscribe(() => {
      alert('pause');
      this.appMinimize.minimize();
    }); */

    //var a = this.location1.path;
    //this._location.subscribe()

    this.regularUsersService.currentUser.subscribe((data) => {
      if (data && !this.product) {
        // if (!this.watingForProduct) {
        this.watingForProduct = true;

        if (!this.lastUrl) {
          this.watingForNavEnd = true;
          /* this.store.dispatch(
              LoginUserFromStorage({
                userName: data.userName,
                password: data.password,
              })
            ); */
        }
        //   }

        return;
      }

      this.watingForProduct = false;
    });

    this.route.queryParams.subscribe((params) => {
      if (params['showchat']) {
        this.showChatFromUrl = true;
      }
      if (params['openChatId']) {
        this.openChatId = params['openChatId'];
        setTimeout(() => {
          this.isChatOpen = true;
        }, 1000);
      }
    });

    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        //console.log(val.url);
      }
    });

    /* if (this.router.url === '/example-report') {
      this.exampleReport = true;
    } */
    /* this.subscriptions.routerSelector = this.store
      .pipe(select(getCurrentRouteState))
      .subscribe((data: any) => {
        if (data) {
          console.log(data);
          var _data = data;
        }
      }); */

    this.user$.subscribe((data) => {
      if (data) {
        this.regularUser = data;
        this.isExapleUser =
          this.regularUser.regularUserId == 10846 ? true : false;
        /* this.globalParamsService.getAll().subscribe((data) => {
          this.showPdfBullets =
            data
              ?.find((x) => x.globalParamsId == GlobalParams.GeneratePdfBullets)
              ?.value.toLocaleLowerCase() === 'true'
              ? true
              : false;
        }); */
      }
    });

    var url = this.location1.path().split('?')[0].toLowerCase();
  }

  navBack() {
    this._location.back();
  }

  /* serviceTimeV2() {
    var tenMinutes = 1000 * 60;
    setTimeout(()=>{

    },tenMinutes)
  } */

  ngOnDestroy(): void {
    this.subscriptions.routerSelector.unsubscribe();
  }
  goToHome() {
    if (this.isExapleUser) return;
    this.router.navigate(['/home']);
  }

  redirectByProductStatus(fromProuct = false) {
    var baseUrl = this.lastUrl?.split('?')[0];
    switch (baseUrl) {
      case '/login':
      case '/register':
      case '/forgot-password':
        if (!this.isFilledDetailsAndSigned()) {
          this.router.navigate(['personal-details']);
        } else if (
          this.productStatusIdsToScanBarcode.includes(this.product?.statusId)
        ) {
          this.router.navigate(['/scan-barcode']);
        } else {
          if (this.lastUrl.indexOf('returnUrl') !== -1) {
            var decodeUrl = decodeURIComponent(this.lastUrl);
            var returnUrl = decodeUrl.split('returnUrl=')[1];
            this.router.navigateByUrl(returnUrl);
          } else {
            this.router.navigate(['home']);
          }
        }
        break;
      case '/personal-details':
        if (this.isFilledDetailsAndSigned()) {
          if (
            this.productStatusIdsToScanBarcode.includes(this.product.statusId)
          ) {
            this.router.navigate(['/scan-barcode']);
          } else {
            this.router.navigate(['/home']);
          }
        }
        break;
      case '/scan-barcode':
        if (!this.isFilledDetailsAndSigned()) {
          this.router.navigate(['personal-details']);
        } else if (
          this.productStatusIdsToHome.includes(this.product?.statusId)
        ) {
          this.router.navigate(['/home']);
        }
        break;
      case '/home':
      case '/':
        if (!this.isFilledDetailsAndSigned()) {
          this.router.navigate(['personal-details']);
        } else if (
          this.productStatusIdsToScanBarcode.includes(this.product.statusId)
        ) {
          this.router.navigate(['/scan-barcode']);
        }
        break;
      case '/terms-of-use':
        if (!this.isFilledDetails()) {
          this.router.navigate(['personal-details']);
        } else if (this.isFilledDetailsAndSigned()) {
          this.router.navigate(['home']);
        }
        break;
      case '/example-report':
        this.exampleReport = true;
    }
  }
  isFilledDetailsAndSigned() {
    var product = this.product;
    if (!product || !product.patient) return false;
    if (product?.patient.filledDetails && product?.patient.isSignedConcent) {
      return true;
    }
    return false;
  }
  isSignedConcent() {
    if (this.product?.patient.isSignedConcent) {
      return true;
    }
    return false;
  }
  isFilledDetails() {
    if (this.product?.patient.filledDetails) {
      return true;
    }
    return false;
  }
  getProductStatus() {
    //if (this.afterSpitting) return true;
    //return this.fakeStatusId;
    return this.product.statusId;
    return 9; //Sent to customer
    return 61; //Arrived to Costumer
    return 64; //EnteredBarcode
    return 7; //Waiting to PickUp
    return 62; //Arrived To Lab
    return 62; //Arrived To Lab
    return 8; //Laboratory Process
    return 63; //Laboratory Process Completed
  }

  isLifeStyleUrl() {
    var url = this.router.url.split('?')[0];
    return url == '/lifestyle-report';
  }

  checkVersions() {
    this.regularUsersService.getVersion().subscribe((version) => {
      var url = this.router.url.split('?')[0];
      if (
        +version != environment.version &&
        this.lastUrl != '/lifestyle-report'
      ) {
        location.reload();
      }
    });
  }

  onActivate(event) {
    window.scroll(0, 0);
    document.body.scrollTop = 0;
    document.querySelector('body').scrollTo(0, 0);
  }
  isNavBar1() {
    var url = this.router.url.split('?')[0];
    return (
      url === '/guide' ||
      url === '/login' ||
      url === '/register' ||
      url === '/forgot-password' ||
      url === '/scan-barcode' ||
      url === '/personal-details' ||
      url === '/terms-of-use'
    );
  }
  showSideNavFn() {
    const url = this.router.url.split('?')[0];
    if (!this.product) return;
    return (
      this.product.statusId === ProductStatusEnum.WaitingToPickUp ||
      this.product.statusId === ProductStatusEnum.ArrivedToLab ||
      this.product.statusId === ProductStatusEnum.Completed ||
      this.product.statusId === ProductStatusEnum.LaboratoryProcess ||
      this.product.statusId === ProductStatusEnum.ResultAccepted ||
      this.product.statusId === ProductStatusEnum.ArrivedToOffice ||
      this.product.statusId === ProductStatusEnum.RetestOnLab
    );
  }
  showSideNav2() {
    var url = this.router.url.split('?')[0];
    return (
      url === '/eating-diary' ||
      url === '/diet-menu' ||
      url === '/diet-menu-meals' ||
      url === '/service-requests' ||
      url === '/lifestyle-report' ||
      url === '/lifestyle-report-new' ||
      url === '/dna-report' ||
      url === '/dna-report-inner' ||
      url === '/example-report-inner' ||
      url === '/home' ||
      url === '/suggar-results' ||
      url === '/bloodtests-results' ||
      url === '/bloodtests-results-new' ||
      url === '/upgrade' ||
      url === '/profile' ||
      url === '/report-recomendations' ||
      url === '/update-goal' ||
      url === '/current-weight' ||
      url === '/chat' ||
      url === '/vod' ||
      url === '/meeting-schedule' ||
      url === '/update-user-data'
    );
  }
  isShowSeperator() {
    var url = this.router.url.split('?')[0];
    return url !== '/eating-diary';
  }
  isMobile() {
    return (
      isMobileAndTable() && !this.allowRoutsToMobile.includes(this.lastUrl)
    );
  }
  isIOS() {
    return iOS();
  }
  isAndroid() {
    return android();
  }
  goToAppStore() {}
  goToPlayStore() {}
  goToLink(link: string) {
    if (
      link === 'report-recomendations' &&
      !this.product.bloodTestForBulletsFilled
    ) {
      this.popupHandlerService.openHasNotBloodTest();
    } else {
      this.router.navigate([link]);
    }
  }

  activeLink(link: string) {
    return this.router.url.indexOf(link) != -1;
  }

  setIndicator() {
    if (this.activeLink('home')) {
      return 'right-1';
    } else if (this.activeLink('dna-report')) {
      return 'right-2';
    } else if (this.activeLink('diet-menu')) {
      return 'right-3';
    } else if (this.activeLink('report-recomendations')) {
      return 'right-4';
    } else if (this.activeLink('profile')) {
      return 'right-5';
    }
  }
  isNotSidenavPage() {
    return (
      this.router.url.indexOf('/scan-barcode') != -1 ||
      this.router.url.indexOf('/personal-details') != -1 ||
      this.router.url.indexOf('/terms-of-use') != -1 ||
      this.router.url.indexOf('/callreview') != -1 ||
      this.router.url.indexOf('/app-redirect') != -1 ||
      this.router.url.indexOf('/lifestyle-app') != -1
    );
  }

  doLogout() {
    this.store.dispatch(LogoutUser());
  }

  hasMenus() {
    return this.product?.menus.some((x) => x.isComplete);
  }

  isNutritionReportExist() {
    if (!this.product) return false;
    var hasMenu = false;
    if (this.product.fileUploads && this.product.fileUploads.length > 0) {
      if (
        this.product.fileUploads.filter(
          (x) => x.fileUploadTypeId == FileUploadTypeEnum.PatientNutritionReport
        ).length > 0
      )
        return true;
    }

    if (this.product.menus && this.product.menus.length > 0) {
      for (var i = 0; i < this.product.menus.length; i++) {
        var menu = this.product.menus[i];
        if (menu.isComplete) {
          return true;
        }
      }
    }
  }

  dnaTestCompleted() {
    if (this.product && this.product.statusId == ProductStatusEnum.Completed) {
      return false;
    }
    return true;
  }
  isLogedIn(): boolean {
    return this.regularUser && this.regularUser.userName != '000111000';
  }

  doLogin() {
    this.router.navigate(['/login']);
  }
  showMobileNavMenu() {}
  getPath() {
    return environment.baseUrl;
  }
  reload() {
    location.reload();
    // this.router.navigate(['home']);
  }
  isNutroPlus() {
    var a = this.product.productTypeId == ProductTypeEnum.NutrogenicsPlus;
    return this.product.productTypeId == ProductTypeEnum.NutrogenicsPlus;
  }

  get environment() {
    return environment;
  }
}
