import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Language } from 'src/app/data/Language';
import { LanguagesService } from 'src/app/Services/languages.service';

@Component({
  selector: 'app-change-lang',
  templateUrl: './change-lang.component.html',
  styleUrls: ['./change-lang.component.scss'],
})
export class ChangeLangComponent implements OnInit {
  languages: Language[];
  @Input() selectedLangId: number;
  @Output() changeLang = new EventEmitter<Language>();
  constructor(private languagesService: LanguagesService) {}

  ngOnInit(): void {
    this.getLanguages();
  }

  getLanguages() {
    this.languagesService.getAll().subscribe((data) => {
      this.languages = data.filter((lang) => lang.languageId !== 3);
      if (!this.selectedLangId) {
        this.selectedLangId = this.languages.find(
          (lang) => lang.languageId === 1
        ).languageId;
      }
    });
  }

  onChangeLang(lang: Language) {
    this.selectedLangId = lang.languageId;
    this.changeLang.emit(lang);
  }
}
