import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-results-info',
  templateUrl: './results-info.component.html',
  styleUrls: ['./results-info.component.scss'],
})
export class ResultsInfoComponent implements OnInit {
  colorHeb: string;
  rankHeb: string;
  constructor(
    public dialogRef: MatDialogRef<ResultsInfoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    if (this.data.color === '#9ccf43') {
      this.colorHeb = 'ירוקה';
      this.rankHeb = 'תקינים';
    } else if (this.data.color === '#ebb527') {
      this.colorHeb = 'כתומה';
      this.rankHeb = 'בינוניים';
    } else if (this.data.color === '#c51f41') {
      this.colorHeb = 'אדומה';
      this.rankHeb = 'גבוהים';
    }
  }
}
