<div *ngIf="question" [ngStyle]="{ direction: isRtl() ? 'rtl' : 'ltr' }">
  <div [ngSwitch]="question.designTypeId" class="wrapper">
    <!-- 232 == header lang direction -->
    <!-- 238 == header center -->

    <ng-container
      *ngIf="
        question.lifestyleQuestionLangs &&
        question.lifestyleQuestionLangs.length > 0 &&
        question.lifestyleQuestionLangs[0].answerText
      "
    >
      <ng-container
        *ngSwitchCase="
          [232, 238].includes(question.designTypeId)
            ? question.designTypeId
            : ''
        "
      >
        <div
          class="title"
          [class.child-title]="isChild"
          [ngStyle]="{
            'text-align':
              question.designTypeId == 232 ? getLangAlign() : 'center',
            direction: isRtl() ? 'rtl' : 'ltr',
            color: isValid(question) || disableRed ? '' : 'red'
          }"
        >
          {{
            question.lifestyleQuestionLangs &&
              question.lifestyleQuestionLangs[0] &&
              question.lifestyleQuestionLangs[0].answerText
          }}
        </div></ng-container
      >
      <!-- 233 == checkbox Big -->
      <ng-container *ngSwitchCase="233">
        <mat-checkbox
          class="big-checkbox"
          [class.important]="important"
          [value]="question && question.lifestyleQuestionId"
          [checked]="
            question &&
            question.lifeStyleAnswers &&
            question.lifeStyleAnswers.length > 0 &&
            question.lifeStyleAnswers[0].value === 'true'
          "
          (change)="onUpdateAnswerUI($event, 1)"
          >{{
            question.lifestyleQuestionLangs &&
              question.lifestyleQuestionLangs[0] &&
              question.lifestyleQuestionLangs[0].answerText
          }}</mat-checkbox
        >
      </ng-container>
    </ng-container>
    <!-- 237 == radio Button Big -->
    <ng-container *ngSwitchCase="237">
      <mat-radio-group aria-label="Select an option">
        <mat-radio-button
          [class.important]="important"
          class="big-checkbox"
          [value]="question && question.lifestyleQuestionId"
          (change)="onUpdateAnswerUI($event, 2)"
          [checked]="
            question &&
            question.lifeStyleAnswers &&
            question.lifeStyleAnswers.length > 0 &&
            question.lifeStyleAnswers[0].value === 'true'
          "
          >{{
            question.lifestyleQuestionLangs &&
              question.lifestyleQuestionLangs[0] &&
              question.lifestyleQuestionLangs[0].answerText
          }}</mat-radio-button
        >
      </mat-radio-group>
    </ng-container>

    <!-- 237 == radio Button regular -->
    <ng-container *ngSwitchCase="235">
      <div class="mt-1 pis-1">
        <mat-radio-group aria-label="Select an option">
          <mat-radio-button
            [class.important]="important"
            class="radio-regular"
            [value]="question && question.lifestyleQuestionId"
            (change)="onUpdateAnswerUI($event, 2)"
            [checked]="
              question &&
              question.lifeStyleAnswers &&
              question.lifeStyleAnswers.length > 0 &&
              question.lifeStyleAnswers[0].value == 'true'
            "
          >
            <div>
              {{
                question.lifestyleQuestionLangs &&
                  question.lifestyleQuestionLangs[0] &&
                  question.lifestyleQuestionLangs[0].answerText
              }}
            </div></mat-radio-button
          >
        </mat-radio-group>
      </div>
    </ng-container>

    <!-- 234 == input text -->
    <ng-container *ngSwitchCase="234">
      <mat-form-field appearance="fill" class="text-right w-100">
        <textarea
          matTextareaAutosize
          matAutosizeMinRows="2"
          placeholder="{{
            question.lifestyleQuestionLangs &&
              question.lifestyleQuestionLangs[0] &&
              question.lifestyleQuestionLangs[0].answerText
          }}"
          matInput
          #input
          id="inputText"
          [(ngModel)]="
            question &&
            question.lifeStyleAnswers &&
            question.lifeStyleAnswers[0] &&
            question.lifeStyleAnswers[0].value
          "
        ></textarea>
        <!-- (change)="onUpdateAnswerUI(inputText.value, 3)" -->
      </mat-form-field>
    </ng-container>

    <!--239 == slider-->
    <ng-container *ngSwitchCase="239"
      ><app-snap-slider
        [value]="
          question &&
          question.lifeStyleAnswers &&
          question.lifeStyleAnswers[0] &&
          question.lifeStyleAnswers[0].value
        "
        (valueChange)="onUpdateAnswerUI($event, 4)"
      ></app-snap-slider
    ></ng-container>

    <!--240 == input number-->
    <!--question.containerDesignTypeId == 265 : spoon-->
    <ng-container *ngSwitchCase="240">
      <div
        class="mt-1 header"
        [class.spoon]="question.containerDesignTypeId == 265"
      >
        {{
          question.lifestyleQuestionLangs &&
            question.lifestyleQuestionLangs[0] &&
            question.lifestyleQuestionLangs[0].answerText
        }}
      </div>
      <input
        [class.spoon]="question.containerDesignTypeId == 265"
        type="number"
        [(ngModel)]="
          question &&
          question.lifeStyleAnswers &&
          question.lifeStyleAnswers[0] &&
          question.lifeStyleAnswers[0].value
        "
        #input
      />
      <!--  (change)="onUpdateAnswerUI(inputNumber.value, 3)" -->
      <img
        *ngIf="question.containerDesignTypeId == 265"
        class="spoon-icon"
        src="../../../../assets/spoon.svg"
        alt=""
      />
    </ng-container>

    <!--241 == success thumb-->
    <ng-container *ngSwitchCase="241">
      <div style="text-align: center">
        <img src="../../../../assets/success-thumb.svg" />
        <div class="mb-2"></div>
        <div
          style="
            font-size: 2rem;
            color: #7209b7;
            font-weight: bold;
            margin-bottom: 1rem;
          "
        >
          מעולה!
        </div>
        <div style="font-size: 1.8rem; font-weight: bold">אנחנו לקראת סיום</div>
      </div>
    </ng-container>
  </div>

  <!-- 'mb-5 mt-2': add margin to card when click on 'other' 
  [ngClass]="{
      'mb-5 mt-2':
        question.lifestyleQuestionId == 36 &&
        question.lifeStyleAnswers[0].value === 'true'
    }"
    [class.p-relative]="question.childQuestions[0]?.designTypeId !== 234"
    [class.p-absolute-right]="childQuestion.containerDesignTypeId == 260"
  -->

  <div
    [class.d-flex-wrap]="question.containerDesignTypeId == 260"
    [class.d-flex-center]="question.containerDesignTypeId == 264"
    *ngIf="
      question &&
      isShowingChilds() &&
      question.childQuestions &&
      question.childQuestions.length > 0
    "
  >
    <div
      class="mie-1 mb-1"
      *ngFor="let childQuestion of question.childQuestions; let index = index"
    >
      <app-lifestyle-question-node
        [class.important]="important"
        [important]="important"
        *ngIf="
          question &&
          question.childQuestions &&
          (!hasRefId(childQuestion) ||
            refAnswered(childQuestion.referenceQuestionId))
        "
        [ngStyle]="{ 'pointer-events': isLoading ? 'none' : '' }"
        [isChild]="true"
        [parentIdx]="parentIdx"
        [childIdx]="index"
        [question]="childQuestion"
        [productId]="productId"
        (radioChange)="onRadioChangeEmitter($event)"
        [langs]="langs"
        [lifestyleQuestions]="lifestyleQuestions"
        (incrementIndex)="onIcrementIndex($event)"
        (updateAnswer)="OnUpdateAnswer($event)"
      ></app-lifestyle-question-node>
    </div>
  </div>
</div>
