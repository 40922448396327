import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class ExelDownloadService {
  constructor(private http: HttpClient, public datepipe: DatePipe) {}

  getPatientNutritionReport(productId: Number) {
    return `${environment.baseUrl}ExelDownload/PatientNutritionReport/${productId}`;
  }
  getProductDnaPdf(productId: Number) {
    return `${environment.baseUrl}ExelDownload/ProductDnaPdf/${productId}`;
  }
}
