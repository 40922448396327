import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { File, FileEntry } from '@ionic-native/file/ngx';
import { Store } from '@ngrx/store';
import { base64StringToBlob } from 'blob-util';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { Language } from 'src/app/data/Language';
import { Product } from 'src/app/data/Product';
import { RegularUser } from 'src/app/data/RegularUser';
import {
  FileUploadType,
  FileUploadTypeEnum,
  ProductStatusEnum,
  ProductTypeEnum,
} from 'src/app/Enum/StatusesEnum';
import { LogoutUser } from 'src/app/Redux/Actions/regular-user.actions';
import { AppState } from 'src/app/Redux/reducers';
import { selectProduct } from 'src/app/Redux/selectors/product.selectors';
import {
  selectShowGeneratePdf,
  selectUser,
} from 'src/app/Redux/selectors/user.selectors';
import { LanguagesService } from 'src/app/Services/languages.service';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';
import { ProductService } from 'src/app/Services/product.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
})
export class SideNavComponent implements OnInit, OnChanges {
  product$: Observable<Product> = this.store.select(selectProduct);
  regularUser$: Observable<RegularUser> = this.store.select(selectUser);
  profileImg: string;
  isPremium: boolean;
  isLoadingReport: boolean;
  isLoadingReportForApp: boolean;
  showGeneratePdf$: Observable<boolean> = this.store.select(
    selectShowGeneratePdf
  );
  ProductStatusEnum = ProductStatusEnum;
  langId: number = 1;
  product: Product;
  @Input() isExapleUser: boolean;
  constructor(
    private productsService: ProductService,
    private file: File,
    private fileOpener: FileOpener,
    private store: Store<AppState>,
    private languagesService: LanguagesService,
    private popupHandlerService: PopupHandlerService,
    private router: Router
  ) {
    this.product$.subscribe((product) => {
      if (product) {
        this.product = product;
        this.profileImg = this.product.fileUploads?.find(
          (x) => x.fileUploadTypeId == FileUploadType.profile
        )?.path;
        this.isPremium =
          this.product.productTypeId == ProductTypeEnum.NutrogenicsPlus;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.product && !changes.product.isFirstChange()) {
      this.profileImg = this.product.fileUploads?.find(
        (x) => x.fileUploadTypeId == FileUploadType.profile
      )?.path;
    }
  }
  ngOnInit(): void {}
  onDnaReportDownloadClick() {
    if (!environment.isApp) {
      const url = this.productsService.generateReport(this.product.productId);

      this.isLoadingReport = true;
      const iframe = document.createElement('iframe');
      const menuElement = document.getElementById('menuId');
      iframe.setAttribute('src', url);
      iframe.style.display = 'none';
      menuElement.append(iframe);
      setTimeout(() => {
        this.isLoadingReport = false;
      }, 12000);
    } else {
      this.isLoadingReportForApp = true;
      this.productsService
        .generateReportForApp(this.product.productId)
        .subscribe((data) => {
          const blob = base64StringToBlob(data, 'application/pdf');
          this.file
            .checkDir('file:///storage/emulated/0/', 'download')
            .then((_) => {
              this.file
                .writeFile(
                  'file:///storage/emulated/0/download',
                  'genetic-report.pdf',
                  blob,
                  { replace: true }
                )
                .then((fileEntry: FileEntry) => {
                  this.fileOpener
                    .showOpenWithDialog(fileEntry.toURL(), data.type)
                    .then(() => {
                      this.isLoadingReportForApp = false;
                    })
                    .catch((err) =>
                      console.error('Error openening file: ' + err)
                    );
                })
                .catch((err) => {
                  console.error('Error creating file: ' + err);
                  throw err; //Rethrow - will be caught by caller
                });
            })
            .catch((err) => console.log("Directory doesn't exist4"));
        });
    }
  }
  isStatusComplete() {
    return this.product.statusId == ProductStatusEnum.Completed;
  }
  hasMenus() {
    const newMenu =
      this.product?.menus?.some((x) => x.isComplete) && this.isStatusComplete();
    const oldMenu =
      this.product?.fileUploads?.filter(
        (x) => x.fileUploadTypeId == FileUploadTypeEnum.PatientNutritionReport
      ).length > 0;
    return newMenu || oldMenu;
  }
  hasOldMenu() {
    var a =
      this.product?.fileUploads?.filter(
        (x) => x.fileUploadTypeId == FileUploadTypeEnum.PatientNutritionReport
      ).length > 0;
    // console.log('hasOldMenu', a);

    return a;
  }
  hasRequiredBloodTest() {
    return this.product.bloodTestForBulletsFilled && this.isStatusComplete();
  }
  lifestyleComplete() {
    return this.product.lifeStyleReportComplete;
  }

  getDefaultPersonImage() {
    return this.product.patient.regularUser.gender == 'male'
      ? '../../../assets/man.svg'
      : '../../../assets/woman.svg';
  }

  logout() {
    this.store.dispatch(LogoutUser());
  }

  onChangeLang(lang: Language) {
    if (this.langId != lang.languageId) {
      this.langId = lang.languageId;
      this.languagesService.setLangId(lang.languageId);
    }
  }

  goToLifestyleReport() {
    if (this.isPremium) {
      this.router.navigate(['/lifestyle-report-new']);
    } else {
      this.openUpgradeModal();
    }
  }
  goToBloodTest() {
    if (this.isPremium) {
      this.router.navigate(['/bloodtests-results-new']);
    } else {
      this.openUpgradeModal();
    }
  }
  menuLink() {
    if (this.isExapleUser) {
      return ['/example-diet-menu'];
    } else {
      return ['/diet-menu'];
    }
  }
  dnaReportLink() {
    if (this.isExapleUser) {
      return ['/example-report'];
    } else {
      return ['/dna-report'];
    }
  }
  goToProfile() {
    if (this.isExapleUser) return;
    this.router.navigate(['/profile']);
  }

  openUpgradeModal() {
    this.popupHandlerService.openUpgradePreviewDialog();
    this.popupHandlerService.upgradePreviewDialogSubject
      .pipe(take(1))
      .subscribe((data) => {
        if (data === 'yes') {
          this.router.navigate(['/upgrade']);
        }
      });
  }
}
