<div style="width: 700px; max-width: 100%; margin: 0 auto; padding: 0 2rem">
  <div style="margin-right: auto" (click)="goBack()" class="backBtn">
    <span> < חזרה </span>
  </div>
  <mat-accordion class="example-headers-align">
    <!--<mat-expansion-panel
      [expanded]="step === 0"
      (opened)="setStep(0)"
      hideToggle
    >
      <mat-expansion-panel-header>
        <mat-panel-title> פרטים אישיים </mat-panel-title>
         <mat-panel-description>
                מלא את הפרטים האישיים
                <mat-icon>account_circle</mat-icon>
              </mat-panel-description>
      </mat-expansion-panel-header>
      <form
        *ngIf="lifestyleForm1"
        (ngSubmit)="saveThis()"
        [formGroup]="lifestyleForm1"
      >
        <div class="d-flex-between">
          <mat-form-field appearance="fill">
            <mat-label>Full name</mat-label>
            <input matInput formControlName="fullName" />
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>email</mat-label>
            <input matInput formControlName="email" />
          </mat-form-field>
        </div>
        <div class="d-flex-between">
          <mat-form-field class="d-block" appearance="fill">
            <mat-label>Id</mat-label>
            <input matInput formControlName="socialSecurityNum" />
          </mat-form-field>
          <mat-form-field class="d-block" appearance="fill">
            <mat-label>age</mat-label>
            <input matInput formControlName="age" />
          </mat-form-field>
        </div>
        <div class="d-flex-between">
          <mat-form-field appearance="fill">
            <mat-label>Select gender</mat-label>
            <mat-select formControlName="sexId">
              <mat-option value="1">Male</mat-option>
              <mat-option value="2">Female</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="d-block" appearance="fill">
            <mat-label>height</mat-label>
            <input matInput formControlName="height" />
          </mat-form-field>
        </div>
        <div class="d-flex-between">
          <mat-form-field appearance="fill">
            <mat-label>Weight</mat-label>
            <input matInput formControlName="weight" />
          </mat-form-field>
        </div>

        <mat-action-row>
          
          <button mat-button color="primary">Next</button>
        </mat-action-row>
      </form>
    </mat-expansion-panel>-->
    <mat-expansion-panel
      [expanded]="step === 1"
      (opened)="setStep(1)"
      hideToggle
    >
      <mat-expansion-panel-header (click)="setStep(1)">
        <mat-panel-title> סוג התזונה </mat-panel-title>
      </mat-expansion-panel-header>
      <form
        (ngSubmit)="saveThis()"
        *ngIf="lifestyleForm2"
        [formGroup]="lifestyleForm2"
      >
        <div class="mb-4">
          <p
            *ngFor="
              let item of lifestyleForm2.get('nutritionTypes')['controls'];
              let i = index
            "
          >
            <mat-checkbox [formControl]="item">{{
              nutritionData[i].name
            }}</mat-checkbox>
          </p>
        </div>

        <!-- sensitivityToFood -->

        <div class="mb-4">
          <mat-form-field style="width: 100%" appearance="fill">
            <mat-label> רגישויות / אלרגיות למזונות:</mat-label>
            <textarea formControlName="sensitivityToFood" matInput></textarea>
          </mat-form-field>
        </div>
        <div class="mb-4">
          <mat-form-field style="width: 100%" appearance="fill">
            <mat-label>מאכלים שאת/ה נוהג להימנע מהם או פחות אוהב/ת</mat-label>
            <textarea formControlName="foodToAvoid" matInput></textarea>
          </mat-form-field>
        </div>

        <div class="mb-4">
          <p>בעיות עיכול?</p>
          <p
            *ngFor="
              let item of lifestyleForm2.get('digestionProblems')['controls'];
              let i = index
            "
          >
            <mat-checkbox [formControl]="item">{{
              digestionData && digestionData[i] && digestionData[i].name
            }}</mat-checkbox>
          </p>
        </div>

        <mat-action-row>
          <!-- <button mat-button type="button" color="warn" (click)="prevStep()">
            Previous
          </button> -->
          <button mat-button type="submit" color="primary">הבא</button>
        </mat-action-row>
      </form>
    </mat-expansion-panel>
    <mat-expansion-panel
      [expanded]="step === 2"
      (opened)="setStep(2)"
      hideToggle
      [disabled]="lifestyle.stage < 2"
    >
      <mat-expansion-panel-header>
        <mat-panel-title> הרגלי שתייה </mat-panel-title>
      </mat-expansion-panel-header>
      <form
        *ngIf="lifestyleForm3"
        (ngSubmit)="saveThis()"
        [formGroup]="lifestyleForm3"
      >
        <div class="d-flex-between">
          <mat-form-field appearance="fill">
            <mat-label>מים</mat-label>
            <input
              placeholder="מספר כוסות ביום"
              matInput
              formControlName="waterNumGlassesPerDay"
            />
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>שתיה מתוקה/מוגזת</mat-label>
            <input
              placeholder="מספר כוסות ביום"
              matInput
              formControlName="sweetNumGlassesPerDay"
            />
          </mat-form-field>
        </div>
        <div class="d-flex-between">
          <mat-form-field appearance="fill">
            <mat-label>שתייה דיאטטית</mat-label>
            <input
              placeholder="מספר כוסות ביום"
              matInput
              formControlName="dietNumGlassesPerDay"
            />
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>קפה</mat-label>
            <input
              placeholder="מספר כוסות ביום"
              matInput
              formControlName="coffeePerDay"
            />
          </mat-form-field>
        </div>
        <div class="d-flex-between">
          <mat-form-field appearance="fill">
            <mat-label>תה</mat-label>
            <input
              placeholder="מספר כוסות ביום"
              matInput
              formControlName="teaPerDay"
            />
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>אלכוהול</mat-label>
            <input
              placeholder="מספר כוסות ביום"
              matInput
              formControlName="alcoholPerDay"
            />
          </mat-form-field>
        </div>
        <mat-action-row>
          <button
            mat-button
            type="button"
            color="warn"
            (click)="prevStep()"
            *ngIf="showPrevBtn"
          >
            הקודם
          </button>
          <button mat-button type="submit" color="primary">הבא</button>
        </mat-action-row>
      </form>
    </mat-expansion-panel>
    <mat-expansion-panel
      [expanded]="step === 3"
      (opened)="setStep(3)"
      hideToggle
      [disabled]="lifestyle.stage < 3"
    >
      <mat-expansion-panel-header>
        <mat-panel-title> הרגלי אכילה </mat-panel-title>
      </mat-expansion-panel-header>
      <form
        *ngIf="lifestyleForm4"
        (ngSubmit)="saveThis()"
        [formGroup]="lifestyleForm4"
      >
        <mat-form-field appearance="fill" style="width: 100%">
          <mat-label>בוקר</mat-label>
          <textarea formControlName="eatingHabitsMorning" matInput> </textarea>
        </mat-form-field>

        <mat-form-field appearance="fill" style="width: 100%">
          <mat-label>ביניים</mat-label>
          <textarea formControlName="eatingHabitsMiddle1" matInput> </textarea>
        </mat-form-field>

        <mat-form-field appearance="fill" style="width: 100%">
          <mat-label>צהריים</mat-label>
          <textarea formControlName="eatingHabitsLunch" matInput> </textarea>
        </mat-form-field>

        <mat-form-field appearance="fill" style="width: 100%">
          <mat-label>ביניים</mat-label>
          <textarea formControlName="eatingHabitsMiddle2" matInput> </textarea>
        </mat-form-field>

        <mat-form-field appearance="fill" style="width: 100%">
          <mat-label>ערב</mat-label>
          <textarea formControlName="eatingHabitsSupper" matInput> </textarea>
        </mat-form-field>
        <mat-action-row>
          <button
            mat-button
            color="warn"
            (click)="prevStep()"
            *ngIf="showPrevBtn"
          >
            הקודם
          </button>
          <button mat-button color="primary">הבא</button>
        </mat-action-row>
      </form>
    </mat-expansion-panel>
    <mat-expansion-panel
      [expanded]="step === 4"
      (opened)="setStep(4)"
      hideToggle
      [disabled]="lifestyle.stage < 4"
    >
      <mat-expansion-panel-header>
        <mat-panel-title> בריאות ואורח חיים </mat-panel-title>
      </mat-expansion-panel-header>
      <form
        *ngIf="lifestyleForm5"
        (ngSubmit)="saveThis()"
        [formGroup]="lifestyleForm5"
      >
        <div>
          <label id="if-activities"
            >תרופות / תוספי תזונה שנלקחים באופן קבוע:</label
          >
          <mat-radio-group
            formControlName="isTakingDrugs"
            (change)="mangeDrugsValidators($event)"
            aria-labelledby="if-activities"
            class="example-radio-group"
          >
            <mat-radio-button
              (change)="ifMedicine($event)"
              class="example-radio-button"
              value="1"
            >
              כן
            </mat-radio-button>
            <mat-radio-button
              (change)="ifMedicine($event)"
              class="example-radio-button"
              value="0"
            >
              לא
            </mat-radio-button>
          </mat-radio-group>
          <div
            style="
              margin: 1rem 0;
              color: #f5587b;
              font-size: 16px;
              font-weight: 600;
            "
            *ngIf="isTakingDrugsError"
          >
            אנא בחר אחת מן האפשרויות
          </div>

          <mat-form-field
            style="width: 100%"
            *ngIf="showMedicineFlag"
            appearance="fill"
          >
            <textarea matInput formControlName="drugsTakenText"></textarea>
          </mat-form-field>
        </div>
        <div>
          <label id="if-activities">עישון</label>
          <mat-radio-group
            formControlName="isSmoking"
            (change)="mangeSmokingValidators($event)"
            aria-labelledby="if-activities"
            class="example-radio-group"
          >
            <mat-radio-button
              (change)="ifSmoking($event)"
              class="example-radio-button"
              value="1"
            >
              כן
            </mat-radio-button>
            <mat-radio-button
              (change)="ifSmoking($event)"
              class="example-radio-button"
              value="0"
            >
              לא
            </mat-radio-button>
          </mat-radio-group>
          <div
            style="
              margin: 1rem 0;
              color: #f5587b;
              font-size: 16px;
              font-weight: 600;
            "
            *ngIf="isSmokingError"
          >
            אנא בחר אחת מן האפשרויות
          </div>

          <ng-container *ngIf="showSmokingFlag">
            <mat-form-field style="width: 100%" appearance="fill">
              <label>מספר סיגריות ליום</label>
              <textarea matInput formControlName="smokingTextNum"></textarea>
            </mat-form-field>
            <mat-form-field style="width: 100%" appearance="fill">
              <label>זמן עישון בשנים</label>
              <textarea matInput formControlName="smokingTextYears"></textarea>
            </mat-form-field>
            <mat-form-field style="width: 100%" appearance="fill">
              <label>הפסקתי לעשן לפני (בשנים)</label>
              <textarea matInput formControlName="smokingTextQuit"></textarea>
            </mat-form-field>
          </ng-container>
        </div>

        <h4>שינה ומנוחה</h4>
        <mat-form-field style="width: 100%" appearance="fill">
          <label>פעילות מרגיעה (מדיטציה, נשימות):</label>
          <textarea matInput formControlName="relaxingActivity"></textarea>
        </mat-form-field>

        <mat-form-field style="width: 100%" appearance="fill">
          <label>שעות ואיכות השינה:</label>
          <textarea matInput formControlName="sleepHours"></textarea>
        </mat-form-field>

        <mat-form-field style="width: 100%" appearance="fill">
          <label>האם את/ה נמצא/ת בלחץ ביום יום?</label>
          <textarea matInput formControlName="areYouInStress"></textarea>
        </mat-form-field>

        <mat-form-field style="width: 100%" appearance="fill">
          <label>מה היית רוצה לשפר בהרגלי האכילה / במה חשוב לך להתמקד</label>
          <textarea
            matInput
            placeholder="ציין/י בקצרה את המטרה"
            formControlName="whatToAprrove"
          ></textarea>
        </mat-form-field>

        <div>
          <label id="if-activities">פעילות גופנית:</label>
          <mat-radio-group
            formControlName="ifActivities"
            aria-labelledby="if-activities"
            class="example-radio-group"
          >
            <mat-radio-button
              (change)="ifActivities($event)"
              class="example-radio-button"
              value="1"
            >
              כן
            </mat-radio-button>
            <mat-radio-button
              (change)="ifActivities($event)"
              class="example-radio-button"
              value="0"
            >
              לא
            </mat-radio-button>
          </mat-radio-group>
          <div
            style="
              margin: 1rem 0;
              color: #f5587b;
              font-size: 16px;
              font-weight: 600;
            "
            *ngIf="isifActivitiesError"
          >
            אנא בחר אחת מן האפשרויות
          </div>
        </div>
        <ng-container *ngIf="showActivitiesFlag">
          <app-test-activity-thumb
            *ngFor="let activity of lifestyleLocal.lifeStyleExercises"
            [ifActivities]="lifestyleLocal.ifActivities"
            [index]="activity.index"
            [name]="activity.name"
            [duration]="activity.duration"
            [numInWeek]="activity.numInWeek"
            (emitActivity)="emitActivity($event)"
          ></app-test-activity-thumb>
        </ng-container>
        <button
          *ngIf="showActivitiesFlag"
          mat-raised-button
          color="accent"
          type="button"
          (click)="addActivity()"
        >
          הוסף פעילות +
        </button>
        <br />
        <div>
          <label>מצב בריאותך</label>
          <mat-radio-group
            formControlName="healthStatus"
            class="example-radio-group"
          >
            <mat-radio-button
              *ngFor="let status of healthStatusData"
              class="example-radio-button"
              [value]="status"
            >
              {{ status }}
            </mat-radio-button>
          </mat-radio-group>
          <div
            style="
              margin: 1rem 0;
              color: #f5587b;
              font-size: 16px;
              font-weight: 600;
            "
            *ngIf="ishealthStatusError"
          >
            אנא בחר אחת מן האפשרויות
          </div>
        </div>
        <mat-action-row>
          <button
            mat-button
            color="warn"
            (click)="prevStep()"
            *ngIf="showPrevBtn"
          >
            הקודם
          </button>
          <button mat-button color="primary">הבא</button>
        </mat-action-row>
      </form>
    </mat-expansion-panel>
    <mat-expansion-panel
      [expanded]="step === 5"
      (opened)="setStep(5)"
      hideToggle
      [disabled]="lifestyle.stage < 5"
    >
      <mat-expansion-panel-header>
        <mat-panel-title> מחלות עבר והווה </mat-panel-title>
      </mat-expansion-panel-header>
      <form
        *ngIf="lifestyleForm6"
        (ngSubmit)="saveThis()"
        [formGroup]="lifestyleForm6"
      >
        <div>
          <div *ngFor="let label of LifeStyleDiseaseLabels; let i = index">
            <div formGroupName="d{{ i }}">
              <label>{{ label }} </label>
              <mat-radio-group
                formControlName="ifActive"
                (change)="manageDiseasesValidators($event, i)"
                class="example-radio-group"
              >
                <mat-radio-button class="example-radio-button" value="1">
                  כן
                </mat-radio-button>
                <mat-radio-button class="example-radio-button" value="0">
                  לא
                </mat-radio-button>
              </mat-radio-group>

              <div *ngIf="showDiseasesFields(i)">
                <input type="hidden" formControlName="diseaseLabel" />
                <mat-form-field appearance="fill" style="width: 100%">
                  <label>שם המחלה</label>
                  <input
                    matInput
                    formControlName="diseaseName"
                    placeholder="שם המחלה"
                  />
                </mat-form-field>
                <mat-form-field appearance="fill" style="width: 100%">
                  <label>מתי התחילה</label>
                  <input
                    matInput
                    formControlName="whenStarted"
                    placeholder="מתי התחילה"
                  />
                </mat-form-field>
                <mat-form-field appearance="fill" style="width: 100%">
                  <label>טיפול תרופתי</label>
                  <input
                    matInput
                    formControlName="medCare"
                    placeholder="טיפול תרופתי"
                  />
                </mat-form-field>
              </div>
            </div>
          </div>
          <div
            style="
              margin: 1rem 0;
              color: #f5587b;
              font-size: 16px;
              font-weight: 600;
              text-align: center;
            "
            *ngIf="diseaseError"
          >
            אחד מהשדות חסרים אנא בדוק/י שהכל מסומן
          </div>
        </div>
        <mat-action-row>
          <button
            mat-button
            color="warn"
            type="button"
            (click)="prevStep()"
            *ngIf="showPrevBtn"
          >
            הקודם
          </button>
          <button mat-button color="primary">הבא</button>
        </mat-action-row>
      </form>
    </mat-expansion-panel>
    <mat-expansion-panel
      [expanded]="step === 6"
      (opened)="setStep(6)"
      hideToggle
      [disabled]="lifestyle.stage < 6"
    >
      <mat-expansion-panel-header>
        <mat-panel-title> דפוסי אכילה </mat-panel-title>
      </mat-expansion-panel-header>
      <form
        *ngIf="lifestyleForm7"
        (ngSubmit)="saveThis()"
        [formGroup]="lifestyleForm7"
      >
        <div *ngFor="let label of eatingPatternLabels; let i = index">
          <div>
            <label>{{ label }} </label>
            <mat-radio-group [formControlName]="i" class="example-radio-group">
              <mat-radio-button
                *ngFor="let pattern of eatingPatternData"
                class="example-radio-button"
                [value]="pattern"
              >
                {{ pattern }}
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <div
          style="
            margin: 1rem 0;
            color: #f5587b;
            font-size: 16px;
            font-weight: 600;
            text-align: center;
          "
          *ngIf="eatingError"
        >
          אחד מהשדות חסרים אנא בדוק/י שהכל מסומן
        </div>
        <mat-action-row>
          <button
            mat-button
            color="warn"
            type="button"
            (click)="prevStep()"
            *ngIf="showPrevBtn"
          >
            הקודם
          </button>
          <button mat-button color="primary">סיום</button>
        </mat-action-row>
      </form>
    </mat-expansion-panel>
  </mat-accordion>
  <br />
  <br />
  <br />
</div>
