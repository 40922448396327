<div class="page-title">
  {{ !(selectedActiveRequestId$ | async) ? "צאט עם נציג" : displayName }}
</div>

<!-- <ng-container *ngIf="isOpen && (user$ | async)">
 -->
<!--  <app-chat *ngIf="selectedActiveRequest$ | async"></app-chat> -->
<div class="wrapper" #chatWrapper>
  <!--  <div class="wrapper__header">
    <button
      mat-icon-button
      style="right: 2rem"
      *ngIf="selectedActiveRequestId$ | async"
      (click)="unSelectServiceRequest()"
    >
      <mat-icon>keyboard_arrow_right</mat-icon>
    </button>
    {{ displayName }}
    <button mat-icon-button (click)="closeChat()">
      <mat-icon>close</mat-icon>
    </button>
    <div *ngIf="isLoading" style="position: absolute; bottom: 0; width: 100%">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
  </div> -->

  <div
    style="display: flex; flex-direction: column"
    *ngIf="selectedActiveRequestId$ | async"
  >
    <span
      style="
        color: #707070;
        font-size: 12px;
        opacity: 0.75;
        margin: 1rem 0;
        text-align: center;
      "
      >ברוכים הבאים למערכת הפניות של My Genes</span
    >
  </div>
  <app-service-requests
    *ngIf="!(selectedActiveRequestId$ | async)"
    [openChatId]="openChatId"
  ></app-service-requests>
  <app-chat
    (displayName)="setDisplayName($event)"
    *ngIf="selectedActiveRequestId$ | async"
  ></app-chat>
</div>
<!-- </ng-container>
 -->
