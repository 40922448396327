import { Action, createAction, props } from '@ngrx/store';
import { RequestForTableMin } from 'src/app/data/RequestForTableMin';

export enum ServiceRequestsActionTypes {
  LoadServiceRequests = '[ServiceRequests] Load ServiceRequests',
  LoadServiceRequestsComplete = '[ServiceRequests] Load ServiceRequests Complete1',
  OpenServiceRequest = '[ServiceRequests] Open ServiceRequests',
  OpenServiceRequestComplete = '[ServiceRequests] Open ServiceRequest Complete',
  OpenServiceRequestError = '[ServiceRequests] Open ServiceRequests Error',
  LoadServiceRequestsError = '[ServiceRequests] Load ServiceRequests Error',
  SelectServiceRequest = '[ServiceRequests] Select ServiceRequests',
  UnSelectServiceRequest = '[ServiceRequests] UnSelect ServiceRequests',
  SubmitGuidedQuestionAnswer = '[ServiceRequests] Submit Guided Question Answer',
  UpdateServiceRequestComplete = '[ServiceRequests] Update Service Request Complete',
  GetChatIndicator = '[ServiceRequests] Get Chat Indicator',
}

export class ServiceRequestsAction implements Action {
  type: string;
  serviceRequests: RequestForTableMin[];
  serviceRequest: RequestForTableMin;
  isComplete: boolean;
  error: string;
  patientId: number;
  productId: number;
  serviceRequestId: number;
  answerId: string;
  actionId: number;
  chatIndicator: number;
}
export const GetChatIndicator = createAction(
  ServiceRequestsActionTypes.GetChatIndicator,
  props<{ chatIndicator: number }>()
);
export const LoadServiceRequests = createAction(
  ServiceRequestsActionTypes.LoadServiceRequests,
  props<{ productId: number }>()
);
export const LoadServiceRequestsComplete = createAction(
  ServiceRequestsActionTypes.LoadServiceRequestsComplete,
  props<{ serviceRequests: RequestForTableMin[] }>()
);
export const LoadServiceRequestsError = createAction(
  ServiceRequestsActionTypes.LoadServiceRequestsError,
  props<{ error: string }>()
);
export const OpenServiceRequest = createAction(
  ServiceRequestsActionTypes.OpenServiceRequest,
  props<{ patientId: number }>()
);

export const OpenServiceRequestComplete = createAction(
  ServiceRequestsActionTypes.OpenServiceRequestComplete,
  props<{ serviceRequest: RequestForTableMin }>()
);

export const OpenServiceRequestError = createAction(
  ServiceRequestsActionTypes.OpenServiceRequestError,
  props<{ error: string }>()
);

export const SelectServiceRequest = createAction(
  ServiceRequestsActionTypes.SelectServiceRequest,
  props<{ serviceRequestId: number }>()
);
export const UnSelectServiceRequest = createAction(
  ServiceRequestsActionTypes.UnSelectServiceRequest
);
export const SubmitGuidedQuestionAnswer = createAction(
  ServiceRequestsActionTypes.SubmitGuidedQuestionAnswer,
  props<{ serviceRequestId: number; answerId: number; actionId: number }>()
);
export const UpdateServiceRequestComplete = createAction(
  ServiceRequestsActionTypes.UpdateServiceRequestComplete,
  props<{ isComplete: boolean; serviceRequest: RequestForTableMin }>()
);
