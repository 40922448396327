import { LifestyleQuestion } from './LifestyleQuestion';
import { Product } from './Product';

export class LifeStyleAnswer {
  lifeStyleAnswerId: number;
  lifestyleQuestionId: number;
  lifestyleQuestion: LifestyleQuestion;
  productId: number;
  product: Product;
  value: string;
}
