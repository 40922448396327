import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { RegularUser } from '../data/RegularUser';
import { LoginUserFromStorage } from '../Redux/Actions/regular-user.actions';

import { AppState } from '../Redux/reducers';
import { selectUser, selectUserError } from '../Redux/selectors/user.selectors';
import { RegularUsersService } from '../Services/regular-users.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  isInited = false;
  exampleUser: RegularUser;
  user$: Observable<RegularUser> = this.store.select(selectUser);
  userError$: Observable<RegularUser> = this.store.select(selectUserError);
  isInitedAndLogedIn: boolean;
  constructor(
    private router: Router,
    private authenticationService: RegularUsersService,
    private store: Store<AppState>
  ) {
    //console.log('constructor AuthGuard');
    authenticationService.currentUser.subscribe((user) => {
      if (!user) {
        this.router.navigate(['/login']);
      }
    });
    var user: RegularUser = JSON.parse(
      localStorage.getItem('currentUser')
    ) as RegularUser;
    if (!user) {
      //  console.log('logout from auth guard');
      // this.store.dispatch(LogoutUser());
    } else {
      /* 
      this.authenticationService.validateFromStorage(user).subscribe((user) => {
        if (user.userName != '000111000')
          localStorage.setItem('currentUser', JSON.stringify(user));

        //   this.isInited = true;
      });
      */

      this.user$.subscribe((data) => {
        var a = this.authenticationService.getUserStorage();
        if (!data && this.getUserFromStorage() && !this.isInitedAndLogedIn) {
          this.isInitedAndLogedIn = true;
          //console.log('from auth guard');
          this.store.dispatch(
            LoginUserFromStorage({
              userName: user.userName,
              password: user.password,
            })
          );
        }
      });
    }
  }

  getUserFromStorage() {
    return localStorage.getItem('currentUser');
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    //if (this.isInited) return true
    const currentUser = this.getUserFromStorage();
    //console.log('from canActivate:', currentUser);

    if (currentUser) {
      /*if(currentUser.userName === '000111000'){
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        return false;
      } */
      // logged in so return true
      return true;
    }
    //console.log('navigate to login from canActivate');

    // not logged in so redirect to login page with the return url
    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    return false;
  }
}
