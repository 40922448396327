<div class="blood-test">
  <ng-container *ngIf="false">
    <div class="blood-test__title">לקוח יקר!</div>
    <div class="blood-test__text">
      הקלקולטור מבוסס על אינטראקציה בין ממצאים גנטיים לבדיקות דם,
      <br />
      לצפיה בקלקולטור עליך להזין תוצאות עדכניות של בדיקות דם.
    </div>
    <div class="blood-test__link">
      <strong> אין לך בדיקות דם?</strong>
      <br class="show-mobile" />
      <span (click)="sendMail()"> לחץ כאן לשליחת מכתב פניה לרופא. </span>
    </div>
    <div class="blood-test__btn">
      <button
        class="btn btn--fill btn--center"
        (click)="dialogRef.close()"
        mat-button
      >
        אישור
      </button>
    </div>
  </ng-container>
  <ng-container *ngIf="mailSent">
    <div class="blood-test__mail-sent">
      <img src="../../../assets/mail-sent.png" alt="" />
      <div class="blood-test__mail-sent__text">מכתב פניה נשלח אליך לדוא״ל</div>
      <button
        class="btn btn--outline btn--center"
        (click)="dialogRef.close()"
        mat-button
      >
        סגור
      </button>
    </div>
  </ng-container>
</div>
