<div class="blood-test-view" style="direction: ltr">
  <div class="blood-test-view__header">
    <div style="display: flex; align-items: center">
      <strong>תאריך הבדיקה: </strong>
      <span *ngIf="!allEditMode">{{ dateTaken | date: "dd/MM/yyyy" }}</span>

      <mat-form-field class="datepicker" appearance="fill" *ngIf="allEditMode">
        <input [(ngModel)]="dateTakenForm" matInput [matDatepicker]="picker" />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>
    <div style="display: flex">
      <button
        mat-button
        class="btn btn--fill btn--small btn--center m-0 px-1"
        [ngClass]="{ disabled: allEditMode }"
        style="align-self: end"
        (click)="onUpdateClick()"
      >
        עדכן הכל
      </button>
    </div>
  </div>

  <div class="result" *ngFor="let result of results; let i = index">
    <div class="result__item">
      <span class="result__item__name">{{ result.bloodTestType.name }}: </span>
      <ng-container *ngIf="!allEditMode && editInx != result.bloodTestTypeId">
        <span class="result__item__value">{{ result.result }}</span>
        <span class="result__item__units">{{
          result.bloodTestType.units
        }}</span>
      </ng-container>
      <ng-container *ngIf="allEditMode || editInx == result.bloodTestTypeId">
        <div class="result__item__input">
          <input
            (change)="onResultChanged(formResults[i])"
            type="number"
            *ngIf="!formResults[i].isAlt"
            [(ngModel)]="formResults[i].result"
          />
          <input
            (change)="onResultAltChanged(formResults[i])"
            type="number"
            *ngIf="formResults[i].isAlt"
            [(ngModel)]="formResults[i].resultAlt"
          />
          <span
            class="result__item__input__unit"
            *ngIf="result.bloodTestType.units === '%'"
            >%</span
          >
        </div>
        <mat-button-toggle-group
          (change)="onAltChange(result, formResults[i])"
          *ngIf="result.bloodTestType.altUnits"
          [(ngModel)]="formResults[i].isAlt"
          aria-label="Font Style"
        >
          <!--  [(ngModel)]="selectedUnit"-->
          <mat-button-toggle [value]="false">{{
            result.bloodTestType.units
          }}</mat-button-toggle>
          <mat-button-toggle [value]="true">{{
            result.bloodTestType.altUnits
          }}</mat-button-toggle>
        </mat-button-toggle-group>
      </ng-container>
    </div>
    <div class="text-center">
      <app-blood-test-result-slider
        [editMode]="allEditMode || editInx == result.bloodTestTypeId"
        class="result__item__slider"
        [result]="result"
        [gender]="gender"
      >
      </app-blood-test-result-slider>
      <div
        *ngIf="errorSingle && editInx == result.bloodTestTypeId"
        class="error"
      >
        {{ errorSingle }}
      </div>
    </div>

    <div class="result__item__date">
      <ng-container *ngIf="!allEditMode && editInx != result.bloodTestTypeId"
        ><span *ngIf="result.result" style="width: 100px">
          {{ result.dateCreated | date: "dd-MM-yyyy" }}
        </span>
        <span class="result__item__date__missing" *ngIf="!result.result">
          חסר
        </span>
      </ng-container>
      <mat-form-field
        appearance="fill"
        *ngIf="!allEditMode && editInx == result.bloodTestTypeId"
        class="datepicker"
      >
        <input
          placeholder="Choose a date"
          [(ngModel)]="dateTakenForm"
          matInput
          [matDatepicker]="picker"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
      <button
        mat-icon-button
        class="action-btn fill disabled"
        *ngIf="
          allEditMode || (editInx != result.bloodTestTypeId && editInx != -1)
        "
      >
        <!-- <img src="../../../assets/ok_button_pink_disabled.svg" /> -->
        <mat-icon>border_color</mat-icon>
      </button>

      <button
        class="action-btn fill"
        (click)="goToEditSingleMode(result)"
        *ngIf="!allEditMode && editInx == -1"
        mat-icon-button
      >
        <!-- <img src="../../../assets/edit_button_pink.svg" /> -->
        <mat-icon>border_color</mat-icon>
      </button>
      <button
        class="action-btn fill ml-1"
        (click)="onOkClicked(result, i)"
        *ngIf="!allEditMode && editInx == result.bloodTestTypeId"
        mat-icon-button
      >
        <!-- <img src="../../../assets/ok_button_pink.svg" /> -->
        <mat-icon>check</mat-icon>
      </button>
      <button
        class="action-btn outline"
        (click)="onCancelEditClicked(result)"
        style="margin-left: 5px"
        *ngIf="!allEditMode && editInx == result.bloodTestTypeId"
        mat-icon-button
      >
        <!-- <img src="../../../assets/cancel_button_pink.svg" /> -->
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <div *ngIf="allEditMode" class="footer-actions">
    <button
      mat-button
      class="btn btn--center btn--big btn--outline"
      style="margin: 0 1rem 0 0"
      *ngIf="allEditMode"
      (click)="cancelAllEditMode()"
      type="button"
    >
      ביטול
    </button>
    <button
      mat-button
      class="btn btn--center btn--big btn--fill m-0"
      *ngIf="allEditMode"
      (click)="saveInAllEditMode()"
    >
      שמירה
    </button>
  </div>
</div>
