import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { BloodTestResult } from 'src/app/data/BloodTestResult';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';

@Component({
  selector: 'app-bloodtest-mobile',
  templateUrl: './bloodtest-mobile.component.html',
  styleUrls: ['./bloodtest-mobile.component.scss'],
})
export class BloodtestMobileComponent implements OnInit {
  validateValues: boolean;

  errorSingle: any[];
  @Output() saveSingle: EventEmitter<any> = new EventEmitter<any>();
  @Output() saveAll: EventEmitter<any> = new EventEmitter<any>();
  @Output() getResults: EventEmitter<any> = new EventEmitter<any>();
  @Output() createFormResults: EventEmitter<any> = new EventEmitter<any>();
  @Output() setEditInx: EventEmitter<any> = new EventEmitter<any>();
  @Output() toggleAllEditMode: EventEmitter<any> = new EventEmitter<any>();
  @Input() results: BloodTestResult[];
  @Input() formResults: BloodTestResult[];
  @Input() dateTaken: string;
  @Input() gender: string;
  @Input() editInx: number;
  @Input() allEditMode: boolean;
  @Input() dateTakenForm: any;
  numFloatRegx = '^[0-9][.]{0,1}[0-9]{0,1}$';
  numIntRegx = '^[1-9]{1}[0-9]{0,10}$';

  constructor(private popupHandlerService: PopupHandlerService) {}

  ngOnInit(): void {}

  onUpdateClick() {
    this.toggleAllEditMode.emit(true);
  }

  goToEditSingleMode(result: BloodTestResult) {
    this.setEditInx.emit(result.bloodTestTypeId);
    this.errorSingle = [];
  }

  onOkClicked(result: BloodTestResult, inx: number) {
    this.errorSingle = [];
    if (!this.formResults[inx].result && this.formResults[inx].result <= 0) {
      this.errorSingle.push('עליך למלא ערך גדול מאפס');
    }
    if (!this.dateTakenForm) {
      this.errorSingle.push('עליך למלא תאריך');
    }
    if (this.validateValues) {
      if (this.formResults[inx].bloodTestType.roundToInt) {
        if (
          this.formResults[inx].result > 0 &&
          !this.testRegex(this.formResults[inx].result, this.numIntRegx)
        ) {
          this.errorSingle.push('עליך להזין מספר שלם ללא נקודות');
        }
      } else {
        if (
          this.formResults[inx].result > 0 &&
          !this.testRegex(this.formResults[inx].result, this.numFloatRegx)
        ) {
          this.errorSingle.push('עליך להזין מספר אחד אחרי הנקודה');
        }
      }
    }

    if (this.errorSingle.length > 0) {
      this.errorSingle.join(' ');
      return;
    }

    result.result = this.formResults.find(
      (x) => x.bloodTestTypeId == result.bloodTestTypeId
    ).result;

    result.dateCreated = moment(this.dateTakenForm).format('yyyy-MM-DD');

    this.saveSingle.emit(result);
  }

  onCancelEditClicked(result: BloodTestResult) {
    this.createFormResults.emit(true);
    this.setEditInx.emit(-1);
  }

  testRegex(value, re) {
    const regex = new RegExp(re);
    return regex.test(value);
  }

  cancelAllEditMode() {
    this.createFormResults.emit(true);
    this.toggleAllEditMode.emit(false);
  }

  saveInAllEditMode() {
    if (!this.dateTakenForm) {
      this.popupHandlerService.openDisapearingAlertDialog(
        '',
        '',
        '',
        'עליך למלא תאריך',
        2000
      );
      return;
    }
    const atLeastOneField = this.formResults.some((x) => x.result);
    if (!atLeastOneField) {
      this.popupHandlerService.openDisapearingAlertDialog(
        '',
        '',
        '',
        'עליך למלא לפחות ערך אחד',
        2000
      );
      return;
    }
    var dateStr = moment(this.dateTakenForm).format('yyyy-MM-DD');
    this.formResults = this.formResults.map((x) => {
      x.dateCreated = dateStr;
      return x;
    });

    this.saveAll.emit(this.formResults);
  }

  onResultChanged(result: BloodTestResult) {
    result.resultAlt = result.result * result.bloodTestType.unitsConvertion;
    result.resultAlt =
      Math.round((result.resultAlt + Number.EPSILON) * 100) / 100;
  }

  onResultAltChanged(result: BloodTestResult) {
    result.result = result.resultAlt / result.bloodTestType.unitsConvertion;
    result.result = Math.round((result.result + Number.EPSILON) * 100) / 100;
  }
  onAltChange(result: BloodTestResult, formResult: BloodTestResult) {
    result.isAlt = formResult.isAlt;
  }
}
