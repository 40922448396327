<div *ngIf="!token">no token</div>
<div *ngIf="!patientId">no patientId</div>

<div
  style="position: relative; direction: ltr"
  class="lifestyle"
  *ngIf="lifestyleQuestions && langs"
>
  <app-lifestyle-progress-bar
    *ngIf="locationId == 0"
    style="position: sticky"
    [maxIndex]="getProgressBarMaxInx()"
    [currentIndex]="showIndex"
  ></app-lifestyle-progress-bar>
  <perfect-scrollbar class="scrollbar" [perfectScrollbar]="config">
    <div class="lifestyle__container">
      <ng-container
        *ngFor="
          let question of lifestyleQuestions;
          let index = index;
          trackBy: trackByQuestionId
        "
      >
        <span *ngIf="false">
          index: {{ index }} <br />
          showIndex: {{ showIndex }} <br />
          refId: {{ hasRefId(question) }} <br />
          refAnswered: {{ refAnswered(question.referenceQuestionId) }} <br />
          locationId: {{ locationId }} <br />
          <hr />
        </span>

        <mat-card
          class="card-question"
          *ngIf="
            (lifestyleQuestions &&
              langs &&
              showIndex >= index &&
              (!hasRefId(question) ||
                refAnswered(question.referenceQuestionId))) ||
            locationId == 1
          "
        >
          <app-lifestyle-question-node
            [ngStyle]="{ 'pointer-events': isLoading ? 'none' : '' }"
            [parentIdx]="index"
            [question]="question"
            [productId]="productId"
            [langs]="langs"
            [isLoading]="isLoading"
            [lifestyleQuestions]="lifestyleQuestions"
            (incrementIndex)="onIcrementIndex($event)"
            (updateAnswer)="OnUpdateAnswer($event)"
          ></app-lifestyle-question-node>
        </mat-card>
      </ng-container>

      <div class="card-question text-center" *ngIf="isDone">
        <button class="finishBtn" mat-button (click)="finishAnswering()">
          סיום
        </button>
      </div>
    </div>
  </perfect-scrollbar>
</div>
