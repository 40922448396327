<div class="graph">
  <div class="text-center">
    <div class="graph__indicators">
      <span class="graph__indicators__title">משקל</span>
      <div class="graph__indicators__item">
        <img src="../../../assets/goal_indicator_circle.svg" />
        <span style="font-size: 1rem">יעד חדש</span>
      </div>
      <div class="graph__indicators__item">
        <img src="../../../assets/bmi_line_green.svg" />
        <span style="font-size: 1rem">{{ bmi18_5Weight }} kg</span>
      </div>
      <div class="graph__indicators__item">
        <img src="../../../assets/bmi_line_red.svg" />
        <span style="font-size: 1rem">{{ bmi25Weight }} kg</span>
      </div>
      <span class="graph__indicators__title">BMI</span>
    </div>
    <div style="display: flex">
      <div style="width: 10%">
        <div
          class="canvasWraperWeight"
          *ngFor="let weight of weightsCanvasWraper; let index = index"
          [style.visibility]="
            index == weightsCanvasWraper.length - 1 ? 'hidden' : 'visible'
          "
        >
          {{ weight.Text }}
        </div>
        <div class="canvasWraperWeight" style="transform: translateY(-60%)">
          שבוע
        </div>
      </div>
      <div style="position: relative; width: 80%">
        <div
          (click)="scrollToCanvasEnd()"
          style="display: flex; position: absolute; right: 10px"
          [style.top.px]="flagY"
          *ngIf="showFlag"
        >
          <mat-icon
            aria-hidden="false"
            aria-label="Example home icon"
            style="position: relative; top: 5px; left: 11px"
            >arrow_right_alt</mat-icon
          >
          <img src="../../../assets/flag_large.svg" />
        </div>

        <div style="overflow-x: scroll; width: 100%" #canvasToScroll>
          <canvas id="canvas" height="400" width="650"> </canvas>
        </div>
      </div>
      <div style="width: 10%">
        <div
          *ngFor="let weight of weightsCanvasWraper; let index = index"
          class="canvasWraperWeight"
          [style.visibility]="
            index == weightsCanvasWraper.length - 1 ? 'hidden' : 'visible'
          "
        >
          <ng-container *ngIf="getBmi(weight.Text) > 0">
            {{ getBmi(weight.Text) }}
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
