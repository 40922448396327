import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Observable } from 'rxjs';
import { Language } from 'src/app/data/Language';
import { LifestyleAnswerMin } from 'src/app/data/LifestyleAnswerMin';
import { LifestyleQuestion } from 'src/app/data/LifestyleQuestion';
import { Product } from 'src/app/data/Product';
import { QuestionDesignType } from 'src/app/Enum/StatusesEnum';
import { LoadProduct } from 'src/app/Redux/Actions/product.actions';
import { AppState } from 'src/app/Redux/reducers';
import {
  lifeStyleReportComplete,
  selectPatientId,
  selectProduct,
} from 'src/app/Redux/selectors/product.selectors';
import { LanguagesService } from 'src/app/Services/languages.service';
import { LifestyleQuestionsService } from 'src/app/Services/lifestyle-questions.service';
import { ProductService } from 'src/app/Services/product.service';
import { isValidUtil, refAnsweredUtil } from 'src/app/Utils/lifestyleUtil';
import { isPremium } from 'src/app/Utils/userHelper';

@Component({
  selector: 'app-life-style-report-new',
  templateUrl: './life-style-report-new.component.html',
  styleUrls: ['./life-style-report-new.component.scss'],
})
export class LifeStyleReportNewComponent implements OnInit {
  config: PerfectScrollbarConfigInterface = {
    wheelSpeed: 0.5,
  };
  isDone: boolean = false;
  @Output('updateLifestlyeDone') updateLifestlyeDone: EventEmitter<any> =
    new EventEmitter<any>();
  patientId$: Observable<number> = this.store.select(selectPatientId);
  product$: Observable<Product> = this.store.select(selectProduct);
  lifeStyleReportComplete$: Observable<boolean> = this.store.select(
    lifeStyleReportComplete
  );
  productId: number;
  langId: number = 1;
  langs: Language[];
  showIndex: number = 0;
  lifestyleQuestions: LifestyleQuestion[];
  isLoading: boolean;
  patientId: number;
  locationId: number = 0;
  //lifestyleQuestions: any;
  constructor(
    private lifestyleQuestionsService: LifestyleQuestionsService,
    private route: ActivatedRoute,
    private lansService: LanguagesService,
    private store: Store<AppState>,
    private router: Router,
    private productsService: ProductService
  ) {}

  ngOnInit(): void {
    var locationIdParam = this.route.snapshot.queryParamMap.get('locationId');

    this.locationId = +this.route.snapshot.queryParamMap.get('locationId');

    this.product$.subscribe((product) => {
      if (product) {
        this.productId = product.productId;
        if (!isPremium(product.productTypeId)) {
          this.router.navigate(['/home']);
        } else {
          this.getQuestions();
        }
      }
    });
    this.lifeStyleReportComplete$.subscribe((isDone) => (this.isDone = isDone));
    this.patientId$.subscribe((patientId) => (this.patientId = patientId));
    this.getLangs();
  }

  getLangs() {
    this.lansService.getAll().subscribe((data) => (this.langs = data));
  }

  onIcrementIndex(index) {
    //console.log('onIcrementIndex', index);
    //this.setIndex(index);
  }

  getQuestions() {
    this.lifestyleQuestionsService
      .getByProductId(this.productId, this.langId, this.locationId)
      .subscribe((data) => {
        this.lifestyleQuestions = data;
        this.setIndex(this.showIndex);
      });
    /*
    this.productId$.subscribe((data) => {
      if (data) {
        this.productId = data;
        this.store.dispatch(
          loadLifestyleQuestions({
            productId: this.productId,
            langId: this.langId,
          })
        );
      }
    });
    this.lifestyleQuestions$.subscribe((data) => {
      let hasData = this.lifestyleQuestions ? true : false;
      if (data) {
        this.lifestyleQuestions = data;
        if (!hasData) this.setIndex(this.showIndex);
      }
    });
    */
  }

  hasRefId(question: LifestyleQuestion) {
    if (question.referenceQuestionId) {
      return true;
    }
    return false;
  }
  refAnswered(refId) {
    //console.log('refAnswered from root');
    return refAnsweredUtil(this.lifestyleQuestions, refId);
  }
  isValid(node: LifestyleQuestion) {
    return isValidUtil(node);
  }
  /*
  isValid(node: LifestyleQuestion) {
  
    if (
      (!node.lifeStyleAnswers || node.lifeStyleAnswers.length == 0) &&
      node.designTypeId !== QuestionDesignType.header &&
      node.designTypeId !== QuestionDesignType.headerCenter &&
      node.designTypeId !== QuestionDesignType.successThumb
    ) {
      return false;
    }
    if (!node.childQuestions || node.childQuestions.length == 0) {
      //  console.log('isValid');

      return true;
    }

    if (node.childQuestions && node.childQuestions.length > 0) {
      let validChildCount = 0;
      for (let index = 0; index < node.childQuestions.length; index++) {
        const child = node.childQuestions[index];
        if (this.isValid(child)) {
          validChildCount++;
        }
      }
      if (validChildCount == 0) {
        return false;
      }
    }
    return true;
  }
  */
  isToShow(node: LifestyleQuestion) {
    //console.log('isToShow' + node);
    if (
      node.referenceQuestionId &&
      !this.refAnswered(node.referenceQuestionId)
    ) {
      return true;
    }
    //console.log('isToShow 1' + node);
    if (
      (!node.lifeStyleAnswers || node.lifeStyleAnswers.length == 0) &&
      node.designTypeId !== QuestionDesignType.header &&
      node.designTypeId !== QuestionDesignType.headerCenter &&
      node.designTypeId !== QuestionDesignType.successThumb
    ) {
      return false;
    }
    //console.log('isToShow 2' + node);
    if (!node.childQuestions || node.childQuestions.length == 0) {
      //  console.log('isValid');

      return true;
    }
    if (node.childQuestions && node.childQuestions.length > 0) {
      let validChildCount = 0;
      for (let index = 0; index < node.childQuestions.length; index++) {
        const child = node.childQuestions[index];
        if (this.isValid(child)) {
          validChildCount++;
        }
      }
      if (validChildCount == 0) {
        return false;
      }
    }

    //console.log('is valid node:', node.lifestyleQuestionId);

    return true;
  }
  setIndex(index) {
    //console.log('setIndex', index);

    this.showIndex = 0;
    while (
      this.lifestyleQuestions[this.showIndex] &&
      this.isToShow(this.lifestyleQuestions[this.showIndex])
    ) {
      //  if (this.isValid(this.lifestyleQuestions[this.showIndex])) {
      if (true) {
        // console.log('setIndex2', this.showIndex);
        if (this.lifestyleQuestions[this.showIndex].referenceQuestionId) {
          if (
            this.refAnswered(
              this.lifestyleQuestions[this.showIndex].referenceQuestionId
            )
          ) {
            this.showIndex++;
          } else {
            this.showIndex += 1;
          }
        } else {
          this.showIndex++;
        }
        //console.log('setIndex end', this.showIndex);
      }
    }
    if (
      this.locationId == 0 &&
      this.showIndex == this.lifestyleQuestions.length &&
      !this.isDone
    ) {
      this.productsService
        .setLifeStyleNewComplete(this.productId, true)
        .subscribe((res) => {
          this.store.dispatch(new LoadProduct({ patientId: this.patientId }));
        });
    }
  }
  trackByQuestionId(index, question: LifestyleQuestion) {
    return question ? question.lifeStyleReportVersionId : undefined;
  }

  OnUpdateAnswer(event) {
    this.isLoading = true;
    this.lifestyleQuestionsService
      .updateAnswer(
        this.productId,
        event.questionId,
        event.inputTypeId,
        event.value
      )
      .subscribe(
        (answers) => {
          this.lifestyleQuestions = this.mergeQuestionsWithAnswers(
            answers,
            this.lifestyleQuestions
          );
          this.setIndex(this.showIndex);
          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
        }
      );
  }
  mergeQuestionsWithAnswers(
    answers: LifestyleAnswerMin[],
    questions: LifestyleQuestion[]
  ) {
    for (let index = 0; index < questions.length; index++) {
      const element: any = questions[index];

      element.lifeStyleAnswers = answers.filter(
        (x) => x.lifestyleQuestionId == element.lifestyleQuestionId
      );
      if (!element.lifeStyleAnswers) element.lifeStyleAnswers = [];
      if (element.childQuestions)
        element.childQuestions = this.mergeQuestionsWithAnswers(
          answers,
          element.childQuestions
        );
    }
    return questions;
  }
  convertAnwerMintoAnswer(answerMin: LifestyleAnswerMin) {}
  getProgressBarMaxInx() {
    return Math.floor(this.lifestyleQuestions.length / 5) * 5;
  }
  finishAnswering() {
    this.store.dispatch(new LoadProduct({ patientId: this.patientId }));
    this.router.navigate(['home']);
  }
}
