import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { Patient } from 'src/app/data/Patients';
import { PatientWeightHistory } from 'src/app/data/PatientWeightHistory';
import { UpdateGoalWeight } from 'src/app/Redux/Actions/product.actions';
import { AppState } from 'src/app/Redux/reducers';
import {
  selectPatient,
  selectProductIsLoading,
} from 'src/app/Redux/selectors/product.selectors';
import { getWeightForBmiUtil } from 'src/app/Utils/userHelper';
import { calcAge, getProperBmiRange } from 'src/app/Utils/validatorsUtils';

@Component({
  selector: 'app-update-goal-page',
  templateUrl: './update-goal-page.component.html',
  styleUrls: ['./update-goal-page.component.scss'],
})
export class UpdateGoalPageComponent implements OnInit {
  patient: Patient;
  age: any;
  minWeight: number;
  maxWeight: number;
  constructor(
    private router: Router,
    private store: Store<AppState>,
    private _snackBar: MatSnackBar
  ) {}
  patient$: Observable<Patient> = this.store.select(selectPatient);
  isLoading$: Observable<boolean> = this.store.select(selectProductIsLoading);
  weightGoal;
  weightRuler: number;
  weightError: string = '';
  date: Date = new Date();
  startingWeight: number;
  endingWeight: number;
  ngOnInit(): void {
    this.patient$.subscribe((res) => {
      if (res) {
        this.patient = { ...res };
        this.weightRuler = this.patient.goalWeight;
        this.age = calcAge(this.patient.regularUser.birthDate);
        const minBmi = getProperBmiRange(this.age).min;
        const maxBmi = getProperBmiRange(this.age).max;
        this.minWeight = getWeightForBmiUtil(
          minBmi,
          this.patient.regularUser.height
        );
        this.maxWeight = getWeightForBmiUtil(
          maxBmi,
          this.patient.regularUser.height
        );
      }
    });
  }
  validateWeight() {
    if (
      this.weightRuler < this.minWeight ||
      this.weightRuler > this.maxWeight
    ) {
      this.weightError = `יש להזין ערך בין ${this.minWeight} ל - ${this.maxWeight}`;
      return false;
    }
    this.weightError = '';
    return true;
  }

  setWeightFromRuler(event: number) {
    this.weightRuler = Math.round(event);
    this.validateWeight();
  }

  weightImages() {
    var minBmi = 18.5;
    var maxBmi = 25;
  }

  onClose(event) {
    this.router.navigate(['home']);
  }
  onCheck() {
    this.router.navigate(['home']);
  }
  updateGoal() {
    let goalWeight = new PatientWeightHistory();
    goalWeight.isGoal = true;
    goalWeight.patientWeightHistoryId = 0;
    goalWeight.patientId = this.patient.patientId;
    goalWeight.value = this.weightRuler;
    goalWeight.dateUpdated = moment().format('yyyy-MM-DD');
    this.store.dispatch(new UpdateGoalWeight({ goalWeight }));
    this._snackBar.open('משקל יעד עודכן בהצלחה', 'סגור', {
      direction: 'rtl',
      duration: 2000,
      panelClass: 'update-success',
    });
  }
  resetProcess() {}
}
