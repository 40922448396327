import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { RegularUsersService } from '../Services/regular-users.service';
import { Globals } from '../globals';
import { environment } from 'src/environments/environment';
import { Store } from '@ngrx/store';
import { AppState } from '../Redux/reducers';
import { RegularUser } from '../data/RegularUser';
import { selectUser } from '../Redux/selectors/user.selectors';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  regularUser$: Observable<RegularUser> = this.store.select(selectUser);
  regularUser: RegularUser;
  constructor(
    private regularUsersService: RegularUsersService,
    private store: Store<AppState>
  ) {
    /* this.regularUser$.subscribe((user) => {
      if (user) {
        this.regularUser = user;
      }
    }); */
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // add auth header with jwt if user is logged in and request is to the api url

    const currentUser = this.regularUsersService.currentUserValue;
    const isLoggedIn = currentUser && currentUser.token;
    const isApiUrl = request.url.startsWith(environment.baseUrl);
    if (isLoggedIn && isApiUrl) {
      var headers = request.headers.set(
        'Authorization',
        `Bearer ${currentUser.token}`
      );
      var headers1 = headers.append(
        'RegularUserId',
        currentUser.regularUserId.toString()
      );

      request = request.clone({
        headers: headers1,
      });

      return next.handle(request);
    } else {
      return next.handle(request);
    }
  }
}
