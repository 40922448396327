import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { VideoForSite } from 'src/app/data/VideoForSite';

@Component({
  selector: 'app-video-dialog',
  templateUrl: './video-dialog.component.html',
  styleUrls: ['./video-dialog.component.scss'],
})
export class VideoDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<VideoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: VideoForSite
  ) {}

  ngOnInit(): void {}

  timeText(duration: string) {
    let durationArr = duration.split(':');
    let hours = durationArr[0];
    let minutes = durationArr[1];
    if (hours !== '00') return 'שעות';
    else if (minutes !== '00') return 'דקות';
    else return 'שניות';
  }

  getDuration(duration: string) {
    let durationArr = duration.split(':');
    let hours = durationArr[0];
    let minutes = durationArr[1];
    let seconds = durationArr[2];
    if (hours !== '00') return hours + ':' + minutes;
    else if (minutes !== '00') return minutes + ':' + seconds;
    else {
      const tempSec = seconds.split('');
      tempSec[0] === '0' ? tempSec.shift() : null;
      return tempSec;
    }
  }
}
