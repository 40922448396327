import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { BloodTestResult } from 'src/app/data/BloodTestResult';
import { Product } from 'src/app/data/Product';
import { LoadProduct } from 'src/app/Redux/Actions/product.actions';
import { AppState } from 'src/app/Redux/reducers';
import {
  selectPatientId,
  selectProduct,
} from 'src/app/Redux/selectors/product.selectors';
import { BloodTestResultsNewService } from 'src/app/Services/blood-test-results-new.service';
import { BloodTestResultsService } from 'src/app/Services/blood-test-results.service';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';
import { isMobileAndTable } from 'src/app/Utils/userAgent';
import { isPremium } from 'src/app/Utils/userHelper';

@Component({
  selector: 'app-blood-test-results-new',
  templateUrl: './blood-test-results-new.component.html',
  styleUrls: ['./blood-test-results-new.component.scss'],
})
export class BloodTestResultsNewComponent implements OnInit {
  product$: Observable<Product> = this.store.select(selectProduct);
  patientId$: Observable<number> = this.store.select(selectPatientId);

  @Output('onEdit') onEdit: EventEmitter<any> = new EventEmitter();
  @Input() patientId: number;
  gender: string;
  results: BloodTestResult[];

  dateTakenForm: any;
  allEditMode: boolean = false;
  editInx: number = -1;
  selectedUnit = '1';
  private fb: FormBuilder = new FormBuilder();
  bloodTestForm: FormGroup;

  formResults: BloodTestResult[];
  errorSingle: any[];
  dateTaken: string;
  afterSave: boolean;

  constructor(
    private bloodTestResultsNewService: BloodTestResultsNewService,
    private bloodTestResultsService: BloodTestResultsService,
    private store: Store<AppState>,
    private router: Router,
    public popupHandlerService: PopupHandlerService
  ) {}

  ngOnInit(): void {
    this.product$.subscribe((data) => {
      if (data) {
        if (!isPremium(data.productTypeId)) {
          this.router.navigate(['/home']);
        } else {
          this.gender = data.patient.regularUser.gender;
          this.patientId$.subscribe((res) => {
            if (res) {
              this.patientId = res;
              if (!this.afterSave) {
                this.getBloodTestResultsNew();
                this.afterSave = false;
              }
            }
          });
        }
      }
    });
  }
  getbloodTestFormLine(inx) {
    return this.bloodTestsArray[inx];
  }
  get bloodTestsArray(): FormArray {
    return this.bloodTestForm.get('bloodTestsArray') as FormArray;
  }

  populateFromData(data) {
    this.results = data;
    this.results = this.results.map((x) => {
      x.isAlt = false;
      return x;
    });
    this.createFormResults();

    this.dateTaken = this.results.reduce((a, b) => {
      return new Date(a.dateCreated) > new Date(b.dateCreated) ? a : b;
    }).dateCreated;
  }

  getBloodTestResultsNew() {
    this.bloodTestResultsNewService
      .getByPatientId(+this.patientId)
      .subscribe((data) => {
        this.populateFromData(data);
        if (this.afterSave) {
          this.store.dispatch(new LoadProduct({ patientId: this.patientId }));
          this.afterSave = false;
        }
      });
  }

  createFormResults() {
    if (this.results) {
      this.formResults = JSON.parse(JSON.stringify(this.results));
      this.formResults = this.formResults.map((x) => {
        x.result = null;
        return x;
      });
    }
  }
  onSaveSingle(result) {
    this.bloodTestResultsNewService.saveSingle(result).subscribe((res) => {
      this.dateTakenForm = null;
      this.editInx = -1;
      this.afterSave = true;
      this.getBloodTestResultsNew();
    });
  }
  onSaveAll(formResults) {
    this.bloodTestResultsNewService.saveAll(formResults).subscribe((res) => {
      this.allEditMode = false;
      this.dateTakenForm = null;
      this.afterSave = true;
      this.getBloodTestResultsNew();
    });
  }
  onCreateFormResults(event) {
    this.createFormResults();
  }

  onSetEditInx(event) {
    this.editInx = event;
  }
  onToggleAllEditMode(event) {
    this.allEditMode = event;
  }
  /*
  getBloodeTestResults() {
    this.bloodTestResultsNewService
      .getByPatientId(+this.patientId)
      .subscribe((data) => {
        this.results = data;
      });
  }
  */
  /*
  onEditClick() {
    this.onEdit.emit();
  }
  */

  goBack() {
    this.router.navigate(['home']);
  }
  isMobile() {
    return isMobileAndTable();
  }
}
