import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DesignService {
  chatWrapperSubject: BehaviorSubject<number> = new BehaviorSubject<number>(
    NaN
  );
  constructor() {}

  getChatHeight(height: number) {
    if (height !== NaN) {
      this.chatWrapperSubject.next(height);
      //console.log('getChatHeight from service', height);
    }
  }
}
