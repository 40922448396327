import { Action } from '@ngrx/store';
import { ErrorMessage } from 'src/app/data/ErrorMessage';
import { PatientWeightHistory } from 'src/app/data/PatientWeightHistory';
import { Product } from 'src/app/data/Product';
import { UserAdress } from 'src/app/data/UserAdress';

/*
export enum ProductActionTypes {
  LoadProduct = '[Product] Load Product',
}

export class LoadProduct implements Action {
  readonly type = ProductActionTypes.LoadProduct;

  constructor(readonly payload: { locationData: LocationData }) {}
}

export type ProductActions = LoadProduct;
*/

export enum ProductActionTypes {
  LoadProduct = '[Product] Load Product',
  LoadProductComplete = '[Product] Load Product Complete',
  ProductError = '[Product] Product Error',
  ChangeStatusId = '[Product] Change Status Id',
  ChangeAddress = '[Product] Change Adress',
  InsertBarcode = '[Product] insert Barcode',
  setLifeStyleComplete = '[Product] set LifeStyle Complete',
  UpdateCurrentWeight = '[Product] Update Weight Goal',
  UpdateWeightSuccess = '[Product] Update Weight success',
  UpdateGoalWeight = '[Product] Update Current Weight',
  CurrentWeightError = '[Product] Update Weight History Erorr',
  LoadWeightHistory = '[Product] Load Weight History',
  LoadWeightHistoryComplete = '[Product] Load Weight History Complete',
  DeleteGeneticData = '[Product] Delete Genetic Data',
  DeleteGeneticDataComplete = '[Product] Delete Genetic Data Complete',
  //SetWeightHistories = '[Product] set weight Histories',
}

export class ProductAction implements Action {
  type: string;
  payload: {
    product: Product;
    error: string;
    errorMessage: ErrorMessage;
    patientId: number;
    productId: number;
    statusId: number;
    barCode: string;
    address: UserAdress;
    weightHistory: number;
    goalWeight: PatientWeightHistory;
    currentWeight: number;
    weightHistories: PatientWeightHistory[];
  };
}

export class LoadProduct implements Action {
  readonly type = ProductActionTypes.LoadProduct;

  constructor(readonly payload: { patientId: number }) {}
}

export class LoadProductComplete implements Action {
  readonly type = ProductActionTypes.LoadProductComplete;

  constructor(readonly payload: { product: Product }) {}
}
export class ProductError implements Action {
  readonly type = ProductActionTypes.ProductError;

  constructor(readonly payload: { error: string }) {}
}
export class ChangeStatusId implements Action {
  readonly type = ProductActionTypes.ChangeStatusId;

  constructor(readonly payload: { statusId: number }) {}
}

export class InsertBarcode implements Action {
  readonly type = ProductActionTypes.InsertBarcode;

  constructor(readonly payload: { barCode: string }) {}
}

export class ChangeAddress implements Action {
  readonly type = ProductActionTypes.ChangeAddress;

  constructor(readonly payload: { address: UserAdress }) {}
}

export class UpdateCurrentWeight implements Action {
  readonly type = ProductActionTypes.UpdateCurrentWeight;

  constructor(readonly payload: { currentWeight: number }) {}
}
export class UpdateWeightSuccess implements Action {
  readonly type = ProductActionTypes.UpdateWeightSuccess;

  constructor(readonly payload: { updateWeightSuccess: boolean }) {}
}
export class UpdateGoalWeight implements Action {
  readonly type = ProductActionTypes.UpdateGoalWeight;

  constructor(readonly payload: { goalWeight: PatientWeightHistory }) {}
}
export class weightHistoryError implements Action {
  readonly type = ProductActionTypes.CurrentWeightError;

  constructor(readonly payload: { error: string }) {}
}

export class LoadWeightHistory implements Action {
  readonly type = ProductActionTypes.LoadWeightHistory;

  constructor(readonly payload: { patientId: number }) {}
}

export class LoadWeightHistoryComplete implements Action {
  readonly type = ProductActionTypes.LoadWeightHistoryComplete;

  constructor(readonly payload: { weightHistories: PatientWeightHistory }) {}
}

export class DeleteGeneticData implements Action {
  readonly type = ProductActionTypes.DeleteGeneticData;

  constructor(readonly payload: { productId: number }) {}
}

export class DeleteGeneticDataComplete implements Action {
  readonly type = ProductActionTypes.DeleteGeneticDataComplete;

  //constructor(readonly payload: { productId: number }) {}
}

export type ProductActions =
  | LoadProduct
  | LoadProductComplete
  | ProductError
  | InsertBarcode
  | ChangeStatusId
  | ChangeAddress
  | UpdateCurrentWeight
  | UpdateGoalWeight
  | LoadWeightHistory
  | LoadWeightHistoryComplete
  | DeleteGeneticData
  | DeleteGeneticDataComplete;
