import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BloodTestType } from '../data/BloodTestType';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BloodTestTypesService {
  constructor(private http: HttpClient) {}
  getAll() {
    return this.http.get<BloodTestType[]>(
      `${environment.baseUrl}BloodTestTypes/`
    );
  }
}
