<div *ngIf="user">
  <div class="terms-of-use">
    <div [class.hidden]="signatureStep != 1" class="page-title">תנאי שימוש</div>

    <div class="use-terms" [class.hidden]="signatureStep != 1">
      <div [class.hidden]="doc1page != 1">
        <p dir="rtl" style="text-align: center">
          <strong>בדיקת רוק גנטית ותוכנית הרזיה וחיטוב ״מיי ג׳ינס״</strong>
        </p>
        <p dir="rtl" style="text-align: center">
          <strong><u>תנאי שימוש וביטול עסקה (להלן: "הסכם זה")</u></strong>
        </p>

        <ol class="main">
          <li>
            <strong><u>כללי</u></strong>
            <ol>
              <li>
                <strong>
                  <u>
                    הסכם זה מהווה, בין היתר, מסמך פרטים בהתאם לזכותך לפי סעיף
                    14ג(ב) לחוק הגנת הצרכן, תשמ"א-1981 וכן "הסבר בכתב" בהתאם
                    לזכותך לפי סע' 12 לחוק מידע גנטי, תשס"א-2000, והסכמתך לאמור
                    בו מהווה "הסכמה מדעת" לפי סע' 11 לחוק זה</u
                  ></strong
                ><span> (להלן בהתאמה: "</span><strong>חוק הגנת הצרכן</strong
                ><span>" ו-"</span><strong>חוק מידע גנטי</strong
                ><span>") בקשר עם עסקה (להלן: "</span><strong>העסקה</strong
                ><span
                  >") שבה רכשת מחברת לב חי גנטיקה בע"מ ח.פ. 516201001 מרחוב יגאל
                  אלון 94 תל אביב טלפון 03-3095244 (להלן: </span
                ><strong>"החברה"</strong><span>) בדיקת רוק גנטית (להלן: </span
                ><strong>"הבדיקה"</strong
                ><span>) הכוללת דו"ח תזונתי ממוחשב (להלן: </span
                ><strong>"הדו"ח"</strong
                ><span
                  >) ושבהתאם לסוג העסקה גם ייעוץ תזונתי המבוסס על הדו"ח (להלן: </span
                ><strong>"המלצות התזונה"</strong
                ><span>) (הבדיקה, הדו"ח והמלצות התזונה להלן ביחד: </span
                ><strong>"המוצר"</strong><span>).</span>
              </li>
              <li>
                <span>להסכם זה מצורפים הנספחים הבאים:</span
                ><strong>&nbsp;</strong>
                <p>
                  <a
                    href="https://mygenes.co.il/privacy-policy/"
                    target="_blank"
                  >
                    <strong>נספח א</strong
                    ><span> – שמירה על פרטיות וויתור על זכויות</span></a
                  >
                </p>
                <p>
                  <a
                    href="//mygenes.co.il/terms-of-cancellation/"
                    target="_blank"
                  >
                    <strong>נספח ב</strong><span> – תנאי ביטול עסקה.</span></a
                  >
                </p>
                <p>
                  <a
                    href="https://admin.mygenes.co.il/wp-content/uploads/2023/08/Mygenes_DNA_Rights.pdf"
                    target="_blank"
                  >
                    <strong>נספח ג</strong
                    ><span>
                      – מסמך הסבר בדבר זכויותיך לפי סעיף 12(ב) בחוק מידע
                      גנטי.</span
                    ></a
                  >
                </p>
                <p>
                  <span
                    >בכל הנוגע לנושאים אלה הנך מופנה בזאת לנספח הרלוונטי.</span
                  >
                </p>
              </li>
              <li>
                <span
                  >המוצר הינו בעל אופי חדשני, בהיותו מבוסס, בין היתר, על מחקרים
                  חדשניים בכל הקשור לתזונה וספורט והשפעת הגנים עליהם.</span
                >
              </li>
              <li>
                תוצאות הבדיקה יסופקו ללקוח באמצעות אפליקציה ייעודית, שניתנת
                להורדה דרך חנות האפליקציה למכשירי איפון / אנדרואיד (להלן:
                <strong>״האפליקציה״</strong>) או, בדרישה מיוחדת של הלקוח באמצעות
                קובץ PDF שישלח לכתובת דואר אלקטרוני שיספק (להלן:
                <strong>"כתובת הדוא"ל"</strong> ).
              </li>
              <li>
                <span
                  >המוצר אינו כולל ייעוץ גנטי ואינו בעל השלכה רפואית-גנטית כלל,
                  אלא משמעות תזונתית-דיאנטית בלבד, המבוססת על נתוניך הגנטיים
                  העולים מהבדיקה ועיבודם, ומטרתו הבלעדית היא למידה עצמית וידיעה
                  אישית שלך.</span
                >
              </li>
              <li>
                <span
                  >החברה אינה ארגון שירותי בריאות. המוצר וכל שירות שיתלווה אליו
                  לא מהווים ייעוץ, טיפול או אבחנה רפואיים ולא יהוו תחליף לפנייה
                  לקבלת ייעוץ או שירות מקצועי. המוצר אינו מיועד לביצוע אבחנה,
                  ריפוי או מניעה של כל מחלה או מצב באיאותי כלשהו.</span
                >
              </li>
              <li>
                <span
                  >בטרם ביצוע שימוש כלשהו במוצר עליך לקרוא בעיון את כל הוראות
                  הסכם זה וכן לתקף את אישור הסכם זה (כהגדרת מונח זה להלן). אישור
                  הסכם זה מהווה, בין היתר, אישור קבלת "טופס גילוי" לפי הוראות
                  חוק הגנת הצרכן, אישור קבלת "הסבר בכתב" בהתאם לזכותך לפי סעיף
                  12 בחוק מידע גנטי, "הסכמה מדעת" לפי הוראות חוק מידע גנטי וכן
                  הסכמה מלאה והתחייבות מוחלטת מצידך לאמיתות ולקיום כל ההצהרות,
                  ההוראות והתנאים המופיעים בו.</span
                >
              </li>
              <li>
                <span
                  >אישור הסכם זה ייעשה באמצעות חתימה אלקטרונית בעת פתיחת חשבונך
                  ורישום ערכת הבדיקה שקיבלת, באתר שלנו שכתובתו www.mygenes.co.il
                  או באפליקציה (להלן: </span
                ><strong>"האתר"</strong
                ><span
                  >), או באמצעות משלוח ההסכם חתום לדוא״ל בכתובת
                  support@mygenes.co.il</span
                >
              </li>
              <li>
                <span
                  >הסכם זה מנוסח בלשון זכר מטעמי נוחות בלבד, וכל האמור בו מופנה
                  כמובן לגברים ולנשים כאחד.</span
                >
              </li>
              <li>
                <span
                  >הינך מתחייב כלפי החברה כי יש לך הסמכות להתחייב כלפיה וכלפי
                  שותפיה, עובדיה וסוכניה בכל חיוביך לפי הסכם זה, וכן יש בידך
                  הרשות והסמכות לפי הוראות כל דין להשתמש במוצר.</span
                >
              </li>
              <li>
                <span
                  >חל איסור מוחלט לעשות או לאפשר שימוש במוצר על ידי קטין שגילו
                  פחות מ-18 שנים, אולם הינך רשאי לרכוש את המוצר לשימוש קטין
                  שגילו מתחת ל- 18 שנים (להלן: "</span
                ><strong>רכישה לשימוש קטין</strong
                ><span
                  >"). במקרה זה, הינך מאשר כי אתה האפוטרופוס (טבעי או אחר) על
                  אותו קטין לפי הוראות חוק הכשרות המשפטית והאפוטרופסות,
                  תשכ"ב-1962 (להלן בהתאמה: "</span
                ><strong>האפוטרופוס</strong><span>" ו-"</span
                ><strong>חוק כשרות משפטית</strong
                ><span
                  >"). הקטין לא יהיה רשאי לעשות שימוש במוצר אלא לאחר שליחת העתק
                  הצהרה בכתב לחברה באמצעות דואר אלקטרוני, שתהיה חתומה על ידי
                  הקטין ועל ידך כאפוטרופוס שלו, בה יוצהר כי כל הוראות הסכם זה
                  נקראו בעיון בפני הקטין, הוסברו לו כדבעי והובנו על ידו &ndash;
                  הכל בנוכחותך, וכן יאושר ויובהר בה כי אתה והקטין מבינים
                  ומסכימים לכל הוראות הסכם זה, ובאופן ספציפי מסכימים לנתינת
                  דגימת הרוק ולביצוע הבדיקה.</span
                >
              </li>
              <li>
                <span
                  >ערכת הבדיקה מיוצרת על ידי DNA Genotek Inc, Ottawa, Canada.
                  הריצוף הגנטי והעבודה המעבדתית מבוצעת על ידי מעבדת הגנטיקה בבית
                  החולים אסף הרופא. .שיווק המוצר, עיבוד תוצאות הבדיקה, הפיענוח
                  ,הדו"ח והאפליקציה נעשים על ידי החברה.</span
                >
              </li>
              <li>
                <span
                  >החברה מציעה 2 סוגי עסקאות: (1) עסקה הכוללת קבלת ערכת הבדיקה,
                  פענוח הבדיקה במעבדה וקבלת דו"ח הכולל את תוצאות הבדיקה ביחס לכ
                  -250 משתנים בגנים, המלצות תזונתיות כלליות המבוססות על תוצאות
                  הבדיקה בלבד ושיחת ייעוץ אחת בת 60 דקות עם תזונאים קלינית
                  (להלן: </span
                ><strong>"עסקה בסיסית"</strong
                ><span
                  >); (2) עסקה הכוללת קבלת ערכת הבדיקה, פענוח הבדיקה במעבדה
                  וקבלת דו"ח הכולל את תוצאות הבדיקה ביחס לכ -425 משתנים בגנים,
                  וכן המלצות תזונתיות כלליות המבוססות על תוצאות הבדיקה, על שאלון
                  אורח חיים אישי ותוצאות בדיקות דם ושיחת ייעוץ אחת בת כ - 60
                  דקות עם תזונאית קלינית . (להלן: </span
                ><strong>"עסקה מקיפה"</strong><span>).</span>
              </li>

              <li>
                <span
                  >העסקאות לא כוללות שירות לייעוץ, ליווי תמיכה או מעקב מקצועי
                  מכל סוג שהוא מעבר לשיחת ייעוץ אחת בת 60 דקות שתיערך ע"י
                  תזונאית קלינית בעלת רישיון של משרד הבאיאות הישראלי. אם הלקוח
                  יהיה מעוניין ביעוץ נוסף, יתכן והחברה תוכל להציע זאת כשירות
                  בתשלום נוסף, בהתאפ לזמינות כוח האדם שלה ולפי שיקול דעתה</span
                >
              </li>
              <li>
                <span>הבדיקה מתבצעת באמצעות ערכה לטובת בדיקת רוק (להלן: "</span
                ><strong>הערכה</strong
                ><span
                  >"), שתישלח אל הכתובת שמסר הלקוח. השילוח ייעשה באמצעות שליח
                  מטעם החברה, בתוך 8 ימי עסקים ממועד השלמת ביצוע התשלום ואישור
                  הסכם זה על ידי הלקוח לפי המאוחר. לאחר מתן דגימת רוק בהתאם
                  להוראות ביחס לערכה, תיאסף הערכה ותועבר אל המעבדה, שם תיבדק
                  דגימת הרוק ויבוצע הריצוף הגנטי למשתנים הגנטיים הנבחרים. (בהסכם
                  זה: "</span
                ><strong>תוצאות הבדיקה</strong
                ><span
                  >"). החברה תבצע את האינטרפרטציה המדעית אל מול המשתנים הגנטיים
                  שנבדקו ותפיק עבורך דו"ח המלצות תזונה ואורח חיים (בהסכם זה:
                  "</span
                ><strong>הדו"ח</strong
                ><span
                  >"), בתוך 28 ימי עסקים ממועד הגעת הערכה למעבדה. חריגה מאותם
                  המועדים בעד 30 ימים נוספים, לא תיחשב כחריגה בעניין הסכם
                  זה.</span
                >
              </li>
              <li>
                <span
                  >קבלת גישה אל תוצאות הבדיקה יחד עם דו״ח ההמלצות, באמצעות האתר,
                  האפליקציה או באמצעות קובץ PDF, ומתן שיחת יעוץ אחת בת 60 דקות
                  עם דיאטנית קלינית יחשבו למתן התמורה המלאה בגין העסקה הבסיסית
                  או המקיפה.</span
                >
              </li>
            </ol>
          </li>
        </ol>
        <br />
        <button class="btn btn--fill" mat-button (click)="gotoPage(2)">
          הבא
        </button>
      </div>
      <div [class.hidden]="doc1page !== 2">
        <ol start="2" class="main">
          <li style="color: white"></li>
          <li>
            <strong>השימוש במוצר ואחריות החברה</strong>
            <ol>
              <li>
                <span
                  >"שימוש" או "שימוש במוצר" הוא מעשה או מחדל כלשהם בקשר עם המוצר
                  או כל ייעוץ בקשר עימו, מכל סוג שהוא.</span
                >
              </li>
              <li>
                <span
                  >השימוש במוצר נעשה תוך ידיעה והבנה מצידך כי החברה או מי
                  מהגופים או היחידים שעימם הם שיתפו או משתפים או ישתפו פעולה
                  (להלן בפרק זה: "</span
                ><strong>החברה והצדדים השלישיים</strong
                ><span
                  >") אינם עוסקים במתן ייעוץ רפואי או משפטי, וכי לא ניתן כלל
                  להסתמך על נכונות או דיוק המידע וכיו"ב המתקבלים מהם בהיבטים
                  רפואיים ו/או משפטיים וכן, כי השימוש במוצר נעשה על אחריותך
                  בלבד.</span
                >
              </li>
              <li>
                <span
                  >החברה והצדדים השלישיים לא יהיו אחראים, גם לא באופן עקיף, לכל
                  מעשה או מחדל שנעשה על ידך או על ידי מאן דהוא, גם אם קרה הדבר
                  בקשר עם שימוש במוצר, במישרין או בעקיפין.</span
                >
              </li>
              <li>
                <span
                  >החברה והצדדים השלישיים לא יהיו אחראיים לכל תוצאה או נזק, מכל
                  סוג ואופן שהם, גם אם ייגרם (חלילה) כתוצאה משימוש במוצר.</span
                >
              </li>
              <li>
                <span
                  >החברה והצדדים השלישיים אינם מתחייבים לתוצאות כלשהן בעקבות
                  שימוש במוצר, ולא תעמוד לך כל טענה במקרה ולא יושגו תוצאות להן
                  ציפית, לרבות גם לא בגין מאמץ או זמן או ממון שהקדשת וכיוצא
                  באלה</span
                >
              </li>
              <li>
                <span
                  >ככל שתחוש שהשימוש במוצר גורר תוצאות-לוואי לא-צפויות או
                  לא-רצויות עליך יהיה לפנות לאלתר לאיש מקצוע ולא להסתמך על מידע
                  אחר כלשהו.</span
                >
              </li>
              <li>
                <span
                  >מבלי לגרוע מן האמור לעיל, בכל מקרה לא תעלה אחריות החברה
                  והצדדים השלישיים ביחס לכל נזק או עילת תביעה אחרת, מכל סוג
                  שהוא, על הסכום ששילמת בפועל לחברה תמורת המוצר.</span
                >
              </li>
              <li>
                <span>
                  השימוש במוצר וכל דבר הנלווה לכך אינם מהווים ייעוץ רפואי או
                  בריאותי, ואינם מהווים תחליף לאבחון מקצועי או ייעוץ רפואי או
                  ייעוץ או שירות מקצועי אחר. המוצר וכל אינטראקציה עם החברה
                  השלישיים לא ישמשו או יהוו תחליף לחוות דעת מקצועית ביחס
                  לעניינים רפואיים או בריאותיים, גופניים, ואחרים, ואין בשימוש
                  במוצר כדי להוות אבחנה רפואית או המלצה לקבלת טיפול או לביצוע
                  פעולה כלשהם. בהתאם לכך, במקרה שמתעורר חשש או צורך, עליך לפנות
                  לאיש מקצוע מוסמך ולקבל חוות דעת המבוססת על ידע מקצועי-רפואי
                  מוסמך ועל היכרות מעמיקה ומקצועית עם עובדות, פרטי ומאפייני
                  המקרה המסוים שלך. אנו ממליצים לך להקדים ולפנות לרופא או לאיש
                  מקצוע אחר לפי העניין בכל שאלה שיש לך בקשר עם עניינים
                  בריאותיים, כבר בטרם ביצוע שימוש במוצר.
                </span>
              </li>
              <li>
                <span>
                  החברה אינה אחראית לכל השפעה לרעה על מחלה, אלרגיה או מצב רפואי
                  או פיזי אחר ממנו הינך סובל או לשינויים במשקלך או לתוצאה כלשהי
                  מהשינוים האמורים.
                </span>
              </li>
              <li>
                <span>
                  המלצות התזונה לא יכילו את כל המזונות ושילובי המזון האפשריים,
                  כמובן. על כן, ייתכן שתוכל להפיק את אותה תועלת גם משילובי מזון
                  שלא מופיעים בהמלצות התזונה.
                </span>
              </li>
              <li>
                <span>
                  יתכן ולקוחות שרכשו את העסקה המקיפה יקבלו גישה אל פונקציות
                  שונות באפליקציה, לרבות גישה אל מודול המאפשר בניה ותיעוד של
                  ארוחות, מודול שמאפשר לקיים צ׳אט עם דיאטן קליני, מודול שמאפשר
                  לחפש מאכלים שונים ולקבל עליהם אחוז התאמה גנטי (FoodGenes)
                  ועוד. החברה רשאית להגביל את השימוש בפונקציות אלו או להתנות את
                  הגישה אליהם בתשלום נוסף בכל שלב וללא התראה מראש, ולא תישמע כל
                  טענה בקשר לכך מצד הלקוח.
                </span>
              </li>
              <li>
                <span>
                  השימוש במוצר יהיה כפוף להצהרות או לתנאים או הנחיות נוספים
                  העשויים להתפרסם על ידינו מעת לעת, שיתפרסמו באתר או בהודעות
                  דואר אלקטרוני שתישלחנה אליך. הינך מתחייב להיכנס אל האתר מפעם
                  לפעם או אל תיבת הדואר האלקטרוני שלך כדי להתעדכן בהתאם. הינך
                  מאשר כי תישלחנה אליך הודעות שונות באמצעות דואר אלקטרוני או
                  אמצעים סלולריים, אשר תכלולנה, בין היתר, פרסום מוצרים ושירותים.
                  ככל שאינך מעוניין לקבל הודעות כאמור או אם תהיה מעוניין בעתיד
                  להפסיק לקבלן, תוכל ליידע אותנו על כך באמצעות דואר אלקטרוני,
                  לכתובת: Support@mygenes.co.il ומשלוח דברי פרסומת (בלבד) יופסק
                  עם קבלת הודעתך כאמור, בהתאם להוראות חוק התקשורת (בזק
                  ושידורים), התשמ&quot;ב-1982. יובהר, כי בעוד שאין אנו גובים
                  תשלום עבור הודעות או מסרונים, ייתכן כי מפעיל הרשת הסלולרית שלך
                  עשוי לגבות תשלום עבור קבלת הודעות, נתונים ודמי תשלום אחרים,
                  ובמקרים כאמור אתה תישא בתשלומים אלה.
                </span>
              </li>
              <li>
                <span>
                  החברה משתדלת לספק ללקוחותיה מידע נכון, שלם ומעודכן המבוסס על
                  מחקרים ומאמרים מדעיים מהארץ ומהעולם תוך שימוש בכלים טכנולוגיים
                  חדשניים, אך הם אינם ערבים לדיוקו, שלמותו או עדכניותו של מידע
                  כאמור, זאת מעבר לכך שממילא הם אינם אחראים לכל נזק שינבע מתוך
                  הסתמכות על מידע כאמור.
                </span>
              </li>
              <li>
                <span>
                  החברה והצדדים השלישיים לא יהיו אחראים לנזק שייגרם מגלישה או
                  שימוש באתר או באפליקציה מכל סוג שהוא. האתר והאפליקציה עשויים
                  להכיל קישורים לאתרים אחרים וזאת כשירות בלבד לגולש. אין לראות
                  בהפניה לאתרים אחרים אישור או המלצה כלשהם ביחס לאתרים אלה או
                  ביחס למידע או תכנים או מוצרים או שירותים המופיעים או נגישים
                  באמצעותם, והשימוש באתרים אחרים אלה, לרבות גם קבלת מידע, תכנים
                  מוצרים ושירותים מהם, הינם על דעת הגולש ובאחריותו הבלעדית.
                </span>
              </li>
              <li>
                <span>
                  החברה והצדדים השלישיים אינם מתחייבים כי הפונקציות הנכללות באתר
                  ובפליקציה תפעלנה ללא-הפרעה או ללא טעות או כי פגמים יתוקנו או
                  כי אתר או האפליקציה או השרתים המאפשרים פעולתם הינם או יהיו
                  נ'יים מווירוסים או רכיבים מזיקים אחרים. כמו כן הם אינם
                  מתחייבים ואינם מציגיןם מצגים כלשהם לגבי השימוש או תוצאות
                  השימוש במוצר או בחומרים המצויים באתר או באפליקציה, לרבות לא
                  בכל הנוגע לנוכחותם, דיוקם, אמינותם או כל מאפיין ותכונה אחרים
                  שלהם.
                </span>
              </li>
              <li>
                <span>
                  מבלי לגרוע מן האמור, לא מן הנמנע כי טעויות בקשר עם הבדיקה
                  תיגרמנה מכך שכמות הדגימה שסיפקת אינה מספיקה, מכך שהערכה או
                  הדגימה נפגמו במהלך המשלוח או מכך שלא אוחסנו כראוי. כדי להקטין
                  את הסיכוי לטעות, אנא וודא שהינך פועל לפי ההנחיות המצורפות
                  לאריזת הערכה. כמו כן ידוע לך כי עלולות להיגרם תקלות כתוצאה
                  מטעות אנוש או ממשתנים או נתונים אחרים שאינם ידועים. במקרה בו
                  לא נוכל לפענח את הדגימה, נציע לשלוח אליך דגימה נוספת ללא עלות.
                  במקרה שנעריך כי הסיכוי לטעות חוזרת גבוה, נציע לשלוח אחות
                  מוסמכת עד אליך ללא עלות נוספת, לביצוע הדגימה באמצעות נטילה של
                  דגימת דם.
                </span>
              </li>
              <li>
                <span>
                  החברה תהיה רשאית להפסיק או להגביל תכונות או גרסאות של האתר
                  והאפליקציה וכן להפסיק או להגביל את סוג המכשירים בהם יתמכו או
                  לבצע פעולות תחזוקה אשר ייתכן שיובילו להפרעות, עיכובים או
                  שגיאות והנך מוותר בזאת על כל טענה בנושא. במקרה בו החברה תחליט
                  להסיר את האתר ו/או האפליקציה מהמרשתת, היא תשלח את דוח ההמלצות
                  הגנטי שלך בפורמט PDF אל תיבת הדואר האלקטרוני שהזנת בעת ההרשמה.
                </span>
              </li>
              <li>
                <span>
                  החברה מציעה ללקוחותיה שביצעו בדיקה מקיפה שימוש באלגוריתם מדעי
                  - טכנולוגי וחדשני הנקרא ״FoodGenes״. הגישה לאלגוריתם ניתנת
                  באמצעות האפליקציה בלבד, כשירות נלווה בתשלום נוסף. האלגוריתם
                  מאפשר הערכה של אחוז התאמה גנטי ביחס לכ- 10,000 מאכלים, מתכונים
                  ושילובי מאכלים. אחוז ההתאמה מוצג בטווח שבין 0-100, ונקבע על
                  בסיס מידע מדעי בשקלול של רכיבי התזונה במאכל, היחס ביניהם, אל
                  מול תוצאות הבדיקה הגנטית, תוצאות בדיקות דם ודיווח עצמי בשאלון
                  אורח חיים. האלגוריתם, פועל בשלב זה בגרסה ניסיונית. הוא טרם
                  נחקר, טרם צבר היסטוריית שימושים מספקת ויתכנו בו טעויות,
                  שגיאות, אי דיוקים, סטיות ותקלות. הנך מתבקש להשתמש בהמלצות ה-
                  FoodGenes לידע כללי בלבד ולאמת כל המלצה מול תזונאי קליני מוסמך
                  מטעם MyGenes. התנאים וההגבלות החלים על השימוש באלגוריתם זהים
                  לתנאים וההגבלות החלים בהסכם זה ביחס למוצר ועליך להתייחס אליהם
                  באותו האופן
                </span>
              </li>
              <li>
                <span>
                  שירות לקוחות או ייעוץ מכל סוג שתבחר החברה לתת, אם תבחר, ייעשו
                  כהטבה בלבד לפי שיקול דעתה הבלעדי. החברה לא תהיה מחויבת לספק את
                  אלה ולא תישמע כל טענה בקשר עימם מצד הלקוח.
                </span>
              </li>
              <li>
                <span>
                  במידה שתבחר לקבל מאת החברה דבר מה שאינו נכלל בעסקה תידרש לשאת
                  בתשלום נוסף לחברה.</span
                >
              </li>
              <li>
                <span>
                  באחריותך הבלעדית לדאוג לכל ציוד שיידרש לשם שימוש במוצר, לרבות
                  גם מחשב, חומרה, טלפון חכם, תוכנות, מודם ושירותי תקשורת וכיו"ב.
                </span>
              </li>
              <li>
                <span>
                  הוראות פרק זה הינן הוראות יסודיות בהסכם זה והן תנאי מקדים לכל
                  שימוש במוצר על ידך.
                </span>
              </li>
            </ol>
          </li>
        </ol>
        <br />
        <br />
        <button class="btn btn--fill" mat-button (click)="gotoPage(3)">
          הבא
        </button>
      </div>
      <div [class.hidden]="doc1page !== 3">
        <ol start="3" class="main">
          <li style="color: white"></li>
          <li style="color: white"></li>
          <li>
            <strong>רישום&nbsp;כמשתמש ותקופת השימוש</strong>
            <ol>
              <li>
                <span
                  >לאחר ביצוע העסקה יהיה עליך להירשם כמשתמש באמצעות יצירת חשבון
                  באפליקציה בהתאם להנחיות שיופיעו בו (להלן: </span
                ><strong>"תהליך הרישום"</strong><span> ו-</span
                ><strong>"החשבון"</strong
                ><span
                  >). במסגרת תהליך הרישום יהיה עליך להזין את שמך הפרטי ושם
                  משפחתך כ"שם משתמש" ולמסור פרטים אישיים נוספים (להלן: </span
                ><strong>"פרטים אישיים"</strong
                ><span>) וכן לבחור סיסמה (כולם ביחד להלן: </span
                ><strong>"פרטי חשבון"</strong
                ><span
                  >). הנך מתחייב לספק פרטים אישיים מדויקים, מעודכנים
                  ומלאים.&nbsp;</span
                >
              </li>
              <li>
                <span
                  >פרטי החשבון הינם סודיים, חובה עליך לשמרם מוגנים ואסור לאפשר
                  במעשה או מחדל כלשהם את גילוים לצד שלישי. מבלי לגרוע מהאמור, כל
                  שימוש שיתבצע באמצעות החשבון יהיה באחריותך גם אם ייעשה ללא
                  ידיעתך והסכמתך.</span
                >
              </li>
              <li>
                <span
                  >מבלי לגרוע מהאמור לעיל, בתהליך הרישום אסור לך להשתמש בשם
                  שאינו שמך או בפרטי חשבון שיש לצד שלישי כלשהו זכויות בהם או
                  בפרטי חשבון שהינם בלתי-הולמים. קביעה אודות היכללות פרטי חשבון
                  בגדר האמור לעיל תהיה לפי שיקול דעתה הבלעדי של החברה.</span
                >
              </li>
              <li>
                <span
                  >באם הינך אפוטרופוס (כהגדרתו לעיל) והינך מבצע את העסקה לשם
                  שימוש קטין (מתחת לגיל 18 כאמור לעיל), תצטרך לבצע את תהליך
                  הרישום ביחד עם הקטין. רישום הקטין כמשתמש יוכל להיעשות רק על
                  ידך ועל ידי הקטין ביחד, בהתאם לעמידה בכל הוראות הסכם זה
                  והוראות כל דין. בהתקשרותך בהסכם זה וכן בביצוע תהליך הרישום
                  הינך מצהיר כי העסקה לשם שימוש הקטין והשימוש במוצר על ידו
                  תואמות במלואן את הוראות הסכם זה והוראות כל דין.</span
                >
              </li>
              <li>
                <span
                  >אם תדע או תחשוד כי צד שלישי נכנס לחשבונך או אם תדע או תחשוד
                  כי הופרה סודיותו של פרט מפרטי החשבון באופן כלשהו, עליך ליידע
                  את החברה על כך לאלתר באמצעות דואר אלקטרוני.&nbsp;</span
                >
              </li>
            </ol>
          </li>
          <br />
          <li>
            <strong>תשלום ותנאי עסקה</strong>
            <ol>
              <li>
                <span
                  >התשלום עבור עסקה בסיסית יהיה בסך של 3,948 ₪ שייגבה מראש בטרם
                  השימוש במוצר. התשלום עבור עסקה מקיפה יהיה בסך של 4,788 ₪
                  שיוסדר מראש בטרם השימוש במוצר. התשלום עבור כל אחת מהעסקאות
                  ניתן לחלוקה עד ב-12 תשלומים שווים, ללא ריבית וללא הצמדה, ככל
                  שיבוצע התשלום באמצעות כרטיס אשראי שאת פרטיו תמסור לחברה. במידה
                  שתבחר לבצע את התשלום באמצעות המחאה, הוראת קבע או העברה בנקאית
                  &ndash; יתאפשר לך לעשות שימוש במוצר רק לאחר פירעון בפועל של
                  מלוא התשלום לחברה.</span
                >
              </li>
              <li>
                <span
                  >החברה תהיה רשאית לערוך שינויים לפי שיקול דעתה הבלעדי בכל
                  שירות שיינתן על ידה או באתר, בין אם אלה מהווים חלק מהעסקה ובין
                  אם לאו. כמו כן, החברה לא תהיה אחראית ביחס לאובדן ומגבלות גישה
                  לנתונים, שהתבצעו כתוצאה מכוח עליון, לרבות התקפות סייבר, תקלות
                  משביתות בשרתי החברה ובבסיסי הנתונים של החברה.</span
                >
              </li>
            </ol>
          </li>
          <br />
          <li>
            <strong>קנין&nbsp;רוחני</strong>
            <ol>
              <li>
                <span
                  >הבדיקה, הדו"ח, המלצות התזונה, האתר, סימן המסחר "My Genes" או
                  סימני מסחר אחרים, וכל התכנים, הנתונים, המידע, העיצובים, השמות
                  המסחריים והסמלילים שיהיו מצויים באלה, וכן הסכם זה, הינם
                  בבעלותה הבלעדית של החברה, ומהווים, בין היתר, "יצירה ספרותית"
                  כמשמעותה בחוק זכות יוצרים, תשס"ח-2007 ו"סוד מסחרי" כמשמעותו
                  בחוק עוולות מסחריות, תשנ"ט-1999. אסור להעתיק או להעביר או
                  לפרסם או לשווק או לתרגם או לחשוף בפומבי או לחקות או להפיץ או
                  למכור או לבצע דה-קומפילציה או הנדסה חוזרת וכל כיו"ב ביחס לכל
                  דבר הקשור בהם. הפרת איסור זה תהווה פגיעה בזכויותיה של החברה
                  לפי כל דין ולפי הוראות הסכם זה ותזכה את החברה בכל סעד העומד
                  לרשותה.</span
                >
              </li>
              <li>
                <span
                  >ייתכן שהבדיקה או הדו"ח או המלצות התזונה או האתר או מערכות
                  המצויות בו מוגנים בפטנט. לפיכך העתקה או שימוש או הפצה או כל
                  פעולה אחרת כיו"ב של אחד מבין אלה, שנעשתה ללא קבלת רשות מפורשת
                  מאת החברה בכתב ומראש, עלולה להוות גם הפרת פטנט, בנוסף לכל
                  עוולה אחרת שבדין.</span
                >
              </li>
              <li>
                <span
                  >החברה אינה מעבירה לידיך כל זכות בשום דבר שהוא, לרבות לא במידע
                  או חומר שתהיה רשאי להוריד למחשבך האישי וכיו"ב, אלה ייוותרו
                  בבעלותה המלאה והבלעדית של החברה ולכל היותר תהיה לך הרשות
                  להשתמש בהם בלבד בכפוף להוראות הסכם זה.&nbsp;</span
                >
              </li>
              <li>
                <span
                  >החברה איננה מבטיחה כי משתמשים אחרים פועלים או יפעלו על פי
                  תנאי הסכם זה והיא לא תהיה אחראית לנזק כלשהו העלול להגרם בשל
                  כך.</span
                >
              </li>
            </ol>
          </li>
          <br />
          <li>
            <strong>התיישנות תביעות</strong>
            <p>
              <span
                >כל עילת תביעה שתהיה לך בקשר עם הבדיקה, הדו"ח, הנתונים או שימוש
                בכל אחד מאלה או בקשר עם הסכם זה או כל דבר שבוצע או לא בוצע
                בעקבותיו וכל כיוצא באלה, תתיישן בתוך שנה אחת (1) ממועד היווצרותה
                בפועל אף אם לא תהא ידועה לך באותו מועד, ואתה תהיה מנוע לצמיתות
                מלתבוע בגינה. הוראה זו הינה בגדר "תקופת התיישנות מוסכמת" לפי סע'
                19 לחוק ההתיישנות, תשי"ח-1958.</span
              >
            </p>
          </li>
          <br />
          <li>
            <strong>הפרה ותרופות</strong>
            <ol>
              <li>
                <span
                  >במקרה של מחלוקת בינך לבין החברה או באם תפר הוראה מהוראות הסכם
                  זה תהיה החברה רשאית לאסור עליך את השימוש בבדיקה או בדו"ח או
                  בכל דבר אחר בקשר עם העסקה, לפרק זמן או לצמיתות, וכן להשהות,
                  לבטל או לסיים את ההתקשרות עמך, להשהות את הגישה לחשבון, למחוק
                  את החשבון ואת נתוניו או חלק מהם או להטיל עליך תשלום נוסף עבור
                  השימוש במוצר (אף בגין שימוש שהיה זמין לפני כן ללא
                  תשלום).</span
                >
              </li>
              <li>
                <span
                  >הינך מתחייב בזאת לשפות באופן מלא את החברה או כל צד שלישי
                  שעימו היא משתפת פעולה בגין כל הוצאה שתיגרם באופן ישיר או עקיף
                  בעקבות הפרת הוראה מהוראות הסכם זה על ידך, לרבות גם הוצאות
                  משפטיות, שכר טרחת עורך דין וכל כיו"ב, לאלתר עם קבלת דרישה
                  כאמור.</span
                >
              </li>
            </ol>
          </li>
          <br />
          <li>
            <strong>שונות</strong>
            <ol>
              <li>
                <span
                  >כל איסור החל עליך לפי הוראות הסכם זה אסור לך לסייע או לאפשר
                  לצד שלישי לבצעו.</span
                >
              </li>
              <li>
                <span
                  >במקרה בו יכריע בית משפט מוסמך שסעיף כלשהו בהסכם זה אינו תקף
                  מכל סיבה שהיא, ייראו את אותו סעיף כאילו נוסח מלכתחילה באופן
                  שישקף ככל האפשר את הכוונות המקוריות של הצדדים. יתר הוראות הסכם
                  זה יישארו בתוקפן.</span
                >
              </li>
              <li>
                <span>לחברה תעמוד זכות קיזוז כלפיך אולם לא להיפך.</span>
              </li>
              <li>
                <span
                  >על הסכם זה וכל הנובע ממנו ו/או כל הקשור בו יחולו אך ורק דיני
                  מדינת ישראל.&nbsp;</span
                >
              </li>
              <li>
                <span
                  >בית משפט המוסמך במחוז תל-אביב יהיה בעל סמכות בלעדית לדון בכל
                  עניין בקשר עם העסקה והסכם זה.</span
                >
              </li>
              <li>
                <span
                  >ככל שתבחר החברה שלא לעשות שימוש בזכות כלשהי העומדת לה לפי
                  הוראות הסכם זה או לפי הוראות כל דין לא יחשב הדבר לוויתור או
                  שיהוי מצידה והיא תהיה רשאית לעשות שימוש בכל זכות העומדת או
                  שתעמוד לה בכל עת לפי שיקול דעתה הבלעדי.&nbsp;</span
                >
              </li>
              <li>
                <span
                  >ככל שתתקיים סתירה או אי-התאמה בין האמור בהסכם זה לבין האמור
                  במקום אחר כגון האתר, הודעת דואר אלקטרוני וכיו"ב, הקובע יהיה
                  האמור בהסכם זה, למעט אם במקורות האמורים נאמר במפורש
                  אחרת.</span
                >
              </li>
            </ol>
          </li>
          <li>
            <strong>הצהרת הנבדק</strong>
            <ol>
              <li>
                אני מצהיר/ה ומאשר/ת בזאת כי ניתן לי הסבר מפורט על הבדיקה
                ומשמעויותיה לגבי. הוסבר לי כי טופס זה מהווה הסכמה מדעת בכתב עפ״י
                חוק מידע גנטי התשמ״א 2000, והוא כולל הסבר לעניין לקיחת DNA
                ועריכת בדיקות גנטיות, שמירת המידע ומסירתו.
              </li>
              <li>
                אני מצהיר שקראתי את תנאי השימוש לעיל והנני מאשר ומסכים לכל האמור
                בהם.
              </li>
              <li>
                אני מאשר כי קיבלתי מסמך הסבר בדבר זכויותיי לפי סעיף 12(ב) בחוק
                מידע גנטי. (המסמך נשלח במייל לאחר ביצוע העסקה).
              </li>
              <li>
                אני מאשר כי עותק חתום של ההסכם ישלח אליי בדואר אלקטרוני לאחר
                חתימה על הסכם זה.
              </li>
              <li>
                הנני נותן/ת בזאת את הסכמתי לביצוע הבדיקה הגנטית הנ״ל והנני
                מצהיר/ה כי הסכמתי לביצוע הבדיקה הגנטית הנ״ל ניתנה מרצוני החופשי
                לאחר שהוסבר לי והבנתי את כל האמור לעיל.
              </li>
            </ol>
          </li>
        </ol>
        <br /><br />
        <div class="d-flex-between">
          <strong
            >שם הנבדק:
            <span class="underline">{{ user.firstName }}</span></strong
          >
          <strong
            >מס׳ תעודת זהות:
            <span class="underline">{{ user.socialSecurityNum }}</span></strong
          >
          <strong
            >תאריך:
            <span class="underline">{{
              today | date : dateFormat
            }}</span></strong
          >
        </div>

        <div class="signature">
          <div
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
            "
          >
            <strong>חתימה:</strong>
            <button
              mat-button
              (click)="clearSig()"
              type="button"
              style="padding: 0; text-decoration: underline"
            >
              ניקוי חתימה
            </button>
          </div>

          <div>
            <app-canvas-drawing-thumb #signaturePad></app-canvas-drawing-thumb>
            <div class="error" *ngIf="sigError">
              אנא חתום כדי להמשיך לשלב הבא
            </div>
          </div>
        </div>
        <div style="text-align: center">
          <button
            (click)="moveToPrivacyPolicy()"
            class="btn btn--fill"
            mat-button
            type="button"
          >
            עבור למדיניות פרטיות
          </button>
        </div>
      </div>
    </div>

    <div #top [class.hidden]="signatureStep != 2" class="page-title">
      מדיניות פרטיות
    </div>

    <div class="privacy-policy" [class.hidden]="signatureStep != 2">
      <br />
      <p style="text-align: center">
        <strong
          >נספח לתנאי שימוש - מדיניות פרטיות, שמירת נתונים וויתור על זכויות
        </strong>
      </p>
      <p>&nbsp;</p>
      <ol class="main">
        <li>
          <span
            >החברה תפעל תוך הגנה על פרטיותך ולא תחלוק מידע אישי עם צד שלישי ללא
            קבלת הסכמתך מראש. החברה תשתדל להביא לידיעת צדדים שלישיים שעימם היא
            משתפת פעולה את החשיבות שבשמירת פרטיותך, אולם לא תהיה אחראית בשום
            אופן לפעולה או מחדל של כל צד שלישי מכל סוג שהם.</span
          >
        </li>
        <li>
          <span
            >החברה תהיה רשאית לשמור את דגימת הרוק, תוצאות הבדיקה וכן כל מידע
            וחומר גנטי או כל מידע וחומר אחר בקשר עמך ללא הגבלת זמן בהתאם להוראות
            הדין (להלן: </span
          ><strong>"הנתונים"</strong
          ><span
            >). הנתונים יישמרו באמצעות מאגר של החברה הרשום בפנקס מאגרי המידע
            הממשלתיים שמספרו 70068969 (להלן: </span
          ><strong>"המאגר"</strong
          ><span
            >) ויסומנו בקוד שבאמצעותו יתאפשר לקשור בינם לבין עצמם ובינם לבינך
            (להלן: "</span
          ><strong>שמירת הנתונים</strong
          ><span
            >"). החברה אינה מתחייבות התחייבות כלשהי בקשר עם שמירת הנתונים, ומעת
            שיופק הדו"ח תוכל הן למחוק ולהשמיד את כל הנתונים או חלקם לפי שיקול
            דעתה הבלעדי וללא מתן הודעה מוקדמת. נתונים שיימחקו יהיו בלתי ניתנים
            לשחזור.</span
          >
        </li>
        <li>
          <span
            >באפשרותך להורות בכל עת לחברה למחוק מהמאגר את הנתונים בקשר עימך,
            ,ובמקרה כאמור החברה תימחק את הנתונים תוך זמן סביר בנסיבות העניין,
            בהתאם ליכולתה והוראות כל דין. הוראה כאמור תינתן בהודעה בדואר
            אלקטרוני שתישלח על ידך מכתובת הדוא"ל לחברה בלבד או באמצעות
            האפליקציה, תחת ״פרופיל אישי״ בלחיצה על ״השמד נתוני בדיקה״. נתונים
            שיימחקו יהיו בלתי ניתנים לשחזור ולכן לפני מחיקת הנתונים עליך לוודא
            כי בידך עותק של תוצאות הדוח הגנטי שביצעת בפורמט PDF או מודפס.</span
          >
        </li>
        <li>
          <span
            >הבדיקה והדו"ח מבוססים על מחקרים בעלי אופי חדשני. המשך מחקר יסייע
            במיטובם והוא עשוי אף להעלות כי הם או המחקרים הקיימים עליהם הם
            מבוססים הינם בלתי שלמים או בלתי מדויקים או בעלי משמעות שונה מכפי
            שהובנו. ייתכן שהחברה או צדדים שלישיים שעימם היא משתפת פעולה יבצעו
            שימוש בנתונים גנטיים, אישיים או בחומרים מכל סוג בקשר עמך המצויים
            בידה,  והינך מאשר בזאת כל שימוש בנתונים מכל סוג לצרכי מחקר כאמור.
            אתה לא תרכוש כל זכות ולא תהיה זכאי לקבל כל תמורה בשל שימוש בנתונים
            או בשל כל דבר שיופק מהם או באמצעותם או בעקבותיהם.</span
          >
        </li>
        <li>
          <span
            >הינך מוותר בזאת כלפי החברה או כל צד שלישי שעימם היא תשתף פעולה על
            כל בעלות, קניין רוחני, זכות לקבלת תמורה, טענות לחיסיון, פגיעה בכבוד,
            פגיעה בפרטיות, פגיעה בצנעת הפרט וכל טענה אחרת העשויה לעמוד לרשותך
            מכוח חוק זכות יוצרים, תשס"ח-2007, סעיפים 1 ו-2 בחוק הגנת הפרטיות,
            תשמ"א-1981, סע' 7(א) בחוק-יסוד: כבוד האדם וחירותו, חסיון לפי פקודת
            הראיות [נוסח חדש], תשל"א-1971 או טענה אחרת לפי כל דין וכן על כל זכות
            אחרת, שעומדים ושיעמדו לך בקשר עם הנתונים, מידע, תמונות, חומרים,
            משובים, רעיונות והצעות שיגיעו ממך וכל כיוצא באלה, לרבות בשל שימוש
            בהם או בשל כל דבר שיקרה בעקבות העסקה או הסכם זה. ויתור מצידך כאמור
            הינו תנאי יסודי בהסכם זה.</span
          >
        </li>
      </ol>
      <p>&nbsp;</p>
      <p>
        <span style="font-weight: 400"
          >אני מאשר/ת בזאת כי קראתי ואני ומסכים למדיניות הפרטיות ותנאי ביטול
          העסקה כאמור לעיל.&nbsp;</span
        >
      </p>
      <p>&nbsp;</p>
      <div class="d-flex-between">
        <strong
          >שם הנבדק: <span class="underline">{{ user.firstName }}</span></strong
        >
        <strong
          >מס׳ תעודת זהות:
          <span class="underline">{{ user.socialSecurityNum }}</span></strong
        >
        <strong
          >תאריך:
          <span class="underline">{{ today | date : dateFormat }}</span></strong
        >
      </div>
      <div class="signature">
        <div
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;
          "
        >
          <strong style="margin-left: 10px">חתימה:</strong>
          <button
            mat-button
            (click)="clearSig2()"
            style="padding: 0; text-decoration: underline"
            type="button"
          >
            ניקוי חתימה
          </button>
        </div>

        <div>
          <!--
          <signature-pad
            [options]="signaturePadOptions"
            (onBeginEvent)="drawStart()"
            (onEndEvent)="sig1drawComplete()"
          ></signature-pad>-->
          <!--
          <canvas #signaturePad2></canvas>
          -->
          <!--
          <signature-pad
            #signaturePad2
            [options]="signaturePadOptions"
            (onBeginEvent)="drawStart()"
            (onEndEvent)="drawComplete()"
          ></signature-pad>
        -->
          <!-- <app-signature-thumb></app-signature-thumb>-->
          <app-canvas-drawing-thumb #signaturePad2></app-canvas-drawing-thumb>
          <div class="error" *ngIf="sigError">אנא חתום כדי להמשיך לשלב הבא</div>
        </div>
      </div>

      <div style="text-align: center">
        <button
          class="btn btn--fill"
          mat-button
          (click)="moveToCancelDeal()"
          type="button"
        >
          המשך
        </button>
      </div>
    </div>
    <div #top [class.hidden]="signatureStep != 3" class="page-title">
      תנאי ביטול עסקה
    </div>
    <div class="cancel-deal" [class.hidden]="signatureStep != 3">
      <br />
      <p style="text-align: center">
        <strong>נספח לתנאי שימוש - תנאי ביטול עסקה</strong>
      </p>
      <ol class="main">
        <li>
          <span
            >עסקה זו ניתנת לביטול בתוך 14 ימים קלנדריים ממועד ביצועה ובלבד שערכת
            הבדיקה לא נפתחה. לאחר שחלפו 14 ימים ממועד ביצועה לא ניתן לבטל את
            העסקה. כמו כן לא ניתן לבטל את העסקה במקרה בו נפתחה ערכת הבדיקה, שכן
            היא בגדר "טובין פסידים" לפי סעיף 14ג(ד)(1) בחוק הגנת הצרכן.</span
          >
        </li>
        <li>
          <span
            >במקרה של ביטול עסקה בתוך 14 (ארבעה עשר) ימים קלנדריים מיום ביצועה
            יוחזר לך התשלום ששילמת בפועל לחברה, בקיזוז דמי ביטול בשיעור של 5% או
            100 ₪, הנמוך מביניהם.</span
          >
        </li>
        <li>
          <span
            >ביטול עסקה יבוצע באמצעות שליחת הודעה לחברה אל כתובת הדוא"ל
            Support@mygenes.co.il.</span
          >
        </li>
        <li>
          <span
            ><span
              >מבלי לגרוע מהאמור לעיל, העסקה עשויה לכלול אפשרות להשתמש באתר,
              ,באפליקציה ובמידע המצוי בו – דברים שהינם בגדר "מידע" כהגדרתו בחוק
              המחשבים, תשנ"ה-1995, ובהתאם לכך חל על העסקה סעיף 14ג(ד)(3) בחוק
              הגנת הצרכן, השולל אפשרות לבטלה. כמו כן, הדו"ח הגנטי או המלצות
              תזונה הינם בגדר טובין המיוצרים ומאופיינים במיוחד עבור הלקוח בעקבות
              העסקה, ובהתאם לכך, חל על העסקה סעיף 14ג(ד)(4) בחוק הגנת הצרכן,
              השולל אפשרות לבטלה.</span
            ></span
          >
          <p>&nbsp;</p>
        </li>
      </ol>
      <p dir="rtl">
        אני מאשר/ת בזאת כי קראתי ואני ומסכים לתנאי ביטול העסקה כאמור לעיל.
      </p>
      <br />
      <div class="d-flex-between">
        <strong
          >שם הנבדק: <span class="underline">{{ user.firstName }}</span></strong
        >
        <strong
          >מס' תעודת זהות:
          <span class="underline">{{ user.socialSecurityNum }}</span></strong
        >
        <strong
          >תאריך:
          <span class="underline">{{ today | date : dateFormat }}</span></strong
        >
      </div>
      <div class="signature">
        <div
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;
          "
        >
          <strong>חתימה:</strong>
          <button
            mat-button
            style="text-decoration: underline; padding: 0"
            (click)="clearSig3()"
            type="button"
          >
            ניקוי חתימה
          </button>
        </div>
        <div>
          <!--
          <signature-pad
            [options]="signaturePadOptions"
            (onBeginEvent)="drawStart()"
            (onEndEvent)="sig1drawComplete()"
          ></signature-pad>-->
          <!--
          <canvas #signaturePad2></canvas>
          -->
          <!--
          <signature-pad
            #signaturePad2
            [options]="signaturePadOptions"
            (onBeginEvent)="drawStart()"
            (onEndEvent)="drawComplete()"
          ></signature-pad>
        -->
          <!-- <app-signature-thumb></app-signature-thumb>-->
          <app-canvas-drawing-thumb #signaturePad3></app-canvas-drawing-thumb>
          <div class="error" *ngIf="sigError">אנא חתום כדי להמשיך לשלב הבא</div>
        </div>
      </div>

      <div style="text-align: center">
        <button
          class="btn btn--fill"
          [disabled]="isLoading$ | async"
          mat-button
          (click)="onSubmitSig()"
          type="button"
        >
          שלח
        </button>
      </div>
    </div>
  </div>
</div>
<canvas
  style="visibility: hidden"
  id="myCanvas"
  #myCanvas
  width="100"
  height="30"
></canvas>
