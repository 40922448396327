import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-eating-diary',
  templateUrl: './eating-diary.component.html',
  styleUrls: ['./eating-diary.component.scss']
})
export class EatingDiaryComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }
  backBtn() {
    this.router.navigate(['home']);
  }
}
