import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { Observable } from 'rxjs';
import { GoogleCaptcha } from 'src/app/data/GoogleRecaptcha';
import { RegularUser } from 'src/app/data/RegularUser';
import { LoginUserFromUI } from 'src/app/Redux/Actions/regular-user.actions';
import {
  selectRedirectObj,
  selectUser,
  selectUserError,
  selectUserIsLoading,
} from 'src/app/Redux/selectors/user.selectors';
import { BackBtnService } from 'src/app/Services/back-btn.service';
import { GoogleCaptchaService } from 'src/app/Services/google-captcha.service';
import { RegularUsersService } from 'src/app/Services/regular-users.service';

//import { TestGoogleMapApiComponent } from '../Manage/test-google-map-api/test-google-map-api.component';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent implements OnInit {
  isLoading$: Observable<boolean> = this.store.select(selectUserIsLoading);
  error$: Observable<boolean> = this.store.select(selectUserError);
  redirectObj$: Observable<any> = this.store.select(selectRedirectObj);
  loginForm: FormGroup;
  isLoading: boolean = false;
  formError: boolean = false;
  redirectObj: any = null;
  hide = true;
  currUser$: Observable<RegularUser> = this.store.select(selectUser);
  isSubmited: boolean;
  constructor(
    private regularUsersService: RegularUsersService,
    private router: Router,
    private store: Store,
    private backButtonService: BackBtnService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private googleCaptchaService: GoogleCaptchaService
  ) {
    this.currUser$.subscribe(
      (data) => {},
      (err) => {
        //this.isLoading = false;
        this.formError = true;
      }
    );
  }
  sendCaptchaToVerify() {
    this.recaptchaV3Service
      .execute('importantAction')
      .subscribe((token: string) => {
        const captcha = new GoogleCaptcha();
        captcha.response = token;
        this.googleCaptchaService
          .VerifyingGoogleCaptchaResponse(captcha)
          .subscribe((data) => {
            this.doLogin();
          });
        //console.debug(`Token [${token}] generated`);
      });
  }
  ionViewWillEnter() {
    this.backButtonService.quitOnBackButton = true;
  }
  ionViewWillLeave() {
    this.backButtonService.quitOnBackButton = false;
  }

  ngOnInit(): void {
    this.createFormGroup();
  }
  createFormGroup() {
    this.loginForm = new FormGroup({
      userName: new FormControl(null, [Validators.required]),
      password: new FormControl(null, [Validators.required]),
    });
  }
  get userName() {
    return this.loginForm.get('userName');
  }
  get password() {
    return this.loginForm.get('password');
  }
  getFalse() {}
  doLogin() {
    if (!this.loginForm.valid) return;

    //this.isLoading = true;
    var user = new RegularUser();
    user.userName = this.loginForm.value.userName;
    user.password = this.loginForm.value.password;

    this.store.dispatch(
      LoginUserFromUI({ userName: user.userName, password: user.password })
    );
  }
}
