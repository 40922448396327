import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
@Component({
  selector: 'app-autocomplete-address',
  templateUrl: './autocomplete-address.component.html',
  styleUrls: ['./autocomplete-address.component.scss'],
})
export class AutocompleteAddressComponent implements OnInit, OnChanges {
  @ViewChild('inputField') inputField: ElementRef;
  @ViewChild('valueComplete') valueComplete: MatMenuTrigger;
  @Input() data: string[];
  @Input() label: string;
  @Input() initialValue: string;
  @Input() widthExp: string;
  @Output() selectedValue: EventEmitter<any> = new EventEmitter<any>();
  @Output() searchTerm: EventEmitter<any> = new EventEmitter<any>();
  openMenu: boolean = true;
  autocomleteError: boolean;
  constructor() {}
  ngOnChanges(changes: SimpleChanges): void {
    /* if (changes.data?.currentValue?.length > 0) {
      this.openMenu = true;
    } else {
      this.openMenu = false;
    } */
  }

  ngOnInit(): void {}

  onValueAutocompleteChanged(event: any) {
    var search = event.target.value;
    this.searchTerm.emit(search);
    this.openMenu = true;

    clearTimeout(timeout);
    var timeout = setTimeout(() => {
      if (this.data.length > 0) {
        this.autocomleteError = false;
      } else {
        this.autocomleteError = true;
      }
    }, 500);
  }
  onFocusOut(event: any) {
    clearTimeout(timeout);
    var timeout = setTimeout(() => {
      if (this.data.length > 0) {
        this.autocomleteError = false;
      } else {
        this.autocomleteError = true;
      }
    }, 500);
  }

  onValueSelected(selectedValue: string) {
    this.inputField.nativeElement.value = selectedValue;
    this.openMenu = false;
    this.autocomleteError = false;
    /*  var valuesDup = [...this.data];
    var value = valuesDup.filter((val) => {
      return val == selectedValue;
    }); */
    this.selectedValue.emit(selectedValue);
  }
}
