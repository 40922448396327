<mat-card class="weight-info">
  <div class="border-end">
    <div class="weight-info__card current">
      <div class="weight-info__card__title">
        <img src="../../../../assets/info-smily.svg" alt="monitor icon" />
        <div class="weight-info__card__title__text">משקל נוכחי:</div>
      </div>
      <div class="weight-info__card__value">
        <div class="weight-info__card__value__text">
          {{ patient && patient.currentWeight }} <small>ק"ג</small>
          <small> {{ getWeightProcess() }} </small>
        </div>
        <button routerLink="/current-weight" mat-icon-button>
          <img src="../../../assets/edit-outline.svg" alt="" />
        </button>
      </div>
    </div>
    <div class="weight-info__card goal">
      <div class="weight-info__card__title">
        <img src="../../../../assets/info-flag.svg" alt="flag icon" />
        <div class="weight-info__card__title__text">משקל יעד:</div>
      </div>
      <div class="weight-info__card__value">
        <div class="weight-info__card__value__text">
          <ng-container
            *ngIf="patient && patient.goalWeight; else goalWeightMissing"
            >{{ patient && patient.goalWeight }}
            <small>ק"ג</small></ng-container
          >
          <ng-template #goalWeightMissing>לא הוגדר משקל יעד</ng-template>
        </div>
        <button routerLink="/update-goal" mat-icon-button>
          <img src="../../../assets/edit-outline.svg" alt="" />
        </button>
      </div>
    </div>
  </div>
</mat-card>
