import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, EmptyError, Observable, of } from 'rxjs';
import { mergeMap, map, catchError, switchMap, tap } from 'rxjs/operators';
import { ErrorMessage } from 'src/app/data/ErrorMessage';
import { RegularUser } from 'src/app/data/RegularUser';
import { GlobalParamsService } from 'src/app/Services/global-params.service';
import { RegularUsersService } from 'src/app/Services/regular-users.service';
import { LoadProduct } from '../Actions/product.actions';
import {
  GlobalParamsLoaded,
  LoadGlobalParams,
  LoginError,
  LoginServerError,
  LoginUserComplete,
  LoginUserFromStorage,
  ResetPassword,
  UserAction,
  UserActionTypes,
  ResetPasswordFromSms,
  LoginUserFromUI,
  EmptyAction,
} from '../Actions/regular-user.actions';
import { LoadServiceRequests } from '../Actions/service-request.actions';
import { AppState } from '../reducers';

@Injectable()
export class UserEffects {
  @Effect()
  loginFromStorage$ = this.actions$.pipe(
    ofType<UserAction>(UserActionTypes.LoginUserFromStorage),
    switchMap((action) => {
      const _user: RegularUser = new RegularUser();
      const loggedUser = this.regularUsersService.getUserStorage();

      if (loggedUser || (action.userName && action.password)) {
        _user.userName = loggedUser?.userName
          ? loggedUser.userName
          : action.userName;
        _user.password = loggedUser?.password
          ? loggedUser.password
          : action.password;
        return this.regularUsersService.validateFromStorage(_user).pipe(
          map((res) => {
            if ((res as RegularUser).firstName) {
              var user = res as RegularUser;
              return LoginUserComplete({ user: user });
            } else {
              var err = res as ErrorMessage;
              if (err.message == 'Reset Password') {
                var jsonObj = JSON.parse(err.innerExeption);
                this.router.navigate(['/register'], {
                  queryParams: {
                    idNum: jsonObj.idNum,
                    phone: jsonObj.phone,
                  },
                });
                return ResetPassword({
                  errorMessage: err,
                  redirectObj: err.innerExeption,
                });
              } else {
                return LoginError({ errorMessage: err });
              }
            }
          })
          /*
            switchMap(() => [
              LoginUserComplete({ user: user }),
                new LoadProduct({ patientId: user.patient.patientId });
                ]    );*/
          /*
            this.store.dispatch(LoginUserComplete({ user: user }));
            this.store.dispatch(
              new LoadProduct({ patientId: user.patient.patientId })
            );
            */
          /*
        mergeMap((user) => [
          LoginUserComplete({ user: user }),
          new LoadProduct({ patientId: user.patient.patientId }),
        ]),
        catchError((errorMessage) =>
          of(LoginError({ errorMessage: errorMessage }))
        )
      );*/
        );
      } else {
        return of();
      }
    })
  );

  @Effect()
  resetPasswordFromSms$ = this.actions$.pipe(
    ofType<UserAction>(UserActionTypes.ResetPasswordFromSms),
    switchMap((action) => {
      var _user: RegularUser = action.user;
      return this.regularUsersService.resetPassFromSms(_user).pipe(
        map(() => {
          return LoginUserFromUI({
            userName: _user.userName,
            password: _user.password,
          });
          /* return this.regularUsersService.validateFromLogin(data).pipe(
            map((res) => {
              if ((res as RegularUser).firstName) {
                var user = res as RegularUser;
                //this.router.navigate(['home']);
                return LoginUserComplete({ user: user });
              }
            })
          ); */
        })
      );
    })
  );

  @Effect()
  loginFromUI$ = this.actions$.pipe(
    ofType<UserAction>(UserActionTypes.LoginUserFromUI),
    switchMap((action) => {
      var _user: RegularUser = new RegularUser();
      _user.userName = action.userName;
      _user.password = action.password;
      return this.regularUsersService.validateFromLogin(_user).pipe(
        map((res) => {
          if ((res as RegularUser).firstName) {
            var user = res as RegularUser;
            return LoginUserComplete({ user: user });
          } else {
            var err = res as ErrorMessage;
            if (err.message == 'Reset Password') {
              var jsonObj = JSON.parse(err.innerExeption);
              this.router.navigate(['/register'], {
                queryParams: {
                  idNum: jsonObj.idNum,
                  phone: jsonObj.phone,
                },
              });
              return ResetPassword({
                errorMessage: err,
                redirectObj: err.innerExeption,
                //  redirectObj: jsonObj,
                // redirectObj: null,
              });
            } else {
              return LoginError({ errorMessage: err });
            }
          }
        })
        /* catchError((errorMessage: string) =>{
          console.log(errorM);
          of(LoginServerError({ errorMessage }))
        }) */
      );
    })
  );

  @Effect()
  loginUserComplete$ = this.actions$.pipe(
    ofType<UserAction>(UserActionTypes.LoginUserComplete),
    switchMap((action) => {
      //console.log('load product from loginUserComplete');
      //console.log(localStorage.getItem('currentUser'));
      return of(
        LoadGlobalParams(),
        new LoadProduct({ patientId: action.user.patient.patientId }),
        LoadServiceRequests({
          productId: action.user.patient.products[0].productId, // todo pharma: bring service request from patientId
        })
      );
    })
  );

  @Effect()
  loadGlobalParams$ = this.actions$.pipe(
    ofType<UserAction>(UserActionTypes.LoadGlobalParams),
    mergeMap(() =>
      this.globalParamsService.getAll().pipe(
        map((globalParams) => GlobalParamsLoaded({ globalParams })),
        catchError(() => EMPTY)
      )
    )
  );

  @Effect()
  logout$ = this.actions$.pipe(
    ofType<UserAction>(UserActionTypes.LogoutUser),
    mergeMap(() =>
      this.regularUsersService.emptyOf().pipe(
        map(() => {
          //console.log('from logout effect');

          localStorage.removeItem('currentUser');
          localStorage.removeItem('access_token');

          setTimeout(() => {
            this.router.navigate(['/login']);
          }, 100);

          return EmptyAction();
        }),
        catchError(() => EMPTY)
      )
    )
  );

  loginError$ = this.actions$.pipe(
    ofType<UserAction>(UserActionTypes.LoginError),
    map(() => {
      this.router.navigate(['/login']);
    })
  );

  /*  @Effect()
  logout$ = this.actions$.pipe(
    ofType<UserAction>(UserActionTypes.LogoutUser),
    map(() => {
      localStorage.removeItem('currentUser');
      localStorage.removeItem('access_token');
      this.router.navigate(['/login']);
      return new EmptyAction();
    })
  ); */

  /*@Effect()
  openServiceRequest$ = this.actions$.pipe(
    ofType(ServiceRequestsActionTypes.OpenServiceRequest),
    switchMap((action: ServiceRequestsAction) =>
      this.serviceRequestService.createNewRequest(action.patientId).pipe(
        switchMap((ans) => [
          OpenServiceRequestComplete({ serviceRequest: ans }),
          SelectServiceRequest({
            serviceRequestId: ans.serviceRequestId,
          }),
        ]),
        catchError((error) => of(LoadServiceRequestsError(error)))
      )
    )
  ); */
  /*
  @Effect()
  loginUserComplete$ = this.actions$.pipe(
  /*

  @Effect()
  loginFromUI$ = this.actions$.pipe(
    ofType<UserAction>(UserActionTypes.LoginUserFromUI),
    switchMap((action) => {
      var _user: RegularUser = new RegularUser();
      _user.userName = action.userName;
      _user.password = action.password;
      return this.regularUsersService.validateFromLogin(_user).pipe(
        tap((res) => {
          if ((res as RegularUser).firstName) {
            var user = res as RegularUser;
            this.store.dispatch(LoginUserComplete({ user: user }));
            this.store.dispatch(
              new LoadProduct({ patientId: user.patient.patientId })
            );
          } else {
            var err = res as ErrorMessage;
            if (err.message == 'Reset Password') {
              var jsonObj = JSON.parse(err.innerExeption);
              return ResetPassword({
                errorMessage: err,
                // redirectObj: jsonObj,
                redirectObj: null,
              });
            } else {
              return new LoginError({ errorMessage: err });
            }
          }
        })
      );
    })
  );
  */
  /*
  @Effect()
  redirectToReset$ = this.actions$.pipe(
    ofType(UserActionTypes.ResetPassword),
    ofType<UserAction>(UserActionTypes.LoginUserFromStorage),
    map((action) => {
     
    })
  );
*/
  /*
  mergeMap((user) => [
    new LoginUserComplete({ user: user }),
    new LoadProduct({ patientId: user.patient.patientId }),
  ]),
  catchError((errorMessage) =>
    of(new LoginError({ error: errorMessage }))
  )
  */

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private regularUsersService: RegularUsersService,
    private router: Router,
    private globalParamsService: GlobalParamsService
  ) {}
}

//constructor(private actions$: Actions) {}
