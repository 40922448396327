import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Patient } from 'src/app/data/Patients';
import { RegularUser } from 'src/app/data/RegularUser';
import { LoadProduct } from 'src/app/Redux/Actions/product.actions';
import {
  selectPatient,
  selectProductIsLoading,
} from 'src/app/Redux/selectors/product.selectors';
import { selectUser } from 'src/app/Redux/selectors/user.selectors';
import { PatientsService } from 'src/app/Services/patients.service';

@Component({
  selector: 'app-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.scss'],
})
export class TermsOfUseComponent implements OnInit {
  @ViewChild('signaturePad') signaturePad: any;
  @ViewChild('signaturePad2') signaturePad2: any;
  @ViewChild('signaturePad3') signaturePad3: any;
  @ViewChild('myCanvas') myCanvas: any;
  @ViewChild('top') top: any;
  doc1page: number = 1;
  today: number = Date.now();
  signatureStep: number = 1;
  signatureMode: boolean = false;
  sigError: boolean = false;
  intervalSig;
  nameDataUri: string;
  dateFormat: string = 'dd/MM/yyyy';
  private signaturePadOptions: Object = {
    // passed through to szimek/signature_pad constructor
    minWidth: 5,
    canvasWidth: 500,
    canvasHeight: 300,
  };
  patient$: Observable<Patient> = this.store.select(selectPatient);
  user$: Observable<RegularUser> = this.store.select(selectUser);
  isLoading$: Observable<boolean> = this.store.select(selectProductIsLoading);
  user: RegularUser;
  patient: Patient;
  constructor(
    private store: Store,
    private patientsService: PatientsService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.user$.subscribe((data) => {
      if (data) {
        this.user = data;
        this.patient = this.user.patient;

        setTimeout(() => {
          var canvas = document.getElementById('myCanvas') as HTMLCanvasElement;

          var context: any = this.myCanvas.nativeElement.getContext('2d');
          context.font = '16px Arial';
          var name = this.user.firstName + ' ' + this.user.lastName;
          context.fillText(name, 90, 18);

          this.nameDataUri =
            this.myCanvas.nativeElement.toDataURL("'image/png'");
        }, 100);
      }
    });
    this.checUserTimeZone();
  }

  checUserTimeZone() {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    if (userTimeZone === 'Asia/Jerusalem') {
      this.dateFormat = 'dd/MM/yyyy';
    } else {
      this.dateFormat = 'MM/dd/yyyy';
    }
  }
  async onSubmitSig() {
    if (this.signaturePad3.isEmpty()) {
      // console.log('isEmpty');
      this.sigError = true;
    } else {
      this.sigError = false;
      // console.log(this.sigPad.toDataURL("'image/png'"));
      // console.log(this.sigPad2.toDataURL("'image/png'"));
      var sigObj: any = new Object();
      /*
      var c: any = document.getElementById('myCanvas');
      var ctx = c.getContext('2d');
      ctx.font = '30px Arial';
      ctx.fillText('Your Text', 10, 50);

      // save img
      Canvas2Image.saveAsImage(c, 200, 100, 'png');
*/

      //   const textToPicture = require('text-to-picture');

      /*
      const result = await convert({
        text:
          this.patient.regularUser.firstName +
          '' +
          this.patient.regularUser.lastName,
      });
      const str = await result.getBase64();

      console.log(str); // data:image/png;base64,iVBORw0KGgoA...

      // useful for http servers
      const buf = await result.getBuffer();
      // http response object
      */

      sigObj.patientId = this.patient.patientId;
      sigObj.nameImg = this.nameDataUri;
      sigObj.sig1 = this.signaturePad.toDataURL();
      sigObj.sig2 = this.signaturePad2.toDataURL();
      sigObj.sig3 = this.signaturePad3.toDataURL();
      this.patientsService.uploadSignatures(sigObj).subscribe((res) => {
        this.store.dispatch(
          new LoadProduct({ patientId: this.patient.patientId })
        );
        this.router.navigate(['home']);
      });
      //this.signatureForm.controls.signature.setValue(this.sigPad.toDataURL("'image/png'"));
      //console.log(this.sigPad.toDataURL("'image/png'"));
    }
  }

  clearSig() {
    this.signaturePad.clear();
  }
  clearSig2() {
    this.signaturePad2.clear();
  }
  clearSig3() {
    this.signaturePad3.clear();
  }

  moveToPrivacyPolicy() {
    if (this.signaturePad.isEmpty()) {
      this.sigError = true;
    } else {
      this.sigError = false;
      this.signatureStep = 2;
      this.scrollToTop();
      /*
      setTimeout(() => {
        this.top.nativeElement.scrollIntoView({ behavior: 'smooth' });
      }, 50);
      */
    }
  }
  moveToCancelDeal() {
    if (this.signaturePad2.isEmpty()) {
      this.sigError = true;
    } else {
      this.sigError = false;
      this.signatureStep = 3;
      this.scrollToTop();
    }
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView({ behavior: 'smooth' });
  }
  gotoPage(val) {
    const matDrawer = document.querySelector('.mat-drawer-content');
    matDrawer.scrollTo({ top: 0, behavior: 'smooth' });
    this.doc1page = val;
    this.scrollToTop();
  }
  scrollToTop() {
    /* document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    window.scrollTo(0, 0); */
    const matDrawer = document.querySelector('.mat-drawer-content');
    matDrawer.scrollTo({ top: 0, behavior: 'smooth' });
  }
  scrollToTopnotWorking() {
    (function smoothscroll() {
      var currentScroll =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0) {
        window.requestAnimationFrame(smoothscroll);
        window.scrollTo(0, currentScroll - currentScroll / 8);
      }
    })();
  }
}
