import { RouterState } from '@angular/router';
import { routerReducer } from '@ngrx/router-store';
import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { Product } from 'src/app/data/Product';
import { RegularUser } from 'src/app/data/RegularUser';
import { environment } from 'src/environments/environment';
import { LogoutUser, UserActionTypes } from '../Actions/regular-user.actions';
import { dnaReportReducer, DnaReportState } from './dna-report.reducer';
import { lifeStyleReducer, LifeStyleState } from './life-style.reducer';
import { productReducer, ProductState } from './product.reducer';
import * as fromRouter from '@ngrx/router-store';

import {
  ServiceRequestsReducer,
  ServiceRequestsState,
} from './service-request.reducer';
import { userReducer, UserState } from './user.reducer';
import {
  lifestyleQuestionReducer,
  LifestyleQuestionState,
} from './lifestyle-question.reducer';

export const appStoreFeatureKey = 'appStore';

export interface AppState {
  user: UserState;
  product: ProductState;
  lifeStyle: LifeStyleState;
  dnaReport: DnaReportState;
  serviceRequests: ServiceRequestsState;
  router: fromRouter.RouterReducerState<any>;
  lifestyleQuestions: LifestyleQuestionState;
}

export const reducers: ActionReducerMap<AppState> = {
  user: userReducer,
  product: productReducer,
  lifeStyle: lifeStyleReducer,
  lifestyleQuestions: lifestyleQuestionReducer,
  dnaReport: dnaReportReducer,
  serviceRequests: ServiceRequestsReducer,
  router: routerReducer,
};
export function clearState(reducer) {
  return function (state, action) {
    if (action.type === UserActionTypes.LogoutUser) {
      state = undefined;
    }

    return reducer(state, action);
  };
}
export const metaReducers: MetaReducer<AppState>[] = !environment.production
  ? []
  : [];
