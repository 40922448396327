import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AlgoUserSummary } from '../data/AlgoUserSummary';
import { AllLabResultUserSummary } from '../data/AllLabResultUserSummary';
import { BulletCrossResult } from '../data/BulletCrossResult';
import { SubCategoryForApp } from '../data/SubCategoryForApp';
import { TubeTestResultForApp } from '../data/TubeTestResultForApp';

export interface resultStat {
  subCategoryForApp: SubCategoryForApp[];
  tubeTestResultForApp: TubeTestResultForApp[];
}
@Injectable({
  providedIn: 'root',
})
export class AlgorithmService {
  constructor(private http: HttpClient) {}

  getUserSummaryStatByCat(
    barcode: string,
    langId: number,
    imgType: number,
    catId = -1
  ) {
    // catId = -1 - all categories
    return this.http.get<AlgoUserSummary | AlgoUserSummary[]>(
      `${environment.baseUrl}Algorithm/SummaryUserStatByCat/${barcode}/${catId}/${langId}/${imgType}`
    );
  }
  getResultStatByBarcode(barcode: string, catId: number, langId: number) {
    return this.http.get<SubCategoryForApp[]>(
      `${environment.baseUrl}Algorithm/StatResultByBarcodeGetApp/${barcode}/${catId}/${langId}`
    );
  }
  getAlgoCrossingGroupByBarcode(barcode: string, langId: number = 1) {
    return this.http.get<BulletCrossResult[]>(
      `${environment.baseUrl}Algorithm/GetAlgoCrossingGroupByBarcode/${barcode}/${langId}`
    );
  }
  getUserLabResultCounts(barcode: string, langId: number) {
    return this.http.get<AllLabResultUserSummary>(
      `${environment.baseUrl}Algorithm/UserLabResultCounts/${barcode}/${langId}`
    );
  }
}
