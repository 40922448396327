import { FormControl, AbstractControl } from '@angular/forms';
import { RegularUser } from '../data/RegularUser';

export function matchOtherValidator(otherControlName: string) {
  let thisControl: FormControl;
  let otherControl: FormControl;

  return function matchOtherValidate(control: FormControl) {
    if (!control.parent) {
      return null;
    }

    // Initializing the validator.
    if (!thisControl) {
      thisControl = control;
      otherControl = control.parent.get(otherControlName) as FormControl;
      if (!otherControl) {
        throw new Error(
          'matchOtherValidator(): other control is not found in parent group'
        );
      }
      otherControl.valueChanges.subscribe(() => {
        thisControl.updateValueAndValidity();
      });
    }

    if (!otherControl) {
      return null;
    }

    if (otherControl.value !== thisControl.value) {
      return {
        matchOther: true,
      };
    }

    return null;
  };
}

export function passwordValidator(
  control: AbstractControl
): { [key: string]: boolean } | null {
  var regEx = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(.{8,})$/;
  if (!regEx.test(control.value)) return { passwordValidator: true };
  /*
     if( control.value !==null && (isNaN(control.value) || control.value <20  || control.value> 70)){
       return {'ageValidator': true}
     }
     */
  return null;
}
export function is_israeli_id_number(id) {
  // https://www.upnext.co.il/articles/israeli-id-numer-validation/
  id = String(id).trim();
  if (id.length > 9 || isNaN(id)) return false;
  id = id.length < 9 ? ('00000000' + id).slice(-9) : id;
  return (
    Array.from(id, Number).reduce((counter, digit, i) => {
      const step = digit * ((i % 2) + 1);
      return counter + (step > 9 ? step - 9 : step);
    }) %
      10 ===
    0
  );
}

export function isElder(age: number) {
  return age > 65 ? true : false;
}
export function getProperBmiRange(age: number) {
  const bmiRank = [
    { val: 12, color: '#5ECAEB' },
    { val: 18.5, color: '#5ECAEB' },
    { val: 25, color: '#8BD54C' },
    { val: 30, color: '#FDCD29' },
    { val: 35, color: '#F82746' },
  ];
  const bmiElderRank = [
    { val: 12, color: '#5ECAEB' },
    { val: 23, color: '#5ECAEB' },
    { val: 30, color: '#8BD54C' },
    { val: 40, color: '#FDCD29' },
  ];
  const bmiRange = isElder(age) ? bmiElderRank : bmiRank;

  return { min: bmiRange[1].val, max: bmiRange[2].val };
}

export function calcAge(birthDate: string) {
  const birthDateYear = new Date(birthDate).getFullYear();
  const d = new Date();
  const n = d.getFullYear();
  return n - birthDateYear;
}
