<ngx-charts-bubble-chart
  [view]="view"
  [scheme]="colorScheme"
  [results]="bubbleData"
 
  [yAxis]="showYAxis"
  
  
  [showYAxisLabel]="showYAxisLabel"
 
  [yAxisLabel]="yAxisLabel"
  [xScaleMin]="xScaleMin"
  [xScaleMax]="xScaleMax"
  [yScaleMin]="yScaleMin"
  [yScaleMax]="yScaleMax"
  [minRadius]="minRadius"
  [maxRadius]="maxRadius"
  (select)="onSelect($event)"
  (activate)="onActivate($event)"
  (deactivate)="onDeactivate($event)"
  [legend]="false"
   [xAxis]="false"
   [xAxisLabel]="false"
  [showXAxisLabel]="false"
  
  
>
<!--
    [legend]="showLegend"
   [xAxis]="showXAxis"
   [xAxisLabel]="xAxisLabel"
  [showXAxisLabel]="showXAxisLabel"
  [xAxisTickFormatting]="this.dateTickFormatting"
 
-->
</ngx-charts-bubble-chart>
