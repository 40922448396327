<div class="navbar" [class.show-add-open]="showAdd">
  <button
    class="navbar__item"
    [class.show-add-open]="showAdd"
    mat-icon-button
    (click)="goToRecomendations()"
    routerLinkActive="navbar__item__link-active"
    [disabled]="dnaTestCompleted()"
  >
    <div class="navbar__item__content">
      <mat-icon [class.show-add-open]="showAdd">check_box</mat-icon>
      <span [class.show-add-open]="showAdd" class="navbar__item__content__text"
        >המלצות</span
      >
    </div>
  </button>
  <button
    [routerLink]="['/diet-menu']"
    routerLinkActive="navbar__item__link-active"
    class="navbar__item"
    [class.show-add-open]="showAdd"
    mat-icon-button
    [disabled]="!isDietMenusExist()"
  >
    <div class="navbar__item__content">
      <mat-icon [class.show-add-open]="showAdd">restaurant</mat-icon>
      <span [class.show-add-open]="showAdd" class="navbar__item__content__text"
        >תפריטים</span
      >
    </div>
  </button>
  <div style="position: relative">
    <button
      class="navbar__item navbar__item--add"
      [ngStyle]="{ 'z-index': showAdd ? '50' : '30' }"
      mat-icon-button
      (click)="toggleShowAdd()"
    >
      <div class="navbar__item--add__content" [class.show-add-open]="showAdd">
        <mat-icon [ngStyle]="{ transform: showAdd ? 'rotate(45deg)' : '' }"
          >add</mat-icon
        >
      </div>
    </button>
    <div *ngIf="showAdd" class="show-add__wrapper">
      <button
        [routerLink]="['/vod']"
        class="show-add__item show-add__item--1"
        mat-icon-button
      >
        <div class="show-add__item__content">
          <mat-icon>smart_display</mat-icon>
          <span class="show-add__item__content__text">VOD</span>
        </div>
      </button>
      <button
        [routerLink]="['/meeting-schedule']"
        class="show-add__item show-add__item--2"
        mat-icon-button
      >
        <div class="show-add__item__content">
          <mat-icon>call</mat-icon>
          <span class="show-add__item__content__text">קבע פגישת ייעוץ</span>
        </div>
      </button>
      <button class="show-add__item show-add__item--3" mat-icon-button>
        <div class="show-add__item__content">
          <mat-icon>restaurant_menu</mat-icon>
          <span class="show-add__item__content__text">מתכונים</span>
        </div>
      </button>
      <button class="show-add__item show-add__item--4" mat-icon-button>
        <div class="show-add__item__content">
          <mat-icon>restaurant</mat-icon>
          <span class="show-add__item__content__text">תפריט</span>
        </div>
      </button>
    </div>
  </div>
  <button
    mat-icon-button
    class="navbar__item"
    [class.show-add-open]="showAdd"
    [disabled]="dnaTestCompleted()"
    routerLinkActive="navbar__item__link-active"
    [routerLink]="['/dna-report']"
  >
    <div class="navbar__item__content">
      <mat-icon [class.show-add-open]="showAdd">assignment</mat-icon>

      <span [class.show-add-open]="showAdd" class="navbar__item__content__text"
        >תוצאות</span
      >
    </div>
  </button>
  <button
    [routerLink]="['/home']"
    routerLinkActive="navbar__item__link-active"
    class="navbar__item"
    [class.show-add-open]="showAdd"
    mat-icon-button
  >
    <div class="navbar__item__content">
      <mat-icon [class.show-add-open]="showAdd">home</mat-icon>
      <span [class.show-add-open]="showAdd" class="navbar__item__content__text"
        >ראשי</span
      >
    </div>
  </button>
</div>

<div *ngIf="showMore" class="show-more">
  <div class="show-more__close">
    <button (click)="toggleShowMore()" mat-icon-button>
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="show-more__links">
    <div class="show-more__links__item">
      <button mat-button>פרופיל אישי</button>
    </div>
  </div>
</div>

<div *ngIf="showAdd" class="show-add"></div>
