import { Inject, Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreModule,
} from '@angular/fire/firestore';
import { Action, createAction } from '@ngrx/store';

import { BehaviorSubject, Observable, of } from 'rxjs';
import { ChatMsg } from '../data/chat-msg';
import { IndicatorMsg } from '../data/indicator-msg';
import { RequestForTableMin } from '../data/RequestForTableMin';
import { ServiceRequestsActionTypes } from '../Redux/Actions/service-request.actions';
import * as firebase from 'firebase/app';
import { selectServiceRequests } from '../Redux/selectors/service-request.selectors';

@Injectable({
  providedIn: 'root',
})
export class FirebaseService {
  indicatorMsg$: BehaviorSubject<IndicatorMsg[]> = new BehaviorSubject(null);

  constructor(private firestore: AngularFirestore) {}

  updateChatIndicator(
    collectionName: string,
    docId: string,
    indicator: Partial<IndicatorMsg>
  ) {
    this.firestore.collection(collectionName).doc(docId).update(indicator);
  }
  setChatIndicator(
    collectionName: string,
    docId: string,
    indicator: IndicatorMsg
  ) {
    this.firestore.collection(collectionName).doc(docId).set(indicator);
  }

  getChatIndicator(productId: number) {
    /*   this.anonAuthService.isFireLogged$.subscribe((data) => {
      if (!data) return; */

    this.firestore
      .collection(
        'chatIndicator'
        //  ,   (ref) =>  ref.where("productId", "==", productId)
      )
      .snapshotChanges()
      .subscribe((res) => {
        //console.log('getChatIndicator', res);
        var chatIndicator = [];

        res.forEach((x) => {
          const docId = +x.payload.doc.id;
          //  if (this.serviceRequests.includes(docId)) {
          chatIndicator[x.payload.doc.id] = x.payload.doc.data();
          //   }
        });
        this.indicatorMsg$.next(chatIndicator);
      });
    //});
  }

  sendMessage(collectionName: string, message: ChatMsg) {
    this.firestore.collection(collectionName).add(message);
  }
}
