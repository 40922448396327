<div class="page-title">משקל יעד</div>
<div style="width: 90%; margin: 1rem auto 0">
  <ngx-skeleton-loader *ngIf="!patient" [theme]="{ height: '50px' }" count="5">
  </ngx-skeleton-loader>
</div>
<div class="goal-weight" *ngIf="patient">
  <img
    class="goal-weight__person"
    src="../../../assets/person-weight-normal.svg"
    alt=""
  />
  <div class="goal-weight__date">{{ date | date: "MMM d" }}</div>
  <div class="goal-weight__field">
    <div class="goal-weight__field__input">
      <input
        #weightInput
        [class.error]="weightError"
        [(ngModel)]="weightRuler"
        (click)="weightInput.select()"
        (keyup)="validateWeight()"
      /><span class="goal-weight__field__input__kg">Kg</span>
    </div>
  </div>
  <app-weight-ruler
    *ngIf="patient"
    [weight]="weightRuler"
    [startingWeight]="minWeight"
    [endingWeight]="maxWeight"
    (setWeight)="setWeightFromRuler($event)"
  ></app-weight-ruler>
  <button
    mat-button
    class="btn btn--big btn--fill mt-4"
    (click)="updateGoal()"
    [disabled]="weightError !== '' || (isLoading$ | async)"
  >
    <div class="loading-wrapper">
      עדכן משקל
      <mat-spinner *ngIf="isLoading$ | async" diameter="20"></mat-spinner>
    </div>
  </button>
  <div class="goal-weight__field__error" *ngIf="weightError">
    {{ weightError }}
  </div>
</div>
