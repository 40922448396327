import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<any> {
  canDeactivate(component: any): boolean {
    if (component.hasUnsavedData()) {
      if (
        confirm(
          'ייתכן שהשינויים שביצעת לא יישמרו, האם אתה בטוח שברצונך לעזוב דף זה'
        )
      ) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  }
}
