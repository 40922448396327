<div class="nutrients">
  <!-- TODO: Genetic rate title hidden -->
  <div *ngIf="!inMenuList" class="nutrients__title">
    <ng-container *ngIf="false">
      <span class="nutrients__title__text">דירוג גנטי</span>
      <span class="nutrients__title__value"
        >{{ getGeneticRate(nutrients)?.amount | number: "1.0-0" }}%</span
      >
    </ng-container>
  </div>
  <div class="nutrients__row">
    <div class="nutrients__box cal" [class.menu-list]="!inMenuList">
      <div class="nutrients__box__image">
        <img *ngIf="!inMenuList" src="../../../assets/calories.svg" alt="" />
        <span *ngIf="inMenuList" class="nutrients__box__image__dot cal"></span>
      </div>
      <div class="nutrients__box__name">קלוריות</div>
      <div class="nutrients__box__value">
        <span class="bold"
          >{{ getCalories(nutrients)?.amount | number: "1.0-0" }} </span
        ><!--/{{ getCalories(nutrients)?.dailyAmount | number: "1.0-0" }}-->
      </div>
    </div>
    <div class="nutrients__box fats" [class.menu-list]="!inMenuList">
      <div class="nutrients__box__image">
        <img *ngIf="!inMenuList" src="../../../assets/fats.svg" alt="" />
        <span class="nutrients__box__image__dot fats" *ngIf="inMenuList"></span>
      </div>
      <div class="nutrients__box__name">שומנים</div>
      <div class="nutrients__box__value fats">
        <span class="bold">{{
          getFats(nutrients)?.amount | number: "1.0-0"
        }}</span
        ><!-- /{{ getFats(nutrients)?.dailyAmount | number: "1.0-0" }} -->
        <span class="light-small">גר'</span>
      </div>
    </div>
    <div class="nutrients__box proteins" [class.menu-list]="!inMenuList">
      <div class="nutrients__box__image">
        <img *ngIf="!inMenuList" src="../../../assets/proteins.svg" alt="" />
        <span
          class="nutrients__box__image__dot proteins"
          *ngIf="inMenuList"
        ></span>
      </div>
      <div class="nutrients__box__name">חלבונים</div>
      <div class="nutrients__box__value proteins">
        <span class="bold">{{
          getHelbonim(nutrients)?.amount | number: "1.0-0"
        }}</span
        ><!-- /{{ getHelbonim(nutrients)?.dailyAmount | number: "1.0-0" }} -->
        <span class="light-small">גר'</span>
      </div>
    </div>
    <div class="nutrients__box carbs" [class.menu-list]="!inMenuList">
      <div class="nutrients__box__image">
        <img *ngIf="!inMenuList" src="../../../assets/carbs.svg" alt="" />
        <span
          class="nutrients__box__image__dot carbs"
          *ngIf="inMenuList"
        ></span>
      </div>
      <div class="nutrients__box__name">פחמימות</div>
      <div class="nutrients__box__value carbs">
        <span class="bold">{{
          getPachmimot(nutrients)?.amount | number: "1.0-0"
        }}</span
        ><!-- /{{ getPachmimot(nutrients)?.dailyAmount | number: "1.0-0" }} -->
        <span class="light-small">גר'</span>
      </div>
    </div>
  </div>
</div>

<!-- <div class="genetic-rate" [ngClass]="{ 'grid-area': inMeal }">
  <div class="genetic-rate__value one">
    <span class="text">דירוג גנטי</span>
    <span class="value"
      >{{ getGeneticRate(nutrients)?.amount | number: "1.0-0" }}%</span
    >
  </div>
  <div class="genetic-rate__bar two">
    <div class="title">
      <div class="name">
        <img src="../../../assets/calories.svg" alt="" />
        קלוריות
      </div>
      <div class="amount calories">
        {{ getCalories(nutrients)?.amount | number: "1.0-0" }}/{{
          getCalories(nutrients)?.dailyAmount | number: "1.0-0"
        }}
      </div>
    </div>
    <div class="bar calories">
      <mat-progress-bar
        mode="determinate"
        [value]="
          setBarValue(
            getCalories(nutrients)?.amount,
            getCalories(nutrients)?.dailyAmount
          )
        "
        dir="rtl"
      ></mat-progress-bar>
    </div>
  </div>
  <div class="genetic-rate__bar three">
    <div class="title">
      <div class="name">
        <img src="../../../assets/fats.svg" alt="" />
        שומנים
        <span
          >({{
            getFats(nutrients)?.unit ? getFats(nutrients)?.unit : "גרם"
          }})</span
        >
      </div>
      <div class="amount fats">
        {{ getFats(nutrients)?.amount | number: "1.0-0" }}/{{
          getFats(nutrients)?.dailyAmount | number: "1.0-0"
        }}
      </div>
    </div>
    <div class="bar fats">
      <mat-progress-bar
        mode="determinate"
        [value]="
          setBarValue(
            getFats(nutrients)?.amount,
            getFats(nutrients)?.dailyAmount
          )
        "
        dir="rtl"
      ></mat-progress-bar>
    </div>
  </div>
  <div class="genetic-rate__bar four">
    <div class="title">
      <div class="name">
        <img src="../../../assets/proteins.svg" alt="" />
        חלבונים
        <span
          >({{
            getHelbonim(nutrients)?.unit ? getHelbonim(nutrients)?.unit : "גרם"
          }})</span
        >
      </div>
      <div class="amount proteins">
        {{ getHelbonim(nutrients)?.amount | number: "1.0-0" }}/{{
          getHelbonim(nutrients)?.dailyAmount | number: "1.0-0"
        }}
      </div>
    </div>
    <div class="bar proteins">
      <mat-progress-bar
        mode="determinate"
        [value]="
          setBarValue(
            getHelbonim(nutrients)?.amount,
            getHelbonim(nutrients)?.dailyAmount
          )
        "
        dir="rtl"
      ></mat-progress-bar>
    </div>
  </div>
  <div class="genetic-rate__bar five">
    <div class="title">
      <div class="name">
        <img src="../../../assets/carbs.svg" alt="" />
        פחמימות
        <span
          >({{
            getPachmimot(nutrients)?.unit
              ? getPachmimot(nutrients)?.unit
              : "גרם"
          }})</span
        >
      </div>
      <div class="amount carbs">
        {{ getPachmimot(nutrients)?.amount | number: "1.0-0" }}/{{
          getPachmimot(nutrients)?.dailyAmount | number: "1.0-0"
        }}
      </div>
    </div>
    <div class="bar carbs">
      <mat-progress-bar
        mode="determinate"
        [value]="
          setBarValue(
            getPachmimot(nutrients)?.amount,
            getPachmimot(nutrients)?.dailyAmount
          )
        "
        dir="rtl"
      ></mat-progress-bar>
    </div>
  </div>
</div> -->
