import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EnterWeightDialogComponent } from '../Fragments/enter-weight-dialog/enter-weight-dialog.component';
import { Subject } from 'rxjs';
import { WeightHistoryDialogComponent } from '../weight-history-dialog/weight-history-dialog.component';
import { PatientWeightHistory } from '../data/PatientWeightHistory';
import { ConfirmDialogComponent } from '../Fragments/confirm-dialog/confirm-dialog.component';
import { UpgradePreviewDialogComponent } from '../Components/upgrade-preview-dialog/upgrade-preview-dialog.component';
import { PostUpgradeDialogComponent } from '../Components/post-upgrade-dialog/post-upgrade-dialog.component';
import { isMobile } from '../Utils/userAgent';
import { AlgoLinkDialogComponent } from '../Components/algo-link-dialog/algo-link-dialog.component';
import { HasNotBloodTestComponent } from '../Fragments/has-not-blood-test/has-not-blood-test.component';
import { LeadConfirmChangeDialogComponent } from '../Fragments/lead-confirm-change-dialog/lead-confirm-change-dialog.component';
import { RemoveDnaDataDialogComponent } from '../Components/remove-dna-data-dialog/remove-dna-data-dialog.component';
import { ResultsInfoComponent } from '../Fragments/results-info/results-info.component';
import { WeightGoalGraphComponent } from '../Components/weight-goal-graph/weight-goal-graph.component';
import { VideoDialogComponent } from '../Fragments/video-dialog/video-dialog.component';
import { VideoForSite } from '../data/VideoForSite';

@Injectable({
  providedIn: 'root',
})
export class PopupHandlerService {
  weightResult: Subject<number> = new Subject<number>();
  confirmDialogSubject: Subject<any> = new Subject<any>();
  removeDnaDataSubject: Subject<any> = new Subject<any>();
  upgradePreviewDialogSubject: Subject<any> = new Subject<any>();
  constructor(public dialog: MatDialog) {}

  openWeightHistoryDialog(weightHistory: PatientWeightHistory[]) {
    var height = isMobile() ? '300px' : '500px';
    var width = isMobile() ? '300px' : '750px';
    const dialogRef = this.dialog.open(WeightHistoryDialogComponent, {
      width,
      height,
      position: {},
      data: {
        weightHistory: weightHistory.filter((x) => !x.isGoal),
      },
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }
  openEnterWeightDialog(weight) {
    const dialogRef = this.dialog.open(EnterWeightDialogComponent, {
      width: '300px',
      height: '340px',
      position: {
        top: '400px',
      },
      data: {
        weight: weight,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      var a = result;
      var weight = +result.weight;
      this.weightResult.next(weight);
    });
  }
  openConfirmationDialog(type: string, resetType = null, width = null) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width,
      data: {
        type,
        resetType,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      //console.log('The dialog was closed' + result.list);
      //var a = result;
      this.confirmDialogSubject.next(result?.data?.answer);
    });
  }
  openAlgoLinkDialog(linkId, langId, color) {
    this.dialog.open(AlgoLinkDialogComponent, {
      width: '750px',
      height: '70vh',
      data: {
        linkId,
        langId,
        color,
      },
    });
  }
  openResultsInfoDialog(color: string, number: number) {
    this.dialog.open(ResultsInfoComponent, {
      /*     width: '750px',
      height: '70vh', */
      data: {
        number,
        color,
      },
    });
  }
  openHasNotBloodTest() {
    this.dialog.open(HasNotBloodTestComponent, {
      width: '700px',
      //height: '342px',
    });
  }
  openUpgradePreviewDialog() {
    const dialogRef = this.dialog.open(UpgradePreviewDialogComponent, {
      width: '450px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      //console.log('The dialog was closed' + result?.list);
      var a = result;
      this.upgradePreviewDialogSubject.next(result?.data?.answer);
    });
  }
  openPostUpgrateDialog() {
    const dialogRef = this.dialog.open(PostUpgradeDialogComponent, {});

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        //console.log('The dialog was closed' + result);
        var a = result;
        this.confirmDialogSubject.next(result.data.answer);
      }
    });
  }
  openRemoveDnaDataDialog() {
    const dialogRef = this.dialog.open(RemoveDnaDataDialogComponent, {
      width: '350px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        //console.log('The dialog was closed' + result);
        //var a = result;
        this.removeDnaDataSubject.next(result.data.answer);
      }
    });
  }
  openDisapearingAlertDialog(
    type = '',
    from = '',
    to = '',
    msg = '',
    time = 2000,
    alwaysOpen = false
  ) {
    //console.log('openDisapearingAlertDialog ', msg);

    const dialogRef = this.dialog.open(LeadConfirmChangeDialogComponent, {
      data: {
        type: type,
        from: from,
        to: to,
        msg: msg,
      },
    });
    if (!alwaysOpen) {
      setTimeout(() => {
        dialogRef.close();
      }, time);
    }

    dialogRef.afterClosed().subscribe((result) => {
      //('The dialog was closed');
      var a = result;
    });
  }
  openWeightGraphDialog() {
    this.dialog.open(WeightGoalGraphComponent, {
      width: '850px',
    });
  }
  openVideoDialog(data: VideoForSite) {
    this.dialog.open(VideoDialogComponent, {
      width: '850px',
      data,
    });
  }
}
