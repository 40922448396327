import { Location } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { PatientWeightHistory } from 'src/app/data/PatientWeightHistory';
import { Product } from 'src/app/data/Product';
import { RegularUser } from 'src/app/data/RegularUser';
import { FileUploadType, ProductTypeEnum } from 'src/app/Enum/StatusesEnum';
import { selectProduct } from 'src/app/Redux/selectors/product.selectors';
import { selectUser } from 'src/app/Redux/selectors/user.selectors';

@Component({
  selector: 'app-top-bar-mobile',
  templateUrl: './top-bar-mobile.component.html',
  styleUrls: ['./top-bar-mobile.component.scss'],
})
export class TopBarMobileComponent implements OnInit {
  @Input() title: string;
  @Input() isHome: boolean;
  @Input() isPage: boolean;
  @Input() back: 'default' | 'custom' = 'default';
  @Output() backEmitter: EventEmitter<boolean> = new EventEmitter();
  @Input() isInnerPage: boolean;
  @Input() isLongTitle: boolean;
  @ViewChild('sidenav') sidenav: MatSidenav;
  product$: Observable<Product> = this.store.select(selectProduct);
  regularUser$: Observable<RegularUser> = this.store.select(selectUser);

  menuOpen: boolean;
  product: Product;
  profileImg: string;
  startWeight: PatientWeightHistory;
  currentWeight: number;
  daysInProcess: number;
  weightLose: number;
  weightUnit: string;
  isExampleUser: boolean;
  isPremium: boolean;

  // @Input() editMode: boolean;
  // @Input() hideCheck: boolean;
  // @Input() disableCheck: boolean;
  // @Output() close: EventEmitter<any> = new EventEmitter<any>();
  // @Output() check: EventEmitter<any> = new EventEmitter<any>();
  constructor(public location: Location, private store: Store) {}

  ngOnInit(): void {
    /* setTimeout(() => {
      this.sidenav.openedChange.subscribe(console.log);
    }, 100); */

    this.product$.subscribe((data) => {
      if (data) {
        this.product = data;
        this.isPremium =
          this.product.productTypeId == ProductTypeEnum.NutrogenicsPlus;
        this.isExampleUser =
          this.product.patient.regularUser.userName == '000111000';
        this.profileImg = this.product.fileUploads?.find(
          (x) => x.fileUploadTypeId == FileUploadType.profile
        )?.path;

        this.startWeight = this.getPatient().patientWeightHistories.find(
          (x) => x.isWeightStart
        );

        this.daysInProcess =
          (new Date().getTime() -
            new Date(this.startWeight?.dateUpdated).getTime()) /
          (1000 * 3600 * 24);

        const tempWeightLose =
          this.startWeight?.value - this.getPatient().currentWeight;

        if (tempWeightLose > 0 && tempWeightLose < 1) {
          this.weightUnit = 'גרם';
          this.weightLose = tempWeightLose * 1000;
        } else if (tempWeightLose >= 1 || tempWeightLose < 0) {
          this.weightUnit = 'ק"ג';
          this.weightLose = tempWeightLose;
        }
      }
    });
  }

  getWeightProcess() {
    var text = '';
    if (this.weightLose < 0) {
      text = '+';
    } else {
      text = '-';
    }

    return `${text} ${Math.abs(this.weightLose)} ${this.weightUnit}`;
  }

  getPatient() {
    return this.product.patient;
  }

  navBack() {
    if (this.back === 'default') {
      this.location.back();
    } else if (this.back === 'custom') {
      this.backEmitter.emit(true);
    }
  }

  openMenu(sidenav: MatSidenav) {
    this.menuOpen = !this.menuOpen;
    sidenav.toggle();
    //this.menuState.emit(this.menuOpen);
  }
  // closeBtn() {
  //   this.close.emit(true);
  // }
  // checkBtn() {
  //   this.check.emit(true);
  // }
}
