<!-- <div class="page-title">
  {{ menu && !isLoading && menu.name }}
</div> -->
<div class="container">
  <ngx-skeleton-loader
    *ngIf="isLoading"
    [theme]="{ height: '50px' }"
    count="5"
  ></ngx-skeleton-loader>
  <ng-container *ngIf="menu && !isLoading">
    <mat-accordion class="meals">
      <mat-expansion-panel
        *ngFor="let meal of meals; let inx = index"
        class="meals__meal"
        (afterExpand)="scrollToTop()"
        hideToggle="true"
        #mealPanel
      >
        <mat-expansion-panel-header class="panelHeader-{{ inx }}">
          <mat-panel-title>
            <div class="header">
              <div class="header__image">
                <img
                  src="../../../assets/meal-types/{{
                    getMealImg(meal.mealType)
                  }}.svg"
                  alt=""
                />
              </div>
              <div class="header__name">
                {{ meal.mealType | translate }}
              </div>
              <div class="header__time">
                <mat-icon>schedule</mat-icon>
                <!-- <img src="../../../assets/meal-time.svg" alt="" /> -->
                {{ formatMealTime(meal.mealTime) }}
              </div>
              <!-- TODO: Rate-bar hidden -->
              <div class="header__rate-bar" *ngIf="false">
                <div class="header__rate-bar__text">ציון גנטי לארוחה</div>
                <mat-progress-bar
                  dir="rtl"
                  [value]="
                    getGeneticRate(meal.nutrients)?.amount | number: '1.0-0'
                  "
                ></mat-progress-bar>
                <div class="header__rate-bar__value">
                  {{
                    getGeneticRate(meal.nutrients)?.amount | number: "1.0-0"
                  }}%
                </div>
              </div>
              <div class="header__sum-cal">
                סה"כ
                {{ getCalories(meal.nutrients)?.amount | number: "1.0-0" }}
                קלוריות
              </div>

              <button
                *ngIf="mealPanel.expanded"
                class="header__btn"
                mat-icon-button
              >
                <mat-icon>keyboard_arrow_down</mat-icon>
              </button>
              <button
                *ngIf="!mealPanel.expanded"
                class="header__btn"
                mat-icon-button
              >
                <mat-icon>keyboard_arrow_left</mat-icon>
              </button>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="bullets">
          <ng-container
            *ngFor="
              let bullet of meal.mealBullets;
              let bulletInx = index;
              let first = first;
              let last = last
            "
          >
            <div *ngIf="bullet.mealBulletOptions.length > 0">
              <div class="bullets__option__and" *ngIf="!first">
                <span></span>
                <span class="text">בנוסף</span>
                <span></span>
              </div>
              <div
                *ngFor="
                  let option of bullet.mealBulletOptions;
                  let firstOption = first
                "
                class="bullets__option"
                [ngStyle]="{
                  'grid-template-columns':
                    bullet.mealBulletOptions.length > 1
                      ? '20px 1fr 100px'
                      : '1fr 100px',
                  'border-top':
                    bullet.mealBulletOptions.length == 1
                      ? '1px solid #EEECF5'
                      : ''
                }"
              >
                <span
                  *ngIf="bullet.mealBulletOptions.length !== 1"
                  class="bullets__option__or"
                  >או</span
                >

                <span class="bullets__option__food">
                  {{ option.foodName }}
                </span>
                <span class="bullets__option__scale">
                  {{ option.quantity | number }}
                  {{ option.scaleName }}
                </span>
              </div>
            </div>
          </ng-container>
          <mat-card class="notes mt-2" *ngIf="meal.notes">
            <div class="notes__title">
              דגשים ל{{ meal.mealType | translate }}:
            </div>
            <div class="mb-1"></div>
            <div [innerHtml]="meal.notes"></div>
          </mat-card>
          <div class="mt-2"></div>
          <app-genetic-rate
            [nutrients]="meal.nutrients"
            [inMenuList]="true"
          ></app-genetic-rate>
        </div>
        <mat-expansion-panel #vitamins class="vitemins" hideToggle="true">
          <mat-expansion-panel-header class="vitemins__header">
            <mat-panel-title>
              <div class="vit-title">
                <img src="../../../assets/vit-icon.svg" alt="" />
                ויטמינים ומינרלים
              </div>

              <div class="vitemins__header__trigger">
                <button mat-icon-button *ngIf="!vitamins.expanded">
                  <mat-icon>keyboard_arrow_down</mat-icon>
                </button>
                <button mat-icon-button *ngIf="vitamins.expanded">
                  <mat-icon>keyboard_arrow_up</mat-icon>
                </button>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div *ngFor="let vitamin of meal.vitamins" class="vitemins__bar">
            <div class="vitemins__bar__data">
              <span>{{ vitamin.nutrientName }}</span>
              <span>
                {{ vitamin.amount | number: "1.0-0" }}&nbsp;
                <!-- /{{vitamin.dailyAmount | number: "1.0-0"}} -->
                <small>({{ vitamin.unit }})</small>
              </span>
            </div>
            <mat-progress-bar
              mode="determinate"
              [value]="getVitaminBarValue(vitamin.amount, vitamin.dailyAmount)"
              dir="rtl"
            ></mat-progress-bar>
          </div>
        </mat-expansion-panel>
      </mat-expansion-panel>
    </mat-accordion>
    <mat-card class="notes" *ngIf="menu.notes">
      <div class="notes__title">דגשים ל{{ menu.name }}:</div>
      <div class="mb-1"></div>
      <div [innerHtml]="blankify(menu.notes)"></div>
    </mat-card>
    <app-genetic-rate [nutrients]="menu.stats"></app-genetic-rate>
  </ng-container>
</div>
