import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ErrorMessage } from '../data/ErrorMessage';
import { Meal } from '../data/Meal';
import { MealForSite } from '../data/MealForSite';
import { MenuForSite } from '../data/MenuForSite';

@Injectable({
  providedIn: 'root',
})
export class MealsService {
  constructor(private http: HttpClient) {}
  getByMenuId(menuId, productId, langId) {
    return this.http.get<MenuForSite | ErrorMessage>(
      `${environment.baseUrl}Meals/ForMenuSite/${menuId}/${productId}/${langId}`
    );
  }
}
