import {
  AfterContentChecked,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Observable } from 'rxjs';
import { IndicatorMsg } from 'src/app/data/indicator-msg';
import { Patient } from 'src/app/data/Patients';
import { Product } from 'src/app/data/Product';
import { RegularUser } from 'src/app/data/RegularUser';
import { RequestForTableMin } from 'src/app/data/RequestForTableMin';
import {
  OpenServiceRequest,
  SelectServiceRequest,
} from 'src/app/Redux/Actions/service-request.actions';
import {
  selectPatient,
  selectProduct,
} from 'src/app/Redux/selectors/product.selectors';
import {
  selectActiveRequest,
  selectServiceRequests,
} from 'src/app/Redux/selectors/service-request.selectors';
import { selectUser } from 'src/app/Redux/selectors/user.selectors';
import { ChatHoursService } from 'src/app/Services/chat-hours.service';
import { DesignService } from 'src/app/Services/design.service';
import { FirebaseAnonimousAuthService } from 'src/app/Services/firebase-anonimous-auth.service';
import { FirebaseService } from 'src/app/Services/firebase.service';
import { iOS, isMobile } from 'src/app/Utils/userAgent';

@Component({
  selector: 'app-service-requests',
  templateUrl: './service-requests.component.html',
  styleUrls: ['./service-requests.component.scss'],
})
export class ServiceRequestsComponent
  implements OnInit, OnDestroy, AfterContentChecked, OnChanges
{
  product$: Observable<Product> = this.store.select(selectProduct);
  patient$: Observable<Patient> = this.store.select(selectPatient);
  serviceRequests$: Observable<RequestForTableMin[]> = this.store.select(
    selectServiceRequests
  );
  selectedActiveRequest$: Observable<RequestForTableMin> =
    this.store.select(selectActiveRequest);
  user$: Observable<RegularUser> = this.store.select(selectUser);
  activeRequestId: RequestForTableMin;
  patient: Patient;
  product: Product;
  serviceRequests: RequestForTableMin[];
  chatIndicator: IndicatorMsg[];
  chatIndicatorSubsription: any;
  config: PerfectScrollbarConfigInterface = {
    wheelSpeed: 0.5,
  };
  outOfService: boolean = true;
  disableChatBtn: boolean = true;
  //outOfServiceElDay: boolean = this.electionDay() ? true : false;
  @Input() showHistoryList: boolean;
  @Input() openChatId: any;
  @Output('selectedActiveRequest')
  selectedActiveRequest: EventEmitter<RequestForTableMin> = new EventEmitter<RequestForTableMin>();

  sub: any;
  serviceRequestsSubscription: any;
  serviceRequestsNotRedux: RequestForTableMin[];
  height: string;
  heightSubject: number;
  isLoadingChatHours: boolean;
  chatDateStart: string;
  chatDateEnd: string;

  constructor(
    private store: Store,
    private firestore: AngularFirestore,
    private router: Router,
    public translate: TranslateService,
    private anonAuthService: FirebaseAnonimousAuthService,
    private firebaseService: FirebaseService,
    private designService: DesignService,
    private chatHoursService: ChatHoursService
  ) {
    //console.log('ServiceRequestsComponent constuctor!!!!');

    this.designService.chatWrapperSubject.subscribe((data) => {
      this.heightSubject = data;
      //console.log('ServiceRequestsComponent: ', data);
    });
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.outOfService) {
      //console.log(changes.outOfService);
    }
  }

  ngOnDestroy(): void {
    this.serviceRequestsSubscription.unsubscribe();
  }

  isMobile() {
    return isMobile();
  }
  ios() {
    return iOS();
  }
  ngAfterContentChecked() {
    if (!isMobile()) {
      this.height = this.heightSubject - 77 + '.px';
    }

    //console.log('ServiceRequestsComponent checked: ', this.height);
  }
  ngOnInit(): void {
    this.serviceTime();
    this.selectedActiveRequest$.subscribe((res) => {
      if (res) {
        this.activeRequestId = res;
      }
    });

    /*  this.product$.subscribe((res) => {
      if (res) {
        this.product = res;
        //console.log('dispatch LoadServiceRequests', this.product.productId);
        this.store.dispatch(
          LoadServiceRequests({ productId: this.product.productId })
        );
      }
    }); */
    this.translate.addLangs(['he', 'en']);
    this.translate.setDefaultLang('he');
    /* const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/en|he/) ? browserLang : 'en'); */

    this.patient$.subscribe((res) => (this.patient = res));

    this.serviceRequestsSubscription = this.serviceRequests$.subscribe(
      (res) => {
        //console.log('serviceRequests$', res);
        this.serviceRequests = res;
        /* this.serviceRequestsNotRedux = [...this.serviceRequests]; */
        this.serviceRequestsNotRedux = [];
        this.serviceRequests.forEach((sr) => {
          this.serviceRequestsNotRedux.push(sr);
        });
      }
    );
    this.anonAuthService.isFireLogged$.subscribe((data) => {
      if (!data) return;

      this.firebaseService.indicatorMsg$.subscribe((indicator) => {
        if (!indicator) return;
        this.chatIndicator = indicator;

        var arr = [];
        [...this.serviceRequestsNotRedux].forEach((sr) => {
          var createdDate =
            this.chatIndicator[sr.serviceRequestId]?.createdDate;
          var _sr = { ...sr, createdDate };
          arr.push(_sr);
        });
        this.serviceRequestsNotRedux = arr.sort((a, b) => {
          var x = moment(a.createdDate, 'DD/MM/YYYY HH:mm:ss').valueOf();
          var y = moment(b.createdDate, 'DD/MM/YYYY HH:mm:ss').valueOf();

          return x > y ? -1 : 1;
        });
      });
    });

    if (this.openChatId) {
      this.store.dispatch(
        SelectServiceRequest({
          serviceRequestId: this.openChatId,
        })
      );
    }
  }

  serviceTime() {
    this.isLoadingChatHours = true;
    this.chatHoursService.getAll().subscribe((data) => {
      var now = new Date();
      var nowTime = now.getTime();
      var date = now.getDate();
      var nowDay = now.getDay();

      data.forEach((day) => {
        var chatHourStart = +day.hourStart?.split(':')[0];
        var chatHourEnd = +day.hourEnd?.split(':')[0];
        var chatDay = day.dayInWeek - 1;

        var chatDateStart = new Date(
          now.getFullYear(),
          now.getMonth(),
          date,
          chatHourStart
        ).getTime();

        var chatDateEnd = new Date(
          now.getFullYear(),
          now.getMonth(),
          date,
          chatHourEnd
        ).getTime();

        if (
          nowDay == chatDay &&
          nowTime >= chatDateStart &&
          nowTime <= chatDateEnd
        ) {
          this.outOfService = false;
          this.disableChatBtn = false;
        }
        if (nowDay == chatDay) {
          const local = 'he-IL';
          this.chatDateStart = new Date(chatDateStart).toLocaleTimeString(
            local,
            { hour: '2-digit', minute: '2-digit' }
          );
          this.chatDateEnd = new Date(chatDateEnd).toLocaleTimeString(local, {
            hour: '2-digit',
            minute: '2-digit',
          });
        }
      });
      this.isLoadingChatHours = false;
    });
  }

  holiday() {
    var date = new Date().toLocaleDateString();
    var holiday1 = '5/16/2021';
    var holiday2 = '5/17/2021';

    if (holiday1 === date || holiday2 === date) {
      return true;
    } else return false;
  }

  getLatestMeta(serviceRequestId) {
    if (this.chatIndicator && this.chatIndicator[serviceRequestId]) {
      return this.chatIndicator[serviceRequestId];
    } else return null;
  }

  getLatestDate(date) {
    var now = moment(new Date(), 'DD/MM/YYYY HH:mm:ss');
    var createdDate = moment(date, 'DD/MM/YYYY HH:mm:ss');
    var duration = moment.duration(now.diff(createdDate));
    var days = duration.asDays();
    var hours = duration.asHours();
    var minutes = duration.asMinutes();

    if (days >= 1) return `לפני ${days.toFixed()} ימים`;
    else if (hours >= 1) return `לפני ${hours.toFixed()} שעות`;
    else if (minutes >= 1) return `לפני ${minutes.toFixed()} דקות`;
    else return `ממש עכשיו`;
  }

  getChatIndicator(serviceRequestId) {
    if (
      this.chatIndicator &&
      this.chatIndicator[serviceRequestId] &&
      this.chatIndicator[serviceRequestId].countSite
    )
      return this.chatIndicator[serviceRequestId].countSite;
    else return null;
  }

  backBtn() {
    this.router.navigate(['home']);
  }
  openServiceRequest() {
    this.store.dispatch(
      OpenServiceRequest({ patientId: this.patient.patientId })
    );
  }
  truncateTxt(txt) {
    return txt.length > 30 ? `${txt.substring(0, 30)}...` : txt;
  }
  onRowClick(request: RequestForTableMin) {
    this.store.dispatch(
      SelectServiceRequest({
        serviceRequestId: request.serviceRequestId,
      })
    );
  }
}
