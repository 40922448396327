import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Video } from '../data/Video';
import { VideoForSite } from '../data/VideoForSite';

@Injectable({
  providedIn: 'root',
})
export class VideosService {
  constructor(private http: HttpClient) {}

  getAll(): Observable<any> {
    return this.http.get<VideoForSite[]>(
      `${environment.baseUrl}Videos/ForSite`
    );
  }
  getAllVideos(langId: number) {
    return this.http.get<Video[]>(
      `${environment.baseUrl}VOD/GetAllVideos/${langId}`
    );
  }
  getVideosForProductByCategory(
    productId: number,
    categoryId: number,
    langId: number
  ) {
    return this.http.get<Video[]>(
      `${environment.baseUrl}VOD/GetVideosForProductByCategory/${productId}/${categoryId}/${langId}`
    );
  }

  productFinishWatchVideo(
    productId: number,
    videoId: number,
    videoDuration: number
  ) {
    return this.http.get(
      `${environment.baseUrl}VOD/ProductFinishWatchVideo/${productId}/${videoId}/${videoDuration}`
    );
  }

  productUpdateProgressVideo(
    productId: number,
    videoId: number,
    videoDuration: number
  ) {
    return this.http.get(
      `${environment.baseUrl}VOD/ProductUpdateProgressVideo/${productId}/${videoId}/${videoDuration}`
    );
  }
}
