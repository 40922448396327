import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Deal } from '../data/Deal';

@Injectable({
  providedIn: 'root',
})
export class DealsService {
  constructor(private http: HttpClient, public datepipe: DatePipe) {}
  post(deal: Deal) {
    return this.http.post<Deal>(`${environment.baseUrl}Deals/FromSite`, deal);
  }
}
