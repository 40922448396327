import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Patient } from '../data/Patients';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { caculateWeightData } from '../Utils/userHelper';
import { ErrorMessage } from '../data/ErrorMessage';

@Injectable({
  providedIn: 'root',
})
export class PatientsService {
  constructor(private http: HttpClient) {}

  getByPatientId(patientId) {
    return this.http
      .get<Patient>(`${environment.baseUrl}Patients/ByPatient/${patientId}`)
      .pipe(
        map((patient) => {
          if (patient) patient = caculateWeightData(patient);
          return patient;
        })
      );
  }
  getByUserId(userId) {
    return this.http.get<Patient>(
      `${environment.baseUrl}Patients/ByUser/${userId}`
    );
  }
  fillDetails(patient: Patient) {
    return this.http.put(
      `${environment.baseUrl}Patients/FillDetails/${patient.patientId}`,
      patient
    );
  }
  uploadSignatures(signatureObj) {
    return this.http.post<Patient>(
      `${environment.baseUrl}UploadResultsCsv/UploadSignatures/`,
      signatureObj
    );
  }
  put(patient: Patient) {
    return this.http.put(
      `${environment.baseUrl}Patients/${patient.patientId}`,
      patient
    );
  }
  post(patient: Patient) {
    return this.http.post<Patient>(`${environment.baseUrl}Patients/`, patient);
  }
  updateWeight(patientId: number, weight: number) {
    return this.http.get<ErrorMessage | any>(
      `${environment.baseUrl}Patients/UpdateWeight/${patientId}/${weight}`
    );
  }
  /*
  resetWeightGoal(patientId, weightGoalDate) {
    return this.http.get<any>(
      `${environment.baseUrl}Patients/resetWeightGoal/${patientId}/${weightGoalDate}`
    );
  }
  */
  RestartGoalSession(patientId) {
    return this.http.post<any>(
      `${environment.baseUrl}PatientWeightHistories/RestartGoalSession/${patientId}`,
      {}
    );
  }

  ifUserTestDataExpired(productId: number) {
    return this.http.get<{
      bloodTestExpired: boolean;
      bloodTestDate: string;
      lifeStyleExpired: boolean;
      lifeStyleDate: string;
    }>(`${environment.baseUrl}Patients/IfUserTestDataExpired/${productId}`);
  }
}
