import { BarCity } from './BarCity';
import { RegularUser } from './RegularUser';

export class BarStreet {
  streetCode: number;
  cityCode: number;
  barCity: BarCity;
  name: string;
  regularUsers: RegularUser[];
}
