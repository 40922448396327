import { Component, OnInit } from '@angular/core';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { take } from 'rxjs/operators';
import { MeetingSchedule } from 'src/app/data/MeetingSchedule';
import { ConfirmDialogEnum } from 'src/app/Enum/ConfirmationDialogEnum';
import {
  MeetingScheduleStepType,
  MeetingType,
} from 'src/app/Enum/StatusesEnum';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';
import 'moment/locale/he';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
} from '@angular/material-moment-adapter';
import { SchedualCalendarHeaderComponent } from 'src/app/Fragments/schedual-calendar-header/schedual-calendar-header.component';
import { Moment } from 'moment';
import * as moment from 'moment';
import { MeetingScheduleService } from 'src/app/Services/meeting-schedule.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-meeting-schedule',
  templateUrl: './meeting-schedule.component.html',
  styleUrls: ['./meeting-schedule.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'he-IL' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class MeetingScheduleComponent implements OnInit {
  MeetingScheduleStepType = MeetingScheduleStepType;
  scheduleStep: MeetingScheduleStepType =
    MeetingScheduleStepType.meetingSettingType;
  meetingSchedule: MeetingSchedule = new MeetingSchedule();
  selectedDate: Moment | null = moment();
  callTypes = [
    {
      id: 1,
      name: 'שיחה ראשונית',
      desc: 'זו השיחה הראשונה שהינך מבצע עם דיאטן/ית בתחילת התהליך והיא נעשית על מנת לבנות לך תפריט מותאם אישית',
    },
    {
      id: 2,
      name: 'שיחה עם דיאטן',
      desc: 'זו השיחה הראשונה שהינך מבצע עם דיאטן/ית בתחילת התהליך והיא נעשית על מנת לבנות לך תפריט מותאם אישית',
    },
    {
      id: 3,
      name: 'שיחת מעקב',
      desc: 'זו השיחה הראשונה שהינך מבצע עם דיאטן/ית בתחילת התהליך והיא נעשית על מנת לבנות לך תפריט מותאם אישית',
    },
  ];
  activeDays = [2, 3, 4, 11];
  activeHours: string[];
  schedualCalendarHeader = SchedualCalendarHeaderComponent;
  constructor(
    private popupHandlerService: PopupHandlerService,
    private _adapter: DateAdapter<any>,
    private meetingScheduleService: MeetingScheduleService,
    private router: Router,
    public translate: TranslateService
  ) {}

  english() {
    this._adapter.setLocale('en-us');
  }

  ngOnInit(): void {
    this.onSelectDate(this.selectedDate);
  }

  goToNextStep() {
    if (this.scheduleStep == MeetingScheduleStepType.meetingSettingType) {
      this.openCallTypePopup();
    } else if (this.scheduleStep == MeetingScheduleStepType.meetingApproval) {
      this.openApprovalPopup();
    }
  }

  openApprovalPopup() {
    this.popupHandlerService.openConfirmationDialog(
      ConfirmDialogEnum.meetingScheduleApproval,
      null,
      '350px'
    );
    this.popupHandlerService.confirmDialogSubject
      .pipe(take(1))
      .subscribe((data) => {
        if (data) {
          this.router.navigate(['/home']);
        }
      });
  }

  openCallTypePopup() {
    this.popupHandlerService.openConfirmationDialog(
      ConfirmDialogEnum.meetingScheduleType,
      null,
      '350px'
    );
    this.popupHandlerService.confirmDialogSubject
      .pipe(take(1))
      .subscribe((meetingTypeId) => {
        if (meetingTypeId) {
          this.meetingSchedule.meetingTypeId = meetingTypeId;
          this.scheduleStep++;
        }
      });
  }

  myDateFilter = (d: Moment): boolean => {
    const day = d.date();
    return this.activeDays.includes(day); //true;
  };

  getFullSelectedDate() {
    const date = this.selectedDate.format('DD/MM/YY');
    const day = this.selectedDate.format('dddd');
    return `${date} יום ${day}`;
  }

  onSelectDate(event: Moment) {
    this.selectedDate = event;

    this.meetingSchedule.meetingDate = this.selectedDate.format('YYYY/MM/DD');

    this.activeHours = this.meetingScheduleService.getActiveHours(
      this.meetingSchedule.meetingDate
    );
  }
  onSelectTime(time: string) {
    this.meetingSchedule.meetingTime = time;
    this.scheduleStep++;
  }

  getMeetingType() {
    if (this.meetingSchedule.meetingTypeId == MeetingType.phone) {
      return 'טלפון';
    } else if (this.meetingSchedule.meetingTypeId == MeetingType.zoom) {
      return 'zoom';
    }
  }
}
