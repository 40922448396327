import { ProductType } from './ProductType';
import { Client } from './Client';
import { ProductForClientMin } from './ProductForClientMin';

export class FutureProduct {
  public constructor(init?: Partial<FutureProduct>) {
    Object.assign(this, init);
  }
  index: number;
  futureProductId: number;
  productTypeId: number;
  productType: ProductType;
  clientId: number;
  client: Client;
  amount: number;
  products: ProductForClientMin[];
}
