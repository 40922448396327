import { Patient } from './Patients';
import { UserGoal } from './UserGoal';

export class GoalToPatient {
  patientToGoalId: number;
  patientId: number;
  patient: Patient;
  goalIdId: number;
  userGoal: UserGoal;
}
