import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { BulletCrossResult } from 'src/app/data/BulletCrossResult';
import { Product } from 'src/app/data/Product';
import { ProductTypeEnum } from 'src/app/Enum/StatusesEnum';
import { AppState } from 'src/app/Redux/reducers';
import { selectProduct } from 'src/app/Redux/selectors/product.selectors';
import { AlgorithmService } from 'src/app/Services/algorithm.service';
import { LanguagesService } from 'src/app/Services/languages.service';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';
import SwiperCore, { Pagination, SwiperOptions } from 'swiper';

// install Swiper modules
SwiperCore.use([Pagination]);
@Component({
  selector: 'app-recomendation-slider',
  templateUrl: './recomendation-slider.component.html',
  styleUrls: ['./recomendation-slider.component.scss'],
})
export class RecomendationSliderComponent implements OnInit, OnDestroy {
  product$: Observable<Product> = this.store.select(selectProduct);
  product: Product;
  hasRequiredBloodTest: boolean;
  isLoading: boolean;
  langId: number = 1;
  recomendations: BulletCrossResult[];
  swiperConfig: SwiperOptions = {
    slidesPerView: 1,
    pagination: { clickable: true, dynamicBullets: true },
    spaceBetween: 30,
    grabCursor: true,
  };
  mode: 'slider' | 'lifestyleForm' | 'bloodTest' | 'lifestyleFormAndBloodTest';
  subscription: any;
  constructor(
    private store: Store<AppState>,
    private algorithmService: AlgorithmService,
    private languagesService: LanguagesService,
    private router: Router,
    private popupHandlerService: PopupHandlerService
  ) {
    this.product$.subscribe((res) => {
      if (res) {
        this.product = res;
        //this.hasRequiredBloodTest = this.product.bloodTestForBulletsFilled;
        this.setMode();
        if (this.mode === 'slider') {
          this.getRecomendations();
        }
      }
    });
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  setMode() {
    if (
      !this.product.bloodTestForBulletsFilled &&
      !this.product.lifeStyleReportComplete
    ) {
      this.mode = 'lifestyleFormAndBloodTest';
    } else if (!this.product.lifeStyleReportComplete) {
      this.mode = 'lifestyleForm';
    } else if (!this.product.bloodTestForBulletsFilled) {
      this.mode = 'bloodTest';
    } else {
      this.mode = 'slider';
    }
  }

  listenToLangChange() {
    this.subscription = this.languagesService.langIdSubject.subscribe(
      (langId) => {
        this.langId = langId;
        this.getRecomendations();
      }
    );
  }
  onSwiper(swiper) {
    //console.log(swiper);
  }
  onSlideChange() {
    // console.log('slide change');
  }

  ngOnInit(): void {
    this.listenToLangChange();
  }

  getRecomendations() {
    this.isLoading = true;
    this.algorithmService
      .getAlgoCrossingGroupByBarcode(this.product.barCode, this.langId)
      .subscribe((data) => {
        this.isLoading = false;
        this.recomendations = data;
      });
  }

  getImageBg(color: string) {
    const hex = `#${color}`;
    const rgba = `rgba(${this.hexToRgb(hex).r},${this.hexToRgb(hex).g},${
      this.hexToRgb(hex).b
    },0.2)`;
    return rgba;
  }
  hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  }

  isPremium() {
    return this.product.productTypeId == ProductTypeEnum.NutrogenicsPlus;
  }

  goToLifestyleForm() {
    if (this.isPremium()) {
      this.router.navigate(['/lifestyle-report-new']);
    } else {
      this.popupHandlerService.openUpgradePreviewDialog();
    }
  }
  goToBloodTest() {
    if (this.isPremium()) {
      this.router.navigate(['/bloodtests-results-new']);
    } else {
      this.popupHandlerService.openUpgradePreviewDialog();
    }
  }
}
