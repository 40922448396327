import { createFeatureSelector, createSelector } from '@ngrx/store';
import { GlobalParams } from 'src/app/Enum/StatusesEnum';
import { AppState } from '../reducers';

export const selectUser = (state: AppState) => state.user.user;
export const selectUserIsLoading = (state: AppState) => state.user.isLoading;
export const selectUserError = (state: AppState) => state.user.error;
export const selectRedirectObj = (state: AppState) => state.user.redirectObj;
export const selectUserState = (state: AppState) => state.user;
export const selectGuidedQuestionTree = (state: AppState) =>
  state.user.globalParams?.find(
    (x) => x.globalParamsId == GlobalParams.GuidedQuestionTree
  )?.value;

export const selectGuidedQuestionData = (state: AppState) =>
  state.user.globalParams?.find(
    (x) => x.globalParamsId == GlobalParams.GuidedQuestionData
  )?.value;

export const selectShowGeneratePdf = (state: AppState) =>
  state.user.globalParams
    ?.find((x) => x.globalParamsId == GlobalParams.GeneratePdf)
    ?.value.toLocaleLowerCase() === 'true'
    ? true
    : false;

export const selectShowPdfBullets = (state: AppState) =>
  state.user.globalParams
    ?.find((x) => x.globalParamsId == GlobalParams.GeneratePdfBullets)
    ?.value.toLocaleLowerCase() === 'true'
    ? true
    : false;
