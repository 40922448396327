import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

import { BarCity } from '../data/BarCity';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CitiesService {
  cities: any;
  constructor(private http: HttpClient) {}
  search(val: string) {
    if (val.length < 2) {
      return of([]); // return from cache
    } else {
      return this.http.get<BarCity[]>(
        `${environment.baseUrl}Cities/Search/${val}`
      );
    }
  }
}
