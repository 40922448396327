<div class="events" *ngIf="zoomDates">
  <img class="event-img" *ngIf="highlightZoomEvent" [src]="getImage()" alt="" />
  <mat-card
    [style.left.px]="cardOffsetLeft"
    class="popover"
    *ngIf="highlightZoomEvent"
  >
    <div class="title">{{ highlightZoomEvent.zoomEventDescription }}</div>
    <div class="sub">
      תאריך:
      {{ highlightZoomEvent.zoomEventDate | date: "dd/MM/YYYY" }}
    </div>
  </mat-card>
  <mat-calendar
    [selected]="selectedDate"
    [headerComponent]="schedualCalendarHeader"
    (selectedChange)="onSelectDate($event)"
    [dateClass]="dateClass()"
  ></mat-calendar>
  <button
    *ngIf="highlightZoomEvent"
    type="button"
    class="btn btn--fill btn--big"
    mat-button
    (click)="onZoomEventClick()"
  >
    הירשם לזום
  </button>
</div>
