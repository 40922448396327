import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Note } from '../data/Note';

@Injectable({
  providedIn: 'root',
})
export class NotesService {
  constructor(private http: HttpClient) {}

  getById(noteId): Observable<Note> {
    return this.http.get<Note>(`${environment.baseUrl}Notes/${noteId}`);
  }
  SubmitReview(noteId, rating, comment): Observable<Note> {
    if (comment == '') comment = 'comment_not_set';
    return this.http.get<Note>(
      `${environment.baseUrl}Notes/SubmitReview/${noteId}/${rating}/${comment}`
    );
  }
}
