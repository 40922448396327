import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BloodTestResult } from '../data/BloodTestResult';

@Injectable({
  providedIn: 'root',
})
export class BloodTestResultsNewService {
  constructor(private http: HttpClient) {}

  getByPatientId(patientId: number) {
    return this.http.get<BloodTestResult[]>(
      `${environment.baseUrl}BloodTestResultsNew/${patientId}`
    );
  }
  getSpecificDateByPatientId(patientId: number, dateStr: string) {
    return this.http.get<any[]>(
      `${environment.baseUrl}BloodTestResultsNew/History/${patientId}/${dateStr}`
    );
  }
  getDatesListByPatientId(patientId: number) {
    return this.http.get<any[]>(
      `${environment.baseUrl}BloodTestResultsNew/DatesList/${patientId}`
    );
  }
  saveAll(bloodTestResults: BloodTestResult[]) {
    return this.http.post<any>(
      `${environment.baseUrl}BloodTestResultsNew/SubmitAll`,
      bloodTestResults
    );
  }
  saveSingle(bloodTestResult: BloodTestResult) {
    return this.http.post<any>(
      `${environment.baseUrl}BloodTestResultsNew/`,
      bloodTestResult
    );
  }
}
