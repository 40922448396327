import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { ErrorMessage } from 'src/app/data/ErrorMessage';
import { PatientWeightHistory } from 'src/app/data/PatientWeightHistory';
import { Product } from 'src/app/data/Product';
import { PatientWeightHistoriesService } from 'src/app/Services/patient-weight-histories.service';
import { PatientsService } from 'src/app/Services/patients.service';
import { ProductService } from 'src/app/Services/product.service';
import {
  DeleteGeneticDataComplete,
  LoadProductComplete,
  LoadWeightHistory,
  LoadWeightHistoryComplete,
  ProductAction,
  ProductActionTypes,
  ProductError,
  UpdateWeightSuccess,
  weightHistoryError,
} from '../Actions/product.actions';
import { LogoutUser } from '../Actions/regular-user.actions';
import { AppState } from '../reducers';
import { selectProduct } from '../selectors/product.selectors';

@Injectable()
export class ProductEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private productService: ProductService,
    private weightHistoriesService: PatientWeightHistoriesService,
    private patientsService: PatientsService
  ) {}

  @Effect()
  loadProduct$ = this.actions$.pipe(
    ofType<ProductAction>(ProductActionTypes.LoadProduct),
    switchMap((action) => {
      //console.log(localStorage.getItem('currentUser'));

      //console.log('from load product effect');

      return this.productService.getByPatientId(action.payload.patientId).pipe(
        mergeMap((product) => {
          //console.log('loadProduct effect: ', product);
          return [new LoadProductComplete({ product: product })];
        }),
        catchError((error: string) => {
          //console.log(error);
          return of(new ProductError({ error }));
        })
      );
    })
  );

  @Effect()
  updateProduct$ = this.actions$.pipe(
    ofType<ProductAction>(
      ProductActionTypes.ChangeStatusId,
      ProductActionTypes.ChangeAddress
    ),
    withLatestFrom(this.store$.select(selectProduct)),
    switchMap(([action, product]) => {
      return this.productService.put(product).pipe(
        map((product) => new LoadProductComplete({ product: product })),
        catchError((errorMessage) =>
          of(new ProductError({ error: errorMessage }))
        )
      );
    })
  );

  /*@Effect()
  updateProductData$ = this.actions$.pipe(
    ofType<ProductAction>(ProductActionTypes.UpdateProductData)
     withLatestFrom(this.store$.select(selectProduct)),
    switchMap(([action, product]) => {
      return this.productService.put(product).pipe(
        map((product) => new LoadProductComplete({ product: product })),
        catchError((errorMessage) =>
          of(new ProductError({ error: errorMessage }))
        )
      );
    }) 
  );*/

  @Effect()
  insertBarcode$ = this.actions$.pipe(
    ofType<ProductAction>(ProductActionTypes.InsertBarcode),
    withLatestFrom(this.store$.select(selectProduct)),
    switchMap(([action, product]) => {
      return this.productService.insertBarcode(product).pipe(
        map((product) => {
          if ((product as Product).productId) {
            return new LoadProductComplete({ product: product as Product });
          } else {
            var a = product;
            var errorMessage = (product as ErrorMessage).message;
            return new ProductError({ error: errorMessage });
          }
        }),
        catchError((errorMessage) =>
          of(new ProductError({ error: errorMessage }))
        )
      );
    })
  );

  @Effect()
  updateCurrentWeight$ = this.actions$.pipe(
    ofType<ProductAction>(ProductActionTypes.UpdateCurrentWeight),
    withLatestFrom(this.store$.select(selectProduct)),
    switchMap(([action, product]) => {
      return this.patientsService
        .updateWeight(product.patientId, action.payload.currentWeight)
        .pipe(
          map((data) => {
            if ((data as ErrorMessage)?.message) {
              return new weightHistoryError({
                error: (data as ErrorMessage).message,
              });
            } else {
              return new UpdateWeightSuccess({ updateWeightSuccess: true });
            }
          })
        );
    })
  );

  @Effect()
  updateGoalWeight$ = this.actions$.pipe(
    ofType<ProductAction>(ProductActionTypes.UpdateGoalWeight),
    switchMap((action) => {
      return this.weightHistoriesService.post(action.payload.goalWeight).pipe(
        map((data) => {
          if ((data as ErrorMessage)?.message) {
            return new weightHistoryError({
              error: (data as ErrorMessage).message,
            });
          } else {
            const patient = data as PatientWeightHistory;
            return new LoadWeightHistory({ patientId: patient.patientId });
          }
        })
      );
    })
  );

  @Effect()
  loadWeightHistory$ = this.actions$.pipe(
    ofType<ProductAction>(ProductActionTypes.LoadWeightHistory),
    switchMap((action) => {
      return this.weightHistoriesService
        .getHistory(action.payload.patientId)
        .pipe(
          map(
            (data) =>
              new LoadWeightHistoryComplete({ weightHistories: data.list })
          )
        );
    })
  );

  @Effect()
  deleteGeneticData$ = this.actions$.pipe(
    ofType<ProductAction>(ProductActionTypes.DeleteGeneticData),
    switchMap((action) => {
      return this.productService
        .deleteGeneticData(action.payload.productId)
        .pipe(map((data) => new DeleteGeneticDataComplete()));
    })
  );
  @Effect()
  deleteGeneticDataComplete$ = this.actions$.pipe(
    ofType<ProductAction>(ProductActionTypes.DeleteGeneticDataComplete),
    switchMap(() => of(LogoutUser()))
  );

  /*
  @Effect
  doHomeUpgradeAnimations$ = this.actions$.pipe(
    ofType(
      ROUTER_NAVIGATED,
      withLatestFrom(this.store$.select(selectProduct)),
      switchMap(([action, product]) => {
        return of(new ProductSummery());
      })
  );
  */
  /* @Effect()
  handleItemsChanges$ = this.actions$.pipe(
    ofType<ProductAction>(ProductActionTypes.ChangeStatusId),
    withLatestFrom(
      this.store.select(fromReducers.getItems),
      this.store.select(fromReducers.getUsers),
    ),
    switchMap(([action, items, users]: [ActionType, Item[], User[]]) => {
       console.log('I AM handleItemsChanges');
       const actions = [];
       if (itemsShouldBeUpdated) {
          actions.push(new UpdateData(changes))
       }
    })
  ) */
}
