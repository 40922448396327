<!-- <div
  *ngIf="
    result.bloodTestType.minFemale === -1.0 ||
    result.bloodTestType.maxFemale == -1.0
  "
  style="display: flex"
  [ngStyle]="{
    color: isInRangeSingleCondition(result) ? 'green' : 'red'
  }"
>
  רצוי ערך
  {{ result.bloodTestType.minFemale === -1.0 ? "קטן" : "גדול" }}

  מ-
  <ng-container *ngIf="result.bloodTestType.minFemale === -1.0">
    {{
      gender === "male"
        ? getUnit(result.bloodTestType.maxMale, result)
        : getUnit(result.bloodTestType.maxFemale, result)
    }}
  </ng-container>
  <ng-container *ngIf="result.bloodTestType.maxFemale === -1.0">
    {{
      gender === "male"
        ? getUnit(result.bloodTestType.minMale, result)
        : getUnit(result.bloodTestType.minFemale, result)
    }}
  </ng-container>
</div>
<ng-container
  *ngIf="
    result.bloodTestType.minFemale != -1.0 &&
    result.bloodTestType.maxFemale != -1.0
  "
>
  <span style="line-height: 48px">
    {{ getSliderMin() }}
  </span>
  <mat-slider
    [displayWith]="formatLabel"
    thumbLabel
    tickInterval="1"
    [min]="getSliderMin()"
    [max]="getSliderMax()"
    step="0.5"
    [value]="getSliderValue()"
    class="bloodRangeSlider"
    style="pointer-events: none"
    [thumbLabel]="true"
  ></mat-slider>
  <span style="line-height: 48px">
    {{ getSliderMax() }}
  </span>
</ng-container>
 -->

<div
  *ngIf="
    result.bloodTestType.minFemale === -1.0 ||
    result.bloodTestType.maxFemale == -1.0
  "
  class="expected-value"
>
  ערך רצוי
  {{ result.bloodTestType.minFemale === -1.0 ? "קטן" : "גדול" }}

  מ-
  <ng-container *ngIf="result.bloodTestType.minFemale === -1.0">
    {{
      gender === "male"
        ? getUnit(result.bloodTestType.maxMale, result)
        : getUnit(result.bloodTestType.maxFemale, result)
    }}
  </ng-container>
  <ng-container *ngIf="result.bloodTestType.maxFemale === -1.0">
    {{
      gender === "male"
        ? getUnit(result.bloodTestType.minMale, result)
        : getUnit(result.bloodTestType.minFemale, result)
    }}
  </ng-container>
</div>
<div
  *ngIf="
    result.bloodTestType.minFemale != -1.0 &&
    result.bloodTestType.maxFemale != -1.0
  "
  class="d-flex"
>
  <div
    *ngIf="!editMode"
    class="indicator-bar__pos start"
    [ngStyle]="{ visibility: isRedMin(result) ? 'visible' : 'hidden' }"
  >
    <div class="indicator-bar__pos__round red"></div>
    <div class="indicator-bar__pos__text red">{{ result.result }}</div>
  </div>
  <!--  *ngIf="isRedMin(result)"-->
  <span class="indicator-bar__start">{{
    gender === "male"
      ? getUnit(result.bloodTestType.minMale, result)
      : getUnit(result.bloodTestType.minFemale, result)
  }}</span>
  <div class="indicator-bar">
    <div
      *ngIf="isGreenVisible(result) && !editMode"
      [ngStyle]="{ left: greenArrowPos(result) }"
      class="indicator-bar__pos"
    >
      <div class="indicator-bar__pos__round"></div>
      <div class="indicator-bar__pos__text">
        {{ result.result }}
      </div>
    </div>
  </div>

  <span class="indicator-bar__end">{{
    gender === "male"
      ? getUnit(result.bloodTestType.maxMale, result)
      : getUnit(result.bloodTestType.maxFemale, result)
  }}</span>
  <div
    *ngIf="!editMode"
    class="indicator-bar__pos end"
    [ngStyle]="{
      visibility: isRedMax(result) || editMode ? 'visible' : 'hidden'
    }"
  >
    <div class="indicator-bar__pos__round red"></div>
    <div class="indicator-bar__pos__text red">{{ result.result }}</div>
  </div>
</div>
