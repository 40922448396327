<div style="text-align: center">
  <!-- <h1 mat-dialog-title>{{ getTitle() }}</h1> -->
  <div mat-dialog-content>
    <div class="inner-pop">
      <div [ngSwitch]="data.type">
        <div *ngSwitchCase="confirmationType.testConfimation">
          <div class="inner-pop__img">
            <img src="../../../assets/tube-popup.png" alt="" />
          </div>
          <div class="inner-pop__text">
            <p class="inner-pop__text__title">לקוח יקר!</p>
            <p>
              <strong>שים לב</strong> ששמת את המבחנה בתוך המעטפה שקיבלת עם
              הערכה.
            </p>
            <div class="inner-pop__action">
              <button
                class="btn btn--fill"
                mat-button
                (click)="onYesClick()"
                cdkFocusInitial
              >
                המבחנה במעטפה אפשר להמשיך
              </button>
              <div class="mb-2"></div>
              <button
                class="btn btn--underline"
                mat-button
                (click)="onNoClick()"
              >
                חזרה >
              </button>
            </div>
          </div>
        </div>
        <div *ngSwitchCase="confirmationType.removeDnaData">
          <div dir="rtl" style="text-align: center">
            <p style="font-weight: 600">שימו לב!</p>
            <div>
              לא ניתן לשחזר את נתוני הבדיקה <br />
              לאחר המחיקה יבוצע ניתוק, הגישה לאפליקציה תיחסם <br />
              ונשלח לך עותק של הדוח הגנטי שלך ב PDF
            </div>
            <br />
            <p style="font-weight: 600">האם אתה בטוח?</p>
            <br />
            <button
              mat-button
              class="btn btn--fill btn--small"
              (click)="onNoClick()"
            >
              התחרטתי ואני לא מעוניין למחוק
            </button>

            <button
              mat-button
              class="btn btn--underline btn--small mt-2"
              (click)="onYesClick()"
            >
              מחק את המידע הגנטי שלי
            </button>
          </div>
        </div>
        <div *ngSwitchCase="confirmationType.updateUserData">
          <div class="text-center" style="font-size: 1.6rem">
            {{ pageTitleSendTo() }}
          </div>
          <div class="mb-4"></div>
          <div>
            <button
              mat-button
              class="btn btn--center btn--small btn--fill"
              (click)="onYesClick()"
            >
              המשך
            </button>
            <span class="ml-2"></span>
            <button
              mat-button
              class="btn btn--center btn--small btn--outline-black"
              (click)="onNoClick()"
            >
              ביטול
            </button>
          </div>
        </div>
        <div *ngSwitchCase="confirmationType.meetingScheduleType">
          <p class="inner-pop__text__title-small">כיצד תרצה לבצע את השיחה?</p>
          <div class="mt-4"></div>
          <button
            mat-button
            class="btn btn--fill"
            (click)="chosenCallType(MeetingType.phone)"
          >
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: center;
              "
            >
              <div class="phone-icon">
                <mat-icon>call</mat-icon>
              </div>

              באמצעות שיחת טלפון
            </div>
          </button>
          <div class="mt-2"></div>
          <button
            mat-button
            class="btn btn btn--outline-black"
            (click)="chosenCallType(MeetingType.zoom)"
          >
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: center;
              "
            >
              <div class="zoom-icon">
                <mat-icon>video_camera_front</mat-icon>
              </div>
              באמצעות ZOOM
            </div>
          </button>
        </div>
        <div *ngSwitchCase="confirmationType.meetingScheduleApproval">
          <p class="inner-pop__text__title">מעולה!</p>
          <p class="inner-pop__text__title-small">
            <span>נקבע לך תור למועד המבוקש</span>
            בדקות הקרובות תקבל SMS ומייל לתזכורת
          </p>
          <p class="inner-pop__text__title-small">תודה</p>
          <div class="mt-4"></div>
          <button
            mat-button
            class="btn btn--fill"
            (click)="
              dialogRef.close({ event: 'close', data: { answer: 'yes' } })
            "
          >
            סגור
          </button>
        </div>
      </div>
    </div>
  </div>
  <!--<div mat-dialog-actions>
    [mat-dialog-close]="data.animal" 
  </div>-->
</div>
