import { Component, OnInit, Input } from '@angular/core';
import { RtlScrollAxisType } from '@angular/cdk/platform';

@Component({
  selector: 'app-tendeny-thumb',
  templateUrl: './tendeny-thumb.component.html',
  styleUrls: ['./tendeny-thumb.component.scss'],
})
export class TendenyThumbComponent implements OnInit {
  constructor() {}
  @Input() persent;
  ngOnInit(): void {}
}
