export var bubbleData = [
  {
    name: 'France',
    series: [
      {
        name: '2010',
        x: '2010',
        y: 81.4,
        r: 63,
      },
      {
        name: '2000',
        x: '2000',
        y: 79.1,
        r: 59.4,
      },
      {
        name: '1990',
        x: '1990',
        y: 77.2,
        r: 56.9,
      },
    ],
  },
];
