import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-remove-dna-data-dialog',
  templateUrl: './remove-dna-data-dialog.component.html',
  styleUrls: ['./remove-dna-data-dialog.component.scss'],
})
export class RemoveDnaDataDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<RemoveDnaDataDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {}

  onClose(answer = 'no') {
    this.dialogRef.close({ event: 'close', data: { answer } });
  }
}
