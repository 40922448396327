<mat-sidenav-container [hasBackdrop]="false">
  <mat-sidenav-content>
    <div class="top-bar" [class.home]="isHome">
      <div *ngIf="isExampleUser && !isInnerPage" style="width: 72px"></div>
      <div
        style="display: flex; align-items: center"
        *ngIf="!isInnerPage && !isExampleUser"
      >
        <div
          (click)="openMenu(sidenav)"
          class="home__hamburger"
          [class.open]="menuOpen"
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div class="home__notifications" matRipple>
          <div class="badge"></div>
          <mat-icon
            ><img src="../../../assets/notifications.svg" alt=""
          /></mat-icon>
        </div>
        <div class="home__avatar" *ngIf="isHome" [routerLink]="['/profile']">
          <img *ngIf="profileImg" [src]="profileImg" alt="" />
          <mat-icon *ngIf="!profileImg">person</mat-icon>
        </div>
      </div>
      <div class="top-bar__title">
        {{ title }}
      </div>
      <img
        *ngIf="isHome"
        class="home__logo"
        src="../../../assets/logo.svg"
        alt=""
      />
      <button
        *ngIf="isInnerPage"
        class="top-bar__back"
        (click)="navBack()"
        mat-icon-button
      >
        <mat-icon>keyboard_arrow_left</mat-icon>
      </button>
      <div
        *ngIf="isPage && !isLongTitle && !isInnerPage"
        style="height: 4rem; width: 7rem"
      ></div>
    </div>
    <mat-sidenav #sidenav mode="over" [fixedInViewport]="true" position="end">
      <div class="menu">
        <div class="menu__header">
          <div class="menu__header__avatar">
            <img *ngIf="profileImg" [src]="profileImg" alt="" />
            <mat-icon *ngIf="!profileImg">person</mat-icon>
          </div>
          <div class="menu__header__name" *ngIf="regularUser$ | async">
            {{
              (regularUser$ | async).firstName +
                " " +
                (regularUser$ | async).lastName
            }}
          </div>
          <div *ngIf="product && daysInProcess" class="menu__header__process">
            {{ daysInProcess | number: "1.0-0" }} ימים בתהליך,
            {{ getWeightProcess() }}
            <!-- ירדת
            {{ weightLose }} {{ weightUnit }} -->
          </div>
        </div>
        <div class="menu__body">
          <div [routerLink]="['/profile']" class="menu__body__item" matRipple>
            <mat-icon>person</mat-icon>
            פרופיל אישי
          </div>
          <div
            [routerLink]="['/lifestyle-report-new']"
            class="menu__body__item"
            matRipple
            *ngIf="isPremium"
          >
            <mat-icon>help</mat-icon>
            שאלון אורח חיים
          </div>
          <div [routerLink]="['/chat']" class="menu__body__item" matRipple>
            <mat-icon>question_answer</mat-icon>
            צ'אט עם נציג
          </div>
          <div class="menu__body__item" matRipple>
            <mat-icon>show_chart</mat-icon>
            ...
          </div>
          <div class="menu__body__item" matRipple>
            <mat-icon>settings</mat-icon>
            ...
          </div>
        </div>
      </div>
    </mat-sidenav>
  </mat-sidenav-content>
</mat-sidenav-container>
