import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MealForSite } from 'src/app/data/MealForSite';
import { MealToNutrientSite } from 'src/app/data/MealToNutrientSite';
import { MenuForSite } from 'src/app/data/MenuForSite';
import { ZameretNutrients } from 'src/app/Enum/StatusesEnum';

@Component({
  selector: 'app-menu-html',
  templateUrl: './menu-html.component.html',
  styleUrls: ['./menu-html.component.scss'],
})
export class MenuHtmlComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() menu: MenuForSite;
  @ViewChild('menuHtml') menuHtml: ElementRef;
  @ViewChild('printBtn') printBtn: MatButton;
  @ViewChild('closeBtn') closeBtn: MatButton;
  meals: MealForSite[];
  constructor() {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.menu && !changes.menu.isFirstChange()) {
      this.printBtn._elementRef.nativeElement.click();
    }
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.printBtn._elementRef.nativeElement.click();
      //this.closeBtn._elementRef.nativeElement.click();
    }, 500);
  }

  ngOnInit(): void {
    this.meals = this.menu.meals;
  }

  getMealType(mealType: string) {
    switch (mealType) {
      case 'Breakfast':
        return 'ארוחת בוקר';
      case 'Lunch':
        return 'ארוחת צהריים';
      case 'Dinner':
        return 'ארוחת ערב';
      case 'Snack':
        return 'ארוחת ביניים';
      case 'Night':
        return 'ארוחת לילה';
    }
  }

  formatMealTime(mealTime: string) {
    var a = mealTime.split(':');
    return a[0] + ':' + a[1];
  }

  getGeneticRate(nutrients: MealToNutrientSite[]) {
    return nutrients.find((x) => x.nutrientId == ZameretNutrients.geneticRate);
  }
  getCalories(nutrients: MealToNutrientSite[]) {
    return nutrients.find((x) => x.nutrientId == ZameretNutrients.food_energy);
  }

  getFats(nutrients: MealToNutrientSite[]) {
    return nutrients.find((x) => x.nutrientId == ZameretNutrients.total_fat);
  }

  getHelbonim(nutrients: MealToNutrientSite[]) {
    return nutrients.find((x) => x.nutrientId == ZameretNutrients.protein);
  }

  getPachmimot(nutrients: MealToNutrientSite[]) {
    return nutrients.find(
      (x) => x.nutrientId == ZameretNutrients.carbohydrates
    );
  }
}
