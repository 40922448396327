import { createReducer, on } from '@ngrx/store';
import { LifeStyleAnswer } from 'src/app/data/LifeStyleAnswer';
import { LifestyleQuestion } from 'src/app/data/LifestyleQuestion';
import { LifeStyleInputType } from 'src/app/Enum/StatusesEnum';
import {
  loadLifestyleQuestions,
  loadLifestyleQuestionsFailure,
  loadLifestyleQuestionsSuccess,
  updateAnswer,
} from '../Actions/lifestyle-question.actions';

export const lifestyleQuestionFeatureKey = 'lifestyleQuestion';

export interface LifestyleQuestionState {
  lifestyleQuestions: LifestyleQuestion[] | null;
  error: boolean;
}

export const initialState: LifestyleQuestionState = {
  lifestyleQuestions: null,
  error: false,
};

export const lifestyleQuestionReducer = createReducer(
  initialState,
  on(loadLifestyleQuestions, (state) => state),
  on(loadLifestyleQuestionsSuccess, (state, { lifestyleQuestions }) => ({
    ...state,
    lifestyleQuestions,
  })),
  on(loadLifestyleQuestionsFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(
    updateAnswer,
    (state, { lifestyleQuestion, productId, eventChecked, inputTypeId }) => {
      let newArray: LifestyleQuestion[] = [];
      for (let index = 0; index < state.lifestyleQuestions.length; index++) {
        let element = state.lifestyleQuestions[index];
        element = deepCopyQuestion(element);
        newArray.push(element);
      }

      newArray = newArray.map((node) => {
        return updateAnswerValue(
          node,
          lifestyleQuestion,
          productId,
          eventChecked,
          inputTypeId
        );
      });
      switch (inputTypeId) {
        case LifeStyleInputType.radio:
          newArray = resetRadioButton(newArray, lifestyleQuestion);
          break;
      }

      return {
        ...state,
        lifestyleQuestions: newArray,
      };
    }
  )
);

export function resetRadioButton(
  array: LifestyleQuestion[],
  lifestyleQuestion: LifestyleQuestion
): LifestyleQuestion[] {
  return array.map((x: LifestyleQuestion) => {
    if (x.lifestyleQuestionId == lifestyleQuestion.parentQuestionId) {
      x.childQuestions = x.childQuestions.map((y) => {
        if (
          y.lifestyleQuestionId !== lifestyleQuestion.lifestyleQuestionId &&
          y.lifeStyleAnswers?.length > 0
        ) {
          y.lifeStyleAnswers[0].value = 'false';
        }
        return y;
      });
    }
    if (x.childQuestions && x.childQuestions.length > 0) {
      resetRadioButton(x.childQuestions, lifestyleQuestion);
    }
    return x;
  });
}  

export function deepCopyQuestion(node: LifestyleQuestion) {
  let newNode = { ...node };

  newNode.lifestyleQuestionLangs = node.lifestyleQuestionLangs
    ? [{ ...node.lifestyleQuestionLangs[0] }]
    : null;
  newNode.lifeStyleAnswers = node.lifeStyleAnswers
    ? [{ ...node.lifeStyleAnswers[0] }]
    : null;

  newNode.childQuestions = newNode.childQuestions.map((x) => {
    return deepCopyQuestion(x);
  });
  return newNode;
}

export function updateAnswerValue(
  node: LifestyleQuestion,
  lifestyleQuestion: LifestyleQuestion,
  productId: number,
  eventValue: string,
  inputTypeId: number
) {
  node = { ...node };

  if (node.lifestyleQuestionId == lifestyleQuestion.lifestyleQuestionId) {
    if (!node.lifeStyleAnswers || node.lifeStyleAnswers.length == 0) {
      node.lifeStyleAnswers = [];
      let lifeStyleAnswer = new LifeStyleAnswer();
      lifeStyleAnswer.lifeStyleAnswerId = 0;
      lifeStyleAnswer.productId = productId;
      lifeStyleAnswer.lifestyleQuestionId = node.lifestyleQuestionId;
      lifeStyleAnswer.value = eventValue;
      node.lifeStyleAnswers.push(lifeStyleAnswer);
    } else {
      node.lifeStyleAnswers[0].value = eventValue;
    }
  }
  if (node.childQuestions && node.childQuestions.length > 0) {
    let childs = [];
    for (let index = 0; index < node.childQuestions.length; index++) {
      let element = { ...node.childQuestions[index] };
      element = updateAnswerValue(
        element,
        lifestyleQuestion,
        productId,
        eventValue,
        inputTypeId
      );

      childs.push(element);
    }

    node.childQuestions = childs;
  }
  return node;
}
