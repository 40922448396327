import { Status } from './Status';

export class ProductForClientMin {
  productId: number;
  clientId: number;
  patientId: number | null;
  patientCreatedDate: string;
  patientName: string;
  socialSecurityNum: string;
  tubeId: string;
  productType: string;
  status: Status;
  statusId: number;
  isMe: boolean;
  isChecked: boolean;
}
