export class IndicatorMsg {
  countCrm: number;
  countSite: number;
  createdDate: string;
  msg: string;
  displayName: string;
  clientName: string;
  seenBy: number[];
  sentFrom: number;
  isGQ: boolean | null;
  isClosed: boolean;
  avatarClientId: string;
  avatarAgentId: string;
  subjectId: number;
  productId: number;
  //isActive: boolean;
}
