import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from '../globals';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { RegularUser } from '../data/RegularUser';
import { catchError, map, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ErrorMessage } from '../data/ErrorMessage';
import { FirebaseAnonimousAuthService } from './firebase-anonimous-auth.service';
import { Store } from '@ngrx/store';
import { AppState } from '../Redux/reducers';
import { LogoutUser } from '../Redux/Actions/regular-user.actions';

@Injectable({
  providedIn: 'root',
})
export class RegularUsersService {
  private currentUserSubject: BehaviorSubject<RegularUser> =
    new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));
  // public currentUser = this.currentUserSubject.asObservable();

  public currentUser: Observable<RegularUser> =
    this.currentUserSubject.asObservable();

  constructor(
    private http: HttpClient,
    private anonAuthService: FirebaseAnonimousAuthService,
    private store: Store<AppState>
  ) {
    /* this.currentUserSubject = new BehaviorSubject<RegularUser>(
      JSON.parse(localStorage.getItem('currentUser'))
    ); 
    this.currentUser = this.currentUserSubject.asObservable();
    */
  }

  getUserStorage() {
    return JSON.parse(localStorage.getItem('currentUser')) as RegularUser;
  }

  registerGeneral(user) {
    return this.http.post<RegularUser>(
      `${environment.baseUrl}RegularUsers/Register/General`,
      user
    );
  }
  registerPatient(user) {
    return this.http.post<RegularUser>(
      `${environment.baseUrl}RegularUsers/Register/Patient`,
      user
    );
  }
  validateFromLogin(user) {
    return this.http
      .post<RegularUser | ErrorMessage>(
        `${environment.baseUrl}RegularUsers/`,
        user
      )
      .pipe(
        map((res) => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          if ((res as RegularUser).firstName) {
            var _user = res as RegularUser;

            localStorage.setItem('currentUser', JSON.stringify(_user));
            localStorage.setItem('access_token', JSON.stringify(_user.token));
            //console.log('setItem from validateFromLogin');
            this.anonAuthService.anonymousLogin();
            this.currentUserSubject.next(_user);
            return _user;
          } else {
            return res;
          }
        }),
        catchError((err) => {
          return throwError(err);
        })
      );
  }
  validateFromStorage(user): Observable<RegularUser | ErrorMessage> {
    return this.http
      .post<RegularUser | ErrorMessage>(
        `${environment.baseUrl}RegularUsers/LoginFromStorage/`,
        user
      )
      .pipe(
        //take(1),
        map((res) => {
          if ((res as RegularUser).firstName) {
            var _user = res as RegularUser;
            if (user.userName != '000111000') {
              localStorage.setItem('currentUser', JSON.stringify(_user));
              localStorage.setItem('access_token', JSON.stringify(_user.token));
            }
            this.anonAuthService.anonymousLogin();
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            this.currentUserSubject.next(_user);
            return _user;
          } else {
            var err = res as ErrorMessage;
            this.logout();
            return err;
          }
        }),
        catchError((err) => {
          this.logout();
          //console.log('error from loginFromStorage service: ', err);
          return throwError('');
        })
      );
  }

  resetPasword(user) {
    return this.http.post<RegularUser>(
      `${environment.baseUrl}RegularUsers/ResetPassword/`,
      user
    );
  }
  sendSmsForPasswordReset(idNum, phone) {
    return this.http.get<RegularUser>(
      `${environment.baseUrl}RegularUsers/SendSmsForPasswordReset/${idNum}/${phone}`
    );
  }
  getResetParmasFromSms(idNum, phone, smsCode) {
    return this.http.get<RegularUser>(
      `${environment.baseUrl}RegularUsers/getResetParmasFromSms/${idNum}/${phone}/${smsCode}`
    );
  }
  resetPassFromSms(user) {
    return this.http.post<RegularUser>(
      `${environment.baseUrl}RegularUsers/ResetPasswordFromSms/`,
      user
    );
  }
  public get currentUserValue(): RegularUser {
    return this.currentUserSubject.value;
  }
  logout() {
    // remove user from local storage to log user out
    //localStorage.removeItem('currentUser');
    //localStorage.removeItem('access_token');
    //this.currentUserSubject.next(null);
    this.store.dispatch(LogoutUser());
  }

  resetStorageParams() {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('access_token');
  }
  getUserFromResetParams(email, token) {
    return this.http.get<RegularUser>(
      `${environment.baseUrl}RegularUsers/getResetParmas/${email}/${token}`
    );
  }
  emptyOf() {
    return of({});
  }
  getVersion() {
    return this.http.get<string>(`${environment.baseUrl}RegularUsers/Version`);
  }
}
