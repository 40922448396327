import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Patient } from 'src/app/data/Patients';
import { PatientWeightHistory } from 'src/app/data/PatientWeightHistory';
import { Product } from 'src/app/data/Product';
import { WeightPointData } from 'src/app/data/weightPointData';
import { AppState } from 'src/app/Redux/reducers';
import {
  selectPatient,
  selectProduct,
} from 'src/app/Redux/selectors/product.selectors';
import { PatientWeightHistoriesService } from 'src/app/Services/patient-weight-histories.service';
import { PatientsService } from 'src/app/Services/patients.service';
import {
  addDaysToDate,
  getDateMonthName,
  getDaysDiff,
} from 'src/app/Utils/dateUtils';
import { getBmiUtil, getWeightForBmiUtil } from 'src/app/Utils/userHelper';

@Component({
  selector: 'app-weight-goal-graph',
  templateUrl: './weight-goal-graph.component.html',
  styleUrls: ['./weight-goal-graph.component.scss'],
})
export class WeightGoalGraphComponent implements OnInit, AfterViewInit {
  @ViewChild('canvasToScroll') private canvasToScroll: ElementRef;
  weightsCanvasWraper = [];
  canvasWraperlength = 7;
  patient$: Observable<Patient> = this.store.select(selectPatient);
  product$: Observable<Product> = this.store.select(selectProduct);
  canvas;
  context;
  patient: Patient;
  patientWeightHistories: PatientWeightHistory[];
  Xlabels: any[];
  weightPoints: WeightPointData[];
  canvasW: number;
  canvasH: number;
  YlabelsRight: any[];
  YlabelsLeft: any[];
  allPatientWeightHistories: PatientWeightHistory[];
  rangeNumWeeks: number;
  PixelsPerKg: number;
  xLabelsSpacings: number = 60;
  bmi25H: number;
  bmi18_5H: number;
  selectedWeightInx: number;
  startCalanderDate: Date;
  snapToDates: any[];
  offsetX: number = 40;
  flagY: number;
  goalPoints: any[];
  allPatientWeightGoals: PatientWeightHistory[];
  goalWeightPoints: WeightPointData[] = [];
  bmi25Weight: number;
  bmi18_5Weight: number;
  showFlag: boolean = true;

  constructor(
    private store: Store<AppState>,
    private patientsService: PatientsService
  ) {}
  ngAfterViewInit(): void {
    setTimeout(() => {
      const canvas = this.canvasToScroll.nativeElement;
      canvas.addEventListener('scroll', (e) => this.toggleFlagVisibility(e));
    }, 100);
  }

  toggleFlagVisibility(e) {
    const scrollWidth = e.target.scrollWidth - 35;
    if (e.target.scrollLeft + e.target.offsetWidth >= scrollWidth) {
      this.showFlag = false;
    } else {
      this.showFlag = true;
    }
  }

  ngOnInit(): void {
    this.setupCanvas();

    this.patient$.subscribe((res) => {
      if (res) {
        this.patient = { ...res };
        if (!this.patient.goalStartDate) {
          this.patient.goalStartDateObj = new Date(1900, 1, 1);
          const patientWeightGoals = this.patient.patientWeightHistories.filter(
            (x) => x.isGoal
          );
          if (patientWeightGoals.length > 0) {
            const weightGoalDate = this.patient.patientWeightHistories
              .filter((x) => x.isGoal)
              .sort((a, b) => {
                const dateA = new Date(a.dateUpdated);
                const dateB = new Date(b.dateUpdated);
                return dateB.getTime() - dateA.getTime();
              })[0].dateUpdated;
            this.patient.goalStartDateObj = new Date(
              Date.parse(weightGoalDate)
            );
          }
        }

        this.allPatientWeightHistories =
          this.patient.patientWeightHistories.filter((x) => !x.isGoal);
        this.allPatientWeightGoals = this.patient.patientWeightHistories.filter(
          (x) => x.isGoal
        );
        const w = this.patient.patientWeightHistories.filter((x) => !x.isGoal);
        console.table(w);

        this.patientWeightHistories =
          this.patient.patientWeightHistories.filter((x) => {
            const dateItem = new Date(Date.parse(x.dateUpdated)).getTime();
            const goalStartDate = this.patient.goalStartDateObj.getTime();
            return !x.isGoal; /* && dateItem >= goalStartDate; */
          });

        this.canvasW = 650;
        this.canvasH = 400;

        const numYLabels = this.canvasWraperlength;
        this.getTargetWeightDate();

        this.snapToDates = [];
        for (let i = 0; i <= this.rangeNumWeeks; i++) {
          this.snapToDates.push(
            addDaysToDate(this.patient.goalStartDateObj, 7 * i)
          );
        }
        const canvasWidthCalc =
          this.rangeNumWeeks * this.xLabelsSpacings + this.offsetX + 30;
        this.canvasW =
          canvasWidthCalc <= this.canvasW ? this.canvasW : canvasWidthCalc;
        let minDisplayWeight = 0;

        let maxDisplayWeight = Math.max.apply(
          Math,
          this.patientWeightHistories.map(function (o) {
            return o.value;
          })
        );
        maxDisplayWeight = Math.round(maxDisplayWeight * 1.25);
        this.PixelsPerKg = this.canvasH / maxDisplayWeight;
        const yLabelsSpacings =
          maxDisplayWeight - minDisplayWeight / numYLabels;
        this.Xlabels = [];
        this.YlabelsRight = [];
        this.YlabelsLeft = [];
        this.weightPoints = [];
        this.goalPoints = [];

        for (let i = 0; i <= this.rangeNumWeeks; i++) {
          this.Xlabels.push({
            X: i * this.xLabelsSpacings + this.offsetX,
            Y: this.canvasH - 20,
            Text: i + 1,
            IsCurrent: false,
          });
        }
        for (let i = 1; i < numYLabels; i++) {
          this.YlabelsRight.push({
            X: 0,
            Y: minDisplayWeight + i * this.PixelsPerKg * yLabelsSpacings,
          });
          const weight =
            Math.round(minDisplayWeight + (i * maxDisplayWeight) / numYLabels) +
            0;

          this.YlabelsLeft.push({
            X: 20,
            //  Y: minDisplayWeight + i * PixelsPerKg * yLabelsSpacings,
            Y: this.canvasH - weight * this.PixelsPerKg,
            Text: weight,
            // Text: minDisplayWeight + (i * maxDisplayWeight) / numYLabels,
          });
        }
        this.YlabelsLeft = this.YlabelsLeft.reverse();
        this.weightsCanvasWraper = [...this.YlabelsLeft];
        let prevWeight = null;
        const now = new Date(Date.now());
        this.startCalanderDate = new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate()
        );

        this.patientWeightHistories.forEach((weight) => {
          const difDays = getDaysDiff(
            new Date(weight.dateUpdated),
            this.patient.goalStartDateObj
          );
          const difWeeks = Math.round(difDays / 7);
          let change = 'flat';
          if (prevWeight) {
            if (prevWeight > weight.value) {
              change = 'down';
            } else if (prevWeight < weight.value) {
              change = 'up';
            }
          }
          prevWeight = weight.value;
          const dateObj = new Date(Date.parse(weight.dateUpdated));
          //const x = difWeeks * this.xLabelsSpacings + this.offsetX;

          if (
            true
            //this.Weightpoints.length < 1
            //  || this.Weightpoints[this.Weightpoints.length - 1].X != x
          ) {
            const weightPointData = new WeightPointData();
            weightPointData.X = difWeeks * this.xLabelsSpacings + this.offsetX;
            weightPointData.Y = this.canvasH - weight.value * this.PixelsPerKg;
            weightPointData.weightTxt = weight.value + 'kg';
            weightPointData.dateTxt =
              dateObj.getDate() + ' ' + getDateMonthName(dateObj);
            weightPointData.change = change;
            this.weightPoints.push(weightPointData);
          }
        });
        this.allPatientWeightGoals.forEach((weight) => {
          const difDays = getDaysDiff(
            new Date(weight.dateUpdated),
            this.patient.goalStartDateObj
          );
          const difWeeks = Math.round(difDays / 7);
          const weightPointData = new WeightPointData();
          weightPointData.X = difWeeks * this.xLabelsSpacings + this.offsetX;
          weightPointData.Y = this.canvasH - weight.value * this.PixelsPerKg;
          weightPointData.weightTxt = weight.value + ' kg';
          const dateObj = new Date(Date.parse(weight.dateUpdated));
          weightPointData.dateTxt =
            dateObj.getDate() + ' ' + getDateMonthName(dateObj);

          this.goalWeightPoints.push(weightPointData);
        });

        const xLast = this.weightPoints[this.weightPoints.length - 1]?.X;
        const boldLabel = this.Xlabels.find((point) => point.X == xLast);
        if (boldLabel) {
          boldLabel.IsCurrent = true;
          boldLabel.Y = boldLabel.Y - 5;
        }

        this.bmi25Weight = getWeightForBmiUtil(
          25,
          this.patient.regularUser.height
        );
        this.bmi18_5Weight = getWeightForBmiUtil(
          18.5,
          this.patient.regularUser.height
        );
        this.bmi25H = this.canvasH - this.bmi25Weight * this.PixelsPerKg;
        this.bmi18_5H = this.canvasH - this.bmi18_5Weight * this.PixelsPerKg;
        this.selectedWeightInx = this.weightPoints.length - 1;
        this.drawGraph();
      }
    });
  }

  drawCircle(X, Y, radius, isBlack = false) {
    if (isBlack) {
      //draw outer circle
      this.context.beginPath();
      this.context.fillStyle = '#ffffff';
      this.context.arc(X, Y, radius, 0, 2 * Math.PI);
      this.context.fill();

      //draw outer circle border
      this.context.lineWidth = 1;
      this.context.strokeStyle = '#7F21BE';
      this.context.stroke();
      this.context.beginPath();

      //draw inner circle
      this.context.arc(X, Y, 4, 0, 2 * Math.PI);
      this.context.fillStyle = '#7F21BE';
      this.context.fill();
    } else {
      this.context.beginPath();
      this.context.arc(X, Y, radius, 0, 2 * Math.PI);
      this.context.fillStyle = '#7F21BE';
      this.context.fill();
    }
  }
  drawActiveWeekCircle(X, Y, radius) {
    this.context.beginPath();
    this.context.arc(X, Y, radius, 0, 2 * Math.PI);
    this.context.fillStyle = '#7F21BE';
    this.context.fill();
  }

  setupCanvas() {
    this.canvas = document.getElementById('canvas');
    if (this.canvas) {
      this.context = this.canvas.getContext('2d');
      this.context.fillStyle = '#0099ff';
      this.context.font = '20 pt Segoe UI';
      this.canvas.addEventListener('click', (event) => {
        this.onCanvasClick(event);
      });
    }
  }
  getTargetWeightDate() {
    const weightGoalStartWeightObj = this.allPatientWeightHistories
      .filter((x) => {
        const dateItem = new Date(Date.parse(x.dateUpdated)).getTime();
        const goalStartDate = this.patient.goalStartDateObj.getTime();
        return dateItem <= goalStartDate;
      })
      .sort((a, b) => {
        const dateA = new Date(a.dateUpdated);
        const dateB = new Date(b.dateUpdated);
        return dateB.getTime() - dateA.getTime();
      })[0];
    const weightGoalStartWeight = weightGoalStartWeightObj?.value;
    const diffWeights = Math.abs(
      weightGoalStartWeight - this.patient.goalWeight
    );
    this.rangeNumWeeks = diffWeights * 2;
  }
  onCanvasClick(event: PointerEvent) {
    /*console.log(
      'pageX:' + event.pageX,
      ' pageY:' +
        event.pageY +
        ' offsetX:' +
        event.offsetX +
        ' offsetY:' +
        event.offsetY
    );*/
    let distances = [];
    this.weightPoints.forEach((point) => {
      distances.push(Math.abs(point.X - event.offsetX));
    });
    this.selectedWeightInx = distances.indexOf(Math.min(...distances));
    // this.selectedWeightInx = Math.floor(event.offsetX / this.xLabelsSpacings);
    this.drawGraph();
  }
  drawGraph() {
    this.clearCanvas();
    this.canvas.width = this.canvasW;
    this.canvas.height = this.canvasH;
    this.drawGradient('#D3247E', this.bmi18_5H); // isRed
    this.drawGradient('#7F21BE', this.bmi25H, false); // isGreen
    this.drawXaxis();
    this.drawYaxis();
    this.context.strokeStyle = '#0000FF';
    this.plotData2(this.weightPoints);
    this.drawBubbleSign(this.weightPoints[this.selectedWeightInx]);
    this.drawGoalIndicators();
  }

  drawFlag(flagX, flagY) {
    let flagImg = new Image();
    flagImg.src = './../../assets/flag_large.svg';

    flagImg.onload = () => {
      this.context.drawImage(flagImg, flagX, flagY);
    };
  }
  clearCanvas() {
    this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
  }

  drawBubbleSign(
    obj: WeightPointData //  x, y, weightTxt, dateTxt, direction)
  ) {
    const bubbleImg = new Image();
    const changeImg = new Image();
    bubbleImg.onload = () => {
      this.context.drawImage(bubbleImg, obj?.X - 42, obj?.Y - 70);
      this.context.save();
      this.context.fillStyle = '#FFFFFF';
      this.context.font = '8px Segoe UI';
      this.context.fillText(obj?.dateTxt, obj?.X - 30, obj?.Y - 50);
      this.context.font = '500 14px Segoe UI';
      this.context.fillText(obj?.weightTxt, obj?.X - 30, obj?.Y - 30);
      this.context.restore();
      switch (obj?.change) {
        case 'flat':
          changeImg.src = './../../assets/MinusWhite.svg';
          break;
        case 'up':
          changeImg.src = './../../assets/arrow_up_white.svg';
          break;
        case 'down':
          changeImg.src = './../../assets/arrow_down_white.svg';
          break;
      }
    };
    changeImg.onload = () => {
      this.context.drawImage(changeImg, obj.X + 18, obj.Y - 35);
    };
    bubbleImg.src = './../../assets/green_bubble.svg';
  }
  drawGradient(color, height, isRed = true) {
    this.context.save();
    this.context.strokeStyle = color;
    if (isRed) {
      this.context.beginPath();
      this.context.moveTo(0, height);
      this.context.lineTo(this.canvasW, height);
      this.context.stroke();
    } else {
      this.context.lineWidth = 1;
      this.context.setLineDash([5, 5]);
      this.context.beginPath();
      this.context.moveTo(0, height);
      this.context.lineTo(this.canvasW, height);
      this.context.stroke();
    }
    if (!isRed) {
      const grd = this.context.createLinearGradient(0, height, 0, height + 40);
      grd.addColorStop(1, '#FFFFFF' + '55');
      grd.addColorStop(0, color + '55');

      // Fill with gradient
      this.context.fillStyle = grd;
      this.context.fillRect(0, height, this.canvasW, height + 40);
    }

    this.context.restore();
  }
  drawDashedLine(height) {
    this.context.save();
    this.context.lineWidth = 0.3;
    this.context.setLineDash([3, 3]);
    this.context.beginPath();
    this.context.moveTo(0, height);
    this.context.lineTo(this.canvasW, height);
    this.context.stroke();
    this.context.restore();
  }
  drawXaxis() {
    for (let i = 0; i < this.Xlabels.length; i++) {
      this.context;
      this.context.fillStyle = '#D1CEE9';
      this.context.font = '12px Segoe UI';
      if (this.Xlabels[i].IsCurrent) {
        this.context.fillStyle = '#7F22BE';
        this.context.font = 'bolder 14px Segoe UI';
        this.drawActiveWeekCircle(
          this.Xlabels[i].X - 1,
          this.Xlabels[i].Y + 2,
          3
        );
      }
      this.context.fillText(
        this.Xlabels[i].Text,
        this.Xlabels[i].X - 5,
        this.Xlabels[i].Y - 5
      );
    }
    this.flagY = this.canvasH - this.patient.goalWeight * this.PixelsPerKg;
    if (this.Xlabels.length > 0) {
      this.drawFlag(this.Xlabels[this.Xlabels.length - 1].X - 20, this.flagY);
    }
  }
  drawYaxis() {
    for (let i = 0; i < this.YlabelsLeft.length; i++) {
      this.drawDashedLine(this.YlabelsLeft[i].Y);
    }
  }
  plotData2(dataSet: any[]) {
    let offsetY = 0;

    this.context.moveTo(dataSet[0]?.X, dataSet[0]?.Y - offsetY);
    for (let i = 1; i < dataSet.length; i++) {
      this.context.lineTo(dataSet[i].X, dataSet[i].Y - offsetY);
    }
    this.context.lineWidth = 2;
    this.context.strokeStyle = '#7F21BE';
    this.context.stroke();

    for (let i = 0; i < dataSet.length; i++) {
      this.drawCircle(
        dataSet[i].X,
        dataSet[i].Y - offsetY,
        i == dataSet.length - 1 ? 8 : 4,
        i == dataSet.length - 1
      );
    }
  }
  drawGoalIndicators() {
    let indicatorImg = new Image();
    indicatorImg.onload = () => {
      for (let i = 0; i < this.goalWeightPoints.length; i++) {
        this.context.drawImage(
          indicatorImg,
          this.goalWeightPoints[i].X - 5,
          this.canvasH - 50 - 2
        );
        let pointHeight = null;
        const pointHeightobj = this.weightPoints.find(
          (x) => x.X == this.goalWeightPoints[i].X
        );
        if (pointHeightobj) {
          pointHeight = pointHeightobj.Y;
        } else {
          const beforeArr = this.weightPoints.filter(
            (x) => x.X < this.goalWeightPoints[i].X
          );
          let before = 0;
          if (beforeArr.length > 0) {
            before = beforeArr[beforeArr.length - 1].Y;
          }
          const after = this.weightPoints.filter(
            (x) => x.X > this.goalWeightPoints[i].X
          )[0]?.Y;
          pointHeight = Math.abs(after - before) / 2;
        }
        this.context.lineWidth = 0.5;
        this.context.strokeStyle = '#9344C8';
        this.context.beginPath();
        this.context.moveTo(this.goalWeightPoints[i].X, pointHeight + 5);
        this.context.lineTo(this.goalWeightPoints[i].X, this.canvasH - 50);
        this.context.stroke();
      }
    };
    indicatorImg.src = './../../assets/goal_indicator.svg';
  }
  onClose(event) {}
  onCheck(event) {}
  getBmi(weight) {
    return getBmiUtil(weight, this.patient.regularUser.height);
  }
  resetProcess() {
    this.patientsService
      .RestartGoalSession(this.patient.patientId)
      .subscribe((res) => {});
  }
  scrollToCanvasEnd() {
    this.canvasToScroll.nativeElement.scrollTo({
      top: 0,
      left: this.canvasToScroll.nativeElement.scrollWidth,
      behavior: 'smooth',
    });
  }
}
