<div dir="rtl" style="position: relative">
  <mat-form-field appearance="fill" class="main-field">
    <!-- <input
      style="cursor: pointer"
      class="input-compelete"
      type="text"
      matInput
      #value
      disabled
      [(ngModel)]="initialValue"
    /> -->
    <input
      (keyup)="onValueAutocompleteChanged($event)"
      (blur)="onFocusOut($event)"
      class="input-compelete"
      type="text"
      matInput
      autocomplete="off"
      #inputField
      [placeholder]="label"
      [(ngModel)]="initialValue"
    />
  </mat-form-field>
  <div *ngIf="autocomleteError">אנא בחרו כתובת תקינה</div>
  <mat-card *ngIf="data.length > 0 && openMenu" class="menu-complete">
    <ng-container>
      <!-- <div class="sticky-search">
        <mat-form-field
          appearance="fill"
          style="width: 100%"
          (click)="$event.stopPropagation()"
        >
          <mat-label>Type something...</mat-label>
          <input
            (keyup)="onValueAutocompleteChanged($event)"
            class="input-compelete"
            type="text"
            matInput
            autocomplete="new-password"
            #inputField
          />
        </mat-form-field>
      </div> -->

      <button
        *ngFor="let value of data"
        class="menu-complete__item"
        mat-button
        (click)="onValueSelected(value)"
      >
        {{ value }}
      </button>
    </ng-container>
    <!-- <ng-template #noData>
        <div style="text-align: center">
          <container-element [ngSwitch]="label">
            <some-element *ngSwitchCase="'Street'"
              >Please Choose City</some-element
            >
            <some-element *ngSwitchDefault>No Data</some-element>
          </container-element>
        </div>
      </ng-template> -->
  </mat-card>
</div>
