import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { CatergoriesService } from 'src/app/Services/catergories.service';
import { TubeTestResultsService } from 'src/app/Services/tube-test-results.service';
import {
  DnaReportAction,
  DnaReportActionTypes,
  LoadDnaReportComplete,
} from '../Actions/dna-report.actions';
import { ProductError } from '../Actions/product.actions';

@Injectable()
export class DnaReportEffects {
  @Effect()
  loadProduct$ = this.actions$.pipe(
    ofType<DnaReportAction>(DnaReportActionTypes.LoadDnaReport),
    switchMap((action) => {
      return this.tubeTestResultsService
        .getAllByBarcode(action.payload.barcode)
        .pipe(
          mergeMap((dnaReport) => [
            new LoadDnaReportComplete({
              dnaReport: dnaReport,
            }),
          ]),
          catchError((errorMessage) =>
            of(new ProductError({ error: errorMessage }))
          )
        );
    })
  );

  constructor(
    private actions$: Actions,
    private catergoriesService: CatergoriesService,
    private tubeTestResultsService: TubeTestResultsService
  ) {}
}
