import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Video } from 'src/app/data/Video';
import { VideoForSite } from 'src/app/data/VideoForSite';
import { FileUploadType } from 'src/app/Enum/StatusesEnum';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-video-item',
  templateUrl: './video-item.component.html',
  styleUrls: ['./video-item.component.scss'],
})
export class VideoItemComponent implements OnInit {
  @Input() video: Video;
  @Input() isSelected: boolean;
  @Input() index: number;
  @Input() sliderItem: boolean;
  @Output() videoClick = new EventEmitter<Video>();
  constructor() {}

  ngOnInit(): void {}
  onVideoClick(video: Video) {
    this.videoClick.emit(video);
  }

  timeText(duration: string) {
    let durationArr = duration.split(':');
    let hours = durationArr[0];
    let minutes = durationArr[1];
    if (hours !== '00') return 'שעות';
    else if (minutes !== '00') return 'דקות';
    else return 'שניות';
  }

  getDuration(duration: string) {
    let durationArr = duration.split(':');
    let hours = durationArr[0];
    let minutes = durationArr[1];
    let seconds = durationArr[2];
    if (hours !== '00') return hours + ':' + minutes;
    else if (minutes !== '00') return minutes + ':' + seconds;
    else {
      const tempSec = seconds.split('');
      tempSec[0] === '0' ? tempSec.shift() : null;
      return tempSec.join('');
    }
  }

  replaceWebPath(image: string) {
    return image.replace('{picdomain}', environment.picsDomain);
  }
  getPoster() {
    const poster = this.video.files.find(
      (x) => x.fileType == FileUploadType.VideoPoster
    );
    if (poster) {
      const imagePath = `${poster.webPath}/${poster.fileName}`;
      return this.replaceWebPath(imagePath);
    }
  }
}
