<div class="personal-details" *ngIf="product$ | async">
  <!-- <div class="personal-details__logo">
    <img src="../../../assets/logo.svg" alt="" />
    <button
      (click)="doLogout()"
      mat-button
      class="btn btn--outline btn--small btn--center"
    >
      התנתק
    </button>
  </div> -->
  <!--  <div class="personal-details__title">השלמת פרטים</div> -->
  <app-status-bar [fillDetailsStatus]="true"></app-status-bar>
  <div [ngSwitch]="personalDetailsStep">
    <div *ngSwitchCase="PersonalDetailsStepEnum.PersonalDetails">
      <form
        [formGroup]="detailsForm"
        (ngSubmit)="saveDetail()"
        class="form"
        *ngIf="detailsForm"
      >
        <div class="form__item">
          <div class="form__item__label">שם פרטי</div>
          <div class="form__item__input">
            <mat-form-field appearance="fill">
              <input
                matInput
                type="text"
                formControlName="firstName"
                placeholder="הכנס/י את שמך הפרטי"
              />
            </mat-form-field>
            <div
              class="error"
              *ngIf="
                isSubmited && firstName.errors && firstName.errors.required
              "
            >
              נא להזין שם פרטי
            </div>
          </div>
        </div>
        <div class="form__item">
          <div class="form__item__label">שם משפחה</div>
          <div class="form__item__input">
            <mat-form-field appearance="fill">
              <input
                matInput
                type="text"
                formControlName="lastName"
                placeholder="הכנס/י את שם המשפחה שלך"
              />
            </mat-form-field>
            <div
              class="error"
              *ngIf="isSubmited && lastName.errors && lastName.errors.required"
            >
              נא להזין שם משפחה
            </div>
          </div>
        </div>
        <div class="form__item form__item--flex">
          <div
            class="form__item__label"
            style="margin-left: 2rem; display: flex; align-items: center"
          >
            <ng-container
              *ngIf="isSubmited && gender.errors && gender.errors.required"
              ><mat-icon style="color: red; margin-left: 5px"
                >error</mat-icon
              ></ng-container
            >
            מין
          </div>
          <mat-radio-group
            class="w320"
            style="margin-left: auto"
            aria-label="בחר מין"
            value=""
            formControlName="gender"
          >
            <mat-radio-button value="male">זכר</mat-radio-button>
            <mat-radio-button value="female">נקבה</mat-radio-button>
          </mat-radio-group>
          <div
            *ngIf="isSubmited && gender.errors && gender.errors.required"
          ></div>
        </div>
        <div class="form__item">
          <div class="form__item__label">תעודת זהות</div>
          <div class="form__item__input">
            <mat-form-field appearance="fill">
              <input matInput type="text" [value]="IdNum" disabled />
            </mat-form-field>
          </div>
        </div>
        <div class="form__item">
          <div class="form__item__label">כתובת למשלוח</div>
          <div class="form__item__input">
            <mat-form-field appearance="fill">
              <input matInput type="text" [value]="Address" disabled />
            </mat-form-field>
          </div>
        </div>
        <div class="form__item">
          <div class="form__item__label">דואר אלקטרוני</div>
          <div class="form__item__input">
            <mat-form-field appearance="fill">
              <input matInput type="text" [value]="Email" disabled />
            </mat-form-field>
          </div>
        </div>
        <div class="form__item">
          <div class="form__item__label">תאריך לידה</div>
          <div class="form__item__input">
            <mat-form-field appearance="fill">
              <input
                matInput
                formControlName="birthDate"
                [min]="minBirthDate"
                [max]="maxBirthDate"
                [matDatepicker]="picker"
                placeholder="הכנס/י את תאריך הלידה שלך"
                (click)="picker.open()"
                autocomplete="off"
              />
              <mat-datepicker-toggle
                #datepickerToggle
                style="position: relative; top: 5px"
                matSuffix
                [for]="picker"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <div
              class="error"
              *ngIf="
                isSubmited && birthDate.errors && birthDate.errors.required
              "
            >
              נא להזין תאריך לידה
            </div>
          </div>
        </div>
        <div class="d-grid-2">
          <div class="form__item">
            <div class="form__item__label">משקל</div>
            <div class="form__item__input">
              <mat-form-field appearance="fill">
                <input
                  matInput
                  placeholder="הכנס/י את משקלך"
                  formControlName="weight"
                />
              </mat-form-field>
              <div
                class="error"
                *ngIf="isSubmited && weight.errors && weight.errors.required"
              >
                נא להזין משקל
              </div>
              <div
                class="error"
                *ngIf="isSubmited && weight.errors && weight.errors.pattern"
              >
                נא להזין משקל מ: 10 - 299
              </div>
            </div>
          </div>
          <div class="form__item">
            <div class="form__item__label">גובה</div>
            <div class="form__item__input">
              <mat-form-field appearance="fill">
                <input
                  matInput
                  placeholder="הכנס/י את גובהך"
                  formControlName="height"
                />
              </mat-form-field>
              <div
                class="error"
                *ngIf="isSubmited && height.errors && height.errors.required"
              >
                נא להזין גובה בסנטימטרים
              </div>
              <div
                class="error"
                *ngIf="isSubmited && height.errors && height.errors.pattern"
              >
                נא להזין גובה מ: 100 - 250
              </div>
            </div>
          </div>
        </div>

        <div class="form__item mt-2">
          <button
            type="submit"
            [disabled]="isLoading$ | async"
            mat-button
            class="btn btn--fill btn--big"
          >
            המשך
          </button>
        </div>
      </form>
    </div>
    <div class="form" *ngSwitchCase="PersonalDetailsStepEnum.Goals">
      <div class="form__title">מה ברצונך להשיג באמצעות הבדיקה?</div>
      <div class="form__sub-title">ניתן לסמן יותר מתשובה אחת</div>

      <mat-selection-list
        #goals
        (selectionChange)="onGoalsChange(goals.selectedOptions.selected)"
        [(ngModel)]="selectedGoalsModel"
        (ngModelChange)="selectedGoalsModel = true"
        class="goals-list"
        multiple
      >
        <mat-list-option
          checkboxPosition="before"
          *ngFor="let goal of userGoals"
          [value]="goal.goalId"
        >
          {{ goal.goalName }}
        </mat-list-option>
      </mat-selection-list>
      <div class="form__item mt-4" *ngIf="!patient.isSignedConcent">
        עליך לחתום על טופס הסכמה לביצוע בדיקה גנטית ולאשר את תנאי השימוש
      </div>
      <div class="form__item">
        <button
          type="button"
          (click)="saveUserGoals()"
          [disabled]="selectedGoalsModel.length == 0"
          mat-button
          class="btn btn--fill btn--big"
        >
          <ng-container *ngIf="!patient.isSignedConcent; else isSignedConcent"
            >המשך לתנאי השימוש</ng-container
          >
          <ng-template #isSignedConcent>המשך</ng-template>
        </button>
      </div>
    </div>
  </div>
</div>

<!-- <div class="flex-container">
  <form
    [formGroup]="detailsForm"
    (ngSubmit)="saveDetail()"
    class="form"
    *ngIf="detailsForm"
  >

    <mat-card class="personal-details-card">
      <div *ngIf="isLoading" class="loadingOverlay">
        <mat-spinner diameter="50"></mat-spinner>
      </div>
      <mat-card-title class="card-title"> פרטים אישיים </mat-card-title>
      <div>
        <mat-list style="margin-top: 20px">
          <mat-list-item class="mb-2">
            <div class="form-title">אנא השלם את פרטיך:</div>
          </mat-list-item>

          <mat-list-item>
            <span class="boldSpan">שם פרטי: </span>
            <mat-form-field>
              <input
                placeholder="ישראל"
                class="edit-field login-field"
                matInput
                formControlName="firstName"
              />
              <div
                class="error"
                *ngIf="
                  isSubmited && firstName.errors && firstName.errors.required
                "
              >
                אנא הזן שם פרטי
              </div>
            </mat-form-field>
          </mat-list-item>
          <mat-list-item>
            <span class="boldSpan">שם משפחה: </span>
            <mat-form-field>
              <input
                matInput
                placeholder="ישראלי"
                class="edit-field login-field"
                matInput
                formControlName="lastName"
              />
              <div
                class="error"
                *ngIf="
                  isSubmited && lastName.errors && lastName.errors.required
                "
              >
                אנא הזן שם משפחה
              </div>
            </mat-form-field>
          </mat-list-item>
          <mat-list-item>
            <div style="display: flex; width: 100%">
              <span class="boldSpan">תעודת זהות: </span>
              <div class="text-right fw-400 w320">{{ getIdNum() }}</div>
            </div>
          </mat-list-item>
          <mat-list-item>
            <div style="display: flex; width: 100%">
              <span class="boldSpan">כתובת למשלוח: </span>
              <div class="text-right fw-400 w320">{{ getAddress() }}</div>
            </div>
          </mat-list-item>
          <mat-list-item>
            <div style="display: flex; width: 100%">
              <span class="boldSpan">דוא"ל: </span>
              <div class="text-right fw-400 w320">{{ getEmail() }}</div>
            </div>
          </mat-list-item>
          <mat-list-item style="margin: 2rem 0">
            <mat-divider></mat-divider>
          </mat-list-item>
          <mat-list-item>
            <div class="form-title">פרופיל אישי:</div>
          </mat-list-item>
          <mat-list-item class="mb-2">
            <span class="boldSpan w-23">תאריך לידה: </span>
            <span
              class="w320"
              style="
                margin-right: 1.8em;
                display: flex;
                justify-content: space-between;
              "
            >
              <mat-form-field class="regular-select">
                <div class="label">שנה</div>
                <mat-select formControlName="year">
                  <mat-option disabled value="-1">שנה</mat-option>
                  <mat-option
                    *ngFor="let _year of yearsOptions"
                    [value]="_year"
                  >
                    {{ _year }}
                  </mat-option>
                </mat-select>
                <div *ngIf="isSubmited && year.value == -1" class="error">
                  יש לבחור שנה
                </div>
              </mat-form-field>
              <mat-form-field class="regular-select">
                <div class="label">חודש</div>
                <mat-select formControlName="month">
                  <mat-option disabled value="-1"> חודש </mat-option>
                  <mat-option
                    *ngFor="let month of monthsOptions"
                    [value]="month - 1"
                  >
                    {{ month }}
                  </mat-option>
                </mat-select>
                <div *ngIf="isSubmited && month.value == -1" class="error">
                  יש לבחור חודש
                </div>
              </mat-form-field>
              <mat-form-field class="regular-select">
                <div class="label">יום</div>
                <mat-select formControlName="day">
                  <mat-option disabled value="-1">יום</mat-option>
                  <mat-option *ngFor="let day of getDays()" [value]="day">
                    {{ day }}
                  </mat-option>
                </mat-select>
                <div *ngIf="isSubmited && day.value == -1" class="error">
                  יש לבחור יום
                </div>
              </mat-form-field>
            </span>
          </mat-list-item>
          <mat-list-item>
            <span class="boldSpan">מין: </span>

            <mat-radio-group
              class="w320"
              style="margin-left: auto"
              aria-label="בחר מין"
              value=""
              formControlName="gender"
            >
              <mat-radio-button value="male">זכר</mat-radio-button>
              <mat-radio-button value="female">נקבה</mat-radio-button>
            </mat-radio-group>
          </mat-list-item>
          <mat-list-item
            *ngIf="isSubmited && gender.errors && gender.errors.required"
            class="error"
          >
            <span class="boldSpan"></span>
            <span class="genderError"> יש לבחור מין </span>
          </mat-list-item>

          <mat-list-item class="mt-2">
            <span class="boldSpan">משקל: </span>
            <mat-form-field>
              <input
                type="text"
                inputmode="numeric"
                maxlength="5"
                placeholder="משקל"
                class="edit-field short-field"
                matInput
                formControlName="weight"
              />
              <div
                class="error"
                *ngIf="isSubmited && weight.errors && weight.errors.required"
              >
                אנא הזן משקל
              </div>
              <div
                class="error"
                *ngIf="isSubmited && weight.errors && weight.errors.pattern"
              >
                אנא הזן משקל מ: 20 - 299
              </div>
            </mat-form-field>
          </mat-list-item>

          <mat-list-item>
            <span class="boldSpan">גובה: </span>
            <mat-form-field>
              <input
                type="text"
                inputmode=""
                matInput
                placeholder="גובה"
                class="edit-field short-field"
                matInput
                formControlName="height"
              />
              <div
                class="error"
                *ngIf="isSubmited && height.errors && height.errors.required"
              >
                אנא הזן גובה בסנטימטרים
              </div>
              <div
                class="error"
                *ngIf="isSubmited && height.errors && height.errors.pattern"
              >
                אנא הזן גובה מ: 100 - 250
              </div>
            </mat-form-field>
          </mat-list-item>
          <mat-list-item style="margin-top: 2rem; text-align: center">
            עליך לחתום על טופס הסכמה לביצוע בדיקה גנטית ולאשר את תנאי השימוש
          </mat-list-item>
          <button type="submit" class="login-btn w-270">
            <ng-container *ngIf="!patient.isSignedConcent; else isSignedConcent"
              >המשך לתנאי השימוש</ng-container
            >
            <ng-template #isSignedConcent>המשך</ng-template>
          </button>
        </mat-list>
      </div>
    </mat-card>
  </form>
</div>
 -->
