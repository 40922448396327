<div
  style="
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 100%;
  "
>
  <div style="width: 100%; padding: 0 7px">
    <div class="custom-ruller">
      <ng-container
        *ngFor="let item of rullerRanks; let first = first; let i = index"
      >
        <div
          *ngIf="!first"
          class="item"
          [style.width.%]="calcRangeRuller(item.val, rullerRanks[i - 1].val)"
          [style.background-color]="item.color"
        >
          {{ bmiText[i - 1] }}
        </div>
      </ng-container>
      <span
        class="smily-wrapper"
        [ngStyle]="{ left: 'calc(' + setBmiRange() + '% - 22px)' }"
      >
        <img
          class="smily-img"
          src="../../../../assets/smiley/{{ setSmiley() }}"
        />
      </span>

      <span
        class="marker-img"
        [ngStyle]="{
          left: 'calc(' + setBmiRange() + '% - 8px)',
          'background-color': getIndicatorColor()
        }"
      ></span>

      <ng-container *ngIf="getCurrentGoalWeight()">
        <span
          class="flag-wrapper"
          [ngStyle]="{ left: 'calc(' + setBmiRange(true) + '% - 1.5px)' }"
        >
          <img class="flag-img" src="../../../assets/red_flag.svg" />
        </span>

        <span
          class="marker-img goal"
          [ngStyle]="{
            left: 'calc(' + setBmiRange(true) + '% - 8px)',
            'background-color': getIndicatorColor(true)
          }"
        ></span>
      </ng-container>
    </div>
    <div class="bmi">
      <div class="bmi__text">
        <span> ה-BMI שלך הוא </span>
        <strong style="font-size: 1.5rem">
          {{ bmi }}
        </strong>
      </div>
      <div class="bmi__info">
        לפי נתוניך האישיים טווח המשקל התקין עבורך הינו
        <span>{{ getCorrectWeight() }}</span>
        ק"ג
      </div>
      <div class="bmi__info__bmi-info">הינך נימצא ב{{ setBmiText() }}</div>
    </div>
  </div>
  <!-- TODO: WightGraphHistroy hidden -->
  <div class="text-center" *ngIf="false">
    <button
      mat-button
      class="text btn btn--center btn--small btn--underline btn--primary"
      (click)="openWightGraph()"
    >
      היסטורית משקלים
    </button>
  </div>
</div>
