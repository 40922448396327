import { copy } from 'copy-anything';
import { PatientWeightHistory } from 'src/app/data/PatientWeightHistory';
import { Product } from 'src/app/data/Product';
import { ProductStatusEnum } from 'src/app/Enum/StatusesEnum';
import { mergeShippingAdress } from 'src/app/Utils/userHelper';
import { ProductAction, ProductActionTypes } from '../Actions/product.actions';

export const productFeatureKey = 'product';

export interface ProductState {
  product: Product | null;
  isLoading: boolean;
  error: any;
  updateWeightSuccess: boolean;
}

const initialProductState: ProductState = {
  product: null,
  isLoading: false,
  error: null,
  updateWeightSuccess: false,
};

export function productReducer(
  state: ProductState = initialProductState,
  action: ProductAction
): ProductState {
  switch (action.type) {
    case ProductActionTypes.LoadProduct:
      return {
        ...state,
        isLoading: true,
        error: false,
      };
    case ProductActionTypes.LoadProductComplete:
      return {
        ...state,
        isLoading: false,
        error: false,
        product: action.payload.product,
      };

    case ProductActionTypes.ChangeStatusId:
      return {
        ...state,
        isLoading: true,
        error: false,
        product: { ...state.product, statusId: action.payload.statusId },
      };

    case ProductActionTypes.InsertBarcode:
      return {
        ...state,
        isLoading: true,
        error: false,
        product: { ...state.product, barCode: action.payload.barCode },
      };

    case ProductActionTypes.ChangeAddress:
      return {
        ...state,
        isLoading: true,
        error: false,
        product: {
          ...state.product,
          statusId: ProductStatusEnum.WaitingToPickUp,
          patient: {
            ...state.product.patient,
            regularUser: {
              ...state.product.patient.regularUser,
              userAdresses: mergeShippingAdress(
                copy([...state.product.patient.regularUser.userAdresses]),
                copy({ ...action.payload.address })
              ),
            },
          },
        },
      };

    case ProductActionTypes.LoadWeightHistory:
      return {
        ...state,
        isLoading: true,
        error: false,
      };

    case ProductActionTypes.LoadWeightHistoryComplete:
      return {
        ...state,
        isLoading: false,
        product: {
          ...state.product,
          patient: {
            ...state.product.patient,
            currentWeight: currentWeight(action.payload.weightHistories),
            goalWeight: goalWeight(action.payload.weightHistories),
            patientWeightHistories: action.payload.weightHistories,
          },
        },
      };

    case ProductActionTypes.UpdateCurrentWeight:
      return {
        ...state,
        isLoading: true,
        error: false,
        product: {
          ...state.product,
          patient: {
            ...state.product.patient,
            currentWeight: action.payload.currentWeight,
          },
        },
      };

    case ProductActionTypes.DeleteGeneticData:
      return {
        ...state,
        isLoading: true,
        product: { ...state.product },
      };
    case ProductActionTypes.DeleteGeneticData:
      return {
        ...state,
        isLoading: true,
      };
    case ProductActionTypes.DeleteGeneticDataComplete:
      return {
        ...state,
        isLoading: false,
      };

    case ProductActionTypes.ProductError:
    case ProductActionTypes.CurrentWeightError:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    case ProductActionTypes.UpdateWeightSuccess:
      return {
        ...state,
        isLoading: false,
        updateWeightSuccess: true,
      };
    default:
      return state;
  }
}

export function currentWeight(weightHistories: PatientWeightHistory[]) {
  var currentWeight = weightHistories.filter((x) => !x.isGoal);
  return currentWeight[currentWeight.length - 1].value;
}
export function goalWeight(weightHistories: PatientWeightHistory[]) {
  return weightHistories
    .filter((x) => x.isGoal)
    .sort((a, b) => {
      var dateA = new Date(a.dateUpdated);
      var dateB = new Date(b.dateUpdated);
      return dateB.getTime() - dateA.getTime();
    })[0].value;
}
