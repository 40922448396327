import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatListOption, MatSelectionListChange } from '@angular/material/list';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { copy } from 'copy-anything';
import { Observable } from 'rxjs';
import { GoalToPatient } from 'src/app/data/GoalToPatient';
import { Patient } from 'src/app/data/Patients';
import { Product } from 'src/app/data/Product';
import { RegularUser } from 'src/app/data/RegularUser';
import { UserAdress } from 'src/app/data/UserAdress';
import { UserGoal } from 'src/app/data/UserGoal';
import { PersonalDetailsStepEnum } from 'src/app/Enum/StatusesEnum';
import { LoadProduct } from 'src/app/Redux/Actions/product.actions';
import { LogoutUser } from 'src/app/Redux/Actions/regular-user.actions';
import { AppState } from 'src/app/Redux/reducers';
import {
  selectProduct,
  selectProductIsLoading,
} from 'src/app/Redux/selectors/product.selectors';
import { PatientsService } from 'src/app/Services/patients.service';
import { ProductService } from 'src/app/Services/product.service';
import { userAddress } from 'src/app/Utils/userHelper';
//import { convert } from 'text-to-picture';
//import { generate } from 'text-to-image';
//import { generate } from 'text-to-image';
//import { SignaturePad } from 'angular2-signaturepad/signature-pad';

@Component({
  selector: 'app-personal-details',
  templateUrl: './personal-details.component.html',
  styleUrls: ['./personal-details.component.scss'],
})
export class PersonalDetailsComponent implements OnInit {
  //sigPad: SignaturePad;
  //sigPad2: SignaturePad;
  user: RegularUser;
  patient: Patient;
  yearsOptions: number[];
  monthsOptions: number[];
  daysOptions: number[];
  isSubmited = false;
  detailsForm: FormGroup = null;
  product$: Observable<Product> = this.store.select(selectProduct);
  isLoading$: Observable<boolean> = this.store.select(selectProductIsLoading);
  minBirthDate = new Date(1905, 0, 1);
  maxBirthDate = new Date(new Date().setFullYear(new Date().getFullYear() - 1));
  userGoals: UserGoal[];
  selectedGoals: GoalToPatient[] = [];
  selectedGoalsModel: any = [];
  personalDetailsStep = PersonalDetailsStepEnum.PersonalDetails;
  PersonalDetailsStepEnum = PersonalDetailsStepEnum;
  product: Product;
  constructor(
    private patientsService: PatientsService,
    private productsService: ProductService,
    private router: Router,
    private datePipe: DatePipe,
    private store: Store<AppState>
  ) {
    /* this.yearsOptions = this.getYear();
    this.monthsOptions = this.getMonths(); */
  }

  ngOnInit(): void {
    this.product$.subscribe((data) => {
      if (data) {
        this.product = data;
        this.patient = copy({ ...data.patient });
        this.getUserGoals();
        this.createFormGroup();
      }
    });
  }
  onGoalsChange(values: MatListOption[]) {
    this.selectedGoals = [];
    values.map((x) => {
      this.pushToSelectedGoals(x.value);
    });
  }
  getUserGoals() {
    this.productsService.getUserGoals().subscribe((data) => {
      this.userGoals = data;
      this.selectedGoalsModel = this.product.patient.goalToPatient.map(
        (x) => x.goalIdId
      );

      this.selectedGoals = [];
      this.selectedGoalsModel.map((x) => {
        this.pushToSelectedGoals(x);
      });
    });
  }

  pushToSelectedGoals(goalId: number) {
    const goalToPatient = new GoalToPatient();
    goalToPatient.goalIdId = goalId;
    goalToPatient.patientToGoalId = 0;
    goalToPatient.patientId = this.product.patientId;
    this.selectedGoals.push(goalToPatient);
  }
  setFormFirstName() {
    var nameArray = [];
    if (!this.patient.regularUser.lastName) {
      var names = this.patient.regularUser.firstName.split(' ');
      nameArray.push(names);
      return nameArray[0][0];
    }
    return this.patient.regularUser.firstName;
  }
  setFormLastName() {
    var nameArray = [];
    if (!this.patient.regularUser.lastName) {
      var names = this.patient.regularUser.firstName.split(' ');
      nameArray.push(names);
      return nameArray[0][1];
    }
    return this.patient.regularUser.lastName;
  }
  createFormGroup() {
    //  this.user.birthDateObj = new Date(this.patient.regularUser.birthDate);
    if (this.patient.regularUser.birthDate == null) {
      this.patient.regularUser.birthDateObj = null;
    } else {
      this.patient.regularUser.birthDateObj = new Date(
        this.patient.regularUser.birthDate
      );
    }

    if (
      this.patient.regularUser.height &&
      this.patient.regularUser.height < 3
    ) {
      this.patient.regularUser.height = this.patient.regularUser.height * 100;
    }

    //var fullYear = this.patient.regularUser.birthDateObj.getFullYear();
    //var a = this.yearsOptions;

    this.detailsForm = new FormGroup({
      firstName: new FormControl(this.setFormFirstName(), [
        Validators.required,
      ]),
      lastName: new FormControl(this.setFormLastName(), [Validators.required]),
      idNum: new FormControl(this.patient.regularUser.socialSecurityNum, [
        Validators.required,
      ]),
      adress: new FormControl(
        this.patient.regularUser.street +
          ' ' +
          this.patient.regularUser.streetNum +
          ' ' +
          this.patient.regularUser.city,
        [Validators.required]
      ),
      email: new FormControl(this.patient.regularUser.email, [
        Validators.required,
      ]),
      /* year: new FormControl(
        this.patient.regularUser.birthDateObj
          ? this.patient.regularUser.birthDateObj.getFullYear()
          : '-1',
        [Validators.required]
      ),
      month: new FormControl(
        this.patient.regularUser.birthDateObj
          ? this.patient.regularUser.birthDateObj.getMonth()
          : '-1',
        [Validators.required]
      ),
      day: new FormControl(
        this.patient.regularUser.birthDateObj
          ? this.patient.regularUser.birthDateObj.getDate()
          : '-1',
        [Validators.required]
      ), */
      birthDate: new FormControl(
        this.patient.regularUser.birthDateObj,
        Validators.required
      ),
      gender: new FormControl(this.patient.regularUser.gender, [
        Validators.required,
      ]),
      weight: new FormControl(this.patient.regularUser.weight, [
        Validators.required,
        Validators.pattern(
          '^([1-8][0-9]|[1-8][0-9].[1-9]|9[0-9]|1[0-9]{2}|1[0-9]{2}.[1-9]|2[0-8][0-9]|2[0-8][0-9].[1-9]|29[0-9]|28[0-9].[1-9])$'
        ),
      ]),
      height: new FormControl(this.patient.regularUser.height, [
        Validators.required,
        //Validators.pattern('^(1[0-9]{2}|2[0-4][0-9]|250)$'),
        Validators.pattern('^(1[0-9]{2}|(2[0-5]0)|2[0-4][0-9])$'),
      ]),
    });
  }
  doLogout() {
    this.store.dispatch(LogoutUser());
  }
  saveDetail() {
    this.isSubmited = true;
    if (!this.detailsForm.valid) return;

    this.patient.regularUser.firstName = this.detailsForm.value.firstName;
    this.patient.regularUser.lastName = this.detailsForm.value.lastName;
    this.patient.regularUser.birthDate = this.datePipe.transform(
      this.detailsForm.value.birthDate,
      'yyyy-MM-dd hh:mm'
    );
    this.patient.regularUser.gender = this.detailsForm.value.gender;
    this.patient.regularUser.weight = this.detailsForm.value.weight;
    this.patient.regularUser.height = this.detailsForm.value.height;

    this.patientsService.fillDetails(this.patient).subscribe((res) => {
      this.store.dispatch(
        new LoadProduct({ patientId: this.patient.patientId })
      );

      this.personalDetailsStep = this.PersonalDetailsStepEnum.Goals;

      //this.signatureMode = true;
    });
  }
  saveUserGoals() {
    this.selectedGoals = this.removeDuplicatesById(this.selectedGoals);
    this.productsService
      .setUserGoals(this.selectedGoals, this.product.productId)
      .subscribe((data) => {
        this.product$.subscribe((data) => {
          if (data) {
            var patient = data.patient;
            if (!patient.isSignedConcent) {
              this.router.navigate(['/terms-of-use']);
            } else {
              this.router.navigate(['/home']);
            }
          }
        });
      });
  }
  removeDuplicatesById(arr: GoalToPatient[]) {
    var obj = {};
    var result = [];
    for (var i = 0; i < arr.length; i++) {
      if (!obj[arr[i].goalIdId]) {
        obj[arr[i].goalIdId] = true;
        result.push(arr[i]);
      }
    }
    return result;
  }
  /* getDaysInMonth = function (month, year) {
    return new Date(year, month + 1, 0).getDate();
  };
  getDays() {
    var days = [];

    var dim = this.getDaysInMonth(
      this.detailsForm.value.month,
      this.detailsForm.value.year
    );

    for (let i = 1; i <= dim; i++) {
      days.push(i);
    }
    return days;
  }
  getMonths() {
    var month = [];
    for (let i = 1; i < 13; i++) {
      month.push(i);
    }
    return month;
  }
  getYear() {
    var years = [];
    var currentYear = new Date().getFullYear();

    for (let i = 1920; i <= currentYear; i++) {
      years.push(i);
    }
    return years;
  } */
  preventNegativeNumber(value, field: 'weight' | 'height') {
    if (!!value && Math.abs(value) >= 0) {
      if (field == 'weight') {
        this.weight.setValue(Math.abs(value));
      } else if (field == 'height') {
        this.height.setValue(Math.abs(value));
      }
    }
  }
  get Address() {
    if (!this.patient.regularUser.userAdresses) return '';
    var userAddress1 = userAddress(
      this.patient.regularUser.userAdresses as UserAdress[]
    );

    return (
      userAddress1.barStreet.name +
      ' ' +
      userAddress1.streetNum +
      ' ' +
      userAddress1.barCity.name
    );
  }
  get Email() {
    return this.patient.regularUser.email;
  }
  get IdNum() {
    return this.patient.regularUser.socialSecurityNum;
  }
  get firstName() {
    return this.detailsForm.get('firstName');
  }
  get lastName() {
    return this.detailsForm.get('lastName');
  }
  get birthDate() {
    return this.detailsForm.get('birthDate');
  }
  get weight() {
    return this.detailsForm.get('weight');
  }
  get height() {
    return this.detailsForm.get('height');
  }
  get gender() {
    return this.detailsForm.get('gender');
  }
  /*   initSignatures() {
    if (this.signaturePad) {
      this.signaturePad.set('minWidth', 5); // set szimek/signature_pad options at runtime
      this.signaturePad.clear(); // invoke functions from szimek/signature_pad API
    }
    if (this.signaturePad2) {
      this.signaturePad2.set('minWidth', 5); // set szimek/signature_pad options at runtime
      this.signaturePad2.clear(); // invoke functions from szimek/signature_pad API
    }
    if (this.signaturePad3) {
      this.signaturePad3.set('minWidth', 5); // set szimek/signature_pad options at runtime
      this.signaturePad3.clear(); // invoke functions from szimek/signature_pad API
    }
  } */
  ngAfterViewInit() {
    //console.log('ngAfterViewInit');
    /*
    this.intervalSig = setInterval(() => {
      if (
        this.signaturePad &&
        this.signaturePad2 &&
        this.signaturePad.nativeElement &&
        this.signaturePad2.nativeElement
      ) {
        var canvas = this.signaturePad.nativeElement;
        this.sigPad = new SignaturePad(canvas, {
          backgroundColor: 'rgba(255, 255, 255, 0)',
          penColor: 'rgb(0, 0, 0)',
          onBegin: () => (this.sigError = false),
        });
        var canvas2 = this.signaturePad2.nativeElement;
        this.sigPad2 = new SignaturePad(canvas2, {
          backgroundColor: 'rgba(255, 255, 255, 0)',
          penColor: 'rgb(0, 0, 0)',
          onBegin: () => (this.sigError = false),
        });
        clearInterval(this.intervalSig);
      }
      console.log('run');
    }, 1000);
    */
  }
  /* sig1drawComplete() {
    // will be notified of szimek/signature_pad's onEnd event
    console.log(this.signaturePad.toDataURL());
  }
  sig2drawComplete() {
    // will be notified of szimek/signature_pad's onEnd event
    console.log(this.signaturePad2.toDataURL());
  }
  drawStart() {
    // will be notified of szimek/signature_pad's onBegin event
    console.log('begin drawing');
  } */

  /*
  watchTermOfUse() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/clients/edit/'], {
        queryParams: { clientId: this.client.clientId },
      })
    );
    window.open(url, '_blank');
  }*/
}
