import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LifeStyleAnswer } from '../data/LifeStyleAnswer';

@Injectable({
  providedIn: 'root',
})
export class LifeStyleAnswersService {
  constructor(private http: HttpClient) {}

  empty() {
    return of({ msg: 'error' });
  }

  post(answer: LifeStyleAnswer) {
    return this.http.post<any>(
      `${environment.baseUrl}LifeStyleAnswers/`,
      answer
    );
  }
  put(answer: LifeStyleAnswer) {
    return this.http.put<any>(
      `${environment.baseUrl}LifeStyleAnswers/${answer.lifeStyleAnswerId}`,
      answer
    );
  }
}
