<div class="container-loader" *ngIf="isLoading">
  <ngx-skeleton-loader [theme]="{ height: '30px' }" count="10">
  </ngx-skeleton-loader>
</div>
<ng-container *ngIf="!isLoading">
  <div class="results" *ngIf="resultsCount">
    <div class="results-count">
      <div class="results-count__title">{{ resultsCount.catCount }} ממצאים</div>
      <div class="results-count__subtitle">
        מתוך
        {{ resultsCount.subCatCount }}
        קטגוריות ו-
        {{ resultsCount.snpCount }}
        ווריאנטים גנטיים
      </div>
    </div>
    <div
      class="results-bar"
      [style.right.px]="(resultsCount.resultTypes.length - 1) * 8.5"
    >
      <div
        class="results-bar__item"
        *ngFor="let item of resultsCount.resultTypes; let i = index"
        [style.width.%]="getBarItemWidth(item)"
        [style.background-color]="'#' + item.color"
        [style.z-index]="resultsCount.resultTypes.length - i"
        [style.left.px]="15 * i"
      ></div>
    </div>
    <div class="results-types">
      <div *ngFor="let item of resultsCount.resultTypes; let i = index">
        <div class="row">
          <div class="results-types__count">{{ item.typeCount }}</div>
          <div class="results-types__text">{{ item.name }}</div>
        </div>
        <div
          class="results-types__color"
          [style.background-color]="'#' + item.color"
        ></div>
      </div>
    </div>
  </div>

  <div class="container" id="dna-report" *ngIf="categories?.length > 0">
    <div class="categories">
      <div
        *ngFor="let category of categories; index as i"
        style="position: relative"
      >
        <div
          *ngIf="category.isPremium && !isPlusAndComplete()"
          class="premium-box"
          (click)="goToCategory(category.categoryId, category.isPremium)"
        >
          <img src="../../../assets/crown.svg" alt="" />
          <div class="text" *ngIf="remainingTime">
            {{ remainingTime }}
          </div>
        </div>
        <mat-card
          class="categories__item"
          [class.is-basic]="category.isPremium && !isPlusAndComplete()"
          (click)="goToCategory(category.categoryId, category.isPremium)"
          matRipple
        >
          <div class="categories__item__body">
            <div class="categories__item__img">
              <img
                *ngIf="getImage(category.image)"
                [src]="getImage(category.image)"
              />
            </div>
            <div class="categories__item__content">
              <div class="categories__item__content__title">
                {{ category.categoryName }}
              </div>
              <div class="categories__item__content__desc">
                {{ category.subCatNames }}
              </div>
            </div>
          </div>
          <div class="categories__item__info">
            <div class="row">
              <div
                *ngFor="let item of category.summary"
                class="categories__item__info__box"
                [style.background-color]="'#' + item.color"
                [matTooltip]="
                  isPremiumAndLockDone(category.isPremium) ? item.typeName : ''
                "
              >
                {{ item.count }}
              </div>
            </div>
            <div>
              <button
                class="categories__item__info__btn"
                (click)="goToCategory(category.categoryId, category.isPremium)"
                mat-icon-button
              >
                <mat-icon>keyboard_arrow_left</mat-icon>
              </button>
            </div>
          </div>
        </mat-card>
      </div>
    </div>
  </div>
  <div *ngIf="categories?.length == 0" class="container">
    <div style="font-size: 16px; text-align: center">
      אירעה שגיאה בהצגת התוצאות
      <br />
      לתמיכה וסיוע אנא פנה אל מוקד השירות בטלפון 03-3095244
    </div>
  </div>
</ng-container>
