<form [formGroup]="loginForm" (ngSubmit)="sendCaptchaToVerify()" class="login">
  <!-- <div class="login__logo">
    <img src="../../../assets/logo.svg" alt="" />
  </div> -->
  <div class="login__title">כניסה לאיזור האישי</div>
  <div class="login__form">
    <div class="login__form__item">
      <div class="login__form__item__label">תעודת זהות</div>
      <div class="login__form__item__input">
        <mat-form-field appearance="fill">
          <input formControlName="userName" matInput type="text" />
        </mat-form-field>
        <mat-error
          *ngIf="userName.errors && userName.errors.required && loginForm.dirty"
        >
          אנא הזן תעודת זהות
        </mat-error>
      </div>
    </div>
    <div class="login__form__item">
      <div class="login__form__item__label">סיסמה</div>
      <div class="login__form__item__input">
        <mat-form-field appearance="fill">
          <input
            formControlName="password"
            matInput
            [type]="hide ? 'password' : 'text'"
            autocomplete="on"
          />
          <button
            class="toggle-password"
            type="button"
            mat-icon-button
            matSuffix
            (click)="hide = !hide"
          >
            <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
          </button>
        </mat-form-field>
        <mat-error
          *ngIf="password.errors && password.errors.required && loginForm.dirty"
        >
          אנא הזן ססמא
        </mat-error>
      </div>
    </div>
    <div class="login__form__item space-between">
      <mat-checkbox [checked]="true">זכור אותי</mat-checkbox>
      <button
        routerLink="/forgot-password"
        type="button"
        class="btn-link"
        mat-button
      >
        שכחתי סיסמה
      </button>
    </div>
  </div>

  <div class="login__form__item">
    <button
      [disabled]="isLoading"
      type="submit"
      class="btn btn--fill btn--big"
      mat-button
    >
      התחבר
    </button>
  </div>
  <mat-error class="form-text mt-2" *ngIf="error$ | async">
    <span class="error"
      ><strong>פרטי ההתחברות שהזנת שגויים אנא בדוק אותם ונסה שנית</strong></span
    ><br />
    או צור קשר עם מוקד השירות 03-3095244 <br />
  </mat-error>
  <div class="text-center mt-4">
    לקוח חדש?&nbsp;
    <button
      type="button"
      class="btn btn--underline btn--small btn--primary btn--center p-0 fw-light"
      mat-button
      routerLink="/register"
    >
      הירשם כאן
    </button>
  </div>
</form>

<!-- <div class="flex-container">
  <form [formGroup]="loginForm" (ngSubmit)="doLogin()" class="example-form">
    <mat-card class="flex-item">
      <div *ngIf="isLoading" class="loadingOverlay">
        <mat-spinner diameter="50"></mat-spinner>
      </div>
      <mat-card-title class="card-title"> כניסה לאזור האישי </mat-card-title>
      <div>
        <mat-list style="margin-top: 35px">
          <mat-list-item style="display: flex; justify-content: center">
            <mat-form-field
              class="example-full-width login-field"
              style="width: 270px"
            >
              <input
                style="border: 1px solid black; height: 40px; line-height: 40px"
                placeholder="תעודת זהות"
                class="edit-field login-field idInput"
                matInput
                formControlName="userName"
              />
              <mat-error *ngIf="userName.errors && userName.errors.required">
                אנא הזן תעודת זהות
              </mat-error>
            </mat-form-field>
          </mat-list-item>
          <br />
          <mat-list-item style="display: flex; justify-content: center">
            <mat-form-field
              class="example-full-width login-field"
              style="width: 270px"
            >
              <mat-label>Enter your password</mat-label>
              <input
                [type]="hide ? 'password' : 'text'"
                matInput
                style="border: 1px solid black; height: 40px; line-height: 40px"
                placeholder="סיסמה"
                class="edit-field login-field passwordInput"
                matInput
                formControlName="password"
              />

              <button
                style="
                  position: absolute;
                  left: 2px;
                  top: 0;
                  transform: translateY(-108%);
                  font-size: 2rem;
                "
                type="button"
                mat-icon-button
                matSuffix
                (click)="hide = !hide"
                [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="hide"
              >
                <mat-icon>{{
                  hide ? "visibility_off" : "visibility"
                }}</mat-icon>
              </button>
              <mat-error *ngIf="password.errors && password.errors.required">
                אנא הזן ססמא
              </mat-error>
            </mat-form-field>
          </mat-list-item>
          <div class="form-text text-right w-270">
            <a href="javascript:void(0);" routerLink="/forgot-password"
              >שכחתי סיסמה
            </a>
          </div>
          <div class="form-text w-270 text-right mt-2" *ngIf="error$ | async">
            <span class="error"
              ><strong
                >פרטי ההתחברות שהזנת שגויים אנא בדוק אותם ונסה שנית</strong
              ></span
            ><br />
            או צור קשר עם מוקד השירות 03-3095244 <br />
            <a href="#">או לחץ כאן לפתיחת פנייה מכוונת</a>
          </div>
          <button class="login-btn w-270">התחבר</button>
          <button
            type="button"
            routerLink="/register"
            class="login-btn mt-2 w-270 purple"
          >
            לקוח חדש? הירשם
          </button>
        </mat-list>
      </div>
    </mat-card>
  </form>
</div>
 -->
