<div class="page-title">תפריטים</div>
<div class="container">
  <div class="menus">
    <ngx-skeleton-loader
      *ngIf="isLoading"
      [theme]="{ height: '50px' }"
      count="5"
    ></ngx-skeleton-loader>
    <mat-accordion>
      <div class="panel-wrapper" *ngFor="let menu of menus">
        <mat-expansion-panel
          [expanded]="menu.expanded"
          hideToggle="true"
          class="menus__card"
          #menuPanel
        >
          <mat-expansion-panel-header>
            <div class="flex-between">
              <div>
                <div class="menus__card__name">
                  {{ menu.name }}
                  <app-menu-html
                    style="display: none"
                    *ngIf="menuHtml"
                    [menu]="menuHtml"
                  ></app-menu-html>
                </div>
                <div style="display: flex; align-items: center">
                  <div class="menus__card__agent">
                    <mat-icon>person</mat-icon>
                    בוצע ע"י
                    {{
                      menu.updatedByAgentName
                        ? menu.updatedByAgentName
                        : menu.createdByAgentName
                    }}
                  </div>
                  <div class="menus__card__date">
                    <mat-icon>date_range</mat-icon>
                    {{ menu.dateUpdated | date: "dd/MM/yyyy" }}
                  </div>
                </div>
              </div>
              <div class="menus__card__nutrients">
                <app-genetic-rate
                  [inMenuList]="true"
                  [nutrients]="menu.stats"
                ></app-genetic-rate>
              </div>
              <!-- TODO: Genetic rate hidden -->
              <div class="menus__card__rate" *ngIf="false">
                <div class="menus__card__rate__value">
                  {{ getGeneticRate(menu.stats)?.amount | number: "1.0-0" }}%
                </div>
                <div class="menus__card__rate__text">דירוג גנטי יומי</div>
              </div>
              <button
                class="menus__card__btn"
                mat-icon-button
                *ngIf="!menuPanel.expanded"
              >
                <mat-icon>keyboard_arrow_left</mat-icon>
              </button>
              <button
                class="menus__card__btn"
                mat-icon-button
                *ngIf="menuPanel.expanded"
              >
                <mat-icon>keyboard_arrow_down</mat-icon>
              </button>
            </div>
          </mat-expansion-panel-header>
          <div class="menus__body">
            <app-diet-menu-meals [menuId]="menu.menuId"></app-diet-menu-meals>
          </div>
        </mat-expansion-panel>
        <button
          mat-icon-button
          class="download-btn"
          (click)="getByMenuIdForHtml(menu.menuId)"
        >
          <mat-icon>download</mat-icon>
        </button>
      </div>
    </mat-accordion>
    <mat-card
      *ngIf="isNutritionReportExist()"
      class="files__card"
      matRipple
      (click)="downloadOldSystemDietMenu()"
    >
      <div class="files__card__name">
        <img src="../../../assets/adobe.svg" alt="" />
        להורדת התפריט
      </div>
      <div class="files__card__download">
        <img src="../../../assets/download.svg" alt="" />
      </div>
    </mat-card>
    <!-- <ng-container *ngIf="menus.length > 0 && !isLoading">
      <mat-card *ngFor="let menu of menus" class="menus__card">
        <div class="flex-between">
          <div>
            <div class="menus__card__name">
              {{ menu.name }}
            </div>
            <div style="display: flex; align-items: center">
              <div class="menus__card__agent">
                <mat-icon>person</mat-icon>
                בוצע ע"י
                {{
                  menu.updatedByAgentName
                    ? menu.updatedByAgentName
                    : menu.createdByAgentName
                }}
              </div>
              <div class="menus__card__date">
                <mat-icon>date_range</mat-icon>
                {{ menu.dateUpdated | date: "dd/MM/yyyy" }}
              </div>
            </div>
          </div>
          <div class="menus__card__nutrients">
            <app-genetic-rate
              [inMenuList]="true"
              [nutrients]="menu.stats"
            ></app-genetic-rate>
          </div>
          <div class="menus__card__rate">
            <div class="menus__card__rate__value">
              {{ getGeneticRate(menu.stats)?.amount | number: "1.0-0" }}%
            </div>
            <div class="menus__card__rate__text">דירוג גנטי יומי</div>
          </div>
          <button
            class="menus__card__btn"
            (click)="gotoMenuMeals(menu)"
            mat-icon-button
          >
            <mat-icon>keyboard_arrow_left</mat-icon>
          </button>
        </div>
      </mat-card>
      <div class="menus__disclamer">
        התפריט לוקח בחשבון את רמת הפעילות הנוכחית שלך רמת הפעילות המדווחת היא
        <span>"{{ PALevel | translate }}"</span>.
      </div>
    </ng-container> -->
  </div>
</div>
