import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Deal } from 'src/app/data/Deal';
import { DealBundle } from 'src/app/data/DealBundle';
import { FutureProduct } from 'src/app/data/FutureProduct';
import { Product } from 'src/app/data/Product';
import { ProductForClientMin } from 'src/app/data/ProductForClientMin';
import { ProductForDealBundle } from 'src/app/data/ProductForDealBundle';
import { TranzillaTransaction } from 'src/app/data/TranzillaTransaction';
import { ProductTypeEnum } from 'src/app/Enum/StatusesEnum';
import { LoadProduct } from 'src/app/Redux/Actions/product.actions';
import { AppState } from 'src/app/Redux/reducers';
import { selectProduct } from 'src/app/Redux/selectors/product.selectors';
import { DealsService } from 'src/app/Services/deals.service';
import { ProductTypesService } from 'src/app/Services/product-types.service';
import { copy } from 'copy-anything';

@Component({
  selector: 'app-upgrade',
  templateUrl: './upgrade.component.html',
  styleUrls: ['./upgrade.component.scss'],
})
export class UpgradeComponent implements OnInit {
  dealAmount: number = 0;
  upgradeForm: FormGroup;
  product$: Observable<Product> = this.store.select(selectProduct);
  product: Product;
  isBankErrorMsg: boolean = false;
  isLoading: boolean = false;
  serverError: boolean;
  tranzillaErrorMsg: string;
  defaultTranzillaErrorMsg =
    'אירעה תקלה בחיוב.<br /> נא נסה שנית או פנה לתמיכה 03-3095244';

  constructor(
    private store: Store<AppState>,
    private dealsService: DealsService,
    private router: Router,
    private productTypeService: ProductTypesService
  ) {}

  step: number = 1;

  ngOnInit(): void {
    this.product$.subscribe((res) => {
      if (res) {
        this.product = copy({ ...res });
        this.getAllproductTypes();
        this.createForm();
      }
    });
  }

  incStep() {
    this.step++;
    setTimeout(() => {
      document.body.scrollTop = 0;
    }, 500);
  }

  getAllproductTypes() {
    this.productTypeService.getAll().subscribe((data) => {
      this.dealAmount = data.find(
        (x) => x.productName == 'UpgradeSite'
      ).priceForPrivate;
    });
  }

  backBtn(step: number) {
    switch (step) {
      case 2:
        this.step--;
        break;
      case 1:
      case 3:
      default:
        this.router.navigate(['home']);
        break;
    }
  }
  goToLifestyleReport() {
    this.router.navigate(['home'], {
      queryParams: {
        unlock: 'true',
      },
    });
  }

  createForm() {
    this.upgradeForm = new FormGroup({
      fullName: new FormControl('', Validators.required),
      cardNumber: new FormControl('', Validators.required),
      expierDate: new FormControl('', Validators.required),
      cvv: new FormControl('', Validators.required),
      socialSecurityNum: new FormControl('', Validators.required),
      numPayments: new FormControl('', Validators.required),
    });
  }
  onSubmit() {
    if (this.upgradeForm.valid) {
      const deal: Deal = new Deal();
      deal.dealBundle = new DealBundle();
      deal.statusId = 3;
      ///// create Bundle
      deal.dealBundle.amount = this.dealAmount;
      deal.dealBundle.agentId = 1;
      deal.dealBundle.clientId = this.product.clientId;
      deal.dealBundle.client = this.product.client;
      deal.amount = this.dealAmount;
      deal.paymentMethodId = 1; // credit card
      deal.dealBundle.productForDealBundles = [];

      var productForDealBundle = new ProductForDealBundle();
      productForDealBundle.dealBundleId = 0;
      productForDealBundle.productId = this.product.productId;
      productForDealBundle.product = this.product;
      (productForDealBundle.product.upgragePurchaseDate as any) = new Date();
      deal.dealBundle.productForDealBundles.push(productForDealBundle);
      deal.futureProducts = [];

      /// from  purchase-choose-products in CRM
      const futureProduct = new FutureProduct();
      futureProduct.clientId = this.product.clientId;
      futureProduct.productTypeId = ProductTypeEnum.Upgrage;
      futureProduct.products = [];
      const product = new ProductForClientMin();
      product.clientId = this.product.clientId;
      product.productId = this.product.productId;
      futureProduct.products.push(product);
      deal.futureProducts.push(futureProduct);

      /// from purchase-personal-details in CRM
      deal.tranzillaTransaction = new TranzillaTransaction();

      /// from purchase-payment-details  in CRM

      deal.tranzillaTransaction.sum = deal.amount.toString();
      deal.tranzillaTransaction.contact = this.upgradeForm.value.fullName;
      deal.tranzillaTransaction.ccno = this.upgradeForm.value.cardNumber;
      deal.tranzillaTransaction.myccv = this.upgradeForm.value.cvv;
      deal.tranzillaTransaction.myid = this.upgradeForm.value.socialSecurityNum;
      deal.tranzillaTransaction.currency = '1'; // Shekel
      deal.tranzillaTransaction.npay = this.upgradeForm.value.numPayments;
      deal.tranzillaTransaction.fpay = (
        deal.amount / this.upgradeForm.value.numPayments
      ).toString();
      deal.tranzillaTransaction.spay = (
        deal.amount / this.upgradeForm.value.numPayments
      ).toString();
      deal.tranzillaTransaction.cred_type = 8; // Payments
      if (deal.tranzillaTransaction.npay == '1') {
        deal.tranzillaTransaction.cred_type = 1; // regular
      }
      deal.tranzillaTransaction.expdate = this.upgradeForm.value.expierDate;
      this.isBankErrorMsg = false;
      this.isLoading = true;
      this.dealsService.post(deal).subscribe(
        (_deal) => {
          this.isLoading = false;
          const bankResponseCode =
            _deal.dealHistories[0].tranzillaTransactionResponse
              .bankResponseCode;

          if (bankResponseCode.isSuccess) {
            this.step = 3;
            this.store.dispatch(
              new LoadProduct({ patientId: this.product.patientId })
            );
          } else {
            this.tranzillaErrorMsg = bankResponseCode.name
              ? bankResponseCode.name
              : this.defaultTranzillaErrorMsg;
            this.isBankErrorMsg = true;
          }
        },
        (error) => {
          this.isBankErrorMsg = true;
          this.tranzillaErrorMsg = this.defaultTranzillaErrorMsg;
          this.isLoading = false;
        }
      );

      /*
          this.openPurchaseCompleteDialog(null, textType, '', '');
          this.purchaseMode = 1;
          //this.clientDeals.push(deal);
          this.dealsService
            .getByClientId(this.client.clientId)
            .subscribe((data) => {
              this.clientDeals = data;
            });
          this.getClientProducts();
          */
      /*
        },
        (error) => {
          this.openPurchaseCompleteDialog(null, 'purchaseRejected', '', '');
          this.purchaseMode = 1;
        }
*/
      /*
      this.dealsService.post(deal).subscribe((res) => {
        this.step = 3;
        console.log(res);
      });
    }
*/
    }
  }
}
