import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ChatHour } from '../data/ChatHour';

@Injectable({
  providedIn: 'root',
})
export class ChatHoursService {
  constructor(private http: HttpClient) {}

  getAll() {
    return this.http.get<ChatHour[]>(`${environment.baseUrl}ChatHours`);
  }
/*
  getById(chatHourId: number) {
    return this.http.get<ChatHour>(
      `${environment.baseUrl}ChatHours/${chatHourId}`
    );
  }

  post(ChatHour: ChatHour) {
    return this.http.post<ChatHour>(
      `${environment.baseUrl}ChatHours`,
      ChatHour
    );
  }
  put(ChatHour: ChatHour) {
    return this.http.put<ChatHour>(
      `${environment.baseUrl}ChatHours/${ChatHour.chatHourId}`,
      ChatHour
    );
  }

  delete(chatHourId: number) {
    return this.http.delete<ChatHour>(
      `${environment.baseUrl}ChatHours/${chatHourId}`
    );
  }
  restoreDefault() {
    return this.http.get<ChatHour[]>(
      `${environment.baseUrl}ChatHours/RestoreDefault/`
    );
  }
  SetDefault(ChatHours: ChatHour[]) {
    return this.http.post<any>(
      `${environment.baseUrl}ChatHours/SetDefault`,
      ChatHours
    );
  }
  */
}
