<div class="page-title">פרופיל אישי</div>
<div class="profile" *ngIf="product">
  <div style="position: relative">
    <div class="profile__header"></div>
    <div class="profile__header__img">
      <img
        *ngIf="!croppedImage && profileImg"
        [src]="profileImg"
        class="img"
        alt=""
      />
      <img
        *ngIf="!profileImg && !croppedImage"
        [src]="getDefaultPersonImage()"
        class="img"
        alt=""
      />

      <img *ngIf="croppedImage" [src]="croppedImage" class="img" />
      <button
        (click)="changeProfileImg = true; profileInputFile.click()"
        mat-icon-button
        class="profile__header__img__btn"
      >
        <mat-icon>camera_alt</mat-icon>
      </button>

      <input
        style="display: none"
        #profileInputFile
        type="file"
        accept="image/*"
        (change)="fileChangeEvent($event)"
      />
    </div>
    <div class="profile__header__name">
      {{ regularUser.firstName + " " + regularUser.lastName }}
    </div>
  </div>
  <!-- <div style="margin-bottom: 12rem"></div> -->
  <div class="profile__container">
    <div class="profile__image-cropper" *ngIf="changeProfileImg">
      <div *ngIf="croppedImage" class="text-center mb-2">
        <button
          mat-button
          class="btn btn--small btn--fill btn--center"
          (click)="onCheck()"
        >
          שמירה
        </button>
        <span class="ml-2"></span>
        <button
          mat-button
          class="btn btn--small btn--outline btn--center"
          (click)="onClose()"
        >
          ביטול
        </button>
      </div>
      <image-cropper
        [imageChangedEvent]="imageChangedEvent"
        [maintainAspectRatio]="true"
        [containWithinAspectRatio]="false"
        [onlyScaleDown]="true"
        [roundCropper]="false"
        [transform]="{}"
        [aspectRatio]="1 / 1"
        [style.display]="showCropper ? null : 'none'"
        alignImage="center"
        canvasRotation="0"
        format="png"
        resizeToWidth="256"
        cropperMinWidth="128"
        (imageCropped)="imageCropped($event)"
        (imageLoaded)="imageLoaded()"
        (cropperReady)="cropperReady($event)"
        (loadImageFailed)="loadImageFailed()"
      ></image-cropper>
    </div>
  </div>
  <div class="profile__details">
    <div class="profile__details__item" *ngIf="product.arrivedToCostumer">
      <div class="profile__details__item__label">
        <mat-icon>date_range</mat-icon>
        תאריך ביצוע הבדיקה:
      </div>
      <div class="profile__details__item__value">
        {{ product.arrivedToCostumer | date: "dd.MM.yyyy" }}
      </div>
    </div>
    <div class="profile__details__item">
      <div class="profile__details__item__label">
        <mat-icon>cake</mat-icon>
        תאריך לידה:
      </div>
      <div class="profile__details__item__value">
        {{ regularUser.birthDate | date: "dd.MM.yyyy" }}
      </div>
    </div>
    <div class="profile__details__item">
      <div class="profile__details__item__label">
        <mat-icon>email</mat-icon>
        דוא"ל:
      </div>
      <div class="profile__details__item__value">
        {{ regularUser.email }}
      </div>
      <button
        (click)="openConfirmationReset(emailResetType)"
        class="profile__details__item__edit"
        mat-icon-button
      >
        <img src="../../../assets/edit-outline.svg" alt="" />
        <!-- <mat-icon>edit</mat-icon> -->
      </button>
    </div>

    <div class="profile__details__item">
      <div class="profile__details__item__label">
        <mat-icon class="rotate">phone</mat-icon>
        טלפון:
      </div>
      <div class="profile__details__item__value">
        {{ regularUser.phone }}
      </div>
      <button
        (click)="openConfirmationReset(phoneResetType)"
        class="profile__details__item__edit"
        mat-icon-button
      >
        <!-- <mat-icon>edit</mat-icon> -->
        <img src="../../../assets/edit-outline.svg" alt="" />
      </button>
    </div>
    <div class="profile__details__item">
      <div class="profile__details__item__label">
        <mat-icon>monitor_weight</mat-icon>
        משקל נוכחי:
      </div>
      <div class="profile__details__item__value" dir="ltr">
        {{ getWeight() | number: "1.0-1" }} kg
      </div>
      <button
        [routerLink]="['/current-weight']"
        mat-icon-button
        class="profile__details__item__edit"
      >
        <!-- <mat-icon>edit</mat-icon> -->
        <img src="../../../assets/edit-outline.svg" alt="" />
      </button>
    </div>
    <div class="profile__details__item">
      <div class="profile__details__item__label">
        <mat-icon>tour</mat-icon>
        משקל יעד:
      </div>
      <div class="profile__details__item__value" dir="ltr">
        {{ getWeight(true) | number: "1.0-1" }} kg
      </div>
      <button
        [routerLink]="['/update-goal']"
        mat-icon-button
        class="profile__details__item__edit"
      >
        <!--  <mat-icon>edit</mat-icon> -->
        <img src="../../../assets/edit-outline.svg" alt="" />
      </button>
    </div>

    <div class="profile__details__item">
      <div class="profile__details__item__label">
        <mat-icon>lock</mat-icon>
        **********
      </div>

      <button
        (click)="openConfirmationReset(passwordResetType)"
        class="profile__details__item__edit"
        mat-icon-button
      >
        <img src="../../../assets/edit-outline.svg" alt="" />
      </button>
    </div>
    <div class="profile__details__alerts">
      <!-- <div class="profile__details__alerts__title">
        סמן את ההתראות שברצונך לקבל:
      </div>
      <div class="profile__details__alerts__item">
        <div class="profile__details__alerts__item__name">מידע שיווקי</div>
        <div class="profile__details__alerts__item__toggle">
          <mat-slide-toggle [checked]="true"></mat-slide-toggle>
        </div>
      </div>
      <div class="profile__details__alerts__item">
        <div class="profile__details__alerts__item__name">
          הזמנות להדרכת ZOOM
        </div>
        <div class="profile__details__alerts__item__toggle">
          <mat-slide-toggle [checked]="true"></mat-slide-toggle>
        </div>
      </div>
      <div class="profile__details__alerts__item">
        <div class="profile__details__alerts__item__name">עידכונים אישיים</div>
        <div class="profile__details__alerts__item__toggle">
          <mat-slide-toggle [checked]="true"></mat-slide-toggle>
        </div>
      </div> -->
      <button
        (click)="doLogout()"
        mat-button
        class="btn btn--outline btn--big mt-3"
      >
        התנתק מהמערכת
      </button>
      <div class="text-center mt-2">
        <button
          class="btn btn--small btn--center btn--underline"
          mat-button
          (click)="removeDnaData()"
        >
          בקשה למחיקת מידע גנטי
        </button>
      </div>
    </div>
  </div>
</div>
