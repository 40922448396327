<button>
    X
</button>
פרופיל אישי 
<button>
    V
</button>
<button>עדכן פרטים</button>
תאריך ביצוע הבדיקה
<input>
שם פרטי
<input>
שם משפחה
<input>
תאריך לידה
<input>
דוא"ל
<input>
טלפון
<input>
<button>עדכן סיסמה</button> 