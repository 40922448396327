<div
  class="video-list__item"
  [class.selected]="isSelected"
  (click)="onVideoClick(video)"
>
  <div class="video-list__item__index">{{ index + 1 }}</div>
  <div class="video-list__item__poster" [class.slider-item]="sliderItem">
    <img
      class="video-list__item__poster__img"
      [src]="getPoster()"
      [alt]="video.title"
    />
  </div>
  <div class="video-list__item__info">
    <div class="video-list__item__info__flex">
      <div class="video-list__item__info__title">{{ video.title }}</div>
      <div class="video-list__item__meta">
        {{ getDuration(video.duration) }} {{ timeText(video.duration) }}
      </div>
    </div>
    <div class="video-list__item__info__description">{{ video.desc }}</div>
  </div>

  <!--<div style="padding: 0 1rem">
    <div class="video-list__item__title">{{ video.title }}</div>
     <div class="video-list__item__meta">
      פורסם: {{ video.createDate | date: "dd/MM/yyy" }} |
      <mat-icon>schedule</mat-icon>
      {{ getDuration(video.duration) }} {{ timeText(video.duration) }}
    </div>
  </div> -->
</div>
