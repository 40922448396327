import { Patient } from './Patients';
import { Status } from './Status';

//import { LifeStyleDisease } from './LifeStyleDisease';
//import { LifeStyleEatingPatern } from './LifeStyleEatingPatern';
import { LifeStyleExercise } from './LifestyleExercise.model';

export class LifeStyleReportLocal {
  lifeStyleReportId: number;
  patientId: number;
  patient: Patient;
  fullName: string;
  email: string;
  socialSecurityNum: string;
  sexId: number;
  sex: Status;
  age: number;
  height: number;
  weight: number;
  nutritionTypeId: any[];
  nutritionTypes: any[];
  otherNutritionTypes: string;
  sensitivityToFood: string;
  foodToAvoid: string;
  //digestionProblemsId: number;
  digestionProblems: any[];
  otherDigestionProblems: string;
  waterNumGlassesPerDay: number;
  sweetNumGlassesPerDay: number;
  dietNumGlassesPerDay: number;
  coffeePerDay: number;
  teaPerDay: number;
  alcoholPerDay: number;
  eatingHabitsMorning: string;
  eatingHabitsMiddle1: string;
  eatingHabitsLunch: string;
  eatingHabitsMiddle2: string;
  eatingHabitsSupper: string;
  ifActivities: string;
  isTakingDrugs: string;
  drugsTakenText: string;
  isSmoking: string;
  smokingYesTextNum: string;
  smokingYesTextYears: string;
  smokingNoTextYears: string;
  smokingNoTextQuit: string;

  isPregnent: any;
  tremester: string;

  getUpHour: string;
  sleepHours: string;
  noonSleep: string;
  occupation: string;
  workHours: string;
 
  sleepQuality: string;
  areYouInStress: string;
  whatToAprrove: string;

  isExercising: boolean;
  yoga: any;
  yogaDuration: any;
  yogaNumInWeek: any;
  pilates: any;
  pilatesDuration: any;
  pilatesNumInWeek: any;
  dance: any;
  danceDuration: any;
  danceNumInWeek: any;
  walk: any;
  walkDuration: any;
  walkNumInWeek: any;
  run: any;
  runDuration: any;
  runNumInWeek: any;
  gym: any;
  gymDuration: any;
  gymNumInWeek: any;
  swim: any;
  swimDuration: any;
  swimNumInWeek: any;
  bicycle: any;
  bicycleDuration: any;
  bicycleNumInWeek: any;
  tennis: any;
  tennisDuration: any;
  tennisNumInWeek: any;
  football: any;
  footballDuration: any;
  footballNumInWeek: any;
  basketball: any;
  basketballDuration: any;
  basketballNumInWeek: any;
  ifOtherSport: any;
  otherSport: any;
  otherSportDuration: any;
  otherSportNumInWeek: any;

  relaxingActivity: string;

  lifeStyleDisease: any;
  lifeStyleEatingPaterns: any;
  healthStatus: string;
}
