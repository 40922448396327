<div class="container-req" *ngIf="!isLoadingChatHours">
  <!-- <select #langSelect (change)="translate.use(langSelect.value)">
    <option *ngFor="let lang of translate.getLangs()" [value]="lang" [selected]="lang === translate.currentLang">
      {{ lang }}</option> 
  </select>-->
  <perfect-scrollbar
    #chatScroller
    class="chat-list-scroll"
    [ngStyle]="{ height: !isMobile() ? height : '' }"
    [class.ios-height]="isMobile() && ios()"
    [config]="config"
  >
    <div class="chat-table" *ngIf="(serviceRequests$ | async).length != 0">
      <mat-card
        class="chat-table__data"
        (click)="onRowClick(serviceRequest)"
        *ngFor="let serviceRequest of serviceRequestsNotRedux"
      >
        <!-- <span>{{ serviceRequest.createdDate | date: "dd-MM-yyyy HH:mm" }}</span> -->
        <!-- <span>{{
          (serviceRequest.subject ? serviceRequest.subject.name : "General")
            | translate
        }}</span> -->
        <div class="chat-table__data__avatar">
          <mat-icon
            [matBadgeHidden]="
              !getChatIndicator(serviceRequest.serviceRequestId)
            "
            matBadge="{{ getChatIndicator(serviceRequest.serviceRequestId) }}"
            matBadgeColor="warn"
          >
            <img
              src="../../../assets/avatar-{{
                getLatestMeta(serviceRequest.serviceRequestId)?.avatarAgentId
                  ? getLatestMeta(serviceRequest.serviceRequestId).avatarAgentId
                  : 0
              }}.svg"
              alt="avatar"
            />
          </mat-icon>
        </div>
        <div>
          <div class="chat-table__data__meta">
            <span>
              {{
                getLatestMeta(serviceRequest.serviceRequestId)?.sentFrom == 1
                  ? "את/ה"
                  : serviceRequest.assigningAgent.name
              }}
            </span>
            &bull;
            <span *ngIf="getLatestMeta(serviceRequest.serviceRequestId)">{{
              getLatestDate(
                getLatestMeta(serviceRequest.serviceRequestId).createdDate
              )
            }}</span>
          </div>
          <div
            class="chat-table__data__msg"
            *ngIf="getLatestMeta(serviceRequest.serviceRequestId)"
          >
            {{
              truncateTxt(getLatestMeta(serviceRequest.serviceRequestId).msg)
            }}
          </div>
        </div>
        <button class="chat-table__data__icon" mat-icon-button>
          <mat-icon>keyboard_arrow_left</mat-icon>
        </button>
      </mat-card>
    </div>
    <div class="out-of-service" *ngIf="(serviceRequests$ | async).length == 0">
      <p style="font-size: 1.8rem; color: #00babf">
        התחל שיחה עם נציג בלחיצת כפתור
      </p>
      <p style="color: #7d7d7d; font-size: 1.6rem">
        לא קיימות שיחות עם MyGenes
      </p>
      <img src="../../../assets/pointer.svg" alt="" />
    </div>
    <div *ngIf="outOfService" class="out-of-service-wrapper">
      <mat-card class="out-of-service">
        <button
          style="position: absolute; top: 0; right: 0"
          mat-button
          (click)="outOfService = false"
        >
          <mat-icon>close</mat-icon>
        </button>
        <p style="margin-top: 3rem">
          שלום לך! <br />
          הצ'אט יהיה זמין בימים א-ה <br />
          בין השעות {{ chatDateStart }}-{{ chatDateEnd }}
        </p>
        <p>מחכים לשמוע ממך 🙂</p>

        <img src="../../../assets/hand.svg" alt="open new chat" />
      </mat-card>
    </div>
    <!-- <div *ngIf="outOfServiceElDay" class="out-of-service-wrapper">
      <mat-card class="out-of-service">
        <button
          style="position: absolute; top: 0; right: 0"
          mat-button
          (click)="outOfServiceElDay = false"
        >
          <mat-icon>close</mat-icon>
        </button>

        <img src="../../../assets/kalpi.svg" alt="open new chat" />

        <p style="margin-top: 3rem">יצאנו לבחור, נחזור מחר! 🙂</p>
      </mat-card>
    </div> -->
  </perfect-scrollbar>

  <div class="open-new-chat">
    <button
      [disabled]="disableChatBtn"
      class="open-req"
      mat-icon-button
      (click)="openServiceRequest()"
    >
      שלחו לנו הודעה
      <mat-icon>send</mat-icon>
    </button>
  </div>
</div>
