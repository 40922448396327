<cloudflare-stream
  [poster]="getPoster()"
  [controls]="true"
  [src]="getVideoFile()"
  (pause)="onVideoPause()"
  [autoplay]="true"
  [currentTime]="video.progress"
  #videoPlayer
></cloudflare-stream>

<!-- (ended)="onVideoEnded()" -->
