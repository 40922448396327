import { Component, OnInit } from '@angular/core';
import { VideoForSite } from 'src/app/data/VideoForSite';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';
import { VideosService } from 'src/app/Services/videos.service';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-home-vod',
  templateUrl: './home-vod.component.html',
  styleUrls: ['./home-vod.component.scss'],
})
export class HomeVodComponent implements OnInit {
  videos: VideoForSite[];
  swiperConfig: SwiperOptions = {
    slidesPerView: 'auto',
    pagination: false,
    spaceBetween: 50,
    grabCursor: true,
  };
  constructor(
    private popupHandlerService: PopupHandlerService,
    private videosService: VideosService
  ) {}

  ngOnInit(): void {
    this.getVideos();
  }

  getVideos() {
    this.videosService.getAll().subscribe((data) => {
      this.videos = data;
    });
  }

  onVideoClick(video: VideoForSite) {
    this.popupHandlerService.openVideoDialog(video);
  }
}
