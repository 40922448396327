import { Action, createReducer, on } from '@ngrx/store';
import { RequestForTableMin } from 'src/app/data/RequestForTableMin';
import {
  ServiceRequestsAction,
  ServiceRequestsActionTypes,
} from '../Actions/service-request.actions';

export const serviceRequestFeatureKey = 'serviceRequest';

export interface State {}

export interface ServiceRequestsState {
  serviceRequests: RequestForTableMin[] | null;
  activeRequestId: number | null;
  isLoading: boolean;
}

export const initialState: ServiceRequestsState = {
  serviceRequests: [],
  activeRequestId: null,
  isLoading: false,
};

export const replaceServiceRequest = (
  serviceRequests: RequestForTableMin[],
  serviceRequest: RequestForTableMin
) => {
  var newServiceRequests = [];
  serviceRequests.forEach((_serviceRequest) => {
    if (serviceRequest.serviceRequestId == _serviceRequest.serviceRequestId) {
      newServiceRequests.push(serviceRequest);
    } else {
      newServiceRequests.push(_serviceRequest);
    }
  });
  return newServiceRequests;
};

export function ServiceRequestsReducer(
  state: ServiceRequestsState = initialState,
  action: ServiceRequestsAction
): ServiceRequestsState {
  switch (action.type) {
    case ServiceRequestsActionTypes.LoadServiceRequestsComplete:
      return {
        ...state,
        serviceRequests: action.serviceRequests,
        isLoading: false,
      };
    case ServiceRequestsActionTypes.OpenServiceRequestComplete:
      return {
        ...state,
        serviceRequests: [action.serviceRequest, ...state.serviceRequests],
        activeRequestId: action.serviceRequest
          ? action.serviceRequest.serviceRequestId
          : null,
        isLoading: false,
      };
    case ServiceRequestsActionTypes.SelectServiceRequest:
      return {
        ...state,
        activeRequestId: action.serviceRequestId,
      };
    case ServiceRequestsActionTypes.UpdateServiceRequestComplete:
      return {
        ...state,
        serviceRequests: replaceServiceRequest(
          state.serviceRequests,
          action.serviceRequest
        ),
      };
    case ServiceRequestsActionTypes.UnSelectServiceRequest:
      return { ...state, activeRequestId: null };
    case ServiceRequestsActionTypes.LoadServiceRequests:
    case ServiceRequestsActionTypes.OpenServiceRequest:
      return {
        ...state,
        isLoading: true,
      };

    default:
      return state;
  }
}
