import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-blood-test-question',
  templateUrl: './blood-test-question.component.html',
  styleUrls: ['./blood-test-question.component.scss'],
})
export class BloodTestQuestionComponent implements OnInit {
  type;
  @Input() bloodTestResult;
  @Input() bloodTestType;
  constructor() {}

  ngOnInit(): void {}
}
