<input type="file" (change)="fileChangeEvent($event)" />
<!-- <br />
<br />
<button (click)="rotateLeft()">Rotate left</button>
<button (click)="rotateRight()">Rotate right</button>
<button (click)="flipHorizontal()">Flip horizontal</button>
<button (click)="flipVertical()">Flip vertical</button>
<br />
<br />
<button (click)="toggleContainWithinAspectRatio()">
  {{
    containWithinAspectRatio
      ? "Fill Aspect Ratio"
      : "Contain Within Aspect Ratio"
  }}
</button>
<button (click)="resetImage()">Reset image</button>
<br />
<br />
<input
  [(ngModel)]="rotation"
  placeholder="Rotation"
  type="number"
  (keyup)="updateRotation()"
/> -->

<button mat-icon-button (click)="zoomOut()">
  <mat-icon>zoom_out</mat-icon>
</button>
<button mat-icon-button (click)="zoomIn()"><mat-icon>zoom_in</mat-icon></button>

<div>
  <image-cropper
    [imageChangedEvent]="imageChangedEvent"
    [maintainAspectRatio]="true"
    [containWithinAspectRatio]="containWithinAspectRatio"
    [aspectRatio]="4 / 3"
    [resizeToWidth]="256"
    [cropperMinWidth]="128"
    [onlyScaleDown]="true"
    [roundCropper]="false"
    [canvasRotation]="canvasRotation"
    [transform]="transform"
    [alignImage]="'left'"
    [style.display]="showCropper ? null : 'none'"
    format="png"
    (imageCropped)="imageCropped($event)"
    (imageLoaded)="imageLoaded()"
    (cropperReady)="cropperReady($event)"
    (loadImageFailed)="loadImageFailed()"
  ></image-cropper>
</div>
<img
  [src]="croppedImage"
  [style.border]="croppedImage ? '1px solid black' : 'none'"
/>
