<div class="upgrade-dialog">
  <div
    style="
      direction: rtl;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      height: 100%;
    "
  >
    <img src="../../../assets/smiley/sad.svg" style="margin-bottom: 2rem" />
    <p class="text">
      אוי, רכשת בדיקה בבסיסית לא ניתן להזין בדיקות דם ולמלא שאלון אורח חיים
      בבדיקה הבסיסית.
    </p>
    <p class="colored-text">
      <strong>רוצה לקבל הרבה יותר מהבדיקה שביצעת?</strong><br />
      שדרג לבדיקה המורחבת שלנו!
    </p>
    <br />
    <button mat-button (click)="onBtnClick()" class="btn btn--fill">
      גלה עוד
    </button>
  </div>
</div>
