import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AlgoBulletLink } from '../data/AlgoBulletLink';

@Injectable({
  providedIn: 'root',
})
export class AlgoBulletLinksService {
  constructor(private http: HttpClient) {}

  getAlgoBulletLink(linkId: number, langId: number) {
    return this.http.get<AlgoBulletLink>(
      `${environment.baseUrl}AlgoBulletLinks/GetAlgoBulletLink/${linkId}/${langId}`
    );
  }
}
