import { Component, OnInit } from '@angular/core';
import { BloodTestResultsService } from 'src/app/Services/blood-test-results.service';
import { BloodTestTypesService } from 'src/app/Services/blood-test-types.service';
import { BloodTestType } from 'src/app/data/BloodTestType';
import { RegularUsersService } from 'src/app/Services/regular-users.service';
import { BloodTestResult } from 'src/app/data/BloodTestResult';
import { RegularUser } from 'src/app/data/RegularUser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-blood-tests-results',
  templateUrl: './blood-tests-results.component.html',
  styleUrls: ['./blood-tests-results.component.scss'],
})
export class BloodTestsResultsComponent implements OnInit {
  isSuggar: boolean = false;
  isAltUnits: boolean = false;
  inputVal: number;
  inx: number = 0;
  bloodTestTypes: BloodTestType[];
  regularUser: RegularUser;
  bloodTestResults: BloodTestResult[];
  constructor(
    private bloodTestResultsService: BloodTestResultsService,
    private bloodTestTypesService: BloodTestTypesService,
    private regularUsersService: RegularUsersService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.isSuggar = this.router.url == '/suggar-results';
    this.bloodTestTypesService.getAll().subscribe((types) => {
      this.bloodTestTypes = types;
      /*
      if (this.isSuggar) {
        this.bloodTestTypes = this.bloodTestTypes.filter(
          (x) => x.bloodTestTypeId == 1
        );
      } else {
        this.bloodTestTypes = this.bloodTestTypes.filter(
          (x) => x.bloodTestTypeId != 1
        );
      }
      */
    });
    this.regularUsersService.currentUser.subscribe((user) => {
      this.regularUser = user;
      this.bloodTestResultsService
        .getByPatientId(this.regularUser.patient.patientId)
        .subscribe((types) => {
          this.bloodTestResults = types;
        });
    });
  }
  goToPrev() {
    this.inx--;
  }
  goToNext() {
    if (
      this.bloodTestResults[this.inx].result &&
      this.bloodTestResults[this.inx].result 
    ) {
      this.bloodTestResultsService
        .saveSingle(
          this.regularUser.patient.patientId,
          this.bloodTestTypes[this.inx].bloodTestTypeId,
          this.bloodTestResults[this.inx].result
        )
        .subscribe((res) => {
          this.inx++;
        });
    } else {
      this.inx++;
    }

    //save
  }
  finishReport() {
    //save
    if (
      this.bloodTestResults[this.inx].result &&
      this.bloodTestResults[this.inx].result
    ) {
      this.bloodTestResultsService
        .saveSingle(
          this.regularUser.patient.patientId,
          this.bloodTestTypes[this.inx].bloodTestTypeId,
          this.bloodTestResults[this.inx].result
        )
        .subscribe((res) => {
          this.router.navigate(['home']);
        });
    } else {
      this.router.navigate(['home']);
    }
  }
  goBack() {
    this.router.navigate(['home']);
  }
  getMinVal() {
    if (!this.isAltUnits) {
      return this.bloodTestTypes[this.inx].minFemale;
    } else {
      return (
        this.bloodTestTypes[this.inx].minFemale *
        this.bloodTestTypes[this.inx].unitsConvertion
      );
    }
  }
  getMaxVal() {
    if (!this.isAltUnits) {
      return this.bloodTestTypes[this.inx].maxFemale;
    } else {
      return (
        this.bloodTestTypes[this.inx].maxFemale *
        this.bloodTestTypes[this.inx].unitsConvertion
      );
    }
  }
  onValChange(val) {
    this.isAltUnits = val == 'alt';
  }
}
