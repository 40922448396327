import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BloodTestType } from '../data/BloodTestType';
import { MenuForSite } from '../data/MenuForSite';
import { MenuForTable } from '../data/MenuForTable';

@Injectable({
  providedIn: 'root',
})
export class MenusService {
  constructor(private http: HttpClient) {}
  getMenusByProductId(productId: number) {
    return this.http.get<MenuForTable[]>(
      `${environment.baseUrl}Menus/ForProduct/${productId}/true`
    );
  }
  getMenusByProductIdV2(productId: number, langId: number) {
    return this.http.get<MenuForSite[]>(
      `${environment.baseUrl}Menus/ForProductSite/${productId}/${langId}`
    );
  }
  getByMenuIdForHtml(menuId, productId, langId) {
    return this.http.get<MenuForSite>(
      `${environment.baseUrl}Meals/ForMenuSite/${menuId}/${productId}/${langId}`
    );
  }
}
