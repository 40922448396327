import { Component, Input, OnInit } from '@angular/core';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import {
  MatCalendarCellCssClasses,
  MatCalendarView,
} from '@angular/material/datepicker';
import * as moment from 'moment';
import { Moment } from 'moment';
import 'moment/locale/he';
import { take } from 'rxjs/operators';
import { ZoomEvent } from 'src/app/data/ZoomEvent';
import { SchedualCalendarHeaderComponent } from 'src/app/Fragments/schedual-calendar-header/schedual-calendar-header.component';
import { LanguagesService } from 'src/app/Services/languages.service';
import { ZoomEventsService } from 'src/app/Services/zoom-events.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-upcoming-events',
  templateUrl: './upcoming-events.component.html',
  styleUrls: ['./upcoming-events.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'he-IL' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class UpcomingEventsComponent implements OnInit {
  @Input() offsetLeft: number;
  selectedDate: Moment | null = moment();
  currentYear = moment().year();
  schedualCalendarHeader = SchedualCalendarHeaderComponent;
  zoomDates: ZoomEvent[];
  highlightZoomEvent: ZoomEvent;
  cardOffsetLeft: number;

  constructor(
    private _adapter: DateAdapter<any>,
    private languagesService: LanguagesService,
    private zoomEventsService: ZoomEventsService
  ) {
    this.zoomEventsService.monthChanged$.subscribe((data) => {
      if (data) {
        this.highlightZoomEvent = null;
      }
    });
  }

  ngOnInit(): void {
    this.onSelectDate(this.selectedDate);
    this.listenToLangChange();
    this.getZoomDates();
    this.cardOffsetLeft = this.offsetLeft - 215;
  }
  getImage() {
    const image = this.highlightZoomEvent.image;
    const webPath = this.replaceWebPath(image?.webPath);
    return image ? `${webPath}/${image.fileName}` : '';
  }

  replaceWebPath(image: string) {
    return image?.replace('{picdomain}', environment.picsDomain);
  }

  getLoaclLang(langId: number) {
    switch (langId) {
      case 1:
        return 'he-IL';
      case 2:
        return 'en-us';
    }
  }

  listenToLangChange() {
    this.languagesService.langIdSubject.subscribe((langId) => {
      this._adapter.setLocale(this.getLoaclLang(langId));
      // the delay is for fixing the bug of the calendar not updating the days language
      setTimeout(() => {
        this._adapter.setLocale(this.getLoaclLang(langId));
      }, 200);
    });
  }

  getZoomDates() {
    this.zoomEventsService.getZoomEventList().subscribe((data) => {
      this.zoomDates = data;
    });
  }

  myDateFilter = (m: Moment | null): boolean => {
    const year = (m || moment()).year();
    return moment().month() === m.month() && year === this.currentYear;
    //return year >= this.currentYear - 1 && year <= this.currentYear + 1;
  };

  dateClass() {
    return (date: Date): MatCalendarCellCssClasses => {
      return this.isInZoomDates(date) ? 'zoom-event' : '';
    };
  }

  isInZoomDates(date: Date) {
    const dateObj = new Date(date);
    return this.zoomDates
      ?.map((x) => {
        const newDate = new Date(x.zoomEventDate);
        return newDate;
      })
      .some((d) => {
        const sameDate = d.getDate() === dateObj.getDate();
        const sameMonth = d.getMonth() === dateObj.getMonth();
        const sameYear = d.getFullYear() === dateObj.getFullYear();
        return sameDate && sameMonth && sameYear;
      });
  }

  onSelectDate(event: any) {
    //if ((event as Date)?.getTime() || (event as Moment)?.isValid()) {
    this.selectedDate = event;
    if (this.isInZoomDates(event)) {
      this.zoomEventsService
        .getZoomEventByDate(event.format('YYYY-MM-DD'))
        .subscribe((data) => {
          this.highlightZoomEvent = data[0];
        });
    } else {
      this.highlightZoomEvent = null;
    }
    //}
  }
  onViewChanged(event: MatCalendarView) {
    //console.log('onViewChanged', event);
  }
  onZoomEventClick() {
    //open zoom event in new tab
    window.open(this.highlightZoomEvent.zoomEventLink, '_blank');
    this.highlightZoomEvent = null;
  }
}
