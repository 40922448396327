import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

import { isMobile } from 'src/app/Utils/userAgent';

@Component({
  selector: 'app-weight-ruler',
  templateUrl: './weight-ruler.component.html',
  styleUrls: ['./weight-ruler.component.scss'],
})
export class WeightRulerComponent implements OnInit, AfterViewInit, OnChanges {
  // @Input() patient: Patient;
  @ViewChild('rulerSlider') rulerSlider: ElementRef;
  @Output('setWeight') setWeight: EventEmitter<number> = new EventEmitter();

  @Input() weight: number;
  @Input() startingWeight: number;
  @Input() endingWeight: number;
  rullerLines: any = [];
  rullerNumbers: any = [];
  rulerSpacingX: number;
  offsetWidth: number = 18.8;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.weight) {
      let isChangeWeight: any;
      this.weight = changes.weight.currentValue;
      window.clearTimeout(isChangeWeight);
      isChangeWeight = setTimeout(() => {
        this.scrollRuler();
      }, 500);
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      const slider = this.rulerSlider.nativeElement;
      let isDown = false;
      let startX: number;
      let scrollLeft: any;
      let isScrolling: any;

      //this.hideScrollBar(slider);
      if (this.weight) {
        this.scrollRuler();
      }

      if (isMobile()) {
        slider.addEventListener(
          'scroll',
          (e) => {
            window.clearTimeout(isScrolling);
            isScrolling = setTimeout(() => {
              this.setWeightFn(slider);
            }, 66);
          },
          false
        );
      } else {
        slider.addEventListener('mousedown', (e) => {
          isDown = true;
          startX = e.pageX - slider.offsetLeft;
          scrollLeft = slider.scrollLeft;
        });
        slider.addEventListener('mouseleave', (e) => {
          isDown = false;
        });
        slider.addEventListener('mouseup', (e) => {
          isDown = false;
          this.setWeightFn(slider);
        });
        slider.addEventListener('mousemove', (e) => {
          //console.log('e', e);

          if (!isDown) return;
          e.preventDefault();
          const x = e.pageX - slider.offsetLeft;
          const walk = (x - startX) * 3; //scroll-fast
          slider.scrollLeft = scrollLeft - walk;
          this.setWeightFn(slider);
        });
      }
    }, 0);
  }

  ngOnInit(): void {
    var pageW = window.innerWidth;
    this.startingWeight = 0;
    const endingWeight = 300;
    var weight = this.startingWeight;
    while (weight < endingWeight) {
      this.rullerLines.push(weight);
      weight++;
    }
    for (let index = 10; index <= endingWeight - 10; index += 10) {
      const element = this.rullerLines[index];
      this.rullerNumbers.push(element);
    }
    /* this.rullerLines.forEach((line) => {
      if (line % 10 == 0) {
        this.rullerNumbers.push(line);
      }
    }); */

    this.rulerSpacingX = pageW / this.rullerLines.length;
  }

  setWeightFn(slider) {
    this.setWeight.emit(slider.scrollLeft / 10 + this.offsetWidth);
  }

  scrollRuler() {
    this.rulerSlider.nativeElement.scrollTo({
      top: 0,
      left: (this.weight - this.offsetWidth) * 10,
      behavior: 'smooth',
    });
  }
}
