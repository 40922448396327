import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LifestyleAnswerMin } from '../data/LifestyleAnswerMin';
import { LifestyleQuestion } from '../data/LifestyleQuestion';
import { LifeStyleUpdateAnswer } from '../data/LifeStyleUpdateAnswer';

@Injectable({
  providedIn: 'root',
})
export class LifestyleQuestionsService {
  constructor(private http: HttpClient) {}

  updateAnswer(
    productId: number,
    questionId: number,
    inputTypeId: number,
    value: any
  ) {
    var obj: LifeStyleUpdateAnswer = new LifeStyleUpdateAnswer();
    obj.productId = productId;
    obj.questionId = questionId;
    obj.inputTypeId = inputTypeId;
    obj.value = value;
    return this.http.post<LifestyleAnswerMin[]>(
      `${environment.baseUrl}LifestyleAnswers/UpdateAnswer/${productId}/${questionId}/${inputTypeId}/${value}`,
      obj
    );
  }
  updateAnswerForApp(
    productId: number,
    questionId: number,
    inputTypeId: number,
    value: any,
    token: string
  ) {
    var obj: LifeStyleUpdateAnswer = new LifeStyleUpdateAnswer();
    obj.productId = productId;
    obj.questionId = questionId;
    obj.inputTypeId = inputTypeId;
    obj.value = value;
    return this.http.post<LifestyleAnswerMin[]>(
      `${environment.baseUrl}LifestyleAnswers/UpdateAnswer/${productId}/${questionId}/${inputTypeId}/${value}`,
      obj,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  getByProductId(productId: number, langId: number, locationId) {
    return this.http.get<LifestyleQuestion[]>(
      `${environment.baseUrl}LifestyleQuestions/PartForProduct/${productId}/${langId}/${locationId}`
    );
  }
  getByProductIdForApp(
    productId: number,
    langId: number,
    locationId,
    token: string
  ) {
    return this.http.get<LifestyleQuestion[]>(
      `${environment.baseUrl}LifestyleQuestions/PartForProduct/${productId}/${langId}/${locationId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }
  /*
  getByProductId(productId: number, langId: number) {
    return this.http.get<LifestyleQuestion[]>(
      `${environment.baseUrl}LifestyleQuestions/ForProduct/${productId}/${langId}`
    );
  }*/
  getByProductIdForPreview(productId: number, langId: number) {
    return this.http.get<any>(
      `${environment.baseUrl}LifestyleQuestions/ForProductPreview/${productId}/${langId}`
    );
  }
  ForProductPreview;

  getAll(langId: number, versionId: number): Observable<any> {
    return this.http.get<any>(
      `${environment.baseUrl}LifestyleQuestions/All/${langId}/${versionId}`
    );
  }
  getTemplates(langId: number): Observable<any> {
    return this.http.get<any>(
      `${environment.baseUrl}LifestyleQuestions/Templates/${langId}/`
    );
  }
  put(lifestyleQuestion: LifestyleQuestion): Observable<LifestyleQuestion> {
    return this.http.put<LifestyleQuestion>(
      `${environment.baseUrl}LifestyleQuestions/${lifestyleQuestion.lifestyleQuestionId}`,
      lifestyleQuestion
    );
  }
  post(lifestyleQuestion: LifestyleQuestion): Observable<LifestyleQuestion> {
    return this.http.post<LifestyleQuestion>(
      `${environment.baseUrl}LifestyleQuestions/`,
      lifestyleQuestion
    );
  }
  delete(lifestyleQuestion: LifestyleQuestion) {
    return this.http.delete(
      `${environment.baseUrl}LifestyleQuestions/${lifestyleQuestion.lifestyleQuestionId}`
    );
  }
  addFromTemplate(templateId: number, questionId: number) {
    return this.http.get(
      `${environment.baseUrl}LifestyleQuestions/AddFromTemplate/${templateId}/${questionId}`
    );
  }
  duplicate(questionId: number) {
    return this.http.get(
      `${environment.baseUrl}LifestyleQuestions/Duplicate/${questionId}`
    );
  }
  move(questionId: number, direction: number) {
    return this.http.get(
      `${environment.baseUrl}LifestyleQuestions/Move/${questionId}/${direction}`
    );
  }
  /*
  moveLevelZero(questionId: number, direction: number) {
    return this.http.get(
      `${environment.baseUrl}LifestyleQuestions/moveLevelZero/${questionId}/${direction}`
    );
  }*/
  setMarkAsReference(questionId: number, referenceId: number) {
    return this.http.get(
      `${environment.baseUrl}LifestyleQuestions/setReferenceId/${questionId}/${referenceId}`
    );
  }
  removeReference(questionId: number) {
    return this.http.get(
      `${environment.baseUrl}LifestyleQuestions/removeReference/${questionId}/`
    );
  }
  moveNodeToParent(questionId: number, parentId: number) {
    return this.http.get(
      `${environment.baseUrl}LifestyleQuestions/moveNodeToParent/${questionId}/${parentId}`
    );
  }
  moveNodeToRoot(questionId: number) {
    return this.http.get(
      `${environment.baseUrl}LifestyleQuestions/moveNodeToRoot/${questionId}/`
    );
  }

  setOrder(questionId: number, order: number) {
    return this.http.get(
      `${environment.baseUrl}LifestyleQuestions/SetOrder/${questionId}/${order}`
    );
  }
}
