import { ChangeDetectorRef, Component, Inject, OnDestroy } from '@angular/core';
import {
  DateAdapter,
  MatDateFormats,
  MAT_DATE_FORMATS,
} from '@angular/material/core';
import { MatCalendar } from '@angular/material/datepicker';
import moment, { Moment } from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ZoomEventsService } from 'src/app/Services/zoom-events.service';

@Component({
  selector: 'app-schedual-calendar-header',
  templateUrl: './schedual-calendar-header.component.html',
  styleUrls: ['./schedual-calendar-header.component.scss'],
})
export class SchedualCalendarHeaderComponent<D> implements OnDestroy {
  private _destroyed = new Subject<void>();

  constructor(
    private _calendar: MatCalendar<D>,
    private _dateAdapter: DateAdapter<D>,
    @Inject(MAT_DATE_FORMATS) private _dateFormats: MatDateFormats,
    cdr: ChangeDetectorRef,
    private zoomEventsService: ZoomEventsService
  ) {
    _calendar.stateChanges
      .pipe(takeUntil(this._destroyed))
      .subscribe(() => cdr.markForCheck());
  }

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }

  get periodLabel() {
    return this._dateAdapter
      .format(
        this._calendar.activeDate,
        this._dateFormats.display.monthYearLabel
      )
      .toLocaleUpperCase();
  }

  previousClicked(mode: 'month' | 'year') {
    this._calendar.activeDate =
      mode === 'month'
        ? this._dateAdapter.addCalendarMonths(this._calendar.activeDate, -1)
        : this._dateAdapter.addCalendarYears(this._calendar.activeDate, -1);
    this.zoomEventsService.monthChanged();
  }

  nextClicked(mode: 'month' | 'year') {
    this._calendar.activeDate =
      mode === 'month'
        ? this._dateAdapter.addCalendarMonths(this._calendar.activeDate, 1)
        : this._dateAdapter.addCalendarYears(this._calendar.activeDate, 1);
    this.zoomEventsService.monthChanged();
  }

  canGoPrevious() {
    const activeMonth = moment(this._calendar.activeDate).month();
    const currentMonth = moment().month();
    if (activeMonth > currentMonth) {
      return true;
    }
  }
  canGoNext() {
    const activeMonth = moment(this._calendar.activeDate).month();
    const currentMonth = moment().month();
    if (activeMonth <= currentMonth) {
      return true;
    }
  }
}
