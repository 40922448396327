<mat-card class="alerts-card">
  <div class="item" *ngIf="dataExpired?.bloodTestExpired">
    <div class="d-flex">
      <div class="item__image">
        <img src="../../../assets/alerts-tube.svg" alt="" />
      </div>
      <div class="item__info">
        <div class="item__info__title">בדיקות דם</div>
        <div class="item__info__desc">
          עברה שנה מאז עדכון תוצאות בדיקות הדם שלך. אנו ממליצים לעדכן לדיוק
          ההמלצות.
        </div>
      </div>
    </div>
    <a [routerLink]="['/bloodtests-results-new']" class="item__link">עידכון</a>
    <div class="item__date">
      {{ dataExpired?.bloodTestDate | date: "dd/MM/yyyy" }}
    </div>
  </div>
  <div class="item" *ngIf="dataExpired?.lifeStyleExpired">
    <div class="d-flex">
      <div class="item__image">
        <img src="../../../assets/alerts-form.svg" alt="" />
      </div>
      <div class="item__info">
        <div class="item__info__title">שאלון אורח חיים</div>
        <div class="item__info__desc">
          עברה שנה מאז עדכון שאלון אורח החיים שלך. אנו ממליצים לעדכן לדיוק
          ההמלצות.
        </div>
      </div>
    </div>
    <a [routerLink]="['/lifestyle-report-new']" class="item__link">עידכון</a>
    <div class="item__date">
      {{ dataExpired?.lifeStyleDate | date: "dd/MM/yyyy" }}
    </div>
  </div>

  <!-- TODO: next sprint -->
  <div class="item" *ngIf="false">
    <div class="item__image">
      <img src="../../../assets/alerts-ph.svg" alt="" />
    </div>
    <div class="item__title">
      רמת הפעילות שלך היא "{{ userActivityLevel | translate }}"
    </div>
    <a (click)="goToActivityLifestyle()" class="item__link">לחץ לעידכון</a>
  </div>
</mat-card>
