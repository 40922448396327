{{ bloodTestType.name }}
<br />
Value Should Be between
{{ bloodTestType.minValue }} and
{{ bloodTestType.maxValue }}

<input [(ngModel)]="bloodTestResult.value" />
{{ bloodTestType.units }}
{{ bloodTestType.altUnits }}
<!--<button>הבא</button>-->
