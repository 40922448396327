import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { base64ToFile, Dimensions, ImageCroppedEvent } from 'ngx-image-cropper';
import { Observable } from 'rxjs';
import { Patient } from 'src/app/data/Patients';
import { Product } from 'src/app/data/Product';
import { RegularUser } from 'src/app/data/RegularUser';
import { ConfirmDialogEnum } from 'src/app/Enum/ConfirmationDialogEnum';
import { FileUploadType, ResetType } from 'src/app/Enum/StatusesEnum';
import {
  DeleteGeneticData,
  LoadProduct,
} from 'src/app/Redux/Actions/product.actions';
import { LogoutUser } from 'src/app/Redux/Actions/regular-user.actions';
import { selectProduct } from 'src/app/Redux/selectors/product.selectors';
import { PersonalProfileService } from 'src/app/Services/personal-profile.service';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';
import { getWeightUtil } from 'src/app/Utils/userHelper';

@Component({
  selector: 'app-personal-profile',
  templateUrl: './personal-profile.component.html',
  styleUrls: ['./personal-profile.component.scss'],
})
export class PersonalProfileComponent implements OnInit {
  product$: Observable<Product> = this.store.select(selectProduct);
  editMode: boolean = false;
  changeProfileImg: boolean;
  profileImg: any;
  product: Product;
  patient: Patient;
  regularUser: RegularUser;
  showCropper = false;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  //canvasRotation = 0;
  //rotation = 0;
  //scale = 1;
  //containWithinAspectRatio = false;
  //transform: ImageTransform = {};

  constructor(
    private store: Store,
    private router: Router,
    private personalProfileService: PersonalProfileService,
    private popupHandlerService: PopupHandlerService
  ) {}

  ngOnInit(): void {
    this.product$.subscribe((data) => {
      if (data) {
        this.product = data;
        this.patient = this.product.patient;
        this.regularUser = this.patient.regularUser;
        this.profileImg = this.product.fileUploads?.find(
          (x) => x.fileUploadTypeId == FileUploadType.profile
        )?.path;
        //this.getProfileImg();
      }
    });
  }

  onClose() {
    this.closeEditMode();
  }
  getDefaultPersonImage() {
    return this.product.patient.regularUser.gender == 'male'
      ? '../../../assets/man.svg'
      : '../../../assets/woman.svg';
  }
  getProfileImg() {
    this.personalProfileService
      .getProfileImg(this.product.productId)
      .subscribe((data) => {
        //if(data.filePath)
        this.profileImg = data.filePath;
      });
  }

  onCheck() {
    if (this.changeProfileImg && this.croppedImage) {
      var imgArray = this.croppedImage.split(',');
      this.personalProfileService
        .uploadProfileImg(this.product.productId, imgArray)
        .subscribe((data) => {
          this.changeProfileImg = false;
          this.croppedImage = null;
          this.profileImg = data.filePath;
          this.closeEditMode();
          this.store.dispatch(
            new LoadProduct({ patientId: this.patient.patientId })
          );
        });
    }
  }

  getWeight(isGoal = false) {
    return getWeightUtil(isGoal, this.product);
  }

  closeEditMode() {
    this.editMode = false;
    this.changeProfileImg = false;
    this.croppedImage = null;
  }

  openEditMode() {
    this.editMode = true;
  }

  openConfirmationReset(type: number) {
    this.popupHandlerService.openConfirmationDialog(
      ConfirmDialogEnum.updateUserData,
      type
    );
    this.popupHandlerService.confirmDialogSubject.subscribe((data) => {
      if (data === 'yes') {
        this.router.navigate(['update-user-data'], { queryParams: { type } });
      }
    });
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    //console.log(event, base64ToFile(event.base64));
  }

  imageLoaded() {
    this.showCropper = true;
    // console.log('Image loaded');
  }

  cropperReady(sourceImageDimensions: Dimensions) {
    // console.log('Cropper ready', sourceImageDimensions);
  }

  loadImageFailed() {
    // console.log('Load failed');
  }

  doLogout() {
    this.store.dispatch(LogoutUser());
  }

  removeDnaData() {
    this.popupHandlerService.openConfirmationDialog(
      ConfirmDialogEnum.removeDnaData
    );
    this.popupHandlerService.confirmDialogSubject.subscribe((data) => {
      if (data === 'yes') {
        // TODO: subscribe to service to remove dna data
        this.store.dispatch(
          new DeleteGeneticData({ productId: this.product.productId })
        );
      }
    });
  }

  get emailResetType() {
    return ResetType.email;
  }
  get phoneResetType() {
    return ResetType.phone;
  }
  get passwordResetType() {
    return ResetType.password;
  }
}
