import { debounceTime, skip, switchMap, takeUntil } from 'rxjs/operators';
import { Patient } from '../data/Patients';
import { Product } from '../data/Product';
import { UserAdress } from '../data/UserAdress';
import {
  AddressType,
  PALevelType,
  ProductTypeEnum,
} from '../Enum/StatusesEnum';

export function getWeightUtil(isGoal: boolean, product: Product) {
  const array = product.patient.patientWeightHistories?.filter(
    (x) => x.isGoal == isGoal
  );
  if (array?.length > 0) {
    return array.reduce((a, b) => {
      return new Date(a?.dateUpdated) > new Date(b?.dateUpdated) ? a : b;
    })?.value;
  }
  return null;
}

export function getBmiUtil(weight, height) {
  if (isNaN(weight)) return 0;
  if (height > 3) height /= 100;
  var bmi = weight / (height * height);
  bmi = Math.round(bmi * 10) / 10;
  return bmi;
}

export function getWeightForBmiUtil(bmi, height) {
  if (height > 3) height /= 100;
  var weight = bmi * (height * height);
  weight = Math.round(weight * 10) / 10;
  return weight;
}

export function caculateWeightData(patient: Patient): Patient {
  var allPatientWeightHistories = patient.patientWeightHistories.filter(
    (x) => !x.isGoal
  );

  if (allPatientWeightHistories.length == 0) return patient;

  patient.currentWeight =
    allPatientWeightHistories[allPatientWeightHistories.length - 1].value;
  patient.startWeight = allPatientWeightHistories.find(
    (x) => x?.isWeightStart
  )?.value;

  if (allPatientWeightHistories.length > 1) {
    var allPatientWeightHistoriesWithoutStartWeight =
      allPatientWeightHistories.filter((x) => !x.isWeightStart);
    patient.prevWeight =
      allPatientWeightHistoriesWithoutStartWeight[
        allPatientWeightHistoriesWithoutStartWeight.length - 2
      ]?.value;
    if (!patient.prevWeight) {
      patient.prevWeight =
        allPatientWeightHistoriesWithoutStartWeight[
          allPatientWeightHistoriesWithoutStartWeight.length - 1
        ]?.value;
    }
  }
  patient.totalWeightLoss = patient.currentWeight - patient.startWeight;

  patient.currentBmi = getBmiUtil(
    patient.currentWeight,
    patient.regularUser.height
  );
  var patientGoalWeightHistories = patient.patientWeightHistories.filter(
    (x) => x.isGoal
  );
  if (patientGoalWeightHistories.length > 0) {
    patient.goalWeight = patientGoalWeightHistories
      .filter((x) => x.isGoal)
      .sort((a, b) => {
        var dateA = new Date(a.dateUpdated);
        var dateB = new Date(b.dateUpdated);
        return dateB.getTime() - dateA.getTime();
      })[0].value;
  }

  return patient;
}

export const autocomplete = (time, selector) => (source$) =>
  source$.pipe(
    debounceTime(time),
    switchMap((...args: any[]) =>
      selector(...args).pipe(takeUntil(source$.pipe(skip(1))))
    )
  );

export const userAddress = (userAdresses: UserAdress[]) => {
  var adress = userAdresses.find((x) => x.adressTypeId == AddressType.shipping);
  if (adress) return adress;
  return userAdresses.find((x) => x.adressTypeId == AddressType.main);
  /*
  return userAdresses?.find((x) => {
    if (x.adressTypeId == AddressType.shipping) {
      return x;
    } else if (x.adressTypeId == AddressType.main) {
      return x;
    }
  });
  */
};

export function PALevel(activityLevel: number) {
  return PALevelType[activityLevel];
}
export function mergeShippingAdress(
  userAdresses: UserAdress[],
  shippingAdress: UserAdress
) {
  if (userAdresses.length < 2) {
    userAdresses.push(shippingAdress);
  } else {
    userAdresses = userAdresses.map((x) => {
      if (x.adressTypeId == AddressType.shipping) {
        var existingShippingAdressId = x.userAdressId;
        x = shippingAdress;
        x.userAdressId = existingShippingAdressId;
      }
      return x;
    });
  }
  return userAdresses;
}

export function isPremium(productTypeId: number) {
  return productTypeId == ProductTypeEnum.NutrogenicsPlus;
}
