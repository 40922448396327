<div dir="rtl" style="position: relative">
  <button
    style="position: absolute; top: -20px; left: -20px"
    mat-icon-button
    (click)="dialogRef.close()"
  >
    <mat-icon>close</mat-icon>
  </button>
  <p style="text-decoration: underline; font-weight: 600; font-size: 1.6rem">
    מה אומרות הקוביות
  </p>
  <div style="display: flex; align-items: center">
    <div
      [ngStyle]="{ 'background-color': data.color }"
      style="
        width: 3rem;
        height: 3rem;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        margin-left: 1rem;
      "
    >
      {{ data.number }}
    </div>
    <div style="flex: 0.9">
      המספר שעל הקוביה ה{{ colorHeb }} מציג בכל קטגוריה את כמות
      <span style="font-weight: 600">הממצאים ה{{ rankHeb }}</span>
    </div>
  </div>
</div>
