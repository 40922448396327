<div class="thumbs">
  <mat-form-field appearance="fill">
    <label>פעילות</label>
    <input
      matInput
      (change)="addActivity($event)"
      name="name"
      [(ngModel)]="name"
      placeholder="פעילות"
    />
  </mat-form-field>

  <mat-form-field appearance="fill">
    <label>משך זמן</label>
    <input
      matInput
      (change)="addActivity($event)"
      name="duration"
      [(ngModel)]="duration"
      placeholder="משך זמן"
    />
  </mat-form-field>
  <mat-form-field appearance="fill">
    <label>מספר פעמים בשבוע</label>
    <input
      matInput
      name="numInWeek"
      (change)="addActivity($event)"
      [(ngModel)]="numInWeek"
      placeholder="מספר פעמים בשבוע"
    />
  </mat-form-field>
</div>
