import { Action, createReducer, on } from '@ngrx/store';
import { DnaReport } from 'src/app/data/DnaReport';
import {
  DnaReportAction,
  DnaReportActionTypes,
} from '../Actions/dna-report.actions';

export const dnaReportFeatureKey = 'dnaReport';

//export interface State {}

//export const initialState: State = {};

//export const reducer = createReducer(initialState);

export interface DnaReportState {
  dnaReport: DnaReport | null;
  isLoading: boolean;
  error: any;
}

const initialProductState: DnaReportState = {
  dnaReport: null,
  isLoading: false,
  error: null,
};

export function dnaReportReducer(
  state: DnaReportState = initialProductState,
  action: DnaReportAction
): DnaReportState {
  switch (action.type) {
    case DnaReportActionTypes.LoadDnaReport:
      return {
        ...state,
        isLoading: true,
      };
    case DnaReportActionTypes.LoadDnaReportComplete:
      return {
        ...state,
        isLoading: false,
        dnaReport: action.payload.dnaReport,
      };

    case DnaReportActionTypes.DnaReportError:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
}
