import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Location } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { fromEvent, Observable } from 'rxjs';
import { debounceTime, map, take, takeWhile } from 'rxjs/operators';
import { BulletCrossResult } from 'src/app/data/BulletCrossResult';
import { Product } from 'src/app/data/Product';
import { AppState } from 'src/app/Redux/reducers';
import { selectProduct } from 'src/app/Redux/selectors/product.selectors';
import { AlgorithmService } from 'src/app/Services/algorithm.service';
import { LanguagesService } from 'src/app/Services/languages.service';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';
import { TubeTestResultsService } from 'src/app/Services/tube-test-results.service';
import { isMobileAndTable } from 'src/app/Utils/userAgent';
import { isPremium } from 'src/app/Utils/userHelper';

@Component({
  selector: 'app-report-recomendations',
  templateUrl: './report-recomendations.component.html',
  styleUrls: ['./report-recomendations.component.scss'],
  animations: [
    trigger('fade', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [
        style({ opacity: 0 }),
        animate('500ms 250ms ease-in'),
      ]),
      transition(':leave', animate('500ms ease-in', style({ opacity: 0 }))),
    ]),
  ],
})
export class ReportRecomendationsComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChildren('elementRef') elementRef: QueryList<ElementRef>;
  @ViewChildren('pageRef') pageRef: ElementRef;
  product$: Observable<Product> = this.store.select(selectProduct);
  recomendationsStep = -1;
  alive: boolean = true;
  result: string;
  firstTimeAnimation: boolean = true;
  panUpTimeout: any;
  recomendations: BulletCrossResult[];
  barcode: string;
  direction = '';
  lastScrollTop = 0;
  scrollY;
  visibleIndex = 0;
  isLoading: boolean;
  hasBloodTest: boolean;
  langId: number = 1;
  wrapperScorlled: boolean = false;
  subscription: any;
  constructor(
    private store: Store<AppState>,
    private tubeTestResultsService: TubeTestResultsService,
    private popupHandlerService: PopupHandlerService,
    private _location: Location,
    private router: Router,
    private algorithmService: AlgorithmService,
    private languagesService: LanguagesService
  ) {}
  ngAfterViewInit(): void {
    setTimeout(() => {
      const el = document.body;
      fromEvent(el, 'scroll')
        .pipe(takeWhile(() => this.alive))
        .subscribe((res) => {
          if (!this.wrapperScorlled) {
            // console.log('wrapperScorlled');

            this.wrapperScorlled = true;
          }
        });
      //console.log('timeout call');
    }, 200);
  }

  goBack() {
    this._location.back();
  }

  ngOnInit(): void {
    this.product$.subscribe((product) => {
      if (product) {
        if (!isPremium(product.productTypeId)) {
          this.router.navigate(['/home']);
        }
        this.barcode = product.barCode;
        this.hasBloodTest = product.bloodTestForBulletsFilled;
        this.getRecomendations();
      }
    });
    this.listenToLangChange();
  }
  listenToLangChange() {
    this.subscription = this.languagesService.langIdSubject.subscribe(
      (langId) => {
        this.langId = langId;
        this.getRecomendations();
      }
    );
  }
  scroll() {
    const el = document.querySelector('.recomendations__page__container');
    fromEvent(el, 'scroll')
      .pipe(
        map((i: any) => i),
        debounceTime(250)
      )
      .subscribe((res) => {
        this.elementRef.toArray().map((x) => {
          console.log(x.nativeElement.id);
        });
      });
  }

  ngOnDestroy() {
    this.alive = false;
    this.subscription.unsubscribe();
  }

  openLinkDialog(linkId, color) {
    console.log(linkId);
    this.popupHandlerService.openAlgoLinkDialog(linkId, this.langId, color);
  }

  elementIsVisibleInViewport = (el, partiallyVisible = false) => {
    const { top, left, bottom, right } = el.getBoundingClientRect();
    const { innerHeight, innerWidth } = window;
    return partiallyVisible
      ? ((top > 0 && top < innerHeight) ||
          (bottom > 0 && bottom < innerHeight)) &&
          ((left > 0 && left < innerWidth) || (right > 0 && right < innerWidth))
      : top >= 0 && left >= 0 && bottom <= innerHeight && right <= innerWidth;
  };

  getRecomendations() {
    this.isLoading = true;
    this.algorithmService
      .getAlgoCrossingGroupByBarcode(this.barcode, this.langId)
      .subscribe((data) => {
        this.isLoading = false;
        this.recomendations = data;
      });
  }

  scrollToRec(index, dir: 'prev' | 'next' = null) {
    if (dir === 'next') {
      index++;
    }
    if (dir === 'prev') {
      index = --index;
    }
    const el = document.querySelector(`#rec-${index}`);
    el.scrollIntoView({
      behavior: 'smooth',
      inline: 'start',
    });
  }
  isMobile() {
    return isMobileAndTable();
  }
  incStep() {
    this.recomendationsStep++;
    if (this.recomendationsStep == 0) {
      setTimeout(() => {
        const el = document.querySelector('.recomendations__page__container');
        fromEvent(el, 'scroll')
          .pipe(takeWhile(() => this.alive))
          .subscribe(() => {
            this.elementRef.toArray().map((x) => {
              const isVisible = this.elementIsVisibleInViewport(
                x.nativeElement
              );
              if (isVisible) {
                let id = x.nativeElement.id.split('-')[1];
                this.visibleIndex = id;
              }
            });
          });
      }, 500);
    }
  }

  decStep() {
    this.recomendationsStep = --this.recomendationsStep;
  }
}
