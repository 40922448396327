import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { MealForSite } from 'src/app/data/MealForSite';
import { MealToNutrientSite } from 'src/app/data/MealToNutrientSite';
import { MenuForSite } from 'src/app/data/MenuForSite';
import { Product } from 'src/app/data/Product';
import { Status } from 'src/app/data/Status';
import { ZameretNutrients } from 'src/app/Enum/StatusesEnum';
import { AppState } from 'src/app/Redux/reducers';
import { selectProduct } from 'src/app/Redux/selectors/product.selectors';
import { MealsService } from 'src/app/Services/meals.service';
import { StatusesService } from 'src/app/Services/statuses.service';

/* import jsPDF from 'jspdf';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import htmlToPdfmake from 'html-to-pdfmake'; */

//import * as html2canvas from 'html2canvas';

@Component({
  selector: 'app-diet-menu-meals',
  templateUrl: './diet-menu-meals.component.html',
  styleUrls: ['./diet-menu-meals.component.scss'],
})
export class DietMenuMealsComponent implements OnInit {
  @Input() menuId: number;
  meals: MealForSite[];
  product$: Observable<Product> = this.store.select(selectProduct);
  product: Product;
  mealTypes: Status[];
  menu: MenuForSite;
  @ViewChild('pdfTable', { static: false }) pdfTable: ElementRef;
  pdfBlob: any;
  isLoading: boolean;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private mealsService: MealsService,
    public translate: TranslateService,
    private store: Store<AppState>,
    private statusesService: StatusesService
  ) {}

  panelOpenState;
  ngOnInit(): void {
    //this.menuId = +this.route.snapshot.queryParamMap.get('menuId');

    this.product$.subscribe((res) => {
      if (res) {
        this.product = res;
        this.isLoading = true;
        this.getMealTypes();
        this.mealsService
          .getByMenuId(this.menuId, this.product.productId, 1)
          .subscribe((data) => {
            if ((data as MenuForSite).name) {
              this.menu = data as MenuForSite;
              this.meals = this.menu.meals;
              this.isLoading = false;
            } else {
              this.router.navigate(['home']);
            }
          });
      }
    });
  }

  scrollToTop() {
    window.scrollTo(0, 150);
  }

  goBackToMenus() {
    this.router.navigate(['diet-menu']);
  }

  getMealTypes() {
    this.statusesService.getAll().subscribe((data) => {
      this.mealTypes = data.filter((x) => x.category === 'MealType');
      //console.table(this.mealTypes);
    });
  }

  getGeneticRate(nutrients: MealToNutrientSite[]) {
    return nutrients.find((x) => x.nutrientId == ZameretNutrients.geneticRate);
  }

  getCalories(nutrients: MealToNutrientSite[]) {
    return nutrients.find((x) => x.nutrientId == ZameretNutrients.food_energy);
  }

  getMealImg(mealName: string) {
    return this.mealTypes.find((x) => x.name == mealName).statusId;
  }

  formatMealTime(mealTime: string) {
    var a = mealTime.split(':');
    return a[0] + ':' + a[1];
  }

  getVitaminBarValue(amount: number, dailyAmount: number) {
    var value = (amount / dailyAmount) * 100;
    var rounded = Math.round(value);
    return rounded;
  }

  showDate() {
    return this.menu.dateUpdated
      ? this.menu.dateUpdated
      : this.menu.dateCreated;
  }
  showAgent() {
    return this.menu.createdByAgentName;
  }

  blankify(html, removeTags: boolean = false) {
    var root = document.createElement('div'),
      fonts = root.getElementsByTagName('font');

    root.innerHTML = html;

    Array.prototype.forEach.call(fonts, function (e) {
      e.removeAttribute('size');
      e.removeAttribute('face');
    });

    if (removeTags) {
      return root.textContent || root.innerText || '';
    } else {
      return root.innerHTML;
    }
  }

  downloadPdf() {
    /*
    const doc = new jsPDF();

    const specialElementHandlers = {
      '#editor': function (element, renderer) {
        return true;
      }
    };
    //var blobPdf=new Blob([doc.output])
    const pdfTable = this.pdfTable.nativeElement;
    doc.html(pdfTable.innerHTML,null).then(res=>{
      var a='1';
      this.pdfBlob= res;
    })
*/
    /*
    doc.fromHTML(pdfTable.innerHTML, 15, 15, {}, function () {
      this.pdfBlob= doc.output('datauri')
    });

    */
    /*
    doc.fromHTML(pdfTable.innerHTML, 15, 15, {
      width: 190,
      'elementHandlers': specialElementHandlers
    });
   */
    //doc.save('tableToPdf.pdf');
    /*
    const pdfTable = this.pdfTable.nativeElement;

    doc.fromHTML(pdfTable.innerHTML, 15, 15, {
      width: 190,
      'elementHandlers': specialElementHandlers
    });

    doc.save('tableToPdf.pdf');

    let self = this;//use this variable to access your class members inside then().
    html2canvas(document.body).then(canvas => {
        var imgData = canvas.toDataURL("image/png");
        self.AddImagesResource(imgData);
        document.body.appendChild(canvas);
   });
   */
  }

  // downloadPdf1()
  // {

  /* html2canvas(document.getElementById("workAreaModel")).then(canvas => {
      
    })


    var img = canvas.toDataURL("image/png");
    var doc = new jsPDF("l", "pt", "letter");
    doc.addImage(img, 'JPEG',20,20);
    var fileEncode = btoa(doc.output()); */
  /*
   */

  /*  downloadPdf() {
    const doc = new jsPDF();

    const pdfTable = this.pdfTable.nativeElement;

    var html = htmlToPdfmake(pdfTable.innerHTML);

    const documentDefinition = { content: html };
    pdfMake.createPdf(documentDefinition).open();
  } */
}
