<div style="height: 20px"></div>
<swiper [config]="swiperConfig" class="vod-swiper">
  <ng-template swiperSlide *ngFor="let item of videos; let i = index"
    ><app-video-item
      [sliderItem]="true"
      [video]="item"
      (videoClick)="onVideoClick($event)"
    ></app-video-item>
  </ng-template>
</swiper>
