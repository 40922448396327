import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { BarCity } from 'src/app/data/BarCity';
import { BarStreet } from 'src/app/data/BarStreet';
import { Product } from 'src/app/data/Product';
import { RegularUser } from 'src/app/data/RegularUser';
import { UserAdress } from 'src/app/data/UserAdress';
import { ConfirmDialogEnum } from 'src/app/Enum/ConfirmationDialogEnum';
import {
  AddressType,
  EnteredBarcodeInnerStatus,
  ProductStatusEnum,
} from 'src/app/Enum/StatusesEnum';
import {
  ChangeAddress,
  ChangeStatusId,
  InsertBarcode,
} from 'src/app/Redux/Actions/product.actions';
import { LogoutUser } from 'src/app/Redux/Actions/regular-user.actions';
import { AppState } from 'src/app/Redux/reducers';
import {
  selectProduct,
  selectProductError,
  selectProductIsLoading,
} from 'src/app/Redux/selectors/product.selectors';
import { selectUser } from 'src/app/Redux/selectors/user.selectors';
import { CitiesService } from 'src/app/Services/cities.service';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';
import { ProductService } from 'src/app/Services/product.service';
import { RegularUsersService } from 'src/app/Services/regular-users.service';
import { StreetsService } from 'src/app/Services/streets.service';
import { isMobileAndTable } from 'src/app/Utils/userAgent';
import { autocomplete, userAddress } from 'src/app/Utils/userHelper';

import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-scan-barcode',
  templateUrl: './scan-barcode.component.html',
  styleUrls: ['./scan-barcode.component.scss'],
})
export class ScanBarcodeComponent implements OnInit {
  selectedAdressType: number = 1;
  isApp: boolean = environment.isApp;
  @ViewChild('slider') slider: any;
  isFailedOnBarcode = false;
  isLoading: boolean;
  enteredBarcodeOnce: boolean = false;
  ProductStatusEnum = ProductStatusEnum;
  EnteredBarcodeInnerStatus = EnteredBarcodeInnerStatus;
  firstTimeBarcode: string = '';
  secondTimeBarcode: string = '';
  user: RegularUser;
  product: Product;
  fakeStatusId: any = ProductStatusEnum.EnteredBarcode;
  pleaseFillBarcode: boolean;
  afterSpitting: boolean = false;
  innerStatusId: number = EnteredBarcodeInnerStatus.BeforeSpitting;
  isFailedScanningBarcode: boolean = false;

  product$: Observable<Product> = this.store.select(selectProduct);
  isLoading$: Observable<boolean> = this.store.select(selectProductIsLoading);
  isError$: Observable<boolean> = this.store.select(selectProductError);
  currUser$: Observable<RegularUser> = this.store.select(selectUser);
  productError$: Observable<string> = this.store.select(selectProductError);
  productError: string;

  userAddress: UserAdress;
  newAddress: UserAdress = new UserAdress();
  cities: BarCity[];
  streets: BarStreet[];
  isCitySelected: boolean;
  isStreetSelected: boolean;
  citiesForAC: string[];
  streetsForAC: string[];
  //cityName: string;
  //streetName: string;
  cityCode: number;
  searchTermCity$ = new BehaviorSubject<string>('');
  citiesResults$: Observable<BarCity[]>;
  searchTermStreet$ = new BehaviorSubject<string>('');
  streetsResults$: Observable<BarStreet[]>;
  isSubmitedBarcode: boolean;

  constructor(
    private store: Store<AppState>,
    private productService: ProductService,
    private regularUsersService: RegularUsersService,
    private router: Router,
    private popupHandlerService: PopupHandlerService,
    private barcodeScanner: BarcodeScanner,
    private citiesService: CitiesService,
    private streetsService: StreetsService
  ) {
    this.newAddress.userAdressId = 0;
    this.newAddress.adressTypeId = AddressType.shipping;
    this.newAddress.barCity = new BarCity();
    this.newAddress.barStreet = new BarStreet();
    this.product$.subscribe((res) => {
      if (res) {
        this.product = res;
        this.userAddress = userAddress(
          this.product.patient.regularUser.userAdresses
        );
        this.citiesResults$ = this.searchTermCity$.pipe(
          autocomplete(800, (term: string) => {
            return this.citiesService.search(term);
          })
        );
        this.streetsResults$ = this.searchTermStreet$.pipe(
          autocomplete(800, (term: string) => {
            return this.streetsService.search(this.cityCode, term);
          })
        );
      }
    });
    this.isLoading$.subscribe((res) => (this.isLoading = res));

    this.productError$.subscribe((err) => {
      if (err) {
        this.productError = err;
      }
    });
  }

  get AddressType() {
    return AddressType;
  }

  ngOnInit(): void {
    /* this.currUser$.subscribe((user) => {
      if (!user) return;
      this.store.dispatch(
        new LoadProduct({ patientId: user.patient.patientId })
      );
    }); */
  }

  mapCities(results: BarCity[]) {
    if (!results) return [];
    this.cities = results;
    return this.cities.map((city: BarCity) => city.name);
  }
  mapStreets(results: BarStreet[]) {
    if (!results) return [];
    this.streets = results;
    return this.streets.map((street: BarStreet) => street.name);
  }

  onCitySelected(cityName: string) {
    this.isCitySelected = true;
    var citiesDup = [...this.cities];
    var city = citiesDup.filter((city) => {
      return city.name == cityName;
    })[0];

    //this.product.patient.
    //this.leadForm.controls['city'].setValue(city.name);
    //this.leadForm.controls['street'].setValue('');
    this.newAddress.barCity.name = city.name;
    this.cityCode = city.cityCode;
    this.newAddress.barStreet.name = '';
    this.newAddress.barCityCode = city.cityCode;
    this.newAddress.barCity.cityCode = city.cityCode;
  }

  onStreetSelected(streetName: string) {
    this.isStreetSelected = true;
    var streetsDup = [...this.streets];
    this.newAddress.barStreet = streetsDup.find((street) => {
      return street.name == streetName;
    });
    this.newAddress.barStreetCode = this.newAddress.barStreet.streetCode;

    //this.leadForm.controls['street'].setValue(this.streetName);
  }

  validChangeAddress() {
    //console.log(this.newAddress);

    return (
      (this.newAddress.barCity &&
        this.newAddress.barCity.name &&
        this.newAddress.barStreet &&
        this.newAddress.barStreet.name &&
        this.newAddress.streetNum) ||
      this.selectedAdressType == AddressType.main
    );
  }

  doLogout() {
    this.store.dispatch(LogoutUser());
  }

  editAdressConfirm() {
    if (this.selectedAdressType == 1) {
      this.store.dispatch(
        new ChangeStatusId({ statusId: ProductStatusEnum.WaitingToPickUp })
      );
    } else {
      if (this.validChangeAddress()) {
        this.newAddress.adressTypeId = AddressType.shipping;
        this.store.dispatch(new ChangeAddress({ address: this.newAddress }));
      }
    }
  }

  /* slides = [
    {
      img: '../../../assets/guide1.png',
      txt: 'יש לירוק לתוך המבחנה עד שכמות הרוק (לא הבועות) מגיעה לקו המילוי',
    },
    { img: '../../../assets/guide2.png', txt: 'יש לסגור בחוזקה את מכסה המשפך' },
    {
      img: '../../../assets/guide3.png',
      txt: 'יש להבריג כלפי חוץ את ראש המשפך',
    },
    {
      img: '../../../assets/guide4.png',
      txt: 'יש לסגור את המבחנה בחוזקה עם המכסה הקטן',
    },
    {
      img: '../../../assets/guide5.png',
      txt: 'יש להכניס את המבחנה לתוך מעטפת הפצפצים המצורפת',
    },
  ]; 

  onIndicatorClick(inx, indicators: HTMLElement) {
    indicators.childNodes.forEach((el) => {
      (el as HTMLElement).classList.remove('active');
    });

    if (inx == 0) {
      this.slider.nativeElement.children[0].scrollIntoView({
        behavior: 'smooth',
        inline: 'start',
      });
    } else if (inx == 1) {
      this.slider.nativeElement.children[1].scrollIntoView({
        behavior: 'smooth',
        inline: 'start',
      });
    } else if (inx == 2) {
      this.slider.nativeElement.children[2].scrollIntoView({
        behavior: 'smooth',
        inline: 'start',
      });
    } else if (inx == 3) {
      this.slider.nativeElement.children[3].scrollIntoView({
        behavior: 'smooth',
        inline: 'start',
      });
    } else if (inx == 4) {
      this.slider.nativeElement.children[4].scrollIntoView({
        behavior: 'smooth',
        inline: 'start',
      });
    }
  }

  onSliderScroll(
    el: HTMLElement,
    target: HTMLElement,
    indicators: HTMLElement
  ) {
    indicators.childNodes.forEach((el) => {
      (el as HTMLElement).classList.remove('active');
    });
    if (el.scrollLeft == 0) {
      target.scrollIntoView({ behavior: 'smooth' });
      indicators.children[4].classList.add('active');
    }

    if (this.elementIsVisibleInViewport(el.children[0], true)) {
      indicators.children[0].classList.add('active');
    } else if (this.elementIsVisibleInViewport(el.children[1], true)) {
      indicators.children[1].classList.add('active');
    } else if (this.elementIsVisibleInViewport(el.children[2], true)) {
      indicators.children[2].classList.add('active');
    } else if (
      this.elementIsVisibleInViewport(
        el.children[3],
        true && el.scrollLeft !== 0
      )
    ) {
      indicators.children[3].classList.add('active');
    }
  }

  elementIsVisibleInViewport = (el, partiallyVisible = false) => {
    const { top, left, bottom, right } = el.getBoundingClientRect();
    const { innerHeight, innerWidth } = window;
    return partiallyVisible
      ? ((top > 0 && top < innerHeight) ||
          (bottom > 0 && bottom < innerHeight)) &&
          ((left > 0 && left < innerWidth) || (right > 0 && right < innerWidth))
      : top >= 0 && left >= 0 && bottom <= innerHeight && right <= innerWidth;
  };*/

  isMobile() {
    return isMobileAndTable();
  }

  getProductStatus() {
    //if (this.afterSpitting) return true;
    //return this.fakeStatusId;
    if (this.product.statusId == ProductStatusEnum.ResendToPatient)
      return ProductStatusEnum.SentToCustomer;
    if (this.product.statusId == ProductStatusEnum.RetestOnLab)
      return ProductStatusEnum.LaboratoryProcess;
    return this.product.statusId;
    // return 9; //Sent to customer
    return 61; //Arrived to Costumer
    return 64; //EnteredBarcode
    return 7; //Waiting to PickUp
    return 62; //Arrived To Lab
    return 62; //Arrived To Lab
    return 8; //Laboratory Process
    return 63; //Laboratory Process Completed
  }

  onEnterBarcodeFirstTime() {
    if (this.firstTimeBarcode == '') {
      this.pleaseFillBarcode = true;
    } else {
      this.enteredBarcodeOnce = true;
      this.pleaseFillBarcode = false;
    }
  }

  onEnterBarcodeSecondTime() {
    this.isSubmitedBarcode = true;
    if (this.isLoading) return;
    if (!this.secondTimeBarcode) {
      this.isSubmitedBarcode = true;
      return;
    }
    if (this.firstTimeBarcode != this.secondTimeBarcode) {
      //show not matching
      this.secondTimeBarcode = '';
      this.enteredBarcodeOnce = false;
      this.isFailedOnBarcode = true;
      return;
    }
    this.isSubmitedBarcode = false;
    this.enteredBarcodeOnce = false;
    this.isFailedOnBarcode = false;
    this.store.dispatch(new InsertBarcode({ barCode: this.firstTimeBarcode }));
    //this.firstTimeBarcode = '';
    /*this.product.barCode = this.firstTimeBarcode;
    //this.isLoading = true;
     this.productService.insertBarcode(this.product).subscribe((product) => {
      this.isLoading = false;
      this.product = product;
      this.fakeStatusId = ProductStatusEnum.EnteredBarcode;
    }); */
  }

  getProductError(err: string) {
    if (err.includes('Exists')) {
      return 'הברקוד שהזנת כבר קיים כבר אצלנו במערכת, אנא בדוק שהברקוד שהזנת תואם לברקוד שעל המבחנה';
    } else {
      return 'אירעה שגיאה, אנא נסה שוב אם שגיאה זו חוזרת אנא פנה לשירות הלקוחות';
    }
  }

  getStatusTitle() {
    switch (this.getProductStatus()) {
      case ProductStatusEnum.SentToCustomer:
      case ProductStatusEnum.ResendToPatient:
        return 'הבדיקה בדרך אליך';
      case ProductStatusEnum.WaitingToPickUp:
        return '';
      case ProductStatusEnum.ArrivedToCostumer:
        return '';
      case ProductStatusEnum.EnteredBarcode:
        return '';
      case ProductStatusEnum.LaboratoryProcess:
      case ProductStatusEnum.RetestOnLab:
        return 'הבדיקה במעבדה';
      case ProductStatusEnum.Completed:
        return 'התקבלו תוצאות';
    }
  }

  onOrderDelivery() {
    if (this.isLoading) return;
    //this.isLoading = true;

    this.store.dispatch(
      new ChangeStatusId({ statusId: ProductStatusEnum.WaitingToPickUp })
    );

    /* this.productService.orderDelivery(this.product).subscribe((product) => {
      this.isLoading = false;
      this.product = product;
      this.fakeStatusId = ProductStatusEnum.WaitingToPickUp;
    }); */
  }

  onRecivedDelivery() {
    if (this.isLoading) return;
    //this.isLoading = true;

    this.store.dispatch(
      new ChangeStatusId({ statusId: ProductStatusEnum.ArrivedToCostumer })
    );

    //this.product.statusId = ProductStatusEnum.ArrivedToCostumer;
    /* this.productService.put(this.product).subscribe((product) => {
      this.isLoading = false;
    }); */
  }
  gotoConfirmAdress() {
    this.innerStatusId = EnteredBarcodeInnerStatus.ConfirmAdress;
  }
  afterSpittingBtn() {
    this.popupHandlerService.openConfirmationDialog(
      ConfirmDialogEnum.testConfimation
    );

    //this.innerStatusId = EnteredBarcodeInnerStatus.AfterSpitting;
    //this.popupHandlerService.openConfirmationDialog();
    this.popupHandlerService.confirmDialogSubject
      .pipe(take(1))
      .subscribe((ans) => {
        if (ans == 'yes') {
          //this.afterSpitting = true;
          this.innerStatusId = EnteredBarcodeInnerStatus.AfterSpitting;
        }
      });
  }

  onScanBarcode() {
    this.isFailedScanningBarcode = true;
    this.barcodeScanner
      .scan()
      .then((barcodeData) => {
        // console.log('Barcode data', barcodeData.text);
        if (barcodeData && barcodeData.text && barcodeData.text != '') {
          this.store.dispatch(new InsertBarcode({ barCode: barcodeData.text }));
          /* this.product.barCode = barcodeData.text;
          this.isLoading = true;
          this.productService
            .insertBarcode(this.product)
            .subscribe((product) => {
              this.isLoading = false;
              this.product = product;
              this.fakeStatusId = ProductStatusEnum.EnteredBarcode;
            }); */
        } else {
          this.isFailedScanningBarcode = true;
        }
      })
      .catch((err) => {
        //console.log('Error', err);
      });
  }
  getUserAdressString(product: Product) {
    const address = userAddress(product.patient.regularUser.userAdresses);
    return (
      address.barStreet.name +
      ' ' +
      address.streetNum +
      ' ' +
      address.barCity.name
    );
    /* const strName =
      this.userAddress &&
      this.userAddress.barStreet &&
      this.userAddress.barStreet.name;
    const cityName =
      this.userAddress &&
      this.userAddress.barCity &&
      this.userAddress.barCity.name;
    const str = strName + ' ' + this.userAddress.streetNum + ' ' + cityName;
    return str; */
  }
  /*  backToDoingTest() {
    if (this.isLoading) return;
    this.isLoading = true;
    this.product.statusId = ProductStatusEnum.EnteredBarcode;
    //this.fakeStatusId = ProductStatusEnum.EnteredBarcode;
    this.productService.put(this.product).subscribe((product) => {
      this.isLoading = false;
    });
  } */
}
