<div class="menu" id="menuId" *ngIf="product$ | async">
  <div class="menu__header">
    <div (click)="goToProfile()">
      <div class="menu__header__avatar">
        <img *ngIf="profileImg" [src]="profileImg" alt="" />
        <img *ngIf="!profileImg" [src]="getDefaultPersonImage()" alt="" />
      </div>
      <div class="menu__header__name">
        <button mat-button class="btn btn--small" *ngIf="regularUser$ | async">
          {{
            (regularUser$ | async).firstName +
              " " +
              (regularUser$ | async).lastName
          }}
        </button>
      </div>
    </div>
  </div>
  <div class="menu__body">
    <!-- [disabled]="!isStatusComplete()" -->
    <button
      mat-button
      [routerLink]="['/home']"
      routerLinkActive="menu__body__item--active"
      class="menu__body__item"
      [disabled]="isExapleUser"
    >
      <img src="../../../assets/nav-icons/home.svg" alt="" />
      ראשי
    </button>
    <div
      matTooltipPosition="after"
      [matTooltip]="!isStatusComplete() ? 'הבדיקה בתהליך' : ''"
      routerLinkActive="active"
    >
      <button
        mat-button
        [routerLink]="isExapleUser ? ['/example-report'] : ['/dna-report']"
        class="menu__body__item dna-button"
        [disabled]="!isStatusComplete()"
      >
        <img src="../../../assets/nav-icons/dna.svg" alt="" />
        DNA
      </button>
      <button [routerLink]="['/dna-report-inner']" style="display: none">
        DNA Inner
      </button>
    </div>
    <button
      mat-button
      routerLinkActive="menu__body__item--active"
      class="menu__body__item"
      (click)="goToLifestyleReport()"
      [disabled]="isExapleUser"
    >
      <mat-icon class="info" *ngIf="!lifestyleComplete()">info</mat-icon>
      <img src="../../../assets/nav-icons/lifestyle.svg" alt="" />
      שאלון אורח חיים
    </button>
    <button
      mat-button
      routerLinkActive="menu__body__item--active"
      class="menu__body__item"
      (click)="goToBloodTest()"
      [disabled]="isExapleUser"
    >
      <mat-icon class="info" *ngIf="!hasRequiredBloodTest() && !isExapleUser"
        >info</mat-icon
      >
      <img src="../../../assets/nav-icons/blood.svg" alt="" />
      בדיקות דם
    </button>
    <div
      matTooltipPosition="after"
      [matTooltip]="!hasMenus() ? 'אין לך עדיין תפריטים' : ''"
    >
      <button
        mat-button
        [routerLink]="isExapleUser ? ['/example-diet-menu'] : ['/diet-menu']"
        routerLinkActive="menu__body__item--active"
        class="menu__body__item"
        [disabled]="!hasMenus()"
      >
        <img src="../../../assets/nav-icons/menus.svg" alt="" />
        תפריטים
      </button>
    </div>
    <div
      matTooltipPosition="after"
      [matTooltip]="
        !isStatusComplete() ? 'ספריית הסרטונים זמינה רק לאחר קבלת תוצאות' : ''
      "
    >
      <button
        mat-button
        [routerLink]="['/vod']"
        routerLinkActive="menu__body__item--active"
        class="menu__body__item"
        [disabled]="!isStatusComplete() || isExapleUser"
      >
        <img src="../../../assets/nav-icons/vod.svg" alt="" />
        VOD
      </button>
    </div>
    <div
      matTooltipPosition="after"
      [matTooltip]="
        !hasRequiredBloodTest() ? 'אנא מלא את בדיקות הדם הדרושות' : ''
      "
    >
      <button
        mat-button
        [routerLink]="['/report-recomendations']"
        routerLinkActive="menu__body__item--active"
        class="menu__body__item"
        [disabled]="!hasRequiredBloodTest() || isExapleUser"
      >
        <img src="../../../assets/nav-icons/report.svg" alt="" />
        קלקולטור
      </button>
    </div>
    <button (click)="logout()" mat-button class="menu__body__item mt-4">
      <img src="../../../assets/nav-icons/logout.svg" alt="" />
      התנתק
    </button>
    <button
      *ngIf="showGeneratePdf$ | async"
      (click)="onDnaReportDownloadClick()"
      [disabled]="isLoadingReport || !isStatusComplete() || isExapleUser"
      mat-button
      class="menu__body__item mt-4 download"
    >
      <ng-container *ngIf="isLoadingReport">
        מוריד
        <mat-icon> <mat-spinner diameter="20"></mat-spinner></mat-icon>
      </ng-container>
      <ng-container *ngIf="!isLoadingReport">
        לחץ להורדת ה PDF
        <mat-icon>file_download</mat-icon>
      </ng-container>
    </button>
    <!-- TODO: next sprint-->
    <div
      class="menu__body__item mt-4"
      style="justify-content: center"
      *ngIf="false"
    >
      <app-change-lang (changeLang)="onChangeLang($event)"></app-change-lang>
    </div>
  </div>
</div>
