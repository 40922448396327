import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-lifestyle-progress-bar',
  templateUrl: './lifestyle-progress-bar.component.html',
  styleUrls: ['./lifestyle-progress-bar.component.scss'],
})
export class LifestyleProgressBarComponent implements OnInit {
  @Input() maxIndex: number;
  @Input() currentIndex: number;
  constructor() {}

  ngOnInit(): void {}
  getCurrentPrecent(inx) {
    var startInx = (this.maxIndex / 5) * (inx - 1);
    var endInx = (this.maxIndex / 5) * inx;
    if (this.currentIndex > endInx) {
      return 100;
    } else if (this.currentIndex >= startInx && this.currentIndex <= endInx) {
      return ((this.currentIndex - startInx) / (endInx - startInx)) * 100;
    } else {
      return 0;
    }
  }
}
