import { Injectable } from '@angular/core';
import { act, Actions, createEffect, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { colorSets } from '@swimlane/ngx-charts';
import { map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { LifeStyleAnswersService } from 'src/app/Services/life-style-answers.service';
import { LifestyleQuestionsService } from 'src/app/Services/lifestyle-questions.service';
import { searchQuestionById } from 'src/app/Utils/lifestyleUtil';
import {
  LifestyleQusetionActionTypes,
  loadLifestyleQuestions,
  loadLifestyleQuestionsFailure,
  loadLifestyleQuestionsSuccess,
  updateAnswer,
  updateAnswerSuccess,
} from '../Actions/lifestyle-question.actions';
import { AppState } from '../reducers';
import { updateAnswerValue } from '../reducers/lifestyle-question.reducer';
import { selectAllLifestyleQuestions } from '../selectors/lifestyle-question.selectors';

@Injectable()
export class LifestyleQuestionEffects {
  /* @Effect()
  loadLifestyleQuestions$ = this.actions$.pipe(
    ofType(LifestyleQusetionActionTypes.LoadLifestyleQuestions),
    mergeMap((action) => {
        return this.lifestyleQuestionsService.getByProductId(action.)
          .pipe(
            mergeMap((lifeStyle) => [
              LoadLifeStyleComplete({ lifeStyle: lifeStyle }),
            ])
            catchError((errorMessage) =>
            of(new ProductError({ error: errorMessage }))
          )
          );
      
    })
  ); */
  /*  loadLifestyleQuestions$ = createEffect(()=> this.actions$.pipe(
    ofType(loadLifestyleQuestions),
    mergeMap((action)=> this.lifestyleQuestionsService.getByProductId(action.productId,action.langId)
    .pipe(
      map(lifestyleQuestions => loadLifestyleQuestionsSuccess,lifestyleQuestions)
    )
    )
  )) */

  /* loadLifestyleQuestions$ = createEffect( () => {
    return this.actions$.pipe(
      ofType(loadLifestyleQuestions),
      mergeMap(
        (action) => this.lifestyleQuestionsService.getByProductId(action.productId,action.langId)
        .pipe(
          map(lifestyleQuestion => loadLifestyleQuestionsSuccess({lifestyleQuestion})),
          catchError(() => EMPTY)
        ))
      )
  }); */
  /*
  updateLifestyleAnswer = createEffect(() =>
    this.actions$.pipe(
      ofType(updateAnswer),
      withLatestFrom(this.store$.select(selectAllLifestyleQuestions)),
      switchMap(([action, questions]) => {
        console.log('updateAnswer effect');
        var question = searchQuestionById(
          questions,
          action.lifestyleQuestion.lifestyleQuestionId
        );
        if (
          !question.lifeStyleAnswers ||
          question.lifeStyleAnswers.length == 0
        ) {
          console.log('updateLifestyleAnswer error');
          return this.lifeStyleAnswersService
            .empty()
            .pipe(map((value) => updateAnswerSuccess()));
        }
        var answer = question.lifeStyleAnswers[0];
        if (answer.lifeStyleAnswerId == 0) {
          return this.lifeStyleAnswersService
            .post(answer)
            .pipe(map((value) => updateAnswerSuccess()));
        } else {
          return this.lifeStyleAnswersService
            .put(answer)
            .pipe(map((value) => updateAnswerSuccess()));
        }
      })
    )
  );
*/
  loadLifestyleQuestions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadLifestyleQuestions),
      act({
        project: (action) => {
          return this.lifestyleQuestionsService
            .getByProductId(action.productId, action.langId,action.locationId)
            .pipe(
              map((value) =>
                loadLifestyleQuestionsSuccess({ lifestyleQuestions: value })
              )
            );
        },
        error: (error) => loadLifestyleQuestionsFailure({ error }),
      })
    )
  );

  constructor(
    private actions$: Actions,
    private lifestyleQuestionsService: LifestyleQuestionsService,
    private lifeStyleAnswersService: LifeStyleAnswersService,
    private store$: Store<AppState>
  ) {}
}
