import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-lifestyle-progress-bar-thumb',
  templateUrl: './lifestyle-progress-bar-thumb.component.html',
  styleUrls: ['./lifestyle-progress-bar-thumb.component.scss']
})
export class LifestyleProgressBarThumbComponent implements OnInit {
  @Input() currentPrecent: number;
  constructor() { }

  ngOnInit(): void {
  }

}
