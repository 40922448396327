import { createAction, props } from '@ngrx/store';
import { LifeStyleAnswer } from 'src/app/data/LifeStyleAnswer';
import { LifestyleQuestion } from 'src/app/data/LifestyleQuestion';

export enum LifestyleQusetionActionTypes {
  LoadLifestyleQuestions = '[LifestyleQuestion] Load LifestyleQuestions',
  LoadLifestyleQuestionsSuccess = '[LifestyleQuestion] Load LifestyleQuestions Success',
  LoadLifestyleQuestionsFailure = '[LifestyleQuestion] Load LifestyleQuestions Failure',
  SaveLifestyleQuestions = '[LifestyleQuestion] Save LifestyleQuestions',
  UpdateAnswer = '[LifestyleQuestion] Update Answer',
  UpdateAnswerSuccess = '[LifestyleQuestion] Update Answer Success ',
  ResetRadioButton = '[LifestyleQuestion] Reset Radio Buttons',
}

export const loadLifestyleQuestions = createAction(
  LifestyleQusetionActionTypes.LoadLifestyleQuestions,
  props<{ productId: number; langId: number; locationId }>()
);

export const loadLifestyleQuestionsSuccess = createAction(
  LifestyleQusetionActionTypes.LoadLifestyleQuestionsSuccess,
  props<{ lifestyleQuestions: LifestyleQuestion[] }>()
);

export const loadLifestyleQuestionsFailure = createAction(
  LifestyleQusetionActionTypes.LoadLifestyleQuestionsFailure,
  props<{ error: any }>()
);

export const updateAnswer = createAction(
  LifestyleQusetionActionTypes.UpdateAnswer,
  props<{
    lifestyleQuestion: LifestyleQuestion;
    productId: number;
    eventChecked: string;
    inputTypeId: number;
    value: string;
  }>()
);
export const updateAnswerSuccess = createAction(
  LifestyleQusetionActionTypes.UpdateAnswerSuccess
);

export const resetRadioButton = createAction(
  LifestyleQusetionActionTypes.ResetRadioButton,
  props<{ lifestyleQuestionId: number }>()
);

export const saveLifestyleQuestions = createAction(
  LifestyleQusetionActionTypes.SaveLifestyleQuestions,
  props<{ data: LifeStyleAnswer[] }>()
);
