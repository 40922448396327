import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { Product } from 'src/app/data/Product';
import { ExelDownloadService } from 'src/app/Services/exel-download.service';
import { ProductService } from 'src/app/Services/product.service';
import { RegularUsersService } from 'src/app/Services/regular-users.service';
import {
  ProductStatusEnum,
  FileUploadTypeEnum,
  ZameretNutrients,
  PALevelType,
} from 'src/app/Enum/StatusesEnum';
import { RegularUser } from 'src/app/data/RegularUser';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/Redux/reducers';
import { fromEvent, Observable } from 'rxjs';
import { selectProduct } from 'src/app/Redux/selectors/product.selectors';
import { Menu } from 'src/app/data/Menu';
import { MenusService } from 'src/app/Services/menus.service';
import { MenuForTable } from 'src/app/data/MenuForTable';
import { map } from 'rxjs/operators';
import { ViewportScroller } from '@angular/common';
import { MenuForSite } from 'src/app/data/MenuForSite';
import { MealToNutrientSite } from 'src/app/data/MealToNutrientSite';
import { PALevel } from 'src/app/Utils/userHelper';
import { TranslateService } from '@ngx-translate/core';
import { LoginUserFromStorage } from 'src/app/Redux/Actions/regular-user.actions';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';

@Component({
  selector: 'app-diet-menu',
  templateUrl: './diet-menu.component.html',
  styleUrls: ['./diet-menu.component.scss'],
})
export class DietMenuComponent implements OnInit {
  @ViewChild('menuHeaderImg') menuHeaderImg: ElementRef;
  patientId: number;
  product: Product;
  regularUser: RegularUser;
  //menus: MenuForTable[] = [];
  menus: MenuForSite[] = [];
  product$: Observable<Product> = this.store.select(selectProduct);
  listener;
  isLoading: boolean;
  langId: number = 1;
  PALevel: string;
  exampleUser: RegularUser;
  mealsUrl: string = '/diet-menu-meals';
  menuHtml: MenuForSite;
  constructor(
    //private regularUsersService: RegularUsersService,
    //private productService: ProductService,
    private popupHandlerService: PopupHandlerService,
    public translate: TranslateService,
    private exelDownloadService: ExelDownloadService,
    private router: Router,
    private store: Store<AppState>,
    private menusService: MenusService //private renderer2: Renderer2
  ) {
    /*   this.renderer2.listen('window', 'scroll', (e) => {
      console.log('sss');
    }); */
  }

  ngOnInit(): void {
    if (this.router.url === '/example-diet-menu') {
      this.mealsUrl = '/example-diet-menu-meals';
      ///report ror example
      var json = `{"regularUserId":10846,"firstName":"לקוח לדוגמא","lastName":"לדוגמא","displayName":"","phone":"345345435","email":"sdf@sdf.dsfg","userName":"000111000","password":"tCO24a/Vko5l3YMmkQgUQDvbagPlhUYxxYg/pH8+Qby5ggPr0xXg3PmvSItolhVIOZwmlKlm9gWN+LdXWU3iVw==","salt":"vMtHkmvil8EzmdC0Je/gHMyEtNy89G9cXJ9cheUUB81kp8l4kjSMwqWWA3PSFhqSOlg5QcIw58LwZ2E6Ymzx5Q==","streetNum":44,"city":"תמר","street":"ללא רחוב","barCityCode":5,"barCity":null,"barStreetCode":100765,"barStreet":null,"country":"","socialSecurityNum":"000111000","birthDate":"1970-01-01T12:00:00","gender":"male","height":150.0,"weight":50.0,"age":null,"roleTypeId":null,"role":null,"agent":null,"client":null,"patient":{"patientId":1859,"regularUserId":10846,"clientId":10243,"client":null,"userId":0,"products":[{"productId":2059,"clientId":10243,"client":null,"patientId":1859,"dealId":2228,"deal":null,"cancelDealId":null,"cancelDeal":null,"productTypeId":1,"productType":null,"barCode":"20D","tubeTest":null,"codeCoupon":"00000000-0000-0000-0000-000000000000","statusId":63,"status":null,"dateCreated":"2020-09-23T17:23:35.8420705","agentId":65,"agent":null,"fileUploads":null}],"codeCoupon":"00000000-0000-0000-0000-000000000000","patientNotes":null,"patientLogs":null,"nutritionReport":null,"bloodTestResults":null,"patientDeliveries":null,"filledDetails":true,"agentId":null,"agent":null,"lifeStyleReport":null,"patientWeightHistories":null,"bloodTestComplete":false,"suggerComplete":false},"createdDate":"2020-09-23T05:23:00","entrance":null,"apartment":null,"shipNotes":null,"resetPasswordToken":"00000000-0000-0000-0000-000000000000","token":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1laWQiOiIwIiwibmJmIjoxNjA1MDkyMzY3LCJleHAiOjE2MDUxNzg3NjcsImlhdCI6MTYwNTA5MjM2NywiaXNzIjoiaHR0cDovL3dlYmFwaS5teWdlbmVzLmNvLmlsIiwiYXVkIjoiaHR0cDovL2FkbWlucGFuZWwubXlnZW5lcy5jby5pbCJ9.p1vM9nDn_9P_qAlHQB4a5qCDkwB5w_9FgVsSo_Pe_-8","phoneVerificationCode":null}`;
      this.exampleUser = JSON.parse(json);
      this.exampleUser.firstName = '';
      this.exampleUser.userName = '000111000';
      this.exampleUser.password =
        'tCO24a/Vko5l3YMmkQgUQDvbagPlhUYxxYg/pH8+Qby5ggPr0xXg3PmvSItolhVIOZwmlKlm9gWN+LdXWU3iVw==';

      localStorage.removeItem('currentUser');
      localStorage.removeItem('access_token');

      this.store.dispatch(
        LoginUserFromStorage({
          userName: this.exampleUser.userName,
          password: this.exampleUser.password,
        })
      );
    }
    this.product$.subscribe((res) => {
      if (res) {
        this.product = res;
        this.PALevel = PALevel(this.product.sportActivityData.activityLevel);
        if (
          this.product.menus &&
          this.product.menus.length > 0 &&
          this.menus.length == 0
        ) {
          this.isLoading = true;
          this.menusService
            //.getMenusByProductId(this.product.productId)
            .getMenusByProductIdV2(this.product.productId, this.langId)
            .subscribe((data) => {
              this.menus = data;
              this.isLoading = false;
              //console.log('menus loaded', this.menus.length);
            });
        }
      }
    });
  }

  getByMenuIdForHtml(menuId: number) {
    this.menusService
      .getByMenuIdForHtml(menuId, this.product.productId, 1)
      .subscribe((data) => {
        this.menuHtml = data;
        //this.menuHtml.menuId = menuId;
        //this.popupHandlerService.openMenuPdfDialog(this.menu);
      });
  }
  getGeneticRate(nutrients: MealToNutrientSite[]) {
    return nutrients.find((x) => x.nutrientId == ZameretNutrients.geneticRate);
  }
  onScroll(event) {
    const scrollTop = event.path[0].scrollTop;
    //console.log(scrollTop);
  }
  isNutritionReportExist() {
    if (!this.product?.fileUploads) return false;
    return (
      this.product.fileUploads.filter(
        (x) => x.fileUploadTypeId == FileUploadTypeEnum.PatientNutritionReport
      ).length > 0
    );
  }
  goBack() {
    this.router.navigate(['home']);
  }
  downloadOldSystemDietMenu() {
    if (this.isNutritionReportExist()) {
      var url = this.exelDownloadService.getPatientNutritionReport(
        this.product.productId
      );
      window.open(url, '_blank');
    }
  }
  gotoMenuMeals(menu: MenuForTable) {
    this.router.navigate([this.mealsUrl], {
      queryParams: { menuId: menu.menuId },
    });
  }
}
