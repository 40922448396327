<div class="container">
  <ngx-skeleton-loader
    *ngIf="isLoading"
    [theme]="{ height: '30px' }"
    count="10"
  >
  </ngx-skeleton-loader>
  <ng-container *ngIf="!isLoading">
    <div
      *ngIf="userSummary"
      class="page-bg-title"
      [style.background-image]="'url(' + getImage(userSummary.image) + ')'"
    >
      <div class="text">{{ userSummary.categoryName }}</div>
    </div>

    <nav class="filter__row" *ngIf="!isLoading">
      <a
        *ngFor="let type of resultTypes; let i = index"
        class="filter__row__item"
        (click)="onSelectType(type)"
        [ngClass]="{
          active: activeType?.typeId == type?.typeId,
          disabled: resultsCount(type?.typeId) == 0
        }"
        [ngStyle]="{
          'background-color':
            activeType?.typeId == type?.typeId ? type.color : '',
          color: activeType?.typeId == type?.typeId ? '#ffffff' : type?.color,
          'border-color': type?.color
        }"
      >
        <span style="position: relative">
          <span
            class="result-count"
            [style.background-color]="
              activeType?.typeId == type.typeId ? '#ffffff' : type.color
            "
            [style.color]="
              activeType?.typeId == type.typeId ? type.color : '#ffffff'
            "
            [class.disabled]="resultsCount(type.typeId) == 0"
          >
            {{ type.count }}
          </span>
          {{ type.typeName }}
        </span>
      </a>
    </nav>
    <mat-accordion>
      <ng-container *ngFor="let result of results">
        <ng-container *ngIf="showResult(result.order)">
          <div
            *ngIf="
              result.order >= this.highOrderGroup &&
              highOrderGroupHasMoreThenOneResult(result.order)
            "
            class="results__item__title"
            [style.background-color]="'#' + result.color"
          >
            {{ getResultTypeName(result.order) }}
          </div>
          <mat-expansion-panel
            #panel
            hideToggle="true"
            (opened)="tableOpen = false"
          >
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="img-name">
                  <div class="image-bg" [style.background-color]="getImageBg()">
                    <img
                      *ngIf="result.imageUrl"
                      class="image"
                      [src]="getImageUrl(result)"
                    />
                  </div>
                  <div class="subcat-name">
                    <span
                      style="font-size: 1.7rem; font-weight: bolder"
                      [innerHtml]="result.name"
                    >
                    </span>
                  </div>
                </div>
                <div class="precenteg-bar-wrapper">
                  <div class="precenteg-bar">
                    <div
                      class="fill"
                      [style.width]="result.persent + '%'"
                      [style.background-color]="'#' + result.color"
                    ></div>
                  </div>
                  <div class="precenteg-text">{{ result.persent }}%</div>
                </div>
                <div class="trigger">
                  <button mat-icon-button *ngIf="!panel.expanded">
                    <mat-icon>keyboard_arrow_down</mat-icon>
                  </button>
                  <button mat-icon-button *ngIf="panel.expanded">
                    <mat-icon>keyboard_arrow_up</mat-icon>
                  </button>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <span class="title-underlined"> סיכום: </span>
            <div
              style="padding-top: 20px; padding-bottom: 20px"
              [innerHtml]="removeHTags(blankify(result.description))"
            ></div>
            <span class="title-underlined"> המלצות: </span>
            <ol class="recomendaion-list">
              <li
                *ngFor="let item of result.recommendationList"
                [innerHtml]="blankify(item.recommend)"
              ></li>
            </ol>
            <div class="genes-table">
              <button mat-button (click)="tableOpen = !tableOpen">
                <mat-icon
                  [ngStyle]="{
                    transform: tableOpen ? 'rotate(45deg)' : 'rotate(0)'
                  }"
                  >add</mat-icon
                >
                לחץ לצפייה בגנים הנבדקים
              </button>
              <div *ngIf="panel.expanded && tableOpen">
                <ng-container *ngIf="result?.snpResultForApp.length > 0">
                  <table
                    *ngIf="!isForPrint"
                    style="direction: ltr"
                    mat-table
                    [dataSource]="result?.snpResultForApp"
                  >
                    <ng-container matColumnDef="geneName">
                      <th
                        class="table-header"
                        mat-header-cell
                        *matHeaderCellDef
                        style="text-align: center"
                      >
                        שם הגן הנבדק
                      </th>
                      <td
                        mat-cell
                        *matCellDef="let element"
                        style="text-align: center"
                      >
                        {{ element.geneName }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="snpNumber">
                      <th
                        class="table-header"
                        mat-header-cell
                        *matHeaderCellDef
                        style="text-align: center"
                      >
                        RS NO.
                      </th>
                      <td
                        mat-cell
                        *matCellDef="let element"
                        style="text-align: center"
                      >
                        rs{{ element.snpNumber }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="result">
                      <th
                        class="table-header"
                        mat-header-cell
                        *matHeaderCellDef
                        style="text-align: center"
                      >
                        הגנוטיפ שהתקבל
                      </th>
                      <td
                        mat-cell
                        *matCellDef="let element"
                        style="text-align: center"
                      >
                        <div [innerHtml]="element.snpResult"></div>
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="info">
                      <th
                        class="table-header"
                        mat-header-cell
                        *matHeaderCellDef
                      ></th>
                      <td mat-cell *matCellDef="let element">
                        <button
                          *ngIf="element.description"
                          mat-icon-button
                          #tooltip="matTooltip"
                          (click)="tooltip.toggle()"
                          matTooltip="{{ element.description }}"
                          matTooltipPosition="left"
                          class="info-circle"
                        >
                          <span>i</span>
                        </button>
                      </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr
                      mat-row
                      *matRowDef="let row; columns: displayedColumns"
                    ></tr>
                  </table>
                </ng-container>
              </div>
            </div>
          </mat-expansion-panel>
        </ng-container>
      </ng-container>
    </mat-accordion>
    <!--   <div class="results">
      <div
        class="results__item"
        *ngFor="let result of userSummary; let inx = index"
      >
        <ng-container *ngIf="subResults.results.length > 0">
          <div
            class="results__item__title"
            [ngClass]="{
              red:
                subResults.results[0].order == 3 ||
                subResults.results[0].order == 4,
              orange: subResults.results[0].order == 2,
              green: subResults.results[0].order == 1
            }"
            [innerHtml]="subResults.title"
          ></div>

          <mat-accordion class="results__item__accordion">
            <ng-container
              *ngFor="let result of subResults.results; let i = index"
            >
              <div class="results__item__accordion__bar">
                <app-tendeny-thumb [persent]="result.persent">
                </app-tendeny-thumb>
              </div>
              <mat-expansion-panel
                [expanded]="isForPrintOpen == true"
                #panel
                (opened)="panelOpenState = true"
                (closed)="panelOpenState = false"
                hideToggle="true"
              >
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div class="results__item__accordion__header">
                      <div class="results__item__accordion__header__content">
                        <div
                          class="results__item__accordion__header__content__image"
                        >
                          <img
                            [src]="
                              result.imageUrl
                                ? result.imageUrl
                                : '../../../assets/fake-res-photo.png'
                            "
                          />
                        </div>
                        <div
                          class="results__item__accordion__header__content__text"
                        >
                          <div
                            class="results__item__accordion__header__content__text__title"
                            [innerHtml]="result.name"
                          ></div>
                          <div
                            #descriptionEl
                            class="results__item__accordion__header__content__text__description"
                            [innerHtml]="blankify(result.description, true)"
                          ></div>
                        </div>
                      </div>
                      <div class="results__item__accordion__header__trigger">
                        <button mat-icon-button *ngIf="!panel.expanded">
                          <mat-icon>keyboard_arrow_down</mat-icon>
                        </button>
                        <button mat-icon-button *ngIf="panel.expanded">
                          <mat-icon>keyboard_arrow_up</mat-icon>
                        </button>
                      </div>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="results__item__accordion__text-title">סיכום:</div>
                <div
                  class="results__item__accordion__text"
                  [innerHtml]="blankify(result.description)"
                ></div>
                <div class="results__item__accordion__text-title">המלצות:</div>
                <div
                  class="results__item__accordion__text"
                  [innerHtml]="blankify(result.recomendations)"
                ></div>
              </mat-expansion-panel>
            </ng-container>
          </mat-accordion>
        </ng-container>
      </div>
    </div> -->
  </ng-container>
</div>
