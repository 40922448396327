import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { AlgoUserSummary } from 'src/app/data/AlgoUserSummary';
import { AllLabResultUserSummary } from 'src/app/data/AllLabResultUserSummary';
import { AppImage } from 'src/app/data/AppImage';
import { Product } from 'src/app/data/Product';
import { RegularUser } from 'src/app/data/RegularUser';
import { ShortResultType } from 'src/app/data/ShortResultType';
import { AlgoImageType, ProductTypeEnum } from 'src/app/Enum/StatusesEnum';
import { LoginUserFromStorage } from 'src/app/Redux/Actions/regular-user.actions';
import { AppState } from 'src/app/Redux/reducers';
import { selectProduct } from 'src/app/Redux/selectors/product.selectors';
import { selectShowGeneratePdf } from 'src/app/Redux/selectors/user.selectors';
import { AlgorithmService } from 'src/app/Services/algorithm.service';
import { LanguagesService } from 'src/app/Services/languages.service';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';
import { padWithZeros } from 'src/app/Utils/dateUtils';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dna-report',
  templateUrl: './dna-report.component.html',
  styleUrls: ['./dna-report.component.scss'],
})
export class DnaReportComponent implements OnInit, OnDestroy {
  product$: Observable<Product> = this.store.select(selectProduct);
  showGeneratePdf$: Observable<boolean> = this.store.select(
    selectShowGeneratePdf
  );
  product: Product;
  categories: AlgoUserSummary[];
  productId: number;
  exampleUser: RegularUser;
  trigerForPrintOpen: boolean = false;
  remainingTime: any;
  remainingTimeInterval: any;
  langId: number = 1;
  isLoadingReport: boolean;
  isLoadingReportForApp: boolean;
  rankResults: { green: number; orange: number; red: number } = {
    green: 0,
    orange: 0,
    red: 0,
  };
  recomendationsUrl: string = '/report-recomendations';
  isPremiumUser: boolean;
  resultsCount: AllLabResultUserSummary;
  zIndexInit = 5000;
  loadingResultsCount: boolean;
  loadingCategories: boolean;
  isLoading: boolean;
  AlgoImageType = AlgoImageType;
  subscription: any;
  constructor(
    private algorithmService: AlgorithmService,
    private router: Router,
    private store: Store<AppState>,
    private popupHandlerService: PopupHandlerService,
    private languagesService: LanguagesService
  ) {}
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.redirectAfterUpgrade();
    this.autoLoginFromExampleReport();
    this.subscribeToProduct();
    this.listenToLangChange();
  }
  listenToLangChange() {
    this.subscription = this.languagesService.langIdSubject.subscribe(
      (langId) => {
        this.langId = langId;
        this.getCategories();
      }
    );
  }
  isPremiumAndLockDone(isPremium: boolean) {
    if (
      !isPremium ||
      !this.isUpgradeLockDone(this.product.upgragePurchaseDate)
    ) {
      return true;
    }
  }
  subscribeToProduct() {
    this.product$.subscribe((res) => {
      if (res) {
        this.product = res;
        this.isPremiumUser =
          this.product.productTypeId == ProductTypeEnum.NutrogenicsPlus;

        this.getCategories();
        //this.getUserLabResultCounts();
        clearInterval(this.remainingTimeInterval);
        if (this.isPlusAndCounting()) {
          this.remainingTimeInterval = setInterval(() => {
            this.remainingTime = this.getRemainingTime();
          }, 1000);
        }
      }
    });
  }
  redirectAfterUpgrade() {
    this.popupHandlerService.upgradePreviewDialogSubject.subscribe((data) => {
      if (data === 'yes') {
        this.router.navigate(['upgrade']);
      }
      // console.log('upgradePreviewDialogSubject');
    });
  }
  autoLoginFromExampleReport() {
    if (this.router.url === '/example-report') {
      this.recomendationsUrl = '/example-report-recomendations';
      ///report ror example
      var json = `{"regularUserId":10846,"firstName":"לקוח לדוגמא","lastName":"לדוגמא","displayName":"","phone":"345345435","email":"sdf@sdf.dsfg","userName":"000111000","password":"tCO24a/Vko5l3YMmkQgUQDvbagPlhUYxxYg/pH8+Qby5ggPr0xXg3PmvSItolhVIOZwmlKlm9gWN+LdXWU3iVw==","salt":"vMtHkmvil8EzmdC0Je/gHMyEtNy89G9cXJ9cheUUB81kp8l4kjSMwqWWA3PSFhqSOlg5QcIw58LwZ2E6Ymzx5Q==","streetNum":44,"city":"תמר","street":"ללא רחוב","barCityCode":5,"barCity":null,"barStreetCode":100765,"barStreet":null,"country":"","socialSecurityNum":"000111000","birthDate":"1970-01-01T12:00:00","gender":"male","height":150.0,"weight":50.0,"age":null,"roleTypeId":null,"role":null,"agent":null,"client":null,"patient":{"patientId":1859,"regularUserId":10846,"clientId":10243,"client":null,"userId":0,"products":[{"productId":2059,"clientId":10243,"client":null,"patientId":1859,"dealId":2228,"deal":null,"cancelDealId":null,"cancelDeal":null,"productTypeId":1,"productType":null,"barCode":"20D","tubeTest":null,"codeCoupon":"00000000-0000-0000-0000-000000000000","statusId":63,"status":null,"dateCreated":"2020-09-23T17:23:35.8420705","agentId":65,"agent":null,"fileUploads":null}],"codeCoupon":"00000000-0000-0000-0000-000000000000","patientNotes":null,"patientLogs":null,"nutritionReport":null,"bloodTestResults":null,"patientDeliveries":null,"filledDetails":true,"agentId":null,"agent":null,"lifeStyleReport":null,"patientWeightHistories":null,"bloodTestComplete":false,"suggerComplete":false},"createdDate":"2020-09-23T05:23:00","entrance":null,"apartment":null,"shipNotes":null,"resetPasswordToken":"00000000-0000-0000-0000-000000000000","token":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1laWQiOiIwIiwibmJmIjoxNjA1MDkyMzY3LCJleHAiOjE2MDUxNzg3NjcsImlhdCI6MTYwNTA5MjM2NywiaXNzIjoiaHR0cDovL3dlYmFwaS5teWdlbmVzLmNvLmlsIiwiYXVkIjoiaHR0cDovL2FkbWlucGFuZWwubXlnZW5lcy5jby5pbCJ9.p1vM9nDn_9P_qAlHQB4a5qCDkwB5w_9FgVsSo_Pe_-8","phoneVerificationCode":null}`;
      this.exampleUser = JSON.parse(json);
      this.exampleUser.firstName = '';
      this.exampleUser.userName = '000111000';
      this.exampleUser.password =
        'tCO24a/Vko5l3YMmkQgUQDvbagPlhUYxxYg/pH8+Qby5ggPr0xXg3PmvSItolhVIOZwmlKlm9gWN+LdXWU3iVw==';

      localStorage.removeItem('currentUser');
      localStorage.removeItem('access_token');

      this.store.dispatch(
        LoginUserFromStorage({
          userName: this.exampleUser.userName,
          password: this.exampleUser.password,
        })
      );
    }
  }
  getUserLabResultCounts() {
    this.algorithmService
      .getUserLabResultCounts(this.product.barCode, this.langId)
      .subscribe((res) => {
        this.resultsCount = res;
        this.isLoading = false;
      });
  }
  getCategories() {
    this.isLoading = true;
    this.algorithmService
      .getUserSummaryStatByCat(
        this.product.barCode,
        this.langId,
        AlgoImageType.AlgoCatBigIcon
      )
      .subscribe(
        (data: AlgoUserSummary[]) => {
          this.loadingCategories = false;
          if ((data as AlgoUserSummary[]).length > 0) {
            this.categories = data.map((x) => {
              x.summary = x.summary
                .map((y) => {
                  if (y.count > 0) {
                    return y;
                  }
                })
                .filter((z) => z)
                .sort((a, b) => {
                  return a.orderBy - b.orderBy;
                });
              return x;
            });
            console.log('categories', this.categories);

            this.getUserLabResultCounts();
          }
        },
        (err) => {
          this.isLoading = false;
          this.loadingCategories = false;
        }
      );
  }
  getBarItemWidth(item: ShortResultType) {
    return (item.typeCount / +this.resultsCount.subCatCount) * 100;
  }
  getImage(image: AppImage) {
    const webPath = this.replaceWebPath(image?.webPath);
    return image ? `${webPath}/${image.fileName}` : '';
  }
  replaceWebPath(image: string) {
    return image?.replace('{picdomain}', environment.picsDomain);
  }
  openInfoDialog(number: number, color: string) {
    this.popupHandlerService.openResultsInfoDialog(color, number);
  }
  goBack() {
    this.router.navigate(['home']);
  }

  goToCategory(categoryId: number, isPremium) {
    if (!isPremium) {
      var url = 'dna-report-inner';
      if (this.exampleUser && this.exampleUser.userName == '000111000') {
        url = 'example-report-inner';
      }
      this.router.navigate([url], {
        queryParams: { categoryId: categoryId, barcode: this.product.barCode },
      });
    } else {
      if (this.isPlusAndComplete()) {
        var url = 'dna-report-inner';
        if (this.exampleUser && this.exampleUser.userName == '000111000') {
          url = 'example-report-inner';
        }
        this.router.navigate([url], {
          queryParams: {
            categoryId: categoryId,
            barcode: this.product.barCode,
          },
        });
      } else if (this.isPlusAndCounting()) {
        this.popupHandlerService.openPostUpgrateDialog();
      } else {
        this.popupHandlerService.openUpgradePreviewDialog();
      }
    }
  }

  openAllPrintExpantions() {
    this.trigerForPrintOpen = true;
  }

  get isApp() {
    return environment.isApp;
  }

  isPlusAndComplete() {
    if (!this.product) return false;
    return (
      this.product.productTypeId == ProductTypeEnum.NutrogenicsPlus &&
      (this.product.upgragePurchaseDate == null ||
        this.isUpgradeLockDone(this.product.upgragePurchaseDate))
    );
  }
  isNotPlus(isPremium: boolean) {
    if (isPremium) {
      return this.product.productTypeId == ProductTypeEnum.Nutrogenics;
    }
  }
  isPlusAndCounting() {
    if (!this.product) return false;
    return (
      this.product.productTypeId == ProductTypeEnum.NutrogenicsPlus &&
      this.product.upgragePurchaseDate != null &&
      !this.isUpgradeLockDone(this.product.upgragePurchaseDate)
    );
  }
  isRegular() {
    if (!this.product) return false;
    this.product.productTypeId == ProductTypeEnum.Nutrogenics;
  }
  isUpgradeLockDone(date: string) {
    var purchaseDate = Date.parse(date);
    var now = moment();
    var upgrdeUnlock = moment(purchaseDate).add(2, 'days');
    if (now > upgrdeUnlock) return true;
    return false;
  }
  getRemainingTime() {
    if (!this.product || !this.product.upgragePurchaseDate) return '';
    var date = Date.parse(this.product.upgragePurchaseDate);
    var upgrdeUnlock = moment(date).add(2, 'days');
    var now = moment();
    const diffTime = Math.abs(
      upgrdeUnlock.toDate().getTime() - now.toDate().getTime()
    );
    // const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    const diffHours = Math.floor(diffTime / (1000 * 60 * 60));
    const difMinutes = Math.floor((diffTime / (1000 * 60)) % 60);
    const difSeconds = Math.floor((diffTime / 1000) % 60);
    //console.log('update time' + now);
    return `${padWithZeros(diffHours, 2)}:${padWithZeros(
      difMinutes,
      2
    )}:${padWithZeros(difSeconds, 2)}`;
  }
}
