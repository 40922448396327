<div class="page-title">{{ pageTitle }}</div>

<ng-container *ngIf="!verificationComplete">
  <div class="sms-code">
    <div class="sms-code__title">
      כדי לעדכן {{ getPageTitle() }} אנא הכנס את הקוד שקיבלת
      {{ pageTitleSendTo() }}
    </div>
    <code-input
      dir="ltr"
      #codeInput
      [isCodeHidden]="false"
      [codeLength]="4"
      (codeChanged)="onCodeChanged($event)"
      (codeCompleted)="onCodeCompleted($event)"
      [initialFocusField]="0"
    >
    </code-input>
    <div style="margin-bottom: 3rem"></div>
    <button #verifyBtn [disabled]="isVerify" mat-button class="btn btn--fill">
      אישור
    </button>
    <div *ngIf="verificationError" class="verification-error">
      הקוד שהכנסת שגוי
    </div>
  </div>
  <div class="send-sms-again" *ngIf="!sendAgainComplete">
    לא קיבלת את הקוד?
    <button mat-button (click)="sendVerificationCode(true)">
      לחץ כאן לשליחה חוזרת
    </button>
  </div>
  <div *ngIf="sendAgainComplete" style="text-align: center; margin-top: 2rem">
    <flx-circle-timer
      #timer
      color="#7209b7"
      [duration]="timerDuration"
      (onComplete)="onTimerComplete()"
    ></flx-circle-timer>
  </div>
</ng-container>

<div class="form" *ngIf="verificationComplete && !changeComplete">
  <ng-container *ngIf="resetType == resetTypeEnum.password">
    <div class="form__item">
      <div class="form__item__label">סיסמה נוכחית</div>
      <mat-form-field appearance="fill" class="form__item__value">
        <input [(ngModel)]="userInput.oldValue" matInput [type]="typeOld" />
        <button
          class="toggle-password"
          type="button"
          mat-icon-button
          matSuffix
          (click)="
            typeOld === 'text' ? (typeOld = 'password') : (typeOld = 'text')
          "
        >
          <mat-icon>{{
            typeOld == "text" ? "visibility_off" : "visibility"
          }}</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="form__item">
      <div class="form__item__label">סיסמה חדשה</div>
      <mat-form-field appearance="fill" class="form__item__value">
        <input [(ngModel)]="userInput.newValue" matInput [type]="typeNew" />
        <button
          *ngIf="resetType == resetTypeEnum.password"
          class="toggle-password"
          type="button"
          mat-icon-button
          matSuffix
          (click)="
            typeNew === 'text' ? (typeNew = 'password') : (typeNew = 'text')
          "
        >
          <mat-icon>{{
            typeNew == "text" ? "visibility_off" : "visibility"
          }}</mat-icon>
        </button>
      </mat-form-field>
    </div>
  </ng-container>
  <div class="form__item">
    <div class="form__item__label">
      <div [ngSwitch]="resetType">
        <div *ngSwitchCase="resetTypeEnum.password">אימות סיסמה חדשה</div>
        <div *ngSwitchCase="resetTypeEnum.phone">מספר טלפון</div>
        <div *ngSwitchCase="resetTypeEnum.email">כתובת דוא"ל</div>
      </div>
    </div>
    <mat-form-field appearance="fill" class="form__item__value">
      <input
        (keyup)="checkForValidation()"
        [(ngModel)]="userInput.valueToSend"
        matInput
        [type]="typeValue"
        #inputValue
        (blur)="setFormError()"
      />
      <button
        *ngIf="resetType == resetTypeEnum.password"
        class="toggle-password"
        type="button"
        mat-icon-button
        matSuffix
        (click)="
          typeValue === 'text' ? (typeValue = 'password') : (typeValue = 'text')
        "
      >
        <mat-icon>{{
          typeValue == "text" ? "visibility_off" : "visibility"
        }}</mat-icon>
      </button>
    </mat-form-field>
  </div>

  <div
    class="form-error"
    *ngIf="resetType !== resetTypeEnum.password && formError"
  >
    {{ formError }}
  </div>
  <div *ngIf="resetType == resetTypeEnum.password" class="form__rules">
    <div class="error" *ngIf="passwordsError">
      {{ passwordsError | translate }}
    </div>
    <div *ngIf="!hasPasswordsIdentical()" class="error">סיסמאות לא תואמות</div>
    <span style="font-weight: bold"> ססמתך חייבת לכלול:</span>
    <div>
      <mat-icon class="check-icon" [class.valid]="is8Length()">done</mat-icon>
      <span [ngClass]="{ validGreen: is8Length() }"> לפחות 8 תווים</span>
    </div>
    <div>
      <mat-icon class="check-icon" [class.valid]="hasLetters()">done</mat-icon>
      <span [ngClass]="{ validGreen: hasLetters() }">
        אותיות באנגלית קטנות וגדולות
      </span>
    </div>
    <div>
      <mat-icon class="check-icon" [class.valid]="hasNumbers()">done</mat-icon>
      <span [ngClass]="{ validGreen: hasNumbers() }"> לפחות ספרה אחת </span>
    </div>
  </div>
  <div class="form__item">
    <button (click)="onCheck()" mat-button class="btn btn--big btn--fill">
      שמירה
    </button>
  </div>
</div>

<!-- <div class="change-complete" *ngIf="changeComplete">
  <div [ngSwitch]="resetType">
    <div class="change-complete__title" *ngSwitchCase="resetTypeEnum.password">
       <mat-icon>check_box</mat-icon>
      סיסמתך שונתה בהצלחה!
    </div>
    <div class="change-complete__title" *ngSwitchCase="resetTypeEnum.phone">
      מספר הטלפון שונה בהצלחה!
    </div>
    <div class="change-complete__title" *ngSwitchCase="resetTypeEnum.email">
      כתובת הדוא"ל שונתה בהצלחה!
    </div>
  </div>
</div> -->
