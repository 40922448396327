import { Component, OnInit, ViewChild } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { ActivatedRoute, Router } from '@angular/router';
import { CircleTimerComponent } from '@flxng/circle-timer';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { CodeInputComponent } from 'angular-code-input';
import * as sha512 from 'js-sha512';
import { Observable } from 'rxjs';
import { ErrorMessage } from 'src/app/data/ErrorMessage';
import { Product } from 'src/app/data/Product';
import { RegularUser } from 'src/app/data/RegularUser';
import { UserInfoResetInfo } from 'src/app/data/UserInfoResetInfo';
import { DisapearingTextDialogEnum } from 'src/app/Enum/DisapearingTextDialogEnum';
import { ResetType } from 'src/app/Enum/StatusesEnum';
import { LoadProduct } from 'src/app/Redux/Actions/product.actions';
import {
  LoginUserFromStorage,
  LoginUserFromUI,
} from 'src/app/Redux/Actions/regular-user.actions';
import {
  selectPatientId,
  selectProduct,
} from 'src/app/Redux/selectors/product.selectors';
import { selectUser } from 'src/app/Redux/selectors/user.selectors';
import { PersonalProfileService } from 'src/app/Services/personal-profile.service';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';
import { RegularUsersService } from 'src/app/Services/regular-users.service';

export class UserInput {
  oldValue?: string;
  newValue?: string;
  valueToSend: string;
}
@Component({
  selector: 'app-update-user-data',
  templateUrl: './update-user-data.component.html',
  styleUrls: ['./update-user-data.component.scss'],
})
export class UpdateUserDataComponent implements OnInit {
  selectRegularUser$: Observable<RegularUser> = this.store.select(selectUser);
  selectPatientId$: Observable<number> = this.store.select(selectPatientId);
  selectProduct$: Observable<Product> = this.store.select(selectProduct);

  @ViewChild('codeInput') codeInput!: CodeInputComponent;
  @ViewChild('verifyBtn') verifyBtn: MatButton;
  @ViewChild('inputValue') inputValue;
  @ViewChild('timer') timer: CircleTimerComponent;

  emailRegex =
    /^[_a-z0-9-]+(\.[_a-z0-9-]+)*(\+[a-z0-9-]+)?@[a-z0-9-]+(\.[a-z0-9-]+)*$/i;
  phoneRegex = /^[0][5][0|2|3|4|5|9]{1}[-]{0,1}[0-9]{7}$/;
  regularUserId: number;
  resetType: number;
  pageTitle: string;
  hideTopBarCheck: boolean = true;
  verificationComplete: boolean;
  verificationError: boolean;
  userInput = new UserInput();
  isVerify: boolean;
  isValid: boolean;
  formError: string;
  changeComplete: boolean;
  verificationCode: string;
  patientId: number;
  regularUser: RegularUser;
  passwordsError: string;
  typeOld: string = 'password';
  typeNew: string = 'password';
  typeValue: string = 'text';
  verificationCodeSent: boolean;
  timerDuration = 30 * 1000; // 30 seconds timer for verification code
  sendAgainComplete: boolean;

  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private router: Router,
    private personalProfileService: PersonalProfileService,
    private regularUserService: RegularUsersService,
    public translate: TranslateService,
    private popupHandlerService: PopupHandlerService
  ) {
    translate.addLangs(['he', 'en']);
    translate.setDefaultLang('he');
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      if (params) {
        this.resetType = +params['type'];
        this.pageTitle = `עדכון ${this.getPageTitle()}`;

        if (this.resetType == ResetType.password) {
          this.typeValue = 'password';
        }

        this.selectRegularUser$.subscribe((data) => {
          if (data && !this.verificationCodeSent) {
            this.regularUser = data;
            this.sendVerificationCode();
          }
        });
        this.selectPatientId$.subscribe((data) => {
          if (data) this.patientId = data;
        });
      }
    });
  }

  onTimerComplete(): void {
    this.sendAgainComplete = false;
  }

  pageTitleSendTo() {
    return this.resetType == ResetType.password ||
      this.resetType == ResetType.email
      ? `ב-SMS למספר ${this.regularUser?.phone}`
      : `במייל לכתובת ${this.regularUser?.email}`;
  }

  sendVerificationCode(sendAgain: boolean = false) {
    this.personalProfileService
      .sendMessageForReset(this.resetType, this.regularUser.regularUserId)
      .subscribe((data) => {
        this.verificationCodeSent = true;
        if (sendAgain) {
          this.sendAgainComplete = true;
          setTimeout(() => {
            this.timer.start();
          }, 200);
        }
      });
  }

  getPageTitle() {
    switch (this.resetType) {
      case ResetType.password:
        return 'סיסמה';
      case ResetType.email:
        return 'דוא"ל';
      case ResetType.phone:
        return 'טלפון';
    }
  }

  onClose(event: boolean) {
    if (event) {
      this.router.navigate(['profile']);
    }
  }

  onCheck() {
    if (this.isValid) {
      let userInfo = new UserInfoResetInfo();
      userInfo.regularUserId = this.regularUser.regularUserId;
      userInfo.newValue = this.userInput.valueToSend;
      userInfo.resetTypeId = this.resetType;
      userInfo.verificationCode = this.verificationCode;

      switch (this.resetType) {
        case ResetType.password:
          var disapearingType = DisapearingTextDialogEnum.infoChangedSMS;
          userInfo.oldValue = this.userInput.oldValue;
          break;
        case ResetType.email:
          var disapearingType = DisapearingTextDialogEnum.infoChangedMail;
          break;
        case ResetType.phone:
          var disapearingType = DisapearingTextDialogEnum.infoChangedPhone;
          break;
      }
      this.personalProfileService
        .ChangeParamWithVerificationCode(userInfo)
        .subscribe((data) => {
          if ((data as RegularUser).regularUserId) {
            var regularUser = data as RegularUser;
            this.passwordsError = '';
            this.changeComplete = true;
            this.hideTopBarCheck = true;
            if (this.resetType === ResetType.password) {
              var { userName } = regularUser;
              this.store.dispatch(
                LoginUserFromUI({ userName, password: userInfo.newValue })
              );
            } else {
              this.store.dispatch(
                new LoadProduct({ patientId: this.patientId })
              );
            }

            this.popupHandlerService.openDisapearingAlertDialog(
              disapearingType
            );
            setTimeout(() => {
              this.router.navigate(['/profile']);
            }, 2250);
          } else {
            var errorMessage = data as ErrorMessage;
            this.passwordsError = errorMessage.message;
            if (this.passwordsError == 'code not valid') {
              this.codeNotValid();
            }
          }
        });
    }
  }

  checkForValidation() {
    this.isValid = false;
    this.formError = null;
    if (this.resetType == ResetType.password) {
      if (
        this.hasPasswordsIdentical() &&
        this.hasNumbers() &&
        this.hasLetters() &&
        this.is8Length()
      ) {
        this.isValid = true;
      }
    } else if (this.resetType == ResetType.email) {
      if (this.testRegx(this.emailRegex, this.userInput.valueToSend)) {
        this.isValid = true;
      }
    } else if (this.resetType == ResetType.phone) {
      if (this.testRegx(this.phoneRegex, this.userInput.valueToSend)) {
        this.isValid = true;
      }
    }
  }

  setFormError() {
    if (!this.isValid) {
      if (this.resetType == ResetType.email) {
        this.formError = 'אנא הזינו כתובת מייל תקינה';
      } else if (this.resetType == ResetType.phone) {
        this.formError = 'אנא הזינו מספר טלפון תקין';
      }
    }
  }

  onCodeChanged(event: string) {
    //console.log('onCodeChanged: ', event);
  }

  onCodeCompleted(event: string) {
    //console.log('onCodeCompleted: ', event);
    this.isVerify = true;
    this.personalProfileService
      .validateVerificationCode(this.regularUser.regularUserId, +event)
      .subscribe((data) => {
        if (data && data.message === 'code not valid') {
          this.codeNotValid();
          return;
        }
        this.verificationCode = event;
        this.verificationComplete = true;
        this.verifyBtn._elementRef.nativeElement.click();
        this.isVerify = false;
        this.hideTopBarCheck = false;
        setTimeout(() => {
          if (
            this.resetType == ResetType.email ||
            this.resetType === ResetType.phone
          ) {
            this.inputValue.nativeElement.focus();
          }
        }, 1000);
      });
  }

  codeNotValid() {
    this.codeInput.reset();
    this.isVerify = false;
    this.verificationError = true;
  }

  sendSmsAgain() {
    this.sendVerificationCode();
  }

  hasPasswordsIdentical() {
    if (this.userInput.newValue !== this.userInput.valueToSend) {
      return false;
    }
    return true;
  }

  /* comparePasswords() {
    this.comparePasswordsError = null;
    const currentPassword = sha512.sha512(this.userInput.oldValue);
    if (this.regularUser.password !== currentPassword) {
      this.comparePasswordsError = 'סיסמה נוכחית לא נכונה';
    }
  } */

  hashedPassword(): string {
    return sha512.sha512(this.userInput.valueToSend);
  }

  is8Length() {
    return this.userInput.newValue && this.userInput.newValue.length >= 8;
  }
  hasLetters() {
    return (
      this.userInput.newValue &&
      /[a-z]/.test(this.userInput.newValue) &&
      /[A-Z]/.test(this.userInput.newValue)
    );
  }
  hasNumbers() {
    return this.userInput.newValue && /[0-9]/.test(this.userInput.newValue);
  }

  testRegx(regex, value) {
    return regex.test(value);
  }

  get resetTypeEnum() {
    return ResetType;
  }
}
