<!-- <div style="height: 1rem"></div>
 -->
<div *ngIf="loading" class="loadingOverlay">
  <mat-spinner diameter="50"></mat-spinner>
</div>
<ng-container *ngIf="!loading && (!note || !note.rating || note.rating < 1)">
  <!--<form [formGroup]="ratingForm" (ngSubmit)="submit()" class="example-form"> -->

  <div class="review-rating">
    <p class="review-rating__text">
      <strong>לקוח יקר</strong> שמחתי לתת לך שרות,<br />
      אשמח שתדרג את שביעות רצונך:
    </p>
    <div class="stars">
      <ng-container *ngFor="let star of stars; let i = index">
        <div class="stars__star" (click)="setRating(star)">
          <img
            *ngIf="star > selectedRating"
            src="../../../assets/star-empty.png"
          />
          <img
            *ngIf="star <= selectedRating"
            src="../../../assets/star-full.png"
          />
          <div *ngIf="i == 0">מאכזב</div>
          <div *ngIf="i == 4">מעולה</div>
        </div>
      </ng-container>
    </div>
    <br />
    <p class="review-rating__text">
      כדי שנוכל לשפר את השירות נשמח <br />
      שתספר לנו מדוע דירגת ככה:
    </p>
    <mat-form-field
      appearance="fill"
      dir="rtl"
      style="text-align: right; width: 100%"
    >
      <textarea
        [(ngModel)]="comment"
        matInput
        cdkTextareaAutosize
        cdkAutosizeMinRows="3"
        cdkAutosizeMaxRows="5"
      ></textarea>
    </mat-form-field>
    <p
      style="color: red"
      *ngIf="
        isSubmited &&
        selectedRating != 0 &&
        selectedRating <= 3 &&
        comment == ''
      "
    >
      *נא רשום מדוע דירגת ככה כי ממש חשוב לנו להשתפר
    </p>
    <p style="color: red" *ngIf="isSubmited && selectedRating == 0">
      *נא לבחור דירוג
    </p>
    <button class="btn btn--fill" mat-button (click)="submit()">שלח</button>
  </div>
  <!-- </form>-->
</ng-container>

<ng-container *ngIf="!loading && note && note.rating && note.rating > 0">
  <div class="review-done">
    <mat-icon class="heart">favorite</mat-icon>
    <p class="thank">תודה</p>
    <p>על שיתוף הפעולה</p>
    <img class="logo" src="../assets/logo.svg" alt="" />
  </div>
</ng-container>
