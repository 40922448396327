import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DisapearingTextDialogEnum } from 'src/app/Enum/DisapearingTextDialogEnum';

@Component({
  selector: 'app-lead-confirm-change-dialog',
  templateUrl: './lead-confirm-change-dialog.component.html',
  styleUrls: ['./lead-confirm-change-dialog.component.scss'],
})
export class LeadConfirmChangeDialogComponent implements OnInit {
  DisapearingTextDialogEnum = DisapearingTextDialogEnum;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void {
    var a = this.data;
  }
  getText() {
    var a = this.data;

    switch (this.data.type) {
      case DisapearingTextDialogEnum.agent:
        return 'The Lead Agent Was Changed';
      case DisapearingTextDialogEnum.requestAgent:
        return 'The Request Agent Was Changed';
      case DisapearingTextDialogEnum.nutAgent:
        return 'The Nutritionist Agent Was Changed';
      case DisapearingTextDialogEnum.followUpAgent:
        return 'The Follow up Agent Was Changed';
      case DisapearingTextDialogEnum.requestStatus:
        return 'The Request Status Was Changed';
      case DisapearingTextDialogEnum.requestManager:
        return 'The Request Sent to Manager';
      case DisapearingTextDialogEnum.fillAllFields:
        return 'Please Fill All Fields';
      case DisapearingTextDialogEnum.clientAlreadyExists:
        return 'A Client with The Same Phone Already Exists';
      case DisapearingTextDialogEnum.bulkAgents:
        return 'The Leads Agents Were Changed';
      case DisapearingTextDialogEnum.purchaseRejected:
        return 'The Purchase Was Rejected';
      case DisapearingTextDialogEnum.cancelRejected:
        return 'The Cancel Was Rejected';
      case DisapearingTextDialogEnum.purchaseSuccess:
        return 'purchaseSuccess';
      case DisapearingTextDialogEnum.status:
        return 'The Status Was Changed';
      case DisapearingTextDialogEnum.agentCreated:
        return 'Agent was created save Successfully';
      case DisapearingTextDialogEnum.agentSaved:
        return 'Agent has been save Successfully';
      case DisapearingTextDialogEnum.chooseFutureProduces:
        return 'Please Choose Product Types';
      case DisapearingTextDialogEnum.resetMailSent:
        return 'Reset Mail Was Sent';
      case DisapearingTextDialogEnum.genericError:
        return 'An Error Has Occured';
      case DisapearingTextDialogEnum.errorFromServer:
      case DisapearingTextDialogEnum.actionResults:
      case DisapearingTextDialogEnum.customerReview:
        return this.data.msg;
      case DisapearingTextDialogEnum.fillAddressFromAutoComlete:
        return 'Please Fill City And Street From Autocomplete';
      case DisapearingTextDialogEnum.packageSentToPatient:
        return 'Package was sent to Patient';
      case DisapearingTextDialogEnum.barcodeChanged:
        return 'Barcode was changed';
      case DisapearingTextDialogEnum.mailSentsuccessfully:
        return 'mail was Sent successfully';
      case DisapearingTextDialogEnum.newPassword:
        return 'the password was changed';
      case DisapearingTextDialogEnum.outDatedVersion:
        return `You're using an OutDated Version.please refresh when You can or You'll experience Bugs`;
      case DisapearingTextDialogEnum.lifeStyleReportComplete:
        return `Lifesyle Report Saved Successfully`;
      case DisapearingTextDialogEnum.refundSaved:
        return `Refund Form Saved Successfully`;
      case DisapearingTextDialogEnum.cancelationSaved:
        return `Cancelation Request Saved Successfully`;
      case DisapearingTextDialogEnum.duplicateCancelationRequest:
        return `Cancelation Request For this Deal Already exist`;
      case DisapearingTextDialogEnum.cancelSuccess:
        return `Deal canceled successfully`;
      case DisapearingTextDialogEnum.deletedSuccessfully:
        return `Item Deleted Successfully`;
      case DisapearingTextDialogEnum.success:
        return `Success`;
      case DisapearingTextDialogEnum.infoChangedSMS:
        return `infoChangedSMS`;
      case DisapearingTextDialogEnum.infoChangedMail:
        return `infoChangedMail`;
      case DisapearingTextDialogEnum.infoChangedPhone:
        return `infoChangedPhone`;
      default:
        return this.data.msg;
    }
  }
}
