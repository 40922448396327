import { Component, Input, OnInit } from '@angular/core';
import { Meal } from 'src/app/data/Meal';
import { MealForSite } from 'src/app/data/MealForSite';
import { MealToNutrientSite } from 'src/app/data/MealToNutrientSite';
import { ZameretNutrients } from 'src/app/Enum/StatusesEnum';

@Component({
  selector: 'app-genetic-rate',
  templateUrl: './genetic-rate.component.html',
  styleUrls: ['./genetic-rate.component.scss'],
})
export class GeneticRateComponent implements OnInit {
  @Input() nutrients: MealToNutrientSite[];
  @Input() inMeal: boolean;
  @Input() inMenuList: boolean;
  constructor() {}

  ngOnInit(): void {}

  setBarValue(amount: number, dailyAmount: number) {
    var value = (amount / dailyAmount) * 100;
    var rounded = Math.round(value);
    return rounded;
  }

  getGeneticRate(nutrients: MealToNutrientSite[]) {
    return nutrients.find((x) => x.nutrientId == ZameretNutrients.geneticRate);
  }

  getCalories(nutrients: MealToNutrientSite[]) {
    return nutrients.find((x) => x.nutrientId == ZameretNutrients.food_energy);
  }

  getFats(nutrients: MealToNutrientSite[]) {
    return nutrients.find((x) => x.nutrientId == ZameretNutrients.total_fat);
  }

  getHelbonim(nutrients: MealToNutrientSite[]) {
    return nutrients.find((x) => x.nutrientId == ZameretNutrients.protein);
  }

  getPachmimot(nutrients: MealToNutrientSite[]) {
    return nutrients.find(
      (x) => x.nutrientId == ZameretNutrients.carbohydrates
    );
  }
}
