import { Action, createAction, props } from '@ngrx/store';
import { ErrorMessage } from 'src/app/data/ErrorMessage';
import { GlobalParam } from 'src/app/data/GlobalParam';
import { RegularUser } from 'src/app/data/RegularUser';

export enum UserActionTypes {
  LoginUserFromStorage = '[Home Page] Login User From Storage',
  LoginUserFromUI = '[Home Page] Login User From UI',
  LoginUserComplete = '[Home Page] Login User Complete',
  LoginError = '[Home Page] Login Error',
  LoginServerError = '[Home Page] Server Error',
  ResetPassword = '[Home Page] Reset Password',
  ResetPasswordFromSms = '[Home Page] Reset Password From Sms',
  LoadGlobalParams = '[Home Page] Load Global Params',
  GlobalParamsLoaded = '[Home Page] Global Params Loaded',
  LogoutUser = '[Home Page] Logout User From UI',
  EmptyAction = '[Empty Action] Empty Action',
}

export class UserAction implements Action {
  type: string;

  user: RegularUser;
  // error: string;
  userName: string;
  password: string;
  errorMessage: ErrorMessage;
  redirectObj: string;
  globalParams: GlobalParam[];
}

export const LoginUserFromStorage = createAction(
  UserActionTypes.LoginUserFromStorage,
  props<{ userName: string; password: string }>()
);

export const GlobalParamsLoaded = createAction(
  UserActionTypes.GlobalParamsLoaded,
  props<{ globalParams: GlobalParam[] }>()
);
export const LoadGlobalParams = createAction(UserActionTypes.LoadGlobalParams);
/*
export class LoginUserFromStorage implements Action {
  readonly type = UserActionTypes.LoginUserFromStorage;

  constructor(readonly payload: { userName: string; password: string }) {}
}
*/
export const LoginUserFromUI = createAction(
  UserActionTypes.LoginUserFromUI,
  props<{ userName: string; password: string }>()
);
/*
export class LoginUserFromUI implements Action {
  readonly type = UserActionTypes.LoginUserFromUI;

  constructor(readonly payload: { userName: string; password: string }) {}
}*/
export const LoginUserComplete = createAction(
  UserActionTypes.LoginUserComplete,
  props<{ user: RegularUser }>()
);
/*
export class LoginUserComplete implements Action {
  readonly type = UserActionTypes.LoginUserComplete;

  constructor(readonly payload: { user: RegularUser }) {}
}
*/
export const LoginError = createAction(
  UserActionTypes.LoginError,
  props<{ errorMessage: ErrorMessage }>()
);

export const LoginServerError = createAction(
  UserActionTypes.LoginServerError,
  props<{ errorMessage: string }>()
);

/*
export class LoginError implements Action {
  readonly type = UserActionTypes.LoginError;

  constructor(readonly payload: { errorMessage: ErrorMessage }) {}
}*/
/*
export class ResetPassword implements Action {
  readonly type = UserActionTypes.ResetPassword;
  constructor(
    readonly payload: { errorMessage: ErrorMessage; redirectObj: string }
  ) {}
}
*/
export const ResetPassword = createAction(
  UserActionTypes.ResetPassword,
  props<{ errorMessage: ErrorMessage; redirectObj: string }>()
);

export const ResetPasswordFromSms = createAction(
  UserActionTypes.ResetPasswordFromSms,
  props<{ user: RegularUser }>()
);

export const LogoutUser = createAction(UserActionTypes.LogoutUser);

export const EmptyAction = createAction(UserActionTypes.EmptyAction);

export type UserActions =
  | typeof LoginUserFromStorage
  | typeof LoginUserComplete
  | typeof LoginError
  | typeof ResetPassword
  | typeof ResetPasswordFromSms;
