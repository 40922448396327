<div class="page-title">משקל נוכחי</div>
<div style="width: 90%; margin: 1rem auto 0">
  <ngx-skeleton-loader *ngIf="!patient" [theme]="{ height: '50px' }" count="5">
  </ngx-skeleton-loader>
</div>

<div class="current-weight" *ngIf="patient">
  <div class="current-weight__date">{{ date | date: "MMM d" }}</div>

  <div class="current-weight__field">
    <div class="current-weight__field__input">
      <input
        #weightInput
        [class.error]="weightError || weightServerError"
        [(ngModel)]="weightRuler"
        (click)="weightInput.select()"
        (keyup)="validateWeight()"
      /><span class="current-weight__field__input__kg">Kg</span>
    </div>
  </div>

  <app-weight-ruler
    *ngIf="patient"
    [weight]="weightRuler"
    (setWeight)="onSetWeight($event)"
  ></app-weight-ruler>

  <button
    mat-button
    [disabled]="weightError !== '' || (isLoading$ | async)"
    class="btn btn--fill btn--big mt-4"
    (click)="updateWeight()"
  >
    <div class="loading-wrapper">
      עדכן משקל
      <mat-spinner *ngIf="isLoading$ | async" diameter="20"></mat-spinner>
    </div>
  </button>
  <div
    class="current-weight__field__error"
    *ngIf="weightError || weightServerError"
  >
    {{ weightError || weightServerError }}
  </div>

  <div class="current-weight__images__container">
    <div class="current-weight__images__text">
      מומלץ להעלות תמונה בה נראה כל הגוף כדי לעקוב אחר השינוי
      <br />
      <strong>אין לך מה לדאוג, לעולם לא נשתמש בתמונות!</strong>
    </div>
    <div
      class="current-weight__images__cards"
      [ngStyle]="{ 'grid-template-columns': lastImage ? '1fr 1fr' : '' }"
      *ngIf="!croppedImage"
    >
      <div
        class="current-weight__images__cards__item current"
        [class.no-current-image]="!lastImage"
      >
        <div class="img">
          <!--  <mat-icon>{{ lastImage ? "emoji_people" : "" }}</mat-icon> -->
          <img [src]="lastImage" alt="" />
        </div>
        {{ lastImage ? "תמונה אחרונה" : "" }}
      </div>
      <div
        class="current-weight__images__cards__item add"
        [class.no-current-image]="!lastImage"
        (click)="weightInputFile.click()"
      >
        <mat-icon matRipple>add</mat-icon>

        <button
          mat-button
          class="text btn btn--center btn--small btn--underline"
          [class.no-image-text]="!lastImage"
        >
          {{ lastImage ? "הוסף תמונה נוכחית" : "לחץ להוספת תמונה" }}
        </button>
      </div>
    </div>
    <input
      style="display: none"
      #weightInputFile
      type="file"
      accept="image/*"
      (change)="fileChangeEvent($event)"
    />
    <div style="max-width: 500px; margin: 0 auto">
      <image-cropper
        class="mt-2"
        [imageChangedEvent]="imageChangedEvent"
        [maintainAspectRatio]="true"
        [containWithinAspectRatio]="false"
        [onlyScaleDown]="true"
        [roundCropper]="false"
        [transform]="{}"
        [aspectRatio]="3 / 4"
        [style.display]="showCropper ? null : 'none'"
        alignImage="center"
        canvasRotation="0"
        format="png"
        resizeToWidth="256"
        cropperMinWidth="128"
        (imageCropped)="imageCropped($event)"
        (imageLoaded)="imageLoaded()"
        (cropperReady)="cropperReady($event)"
        (loadImageFailed)="loadImageFailed()"
      ></image-cropper>
    </div>

    <div *ngIf="croppedImage" class="text-center mt-2">
      <button
        mat-button
        class="btn btn--small btn--fill btn--center"
        (click)="uploadImage()"
      >
        שמירה
      </button>
      <span class="ml-2"></span>
      <button
        mat-button
        class="btn btn--small btn--outline btn--center"
        (click)="onClose()"
      >
        ביטול
      </button>
    </div>
  </div>
</div>
