<mat-dialog-content>
  <div class="video-container" *ngIf="data">
    <div #videoPlayer class="video-container__player">
      <cloudflare-stream
        [poster]="data.posterUrl ? data.posterUrl : ''"
        [controls]="true"
        [src]="data.videoUrl"
        [autoplay]="true"
        height="400px"
      ></cloudflare-stream>
    </div>
    <div style="padding: 0 2rem">
      <div class="video-container__title">
        {{ data.title }}
      </div>
      <div class="video-container__meta">
        עורך: {{ data.agentName }} | פורסם:
        {{ data.createDate | date: "dd/MM/yyy" }} |
        {{ getDuration(data.duration) }}
        {{ timeText(data.duration) }}
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close>סגירה</button>
</mat-dialog-actions>
