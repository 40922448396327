import { Patient } from './Patients';
import { Status } from './Status';

//import { LifeStyleDisease } from './LifeStyleDisease';
//import { LifeStyleEatingPatern } from './LifeStyleEatingPatern';
import { LifeStyleExercise } from './LifestyleExercise.model';

export class LifeStyleReportLocalOld {
  lifeStyleReportId: number;
  patientId: number;
  patient: Patient;
  fullName: string;
  email: string;
  socialSecurityNum: string;
  sexId: number;
  sex: Status;
  age: number;
  height: number;
  weight: number;
  nutritionTypeId: any[];
  nutritionTypes: any[];
  otherNutritionTypes: string;
  sensitivityToFood: string;
  foodToAvoid: string;
  //digestionProblemsId: number;
  digestionProblems: any[];
  otherDigestionProblems: string;
  waterNumGlassesPerDay: number;
  sweetNumGlassesPerDay: number;
  dietNumGlassesPerDay: number;
  coffeePerDay: number;
  teaPerDay: number;
  alcoholPerDay: number;
  eatingHabitsMorning: string;
  eatingHabitsMiddle1: string;
  eatingHabitsLunch: string;
  eatingHabitsMiddle2: string;
  eatingHabitsSupper: string;
  ifActivities: string;
  isTakingDrugs: string;
  drugsTakenText: string;
  isSmoking: string;
  smokingTextNum: string;
  smokingTextYears: string;
  smokingTextQuit: string;
  isExercising: boolean;
  lifeStyleExercises: LifeStyleExercise[];
  relaxingActivity: string;
  sleepHours: string;
  areYouInStress: string;
  whatToAprrove: string;
  lifeStyleDisease: any;
  lifeStyleEatingPaterns: any;
  healthStatus: string;
}
