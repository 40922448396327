export const environment = {
  version: 7,
  production: false,
  baseUrl: 'https://webapitest.mygenes.co.il/api/',
  isApp: false,
  phase: 2,
  chatRoomPre: '_dev_',
  disableServiceRequest: false,
  picsDomain: 'https://picstest.mygenes.co.il',
  firebaseConfig: {
    apiKey: 'AIzaSyB_CjdzAqQjHCZWq_TS2W-wMUJMKOpfKsI',
    authDomain: 'my-genes-dev.firebaseapp.com',
    projectId: 'my-genes-dev',
    storageBucket: 'my-genes-dev.appspot.com',
    messagingSenderId: '624918560266',
    appId: '1:624918560266:web:09513d891a48a816c529f3',
    measurementId: 'G-YJHRQB02KF',
  },
  recaptcha: {
    siteKey: '6LdcGxgeAAAAAGpKIQo4ZFP4aSzVksGrlEIq1BWh',
    secretKey: '6LdcGxgeAAAAAKeusXoc9kCr5h3XDGtw-DdtXGxI',
  },
  /*
 firebaseConfig: {
  apiKey: 'AIzaSyDdIm8PGQJS45Sr7_hYAUDFrJrNvQ8HSZk',
  authDomain: 'mygeneschat.firebaseapp.com',
  databaseURL: 'https://mygeneschat.firebaseio.com',
  projectId: 'mygeneschat',
  storageBucket: 'mygeneschat.appspot.com',
  messagingSenderId: '1087373424417',
  appId: '1:1087373424417:web:379dcd7919a9584a243060',
  measurementId: 'G-VFY8K5FF83',
},
*/
};
