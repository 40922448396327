import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlgoBulletLink } from 'src/app/data/AlgoBulletLink';
import { AlgoBulletLinksService } from 'src/app/Services/algo-bullet-links-service.service';

@Component({
  selector: 'app-algo-link-dialog',
  templateUrl: './algo-link-dialog.component.html',
  styleUrls: ['./algo-link-dialog.component.scss'],
})
export class AlgoLinkDialogComponent implements OnInit {
  algoLink: AlgoBulletLink;
  constructor(
    public dialogRef: MatDialogRef<AlgoLinkDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private algoBolletLinksService: AlgoBulletLinksService
  ) {}

  ngOnInit(): void {
    this.algoBolletLinksService
      .getAlgoBulletLink(this.data.linkId, this.data.langId)
      .subscribe((data) => {
        this.algoLink = data;
      });
  }
}
