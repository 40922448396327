import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ServiceRequestService } from 'src/app/Services/service-request.service';

@Component({
  selector: 'app-close-service-request',
  templateUrl: './close-service-request.component.html',
  styleUrls: ['./close-service-request.component.scss'],
})
export class CloseServiceRequestComponent implements OnInit {
  serviceRequestId: number;

  constructor(
    private route: ActivatedRoute,
    private serviceRequestService: ServiceRequestService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      if (params['requestId']) {
        this.serviceRequestId = params['requestId'];
        this.serviceRequestService
          .closeServiceRequest(this.serviceRequestId)
          .subscribe((res) => {});
      }
    });
  }
}
