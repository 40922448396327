<div class="register">
  <!-- <div class="register__logo">
    <img src="../../../assets/logo.svg" alt="" />
  </div> -->
  <div class="register__title">{{ setTitle() }}</div>
  <form
    class="form"
    [formGroup]="registerForm"
    (ngSubmit)="sendCaptchaToVerify('register')"
    *ngIf="formState == 1"
  >
    <div class="form__item">
      <div class="form__item__label">תעודת זהות</div>
      <div class="form__item__input">
        <mat-form-field appearance="fill">
          <input formControlName="idNum" matInput type="text" />
        </mat-form-field>
        <mat-error
          *ngIf="idNum.errors && idNum.errors.required && registerForm.dirty"
        >
          אנא הזן תעודת זהות
        </mat-error>
      </div>
    </div>
    <div class="form__item">
      <div class="form__item__label">מספר טלפון</div>
      <div class="form__item__input">
        <mat-form-field appearance="fill">
          <input formControlName="phone" mask="0000000000" matInput />
        </mat-form-field>
        <mat-error
          *ngIf="phone.errors && phone.errors.required && registerForm.dirty"
        >
          אנא הזן מספר טלפון
        </mat-error>
      </div>
    </div>
    <div class="form__item">
      <button
        [disabled]="isLoading"
        type="submit"
        class="btn btn--fill btn--big"
        mat-button
      >
        הירשם
      </button>
    </div>
    <mat-error class="form-text mt-2" *ngIf="smsError">
      <span class="error"
        ><strong
          >מספר תעודת הזהות שהזנת אינו תואם למספר הנייד שהוזן.</strong
        ></span
      ><br />
      נא צור קשר עם מוקד השירות 03-3095244 <br />
    </mat-error>
    <div class="text-center mt-4">
      לקוח קיים?&nbsp;
      <button
        type="button"
        class="btn btn--underline btn--small btn--primary btn--center p-0 fw-light"
        mat-button
        routerLink="/login"
      >
        היכנס כאן
      </button>
    </div>
  </form>
  <form [formGroup]="smsCodeForm" *ngIf="formState == 2">
    <div class="form-text mb-3">להמשך הרשמה אנא הזו את הקוד</div>
    <code-input
      dir="ltr"
      #codeInput
      [isCodeHidden]="false"
      [codeLength]="4"
      (codeChanged)="onCodeChanged($event)"
      (codeCompleted)="onCodeCompleted($event)"
      [initialFocusField]="0"
    >
    </code-input>
    <div *ngIf="smsResponseError" class="form-text mt-1">
      <span class="error">
        <strong>קוד האימות לא תקין</strong>
      </span>
    </div>
    <div *ngIf="!secondSendSms" class="form-text mt-4">
      לא קיבלת את הקוד?&nbsp;
      <button mat-button class="p-0" (click)="doRegistration(2)">
        לחץ כאן לשליחה חוזרת
      </button>
    </div>
    <div *ngIf="secondSendSms" class="form-text mt-4">
      אם עדיין לא קיבלת את הקוד נא צור קשר עם מוקד השירות 03-3095244
    </div>
  </form>
  <form
    [formGroup]="resetPassForm"
    (ngSubmit)="sendCaptchaToVerify('reset')"
    *ngIf="formState == 3"
    class="form"
  >
    <div class="form__item">
      <div class="form__item__label">בחר סיסמה</div>
      <div class="form__item__input">
        <mat-form-field appearance="fill">
          <input
            matInput
            [type]="hide ? 'password' : 'text'"
            (keyup)="checkPasswordMatchAndConditions($event)"
            formControlName="password"
            #passwordInput="matInput"
            placeholder="הכנס סיסמה חדשה"
          />
          <button
            class="toggle-password"
            type="button"
            mat-icon-button
            matSuffix
            (click)="hide = !hide"
          >
            <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
          </button>
        </mat-form-field>
        <mat-error
          *ngIf="isSubmited && password.errors && password.errors?.required"
        >
          אנא הזן ססמא
        </mat-error>
        <mat-error
          *ngIf="isSubmited && password.errors && password.errors?.minlength"
        >
          אנא הזן ססמא של לפחות 8 ספרות
        </mat-error>
        <mat-error *ngIf="passwordNotValid">
          ססמתך לא עומדת בתנאים הדרושים
        </mat-error>
      </div>
    </div>

    <div class="form__item">
      <div class="form__item__label">אמת את סיסמתך</div>
      <div class="form__item__input">
        <mat-form-field appearance="fill">
          <input
            matInput
            [type]="hideVerify ? 'password' : 'text'"
            (keyup)="checkPasswordMatchAndConditions($event)"
            formControlName="confirmPassword"
            placeholder="הכנס שוב את אותה סיסמה"
          /><button
            class="toggle-password"
            type="button"
            mat-icon-button
            matSuffix
            (click)="hideVerify = !hideVerify"
          >
            <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
          </button>
        </mat-form-field>

        <mat-error
          *ngIf="
            isSubmited &&
            confirmPassword &&
            confirmPassword.errors &&
            confirmPassword.errors.required
          "
        >
          אנא הזן אימות ססמא
        </mat-error>
        <mat-error
          *ngIf="
            isSubmited &&
            password &&
            password.errors &&
            confirmPassword &&
            confirmPassword.errors?.minlength
          "
        >
          אנא הזן ססמא של לפחות 6 ספרות
        </mat-error>
      </div>
      <mat-error *ngIf="isSubmited && confirmPasswordNotMatch">
        ססמאות לא תואמות
      </mat-error>
    </div>

    <div
      style="
        color: #707070;
        text-align: right;
        font-size: 12px;
        font-weight: lighter;
        margin-bottom: 20px;
      "
    >
      <span style="color: #1b3f4e; font-weight: bold"> ססמתך חייבת לכלול:</span>
      <br />
      <div>
        <mat-icon class="check-icon" [class.valid]="is8Length()">done</mat-icon>
        <span [ngClass]="{ validGreen: is8Length() }"> לפחות 8 תווים</span>
      </div>
      <div>
        <mat-icon class="check-icon" [class.valid]="hasLetters()"
          >done</mat-icon
        >
        <span [ngClass]="{ validGreen: hasLetters() }">
          אותיות באנגלית קטנות וגדולות
        </span>
      </div>
      <div>
        <mat-icon class="check-icon" [class.valid]="hasNumbers()"
          >done</mat-icon
        >
        <span [ngClass]="{ validGreen: hasNumbers() }"> לפחות ספרה אחת </span>
      </div>
    </div>
    <div class="form-text mt-1" *ngIf="resetPasswordError == true">
      <span class="error"><strong>אירעה שגיאה אנא נסה שנית</strong></span>
    </div>
    <button
      [disabled]="confirmPasswordNotMatch"
      mat-button
      class="btn btn--fill btn--big"
    >
      הבא
    </button>

    <!--   <mat-list style="margin-top: 20px">
      <div class="form-text text-right w-270 mb-1">
        <strong>בחר סיסמה</strong>
      </div>
      <mat-list-item style="display: flex; justify-content: center">
        <mat-form-field
          class="example-full-width login-field"
          style="width: 270px"
        >
          <input
            #passwordInput="matInput"
            style="border: 1px solid black; height: 40px; line-height: 40px"
            placeholder="ססמא"
            class="edit-field login-field passwordInput"
            matInput
            (keyup)="checkPasswordMatch($event)"
            formControlName="password"
            type="password"
          />
          <mat-error
            *ngIf="isSubmited && password.errors && password.errors?.required"
          >
            אנא הזן ססמא
          </mat-error>
          <mat-error
            *ngIf="isSubmited && password.errors && password.errors?.minlength"
          >
            אנא הזן ססמא של לפחות 8 ספרות
          </mat-error>
        </mat-form-field>
      </mat-list-item>
      <br />

      <div class="form-text text-right w-270 mb-1">
        <strong>אמת את סיסמתך</strong>
      </div>
      <mat-list-item style="display: flex; justify-content: center">
        <mat-form-field
          class="example-full-width login-field"
          style="width: 270px"
        >
          <input
            style="border: 1px solid black; height: 40px; line-height: 40px"
            placeholder="אימות ססמא"
            class="edit-field login-field passwordInput"
            (keyup)="checkPasswordMatch($event)"
            matInput
            formControlName="confirmPassword"
            type="password"
          />
          <mat-error
            *ngIf="
              isSubmited &&
              confirmPassword &&
              confirmPassword.errors &&
              confirmPassword.errors.required
            "
          >
            אנא הזן אימות ססמא
          </mat-error>
          <mat-error
            *ngIf="
              isSubmited &&
              password &&
              password.errors &&
              confirmPassword &&
              confirmPassword.errors?.minlength
            "
          >
            אנא הזן ססמא של לפחות 6 ספרות
          </mat-error>
        </mat-form-field>
      </mat-list-item>

      <div
        class="form-text w-270 mt-1"
        *ngIf="isSubmited && confirmPasswordNotMatch == true"
      >
        <span class="error"><strong>ססמאות לא תואמות</strong></span>
      </div>
      <div class="form-text w-270 mt-1" *ngIf="resetPasswordError == true">
        <span class="error"><strong>אירעה שגיאה אנא נסה שנית</strong></span>
      </div>
      <button
        [disabled]="confirmPasswordNotMatch"
        mat-raised-button
        class="login-btn"
      >
        המשך
      </button>
    </mat-list> -->
  </form>
</div>

<!-- <div class="flex-container">
  <mat-card class="flex-item">
    <div *ngIf="isLoading" class="loadingOverlay">
      <mat-spinner diameter="50"></mat-spinner>
    </div>
    <mat-card-title class="card-title">
      {{ setTitle() }}
    </mat-card-title>
    <div>
      <form
        [formGroup]="registerForm"
        (ngSubmit)="doRegistration()"
        class="example-form"
        *ngIf="formState == 1"
      >
        <mat-list style="margin-top: 20px">
          <div class="form-text text-right w-270 mb-1">
            <strong>אנא הזן מספר תעודת זהות</strong>
          </div>
          <mat-list-item style="display: flex; justify-content: center">
            <mat-form-field
              class="example-full-width login-field"
              style="width: 270px"
            >
              <input
                style="border: 1px solid black; height: 40px; line-height: 40px"
                placeholder="תעודת זהות"
                class="edit-field login-field idInput"
                matInput
                formControlName="idNum"
                mask="000000000"
              />
              <mat-error *ngIf="idNum.errors && idNum.errors.required">
                אנא הזן תעודת זהות
              </mat-error>
            </mat-form-field>
          </mat-list-item>
          <br />
          <div class="form-text text-right w-270 mb-1">
            <strong>אנא הזן מס' טלפון נייד</strong>
          </div>
          <mat-list-item style="display: flex; justify-content: center">
            <mat-form-field
              class="example-full-width login-field"
              style="width: 270px"
            >
              <input
                type="phone"
                matInput
                style="border: 1px solid black; height: 40px; line-height: 40px"
                placeholder="מספר טלפון"
                class="edit-field login-field phoneInput"
                matInput
                formControlName="phone"
                mask="0000000000"
              />
              <mat-error *ngIf="phone.errors && phone.errors.required">
                אנא הזן מספר טלפון
              </mat-error>
            </mat-form-field>
          </mat-list-item>
          <div class="form-text w-270 text-right" *ngIf="smsError">
            <span class="error"
              ><strong
                >מספר תעודת הזהות שהזנת אינו תואם למספר הנייד שהוזן.</strong
              ></span
            ><br />
            נא צור קשר עם מוקד השירות 03-3095244 <br />
            <a href="#">או לחץ כאן לפתיחת פנייה מכוונת</a>
          </div>
          <div>
            <button mat-button-raised class="login-btn">שלח/י</button>
          </div>
          <div>
            <button
              mat-button
              type="button"
              style="color: #d3247e"
              routerLink="/login"
              class="mt-2"
            >
              לקוח רשום? התחבר עכשיו!
            </button>
          </div>
        </mat-list>
      </form>
      <form
        [formGroup]="smsCodeForm"
        (ngSubmit)="handelSmsResponse()"
        class="example-form"
        *ngIf="formState == 2"
      >
        <mat-list style="margin-top: 20px">
          <div class="form-text mb-3">
            <strong>שלחנו קוד אימות למספר הטלפון הנייד שלך.</strong><br />
            להמשך הרשמה אנא הזו את הקוד
          </div>
          <mat-list-item style="display: flex; justify-content: center">
            <mat-form-field
              class="example-full-width login-field verificationField"
            >
              <input
                #verificationCode
                class="verificationInput"
                formControlName="smsCode"
                matInput
                mask="0 0 0 0"
                autofocus
              />
              <div class="verificationSpans">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </div>
              <mat-error *ngIf="smsCode.errors && smsCode.errors.required">
                אנא הזן קוד אימות
              </mat-error>
            </mat-form-field>
          </mat-list-item>
          <div *ngIf="smsResponseError" class="form-text mt-1">
            <span class="error">
              <strong>קוד האימות לא תקין</strong>
            </span>
          </div>
          <div
            *ngIf="!secondSendSms"
            class="form-text"
            style="padding-top: 1.25em"
          >
            לא קיבלת לחץ
            <a href="javascript:void(0);" (click)="doRegistration(2)">כאן</a>
            לשליחה חוזרת
          </div>
          <div *ngIf="secondSendSms" class="form-text">
            <strong>עדיין לא קיבלת </strong> <a href="">לחץ כאן לפתיחת פנייה</a>
            <br />
            למוקד שירות הלקוחות
          </div>
          <button mat-raised-button class="login-btn">המשך</button>
        </mat-list>
      </form>
      <form
        [formGroup]="resetPassForm"
        (ngSubmit)="handelPasswordReset()"
        class="example-form"
        *ngIf="formState == 3"
      >
        <mat-list style="margin-top: 20px">
          <div class="form-text text-right w-270 mb-1">
            <strong>אנא בחר סיסמה</strong>
          </div>
          <mat-list-item style="display: flex; justify-content: center">
            <mat-form-field
              class="example-full-width login-field"
              style="width: 270px"
            >
              <input
                #passwordInput="matInput"
                style="border: 1px solid black; height: 40px; line-height: 40px"
                placeholder="ססמא"
                class="edit-field login-field passwordInput"
                matInput
                (keyup)="checkPasswordMatch($event)"
                formControlName="password"
                type="password"
              />
              <mat-error
                *ngIf="
                  isSubmited && password.errors && password.errors?.required
                "
              >
                אנא הזן ססמא
              </mat-error>
              <mat-error
                *ngIf="
                  isSubmited && password.errors && password.errors?.minlength
                "
              >
                אנא הזן ססמא של לפחות 8 ספרות
              </mat-error>
            </mat-form-field>
          </mat-list-item>
          <br />

          <div
            style="
              color: #707070;
              text-align: right;
              margin-right: 100px;
              font-size: 12px;
              font-weight: lighter;
              margin-bottom: 10px;
            "
          >
            <span style="color: #1b3f4e; font-weight: bold">
              ססמתך חייבת לכלול:</span
            >
            <br />
            <img
              src="../../../../assets/validationV.svg"
              class="v_icon"
              *ngIf="is8Length()"
            />
            <img
              src="../../../../assets/validationV_1.svg"
              class="v_icon"
              *ngIf="!is8Length()"
            />
            <span [ngClass]="{ validGreen: is8Length() }"> לפחות 8 תווים</span>
            <br />
            <img
              src="../../../../assets/validationV.svg"
              class="v_icon"
              *ngIf="hasLetters()"
            />
            <img
              src="../../../../assets/validationV_1.svg"
              class="v_icon"
              *ngIf="!hasLetters()"
            />
            <span [ngClass]="{ validGreen: hasLetters() }">
              אותיות באנגלית קטנות וגדולות
            </span>
            <br />
            <img
              src="../../../../assets/validationV.svg"
              class="v_icon"
              *ngIf="hasNumbers()"
            />
            <img
              src="../../../../assets/validationV_1.svg"
              class="v_icon"
              *ngIf="!hasNumbers()"
            />
            <span [ngClass]="{ validGreen: hasNumbers() }">
              לפחות ספרה אחת
            </span>
            <br />
          </div>

          <div class="form-text text-right w-270 mb-1">
            <strong>אמת את סיסמתך</strong>
          </div>
          <mat-list-item style="display: flex; justify-content: center">
            <mat-form-field
              class="example-full-width login-field"
              style="width: 270px"
            >
              <input
                style="border: 1px solid black; height: 40px; line-height: 40px"
                placeholder="אימות ססמא"
                class="edit-field login-field passwordInput"
                (keyup)="checkPasswordMatch($event)"
                matInput
                formControlName="confirmPassword"
                type="password"
              />
              <mat-error
                *ngIf="
                  isSubmited &&
                  confirmPassword &&
                  confirmPassword.errors &&
                  confirmPassword.errors.required
                "
              >
                אנא הזן אימות ססמא
              </mat-error>
              <mat-error
                *ngIf="
                  isSubmited &&
                  password &&
                  password.errors &&
                  confirmPassword &&
                  confirmPassword.errors?.minlength
                "
              >
                אנא הזן ססמא של לפחות 6 ספרות
              </mat-error>
            </mat-form-field>
          </mat-list-item>
          <div
            class="form-text w-270 mt-1"
            *ngIf="isSubmited && confirmPasswordNotMatch == true"
          >
            <span class="error"><strong>ססמאות לא תואמות</strong></span>
          </div>
          <div class="form-text w-270 mt-1" *ngIf="resetPasswordError == true">
            <span class="error"><strong>אירעה שגיאה אנא נסה שנית</strong></span>
          </div>
          <button
            [disabled]="confirmPasswordNotMatch"
            mat-raised-button
            class="login-btn"
          >
            המשך
          </button>
        </mat-list>
      </form>
    </div>
  </mat-card>
</div> -->

<div *ngIf="!isChrome() && !isApp" class="chrome-recomendation">
  Google Chrome הגלישה מומלצת דרך דפדפן
</div>
