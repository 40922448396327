import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ErrorMessage } from '../data/ErrorMessage';
import { GoalToPatient } from '../data/GoalToPatient';
import { Product } from '../data/Product';
import { UserGoal } from '../data/UserGoal';
import { caculateWeightData } from '../Utils/userHelper';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  constructor(private http: HttpClient) {}
  getByPatientId(patientId): Observable<Product> {
    return this.http
      .get<Product | ErrorMessage>(
        `${environment.baseUrl}Products/ByPatient/${patientId}`
      )
      .pipe(
        map((data) => {
          if ((data as Product).productId) {
            var product = data as Product;
            if (product.patient) {
              product.patient = caculateWeightData(product.patient);
            }
            return data as Product;
          } else {
            throw (data as ErrorMessage).message;
          }
        })
      );
  }
  getByPatientIdForApp(patientId: number, token: string): Observable<Product> {
    return this.http
      .get<Product | ErrorMessage>(
        `${environment.baseUrl}Products/ByPatient/${patientId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .pipe(
        map((data) => {
          if ((data as Product).productId) {
            var product = data as Product;
            if (product.patient) {
              product.patient = caculateWeightData(product.patient);
            }
            return data as Product;
          } else {
            throw (data as ErrorMessage).message;
          }
        })
      );
  }
  getByUserId(userId) {
    return this.http.get<Product>(
      `${environment.baseUrl}Products/ByUser/${userId}`
    );
  }
  getByProductId(productId) {
    return this.http.get<Product>(
      `${environment.baseUrl}Products/SingleProduct/${productId}`
    );
  }

  put(product: Product) {
    return this.http.put<Product>(
      `${environment.baseUrl}Products/${product.productId}`,
      product
    );
  }
  /*
  post(product: Product) {
    return this.http.post<Product>(`${environment.baseUrl}Products/`, product);
  }
  */
  insertBarcode(product: Product) {
    return this.http.put<Product | ErrorMessage>(
      `${environment.baseUrl}Products/InsertBarcode/${product.productId}`,
      product
    );
  }

  orderDelivery(product: Product) {
    return this.http.put<Product>(
      `${environment.baseUrl}Products/OrderDelivery/${product.productId}`,
      product
    );
  }
  generateReportForApp(productId) {
    return this.http.get<any>(
      `${environment.baseUrl}Products/GenerateReportForApp/${productId}`
    );
  }
  generateReport(productId: number) {
    //return `${environment.baseUrl}Products/GenerateReport/${productId}/${token}`;
    return `${environment.baseUrl}Products/GenerateReport/${productId}`;
  }
  setLifeStyleNewComplete(productId: number, value: boolean) {
    return this.http.get<Product>(
      `${environment.baseUrl}Products/setLifeStyleNewComplete/${productId}/${value}`
    );
  }
  setLifeStyleNewCompleteForApp(productId: number, value: boolean, token) {
    return this.http.get<Product>(
      `${environment.baseUrl}Products/setLifeStyleNewComplete/${productId}/${value}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  requestBloodPrescriptions(productId: number) {
    return this.http.get<Product>(
      `${environment.baseUrl}Products/RequestBloodPrescriptions/${productId}`
    );
  }

  UploadWeightImage(productId: number, image: any) {
    return this.http.post<{ filePath: string }>(
      `${environment.baseUrl}Products/UploadWeightImageBase64/${productId}`,
      image
    );
  }

  deleteGeneticData(productId: number) {
    return this.http.get<Product>(
      `${environment.baseUrl}Products/DeleteGeneticData/${productId}`
    );
  }
  getUserGoals() {
    return this.http.get<UserGoal[]>(`${environment.baseUrl}Products/GetGoals`);
  }

  setUserGoals(goals: GoalToPatient[], productId: number) {
    return this.http.post<any>(
      `${environment.baseUrl}Products/SetGoals/${productId}`,
      goals
    );
  }
  /*
  changeStatusId(productId:number,statusId:number)
    {
      return this.http.get<any>(
        `${environment.baseUrl}Products/OrderDelivery/${productId}/${statusId}`,
      );
    }
    */
}
