<div class="example-header">
  <!-- <button
    mat-icon-button
    class="example-double-arrow"
    (click)="previousClicked('year')"
  >
    <mat-icon>keyboard_arrow_left</mat-icon>
    <mat-icon>keyboard_arrow_left</mat-icon>
  </button> -->
  <button
    class="example-arrow"
    mat-icon-button
    (click)="previousClicked('month')"
    [disabled]="!canGoPrevious()"
  >
    <mat-icon>keyboard_arrow_right</mat-icon>
  </button>
  <span class="example-header-label">{{ periodLabel }}</span>
  <button
    class="example-arrow"
    mat-icon-button
    (click)="nextClicked('month')"
    [disabled]="!canGoNext()"
  >
    <mat-icon>keyboard_arrow_left</mat-icon>
  </button>
  <!-- <button
    mat-icon-button
    class="example-double-arrow"
    (click)="nextClicked('year')"
  >
    <mat-icon>keyboard_arrow_right</mat-icon>
    <mat-icon>keyboard_arrow_right</mat-icon>
  </button> -->
</div>
