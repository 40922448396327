<div [ngSwitch]="mode">
  <div class="sider" *ngSwitchCase="'slider'">
    <swiper
      [config]="swiperConfig"
      (swiper)="onSwiper($event)"
      (slideChange)="onSlideChange()"
    >
      <ng-template
        swiperSlide
        *ngFor="let item of recomendations; let i = index"
        ><div class="item">
          <div class="text">
            <div
              class="title"
              [ngStyle]="{
                color: '#' + item.algoBulletCategoryColor
              }"
            >
              {{ item.algoBulletTitle }}
            </div>
            <div class="desc rec">{{ item.algoBulletDescription }}</div>
            <br />
            <div class="link">
              <a [routerLink]="['/report-recomendations']"
                >לחץ כאן לצפייה בדוח המלא</a
              >
              <!--  <button mat-button [routerLink]="['/report-recomendations']">
                עבור לדוח המלא
                <mat-icon>keyboard_backspace</mat-icon>
              </button> -->
            </div>
          </div>
          <div class="image">
            <div
              class="image-bg"
              [style.background-color]="
                getImageBg(item.algoBulletCategoryColor)
              "
            >
              <img [src]="item.algoBulletImageUrl" alt="" />
            </div>
          </div>
        </div>
      </ng-template>
    </swiper>
  </div>
  <div *ngSwitchCase="'lifestyleForm'">
    <div class="item">
      <div class="text">
        <div class="title">
          <mat-icon>info</mat-icon>
          שאלון אורח חיים
        </div>
        <div class="desc">
          בדיקות דם כבר יש לך, אבל שמנו לב שחסר לך, שאלון אורח חיים. להזכירך, על
          מנת לצפות בקלקולטור חובה למלא שאלון אורח חיים .
        </div>
        <div class="link">
          <a (click)="goToLifestyleForm()">לחץ כאן</a> למעבר לשאלון אורח חיים
        </div>
      </div>
      <div class="image">
        <div class="image-bg">
          <img src="../../../assets/big-form.svg" alt="" />
        </div>
      </div>
    </div>
  </div>
  <div *ngSwitchCase="'bloodTest'">
    <div class="item">
      <div class="text">
        <div class="title"><mat-icon>info</mat-icon>בדיקות דם</div>
        <div class="desc">
          שאלון אורח חיים כבר יש לך, אבל שמנו לב שחסר לך תוצאות בדיקות דם.
          להזכירך, על מנת לצפות בקלקולטור נחוצה בדיקת דם מחצי השנה האחרונה.
        </div>
        <div class="link">
          <a (click)="goToBloodTest()">לחץ כאן</a> לעמוד בדיקות הדם
        </div>
      </div>
      <div class="image">
        <div class="image-bg">
          <img src="../../../assets/big-tube.svg" alt="" />
        </div>
      </div>
    </div>
  </div>
  <div *ngSwitchCase="'lifestyleFormAndBloodTest'">
    <div class="item">
      <div class="text">
        <div class="title">
          <mat-icon>info</mat-icon>שאלון אורח חיים ובדיקות דם
        </div>
        <div class="desc">
          על מנת שנוכל להפיק לך דוח המלצות אנו זקוקים לתוצאות בדיקות דם מחצי
          השנה האחרונה ולשאלון אורח חיים.
        </div>
        <div class="link">
          <a (click)="goToBloodTest()">לחץ כאן</a> לעמוד בדיקות הדם
        </div>
        <div class="link">
          <a (click)="goToLifestyleForm()">לחץ כאן</a> למעבר לשאלון אורח חיים
        </div>
      </div>
      <div class="image">
        <div class="image-bg">
          <img src="../../../assets/big-from-tube.svg" alt="" />
        </div>
      </div>
    </div>
  </div>
</div>
