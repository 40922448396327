<div style="text-align: center">
  <div>אם לא הועברת אוטומטית לאפליקציה, לחץ על הקישור הבא:</div>
  <a
    style="margin-top: 1rem; display: block"
    *ngIf="isAndroid"
    href="https://play.google.com/store/apps/details?id=com.mygenes.myapp"
    target="_blank"
  >
    פתח אפליקציה באנדרואיד
  </a>
  <a
    style="margin-top: 1rem; display: block"
    *ngIf="isIos"
    href="https://apps.apple.com/us/app/mygenes/id6443790685"
    target="_blank"
  >
    פתח אפליקציה באייפון
  </a>
</div>
