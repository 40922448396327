import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { MatSliderChange } from '@angular/material/slider';

@Component({
  selector: 'app-snap-slider',
  templateUrl: './snap-slider.component.html',
  styleUrls: ['./snap-slider.component.scss'],
})
export class SnapSliderComponent implements OnInit {
  @Output('valueChange') valueChange: EventEmitter<number> =
    new EventEmitter<number>();
  @Input() value: number;
  constructor() {}

  ngOnInit(): void {}

  onSliderChange(event: MatSliderChange) {
    this.valueChange.emit(event.value);
  }
}
