import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectProductId } from 'src/app/Redux/selectors/product.selectors';
import { ProductService } from 'src/app/Services/product.service';

@Component({
  selector: 'app-has-not-blood-test',
  templateUrl: './has-not-blood-test.component.html',
  styleUrls: ['./has-not-blood-test.component.scss'],
})
export class HasNotBloodTestComponent implements OnInit {
  mailSent: boolean = false;
  productId: number;
  selectProductId$: Observable<number> = this.store.select(selectProductId);
  constructor(
    public dialogRef: MatDialogRef<HasNotBloodTestComponent>,
    private productsService: ProductService,
    private store: Store
  ) {
    this.selectProductId$.subscribe((data) => {
      if (data) {
        this.productId = data;
      }
    });
  }

  ngOnInit(): void {
    this.sendMail();
  }

  sendMail() {
    this.productsService
      .requestBloodPrescriptions(this.productId)
      .subscribe((data) => {
        this.mailSent = true;
      });
  }
}
