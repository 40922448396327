<div style="position: relative">
  <button (click)="dialogRef.close()" class="close" mat-icon-button>
    <mat-icon>close</mat-icon>
  </button>
</div>
<div *ngIf="algoLink" class="link">
  <div class="title" [ngStyle]="{ color: '#' + data.color }">
    {{ algoLink.algoBulletLinkLangs[0].title }}
  </div>
  <div class="description">
    {{ algoLink.algoBulletLinkLangs[0].description }}
  </div>
</div>
