<div class="blood-test-view" style="direction: ltr">
  <div class="blood-test-view__header">
    <strong>תאריך הבדיקה: </strong>

    <span *ngIf="!allEditMode">{{ dateTaken | date: "MM/dd/yyyy" }}</span>

    <mat-form-field class="datepicker" appearance="fill" *ngIf="allEditMode">
      <input [(ngModel)]="dateTakenForm" matInput [matDatepicker]="picker" />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    <button
      mat-button
      class="btn btn--fill btn--small btn--center m-0 px-1"
      [ngClass]="{ disabled: allEditMode }"
      style="align-self: end"
      (click)="onUpdateClick()"
    >
      עדכן הכל
    </button>
  </div>

  <div class="result" *ngFor="let result of results; let i = index">
    <div class="result__item">
      <div class="result__item__name">
        <div
          class="result__item__name__date"
          *ngIf="!allEditMode && editInx !== result.bloodTestTypeId"
        >
          <small>{{ result.dateCreated | date: "dd-MM-yyyy" }}</small>
        </div>
        <strong> {{ result.bloodTestType.name }}</strong>
      </div>
      <div
        class="result__item__inputs"
        *ngIf="allEditMode || editInx == result.bloodTestTypeId"
      >
        <div class="result__item__inputs__value">
          <input
            (change)="onResultChanged(formResults[i])"
            type="number"
            *ngIf="!formResults[i].isAlt"
            [(ngModel)]="formResults[i].result"
            [class.short]="result.bloodTestType.altUnits"
          />
          <input
            (change)="onResultAltChanged(formResults[i])"
            type="number"
            *ngIf="formResults[i].isAlt"
            [(ngModel)]="formResults[i].resultAlt"
            [class.short]="result.bloodTestType.altUnits"
          />
          <mat-button-toggle-group
            (change)="onAltChange(result, formResults[i])"
            *ngIf="result.bloodTestType.altUnits"
            [(ngModel)]="formResults[i].isAlt"
            aria-label="Font Style"
          >
            <mat-button-toggle [value]="false">{{
              result.bloodTestType.units
            }}</mat-button-toggle>
            <mat-button-toggle [value]="true">{{
              result.bloodTestType.altUnits
            }}</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
        <div class="result__item__inputs__date" *ngIf="!allEditMode">
          <mat-form-field appearance="fill" class="datepicker">
            <input
              [(ngModel)]="dateTakenForm"
              matInput
              [matDatepicker]="picker"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <div class="result__item__inputs__action">
        <button
          mat-icon-button
          class="btn btn--center btn--underline btn--primary m-0 p-0"
          [disabled]="true"
          *ngIf="
            allEditMode || (editInx != result.bloodTestTypeId && editInx != -1)
          "
        >
          עריכה
        </button>
        <button
          class="btn btn--center btn--underline btn--primary m-0 p-0"
          (click)="goToEditSingleMode(result)"
          *ngIf="!allEditMode && editInx == -1"
          mat-button
        >
          עריכה
        </button>
        <button
          class="btn btn--center btn--fill btn--small mb-1 px-1"
          (click)="onOkClicked(result, i)"
          *ngIf="!allEditMode && editInx == result.bloodTestTypeId"
          mat-button
        >
          שמירה
        </button>
        <button
          class="btn btn--center btn--outline btn--small px-1"
          (click)="onCancelEditClicked(result)"
          *ngIf="!allEditMode && editInx == result.bloodTestTypeId"
          mat-button
        >
          ביטול
        </button>
      </div>
    </div>
    <div *ngIf="editInx !== result.bloodTestTypeId" class="result__slider">
      <app-blood-test-result-slider
        class="slider"
        [result]="result"
        [gender]="gender"
      >
      </app-blood-test-result-slider>
    </div>
    <div
      class="result__error"
      *ngIf="errorSingle && editInx == result.bloodTestTypeId"
      [innerHtml]="errorSingle"
    ></div>
    <!--  <ng-container *ngIf="!allEditMode && editInx != result.bloodTestTypeId">
        <span class="result__item__value">{{ result.result }}</span>
        <span class="result__item__units">{{
          result.bloodTestType.units
        }}</span>
      </ng-container> 

    <ng-container *ngIf="allEditMode || editInx == result.bloodTestTypeId">
      <div class="result__item__input">
        <input
          (change)="onResultChanged(formResults[i])"
          type="number"
          *ngIf="!formResults[i].isAlt"
          [(ngModel)]="formResults[i].result"
        />
        <input
          (change)="onResultAltChanged(formResults[i])"
          type="number"
          *ngIf="formResults[i].isAlt"
          [(ngModel)]="formResults[i].resultAlt"
        />
        <span
          class="result__item__input__unit"
          *ngIf="result.bloodTestType.units === '%'"
          >%</span
        >
      </div>
      <mat-button-toggle-group
        *ngIf="result.bloodTestType.altUnits"
        [(ngModel)]="formResults[i].isAlt"
        aria-label="Font Style"
      >
      
        <mat-button-toggle [value]="false">{{
          result.bloodTestType.units
        }}</mat-button-toggle>
        <mat-button-toggle [value]="true">{{
          result.bloodTestType.altUnits
        }}</mat-button-toggle>
      </mat-button-toggle-group>
    </ng-container>
    <div class="text-center">
      <app-blood-test-result-slider
        class="result__item__slider"
        [result]="result"
        [gender]="gender"
      >
      </app-blood-test-result-slider>
      <div
        *ngIf="errorSingle && editInx == result.bloodTestTypeId"
        class="error"
      >
        {{ errorSingle }}
      </div>
    </div>

    <div class="result__item__date">
      <ng-container *ngIf="!allEditMode && editInx != result.bloodTestTypeId"
        ><span *ngIf="result.result" style="width: 100px">
          {{ result.dateCreated | date: "dd-MM-yyyy" }}
        </span>
        <span class="result__item__date__missing" *ngIf="!result.result">
          חסר
        </span>
      </ng-container>
      <mat-form-field
        appearance="fill"
        *ngIf="!allEditMode && editInx == result.bloodTestTypeId"
        class="datepicker"
      >
        <input
          placeholder="Choose a date"
          [(ngModel)]="dateTakenForm"
          matInput
          [matDatepicker]="picker"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
      <button
        mat-icon-button
        class="action-btn"
        *ngIf="
          allEditMode || (editInx != result.bloodTestTypeId && editInx != -1)
        "
      >
        <img src="../../../assets/ok_button_pink_disabled.svg" />
      </button>

      <button
        class="action-btn update"
        (click)="goToEditSingleMode(result)"
        *ngIf="!allEditMode && editInx == -1"
        mat-icon-button
      >
        <img src="../../../assets/edit_button_pink.svg" />
      </button>
      <button
        class="action-btn"
        (click)="onOkClicked(result, i)"
        *ngIf="!allEditMode && editInx == result.bloodTestTypeId"
        mat-icon-button
      >
        <img src="../../../assets/ok_button_pink.svg" />
      </button>
      <button
        class="action-btn"
        (click)="onCancelEditClicked(result)"
        *ngIf="!allEditMode && editInx == result.bloodTestTypeId"
        mat-icon-button
      >
        <img src="../../../assets/cancel_button_pink.svg" />
      </button>
    </div>
  </div>-->
  </div>
  <div *ngIf="allEditMode" class="footer-actions">
    <button
      mat-button
      class="btn btn--small btn--outline mr-2"
      style="justify-content: center"
      *ngIf="allEditMode"
      (click)="cancelAllEditMode()"
      type="button"
    >
      ביטול
    </button>
    <button
      mat-button
      class="btn btn--small btn--fill"
      style="justify-content: center"
      *ngIf="allEditMode"
      (click)="saveInAllEditMode()"
    >
      שמירה
    </button>
  </div>
</div>
