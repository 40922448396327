import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AlgoUserSummary } from 'src/app/data/AlgoUserSummary';
import { AppImage } from 'src/app/data/AppImage';
import { InnerDnaResult } from 'src/app/data/InnerDnaResults';
import { Product } from 'src/app/data/Product';
import { RegularUser } from 'src/app/data/RegularUser';
import { SubCategoryForApp } from 'src/app/data/SubCategoryForApp';
import { SummaryByType } from 'src/app/data/SummaryByType';
import { TubeTestResultForApp } from 'src/app/data/TubeTestResultForApp';
import { AlgoImageType, ResultOrderTypeEnum } from 'src/app/Enum/StatusesEnum';
import { LoginUserFromStorage } from 'src/app/Redux/Actions/regular-user.actions';
import { AppState } from 'src/app/Redux/reducers';
import { selectProduct } from 'src/app/Redux/selectors/product.selectors';
import { AlgorithmService } from 'src/app/Services/algorithm.service';
import { LanguagesService } from 'src/app/Services/languages.service';
import { RegularUsersService } from 'src/app/Services/regular-users.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dna-report-inner',
  templateUrl: './dna-report-inner.component.html',
  styleUrls: ['./dna-report-inner.component.scss'],
})
export class DnaReportInnerComponent implements OnInit, OnDestroy {
  @ViewChild('descriptionEl') descriptionEl: ElementRef;
  product$: Observable<Product> = this.store.select(selectProduct);
  categoryId: number;
  productId: number;
  results: SubCategoryForApp[];
  rankResults: InnerDnaResult[] = [];

  // rankTwoResults: TubeTestSubCategoryResult[];
  // rankThreeResults: TubeTestSubCategoryResult[];

  barcode: string;
  tubeTestResults: TubeTestResultForApp[];
  displayedColumns: string[] = ['geneName', 'snpNumber', 'result', 'info'];
  rankInx: number = 3;
  @Input() isForPrint: boolean = false;
  @Input() isForPrintOpen: boolean = false;
  categoryName: any;
  allRankResults: InnerDnaResult[];
  activeFilterOrder: number = 0;
  filterRanks: {
    order: number;
    name: string;
    color: string;
    disabled: boolean;
  }[] = [
    { order: 0, name: 'הכל', color: '', disabled: false },
    { order: 1, name: 'נטייה נמוכה', color: 'green', disabled: true },
    { order: 2, name: 'נטייה בינונית', color: 'orange', disabled: true },
    { order: 3, name: 'נטייה גבוהה', color: 'red', disabled: true },
  ];
  regularUser: RegularUser;
  isLoading: boolean;
  langId: number = 1;
  userSummary: AlgoUserSummary;
  resultTypes: SummaryByType[];
  activeType: SummaryByType;
  activeOrder: number;
  panelOpenState: boolean;
  tableOpen: boolean = false;
  highOrderGroup = ResultOrderTypeEnum.High;
  resultTypesDup: SummaryByType[];
  AlgoImageType = AlgoImageType;
  subscription: any;
  exampleUser: RegularUser;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private algorithmService: AlgorithmService,
    private regularUsersService: RegularUsersService,
    private store: Store<AppState>,
    private languagesService: LanguagesService
  ) {}
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  ngOnInit(): void {
    this.categoryId = +this.route.snapshot.queryParamMap.get('categoryId');
    this.barcode = this.route.snapshot.queryParamMap.get('barcode');
    if (!this.route.snapshot.queryParamMap.get('categoryId')) {
      this.categoryId = -1;
    }
    //this.autoLoginFromExampleReport();
    this.listenToLangChange();
    this.product$.subscribe((res) => {
      if (res) {
        this.getCurrentUser();
        this.getSingleCategory();
      }
    });
  }
  autoLoginFromExampleReport() {
    if (this.router.url.split('?')[0] === '/example-report-inner') {
      ///report ror example
      var json = `{"regularUserId":10846,"firstName":"לקוח לדוגמא","lastName":"לדוגמא","displayName":"","phone":"345345435","email":"sdf@sdf.dsfg","userName":"000111000","password":"tCO24a/Vko5l3YMmkQgUQDvbagPlhUYxxYg/pH8+Qby5ggPr0xXg3PmvSItolhVIOZwmlKlm9gWN+LdXWU3iVw==","salt":"vMtHkmvil8EzmdC0Je/gHMyEtNy89G9cXJ9cheUUB81kp8l4kjSMwqWWA3PSFhqSOlg5QcIw58LwZ2E6Ymzx5Q==","streetNum":44,"city":"תמר","street":"ללא רחוב","barCityCode":5,"barCity":null,"barStreetCode":100765,"barStreet":null,"country":"","socialSecurityNum":"000111000","birthDate":"1970-01-01T12:00:00","gender":"male","height":150.0,"weight":50.0,"age":null,"roleTypeId":null,"role":null,"agent":null,"client":null,"patient":{"patientId":1859,"regularUserId":10846,"clientId":10243,"client":null,"userId":0,"products":[{"productId":2059,"clientId":10243,"client":null,"patientId":1859,"dealId":2228,"deal":null,"cancelDealId":null,"cancelDeal":null,"productTypeId":1,"productType":null,"barCode":"20D","tubeTest":null,"codeCoupon":"00000000-0000-0000-0000-000000000000","statusId":63,"status":null,"dateCreated":"2020-09-23T17:23:35.8420705","agentId":65,"agent":null,"fileUploads":null}],"codeCoupon":"00000000-0000-0000-0000-000000000000","patientNotes":null,"patientLogs":null,"nutritionReport":null,"bloodTestResults":null,"patientDeliveries":null,"filledDetails":true,"agentId":null,"agent":null,"lifeStyleReport":null,"patientWeightHistories":null,"bloodTestComplete":false,"suggerComplete":false},"createdDate":"2020-09-23T05:23:00","entrance":null,"apartment":null,"shipNotes":null,"resetPasswordToken":"00000000-0000-0000-0000-000000000000","token":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1laWQiOiIwIiwibmJmIjoxNjA1MDkyMzY3LCJleHAiOjE2MDUxNzg3NjcsImlhdCI6MTYwNTA5MjM2NywiaXNzIjoiaHR0cDovL3dlYmFwaS5teWdlbmVzLmNvLmlsIiwiYXVkIjoiaHR0cDovL2FkbWlucGFuZWwubXlnZW5lcy5jby5pbCJ9.p1vM9nDn_9P_qAlHQB4a5qCDkwB5w_9FgVsSo_Pe_-8","phoneVerificationCode":null}`;
      this.exampleUser = JSON.parse(json);
      this.exampleUser.firstName = '';
      this.exampleUser.userName = '000111000';
      this.exampleUser.password =
        'tCO24a/Vko5l3YMmkQgUQDvbagPlhUYxxYg/pH8+Qby5ggPr0xXg3PmvSItolhVIOZwmlKlm9gWN+LdXWU3iVw==';

      localStorage.removeItem('currentUser');
      localStorage.removeItem('access_token');

      this.store.dispatch(
        LoginUserFromStorage({
          userName: this.exampleUser.userName,
          password: this.exampleUser.password,
        })
      );
    }
  }
  listenToLangChange() {
    this.subscription = this.languagesService.langIdSubject.subscribe(
      (langId) => {
        this.langId = langId;
        this.getSingleCategory();
      }
    );
  }
  getSingleCategory() {
    this.isLoading = true;
    this.algorithmService
      .getResultStatByBarcode(this.barcode, this.categoryId, this.langId)
      .subscribe((data) => {
        this.results = data;
        this.isLoading = false;
        this.setRedLetters();
        this.getResultTypes();
      });
  }
  getResultTypeName(order: number) {
    return this.userSummary.summary.find((x) => x.typeId == order).typeName;
  }
  highOrderGroupHasMoreThenOneResult(order: number) {
    return this.resultTypesDup.some((x) => x.typeId > this.highOrderGroup);
  }
  getResultTypes() {
    if (this.categoryId == -1) return;
    this.algorithmService
      .getUserSummaryStatByCat(
        this.barcode,
        this.langId,
        AlgoImageType.AlgoPageTitleImage,
        this.categoryId
      )
      .subscribe((data: AlgoUserSummary[]) => {
        this.userSummary = data[0];
        this.resultTypes = this.userSummary.summary;
        this.resultTypesDup = this.userSummary.summary;

        this.combineHighOrders();
        this.orderByAndSetColorsToType();
        this.setActiveType();
      });
  }
  combineHighOrders() {
    var tempCount = 0;
    var tempHighIndex = -1;
    this.resultTypes = this.resultTypes
      .filter((x) => x.count > 0)
      .map((x, i) => {
        //combine all High orders greater then 3
        if (x.orderBy >= this.highOrderGroup) {
          if (x.orderBy == this.highOrderGroup) {
            //first high order
            tempHighIndex = i;
          }
          tempCount += x.count;
        }
        return x;
      });

    if (tempHighIndex !== -1) {
      this.resultTypes[tempHighIndex].count = tempCount;
    }
    this.resultTypes = this.resultTypes.filter(
      (x) => x.orderBy <= this.highOrderGroup
    );
  }
  setActiveType() {
    this.activeType = this.resultTypes
      .map((x) => {
        if (this.resultsCount(x.typeId) > 0) {
          return x;
        }
      })
      .filter((y) => y)[0];
  }
  orderByAndSetColorsToType() {
    this.resultTypes = this.resultTypes
      .map((x) => {
        let type = x;
        type.color = `#${x.color || '00000080'}`;
        return type;
      })
      .sort((a, b) => {
        return a.orderBy - b.orderBy;
      });
  }
  setRedLetters() {
    this.results = this.results.map((x) => {
      let res = x;
      res.snpResultForApp.map((x) => {
        let snpResultSpl = x.snpResult.split('');
        snpResultSpl = snpResultSpl.map((y) => {
          if (y === x.redLetter) {
            y = `<span class='red'>${y}</span>`;
          }
          return y;
        });
        x.snpResult = snpResultSpl.join('');
        return x;
      });
      return res;
    });
  }
  resultsCount(resultId: number) {
    const result = this.results.filter((x) => x.order == resultId);
    return result.length;
  }
  showResult(order: number) {
    //combine high oreders togheter
    if (
      this.activeType?.orderBy >= this.highOrderGroup &&
      order >= this.highOrderGroup
    ) {
      return true;
    }
    if (this.activeType?.orderBy == order || this.categoryId == -1) {
      return true;
    }
  }
  onSelectType(type: SummaryByType) {
    this.activeType = type;
    this.activeOrder = type.orderBy;
    //this.getResultByType();
  }
  getImage(image: AppImage) {
    if (image) {
      const webPath = this.replaceWebPath(image.webPath);
      return image ? `${webPath}/${image.fileName}` : '';
    }
  }
  replaceWebPath(image: string) {
    return image.replace('{picdomain}', environment.picsDomain);
  }

  getCurrentUser() {
    return this.regularUsersService.currentUser.subscribe((data) => {
      this.regularUser = data;
      if (
        !this.regularUser &&
        this.router.url.split('?')[0] === '/example-report-inner'
      ) {
        this.router.navigate(['example-report']);
      }
    });
  }
  goBack() {
    var url = 'dna-report';
    if (this.router.url.split('?')[0] === '/example-report-inner') {
      url = 'example-report';
    }
    this.router.navigate([url]);
  }
  blankify(html, removeTags: boolean = false) {
    var root = document.createElement('div'),
      fonts = root.getElementsByTagName('font');

    root.innerHTML = html;

    Array.prototype.forEach.call(fonts, function (e) {
      e.removeAttribute('size');
      e.removeAttribute('face');
    });

    if (removeTags) {
      return root.textContent || root.innerText || '';
    } else {
      return root.innerHTML;
    }
  }
  removeHTags(html) {
    return html.replace(/(<h([^>]+)>)/gi, '');
  }
  getImageUrl(subcat: SubCategoryForApp) {
    return environment.picsDomain + '/algocat/' + subcat.imageUrl;
  }
  getImageBg() {
    const hex = `#${this.userSummary.color}`;
    const rgba = `rgba(${this.hexToRgb(hex).r},${this.hexToRgb(hex).g},${
      this.hexToRgb(hex).b
    },0.2)`;
    return rgba;
  }
  hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  }
  ////////////////////////////////////////////////////////////////////////////////

  filterBy(order: number) {
    this.activeFilterOrder = order;
    if (order == 0) {
      this.rankResults = this.allRankResults;
    } else
      this.rankResults = this.allRankResults.filter(
        (x) => x.results[0].order == order
      );
  }
}
