import { GlobalParam } from 'src/app/data/GlobalParam';
import { RedirectObj } from 'src/app/data/RedirectObj';
import { RegularUser } from 'src/app/data/RegularUser';
import { UserAction, UserActionTypes } from '../Actions/regular-user.actions';

export const userFeatureKey = 'user';

export interface UserState {
  user: RegularUser | null;
  isLoading: boolean;
  error: any;
  redirectObj: RedirectObj | null;
  globalParams: GlobalParam[] | null;
}

const initialUserState: UserState = {
  user: null,
  isLoading: false,
  error: null,
  redirectObj: null,
  globalParams: null,
};

export function userReducer(
  state: UserState = initialUserState,
  action: UserAction
): UserState {
  switch (action.type) {
    case UserActionTypes.LoginUserFromStorage:
    case UserActionTypes.LoginUserFromUI:
      return {
        ...state,
        isLoading: true,
        error: false,
        redirectObj: null,
      };
    case UserActionTypes.LoginUserComplete:
      return { ...state, isLoading: false, user: action.user };
    case UserActionTypes.LoginError:
      return {
        ...state,
        isLoading: false,
        error: action.errorMessage,
        redirectObj: null,
      };
    case UserActionTypes.LoginServerError:
      return {
        ...state,
        isLoading: false,
        error: action.errorMessage,
        redirectObj: null,
      };
    case UserActionTypes.ResetPassword:
      return {
        ...state,
        isLoading: false,
        error: action.errorMessage,
        redirectObj: JSON.parse(action.redirectObj),
      };
    case UserActionTypes.ResetPasswordFromSms:
      return {
        ...state,
        user: action.user,
        isLoading: false,
      };
    case UserActionTypes.GlobalParamsLoaded:
      return {
        ...state,
        globalParams: action.globalParams,
      };

    case UserActionTypes.LogoutUser:
      return initialUserState;

    default:
      return { ...state, redirectObj: null };
  }
}
