<div class="page-title">בדיקות דם</div>
<div class="container">
  <!-- <div style="margin-right: auto" (click)="goBack()" class="backBtn">
    חזרה >
  </div>
  <h1 style="font-weight: bold; font-size: 30px">בדיקות דם</h1>
  <hr *ngIf="isMobile()" class="hr-mobile" /> -->
  <div
    *ngIf="!isMobile()"
    style="color: #8d9298; text-align: center; font-weight: 600"
  >
    אנא מלא את טופס בדיקות הדם בדיוק כפי שמופיע בבדיקות דם שקיבלת מקופת החולים
    בה אתה חבר
  </div>
  <div class="send-bloodtest">
    <div class="strong">אין לך בדיקות דם מחצי השנה האחרונה?</div>
    <div class="send-bloodtest">
      <span class="link" (click)="popupHandlerService.openHasNotBloodTest()"
        >לחץ כאן
      </span>
      למשלוח הפניה לבדיקות דם
    </div>
  </div>
  <div style="max-width: 100%" class="mt-1">
    <app-bloodtest-desktop
      *ngIf="results && formResults && dateTaken && !isMobile()"
      [allEditMode]="allEditMode"
      [editInx]="editInx"
      [gender]="gender"
      [dateTaken]="dateTaken"
      [results]="results"
      [formResults]="formResults"
      (saveSingle)="onSaveSingle($event)"
      (saveAll)="onSaveAll($event)"
      (createFormResults)="onCreateFormResults($event)"
      (setEditInx)="onSetEditInx($event)"
      (toggleAllEditMode)="onToggleAllEditMode($event)"
    ></app-bloodtest-desktop>
    <app-bloodtest-mobile
      *ngIf="results && formResults && dateTaken && isMobile()"
      [allEditMode]="allEditMode"
      [editInx]="editInx"
      [dateTaken]="dateTaken"
      [results]="results"
      [formResults]="formResults"
      (saveSingle)="onSaveSingle($event)"
      (saveAll)="onSaveAll($event)"
      (createFormResults)="onCreateFormResults($event)"
      (setEditInx)="onSetEditInx($event)"
      (toggleAllEditMode)="onToggleAllEditMode($event)"
      [gender]="gender"
    ></app-bloodtest-mobile>
  </div>
</div>
