export class VideoForSite {
  videoId: number;
  title: string;
  desc: string;
  agentName: string;
  createDate: string;
  duration: string;
  categoryId: number;
  videoUrl: string;
  posterUrl: string;
}
