import { Action } from '@ngrx/store';
import { LifeStyleReport } from 'src/app/data/LifeStyleReport';
import {
  LifeStyleAction,
  LifeStyleActionTypes,
} from '../Actions/life-style.actions';

export const lifeStyleFeatureKey = 'lifeStyle';

export interface LifeStyleState {
  lifeStyle: LifeStyleReport | null;
}

export const initialState: LifeStyleState = {
  lifeStyle: null,
};

export function lifeStyleReducer(
  state: LifeStyleState = initialState,
  action: LifeStyleAction
): LifeStyleState {
  switch (action.type) {
    case LifeStyleActionTypes.LoadLifeStyleComplete:
      return {
        ...state,
        lifeStyle: action.lifeStyle,
      };
    case LifeStyleActionTypes.LoadLifeStyle:
    case LifeStyleActionTypes.UpdateLifeStyle:
    default:
      return state;
  }
}
