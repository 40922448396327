import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class BackBtnService {
  quitOnBackButton = false;
  constructor() {}
  closeApp() {
    (navigator as any).app.exitApp();
  }
}
