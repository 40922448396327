import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Language } from '../data/Language';

@Injectable({
  providedIn: 'root',
})
export class LanguagesService {
  constructor(private http: HttpClient) {}
  langIdSubject = new Subject<number>();
  getAll() {
    return this.http.get<Language[]>(`${environment.baseUrl}Languages`);
  }
  getAllForApp(token: string) {
    return this.http.get<Language[]>(`${environment.baseUrl}Languages`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
  setLangId(langId: number) {
    this.langIdSubject.next(langId);
  }
}
