import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Status } from '../data/Status';

@Injectable({
  providedIn: 'root',
})
export class StatusesService {
  statuses: Status[];
  constructor(private http: HttpClient) {}
  getAll() {
    if (this.statuses) {
      return of(this.statuses); // return from cache
    } else {
      return this.http
        .get<Status[]>(`${environment.baseUrl}Statuses/`)
        .pipe(map((res) => (this.statuses = res)));
    }
    // return this.http.get<Status[]>(`${environment.baseUrl}Statuses/`);
  }
}
