import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Note } from 'src/app/data/Note';
import { NotesService } from 'src/app/Services/notes.service';

@Component({
  selector: 'app-call-rating',
  templateUrl: './call-rating.component.html',
  styleUrls: ['./call-rating.component.scss'],
})
export class CallRatingComponent implements OnInit {
  loading = true;
  isSubmited = false;
  comment: string = '';
  step: number = 1;
  stars = [1, 2, 3, 4, 5];
  selectedRating: number = 0;
  note: Note;
  noteId: number;
  constructor(
    private notesService: NotesService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  setRating(val) {
    this.selectedRating = val;
  }
  ngOnInit(): void {
    this.noteId = this.route.snapshot.queryParams['noteId'];
    if (!this.noteId) {
      this.router.navigate(['/login']);
    }

    if (this.noteId)
      this.notesService.getById(this.noteId).subscribe(
        (res) => {
          this.loading = false;
          this.note = res;
          if (!this.note) {
            this.router.navigate(['/login']);
          }
        },
        (error) => {
          this.router.navigate(['/login']);
        }
      );
  }
  submit() {
    this.isSubmited = true;
    if (
      this.selectedRating == 0 ||
      (this.selectedRating <= 3 && this.comment == '')
    ) {
      return;
    }
    this.notesService
      .SubmitReview(this.note.noteId, this.selectedRating, this.comment)
      .subscribe((res) => {
        this.note = res;
      });
  }
}
