import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthenticationRoutingModule } from './authentication-routing.module';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';

import { environment } from 'src/environments/environment';
import { LoginPageComponent } from '../Components/login-page/login-page.component';
import { RegisterPageComponent } from '../Components/register-page/register-page.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material/material.module';
import { CodeInputModule } from 'angular-code-input';

@NgModule({
  declarations: [LoginPageComponent, RegisterPageComponent],
  imports: [
    CommonModule,
    AuthenticationRoutingModule,
    RecaptchaV3Module,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    CodeInputModule,
  ],
  providers: [
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptcha.siteKey,
    },
  ],
})
export class AuthenticationModule {}
