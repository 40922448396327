import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { LifeStyleExercise } from 'src/app/data/LifestyleExercise.model';

@Component({
  selector: 'app-test-activity-thumb',
  templateUrl: './test-activity-thumb.component.html',
  styleUrls: ['./test-activity-thumb.component.scss'],
})
export class TestActivityThumbComponent implements OnInit {
  @Input() name;
  @Input() duration;
  @Input() numInWeek;
  @Input() index;
  @Input() ifActivities;
  activitiesForm;
  formReady: boolean;
  constructor() {}
  @Output('emitActivity') emitActivity: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void {
    //this.createFormGroup();
  }

  addActivity(value) {
    var lifestyleExercise = new LifeStyleExercise();
    lifestyleExercise.index = this.index;
    lifestyleExercise.name = this.name;
    lifestyleExercise.duration = this.duration;
    lifestyleExercise.numInWeek = this.numInWeek;
    this.emitActivity.emit(lifestyleExercise);
  }

  /*   createLifestyleFields(lifestyleData) {
    if (this.activity) {
      const arr = this.activity.map((item) => {
        return new FormControl(item);
      });
      return new FormArray(arr);
    } else {
      const arr = lifestyleData.map((item) => {
        console.log('from func ', item);

        return new FormControl(item);
      });
      return new FormArray(arr);
    }
  } */

  /*   createFormGroup() {
    this.activitiesForm = new FormGroup({
      name: new FormControl(this.name ? this.name : null, [
        Validators.required,
      ]),
      duration: new FormControl(this.duration ? this.duration : null, [
        Validators.required,
      ]),
      numInWeek: new FormControl(this.numInWeek ? this.numInWeek : null, [
        Validators.required,
      ]),
    });
    setTimeout(() => {
      this.formReady = true;
    }, 100);
  } */
  saveThis() {}
  /*  get activitiesArray(): FormArray {
    return this.lifestyleForm5.get('activitiesArray') as FormArray;
  }

  get ifActivitiesYes() {
    return this.lifestyleForm5.get('ifActivities').value;
  } */

  /*  setActivities(lifeStyleExercises: LifeStyleExercise[]) {
    var activitiesFA = this.fb.group(
      this.lifestyleLocal.lifeStyleExercises.name || lifeStyleExercises
    );

    this.lifestyleForm5.setControl('activitiesArray', activitiesFA);
  }  */

  /*   showActivities() {
    //if (this.lifestyleLocal.ifActivities) return true;
    return this.ifActivitiesYes == 1;
  }

  */

  /*  addActivity() {
    this.activitiesArray.push(this.fb.group(new LifeStyleExercise()));
  }
  removeActivity() {
    this.activitiesArray.removeAt(this.activitiesArray.value - 1);
  } */
}
