<div class="ruler">
  <div style="position: relative">
    <div class="ruler__indicator"></div>
  </div>
  <div dir="ltr" class="ruler__slider" #rulerSlider>
    <div class="ruler__slider__lines">
      <div class="ruler__slider__lines__item" *ngFor="let item of rullerLines">
        <div
          [ngStyle]="{
            height: item % 5 == 0 ? (item % 10 == 0 ? '25px' : '15px') : '7px'
          }"
          class="ruler__slider__lines__item__line"
        ></div>
      </div>
    </div>
    <div class="ruler__slider__numbers">
      <div
        *ngFor="let item of rullerNumbers; let first = first"
        [ngStyle]="{ 'margin-left': first ? '51px' : '' }"
        class="ruler__slider__numbers__item"
      >
        {{ item }}
      </div>
    </div>
  </div>
</div>
