<div class="page-title">קלקולטור</div>
<div>
  <div class="recomendations">
    <div class="recomendations__page" #pageRef>
      <div class="recomendations__page__container">
        <div
          *ngIf="isLoading"
          style="
            width: 90%;
            max-width: 800px;
            margin: 0 auto;
            padding-top: 2rem;
          "
        >
          <ngx-skeleton-loader
            [theme]="{ height: '50px' }"
            count="5"
          ></ngx-skeleton-loader>
        </div>
        <ng-container *ngIf="!isLoading">
          <div dir="ltr" style="width: 90%; max-width: 800px; margin: 0 auto">
            <div class="scroll-bar" *ngIf="isMobile()">
              <ng-container
                *ngFor="
                  let item of recomendations;
                  let index = index;
                  let last = last
                "
              >
                <div class="scroll-bar__item">
                  <div
                    class="scroll-bar__item__dot"
                    (click)="scrollToRec(index)"
                    [ngStyle]="{
                      'background-color':
                        visibleIndex == index
                          ? '#' + item.algoBulletCategoryColor
                          : '#DBDBDB',
                      transform:
                        visibleIndex == index ? 'scale(1.5)' : 'scale(1)'
                    }"
                  ></div>
                  <div *ngIf="!last" class="scroll-bar__item__line"></div>
                </div>
              </ng-container>
            </div>
          </div>

          <ng-container
            *ngFor="
              let item of recomendations;
              let index = index;
              let last = last
            "
          >
            <div
              class="recomendations__page__wrapper"
              [class.wrapper-scrolled]="wrapperScorlled"
              [class.last]="last"
              #elementRef
              id="rec-{{ index }}"
            >
              <div
                class="swipe-animation"
                *ngIf="index == 0 && firstTimeAnimation && isMobile()"
                (click)="firstTimeAnimation = false"
                (touchstart)="firstTimeAnimation = false"
              >
                <div>
                  <div class="text">
                    גלול כלפי מעלה כדי לעבור <br />
                    בין ההמלצות השונות
                  </div>
                  <img src="../../../assets/Swipe.gif" alt="" />
                </div>
              </div>
              <div class="desktop-grid">
                <div
                  *ngIf="item.algoBulletImageUrl && !isMobile()"
                  class="desktop-grid__img"
                >
                  <div class="desktop-grid__img__index">
                    {{ index + 1 }}
                  </div>
                  <img src="{{ item.algoBulletImageUrl }}" alt="" />
                </div>
                <div>
                  <div class="recomendations__page__header">
                    <span
                      class="recomendations__page__header__title"
                      [ngStyle]="{
                        color: '#' + item.algoBulletCategoryColor
                      }"
                    >
                      {{ item.algoBulletTitle }}</span
                    >
                  </div>
                  <div
                    class="recomendations__page__desc"
                    [ngStyle]="{
                      'border-color': isMobile()
                        ? '#' + item.algoBulletCategoryColor
                        : ''
                    }"
                  >
                    {{ item.algoBulletDescription }}
                    <div *ngIf="item.algoBulletLinkId">
                      <button
                        mat-button
                        class="recomendations__page__link"
                        (click)="
                          openLinkDialog(
                            item.algoBulletLinkId,
                            item.algoBulletCategoryColor
                          )
                        "
                      >
                        {{ item.algoBulletLinkName }}
                      </button>
                    </div>
                  </div>
                  <div
                    *ngIf="!isMobile()"
                    class="recomendations__page__reference"
                  >
                    <div class="text">
                      <div class="text-title">
                        <strong>מדדים שנבדקו: </strong>
                      </div>
                      {{ item.algoBulletReference }}
                    </div>
                  </div>
                </div>
              </div>

              <div
                *ngIf="item.algoBulletImageUrl && isMobile()"
                class="recomendations__page__img"
              >
                <img src="{{ item.algoBulletImageUrl }}" alt="" />
              </div>
              <div *ngIf="isMobile()" class="recomendations__page__reference">
                <div class="text">
                  <div class="text-title">
                    <strong>מדדים שנבדקו: </strong>
                  </div>
                  {{ item.algoBulletReference }}
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
    <!-- </div> -->
  </div>
</div>
