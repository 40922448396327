export const oneDay = 24 * 60 * 60 * 1000;
export function padWithZeros(n, width, z = '0') {
  if (!z) z = z || '0';
  n = n + '';
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

export function getDay(dateStr) {
  var d = (new Date(Date.parse(dateStr)) + '').split(' ');
  d[2] = d[2] + ',';

  return [d[0], d[1], d[2], d[3]].join(' ');
}

export function serviceTime() {
  var date: Date = new Date();
  var hours: number = date.getHours();
  var electionDay = '3/23/2021';
  if (electionDay === date.toLocaleDateString()) return false;
  if (date.getDay() == 5 || date.getDay() == 6) return false;
  // closed in friday and saturday
  else if (hours >= 18 || hours < 9) return false;
  // closed between 18:00 to 9:00
  else return true;
}

export function serviceTimeV2() {}

export function getDaysDiff(dateA: Date, dateB: Date) {
  return (dateA.getTime() - dateB.getTime()) / oneDay;
}
export function getDateFormatToApi(date: Date) {
  let year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
  let month = new Intl.DateTimeFormat('en', { month: 'numeric' }).format(date);
  let day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);
  return `${year}-${month}-${day}`;
}
export function getDateMonthName(date: Date, numLeters = 3) {
  /// made for taking first 3 letters.if numLeters>4 subStr may throw error
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  return monthNames[date.getMonth()].substr(0, numLeters);
}
export function addDaysToDate(date, days) {
  var dayms = days * 24 * 60 * 60 * 1000;
  return new Date(date.getTime() + dayms);
}
