import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-enter-weight-dialog',
  templateUrl: './enter-weight-dialog.component.html',
  styleUrls: ['./enter-weight-dialog.component.scss'],
})
export class EnterWeightDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<EnterWeightDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {}
  submit() {
    this.dialogRef.close(this.data);
  }
}
