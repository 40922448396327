import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { Dimensions, ImageCroppedEvent } from 'ngx-image-cropper';
import { fromEvent, Observable } from 'rxjs';
import { debounceTime, map, take } from 'rxjs/operators';
import { Patient } from 'src/app/data/Patients';
import { PatientWeightHistory } from 'src/app/data/PatientWeightHistory';
import { Product } from 'src/app/data/Product';
import { FileUploadType } from 'src/app/Enum/StatusesEnum';
import {
  LoadProduct,
  UpdateCurrentWeight,
} from 'src/app/Redux/Actions/product.actions';
import { AppState } from 'src/app/Redux/reducers';
import {
  selectPatient,
  selectProduct,
  selectProductError,
  selectProductIsLoading,
  selectUpdateWeightSuccess,
} from 'src/app/Redux/selectors/product.selectors';
import { ProductService } from 'src/app/Services/product.service';

@Component({
  selector: 'app-current-weight-page',
  templateUrl: './current-weight-page.component.html',
  styleUrls: ['./current-weight-page.component.scss'],
})
export class CurrentWeightPageComponent implements OnInit {
  @ViewChild('weightInput') weightInput: ElementRef;
  patient$: Observable<Patient> = this.store.select(selectPatient);
  product$: Observable<Product> = this.store.select(selectProduct);
  isLoading$: Observable<boolean> = this.store.select(selectProductIsLoading);
  weightError$: Observable<string> = this.store.select(selectProductError);
  selectUpdateWeightSuccess$: Observable<boolean> = this.store.select(
    selectUpdateWeightSuccess
  );
  patient: Patient;
  weightGoal;
  weightRuler: number;
  weightError: string = '';
  date: Date = new Date();
  lastImage: string;

  showCropper = false;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  product: Product;
  productId: number;
  weightServerError: string;
  constructor(
    private router: Router,
    private store: Store<AppState>,
    private productsService: ProductService,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.patient$.subscribe((res) => {
      if (res) {
        this.patient = { ...res };
        this.weightRuler = this.patient.currentWeight;
        this.passWeightToRuler();
      }
    });
    this.product$.subscribe((data) => {
      if (data) {
        this.product = data;
        this.productId = this.product.productId;
        this.getLastImage();
      }
    });
  }

  passWeightToRuler() {
    setTimeout(() => {
      const input = this.weightInput.nativeElement;
      const keyup$ = fromEvent(input, 'keyup');
      keyup$
        .pipe(
          map((i: any) => i.currentTarget.value),
          debounceTime(1000)
        )
        .subscribe((value) => {
          //console.log('debounceTime: ', value);
          if (this.validateWeight()) {
            this.weightRuler = value;
          }
        });
    }, 0);
  }

  onSetWeight(event) {
    this.weightRuler = Math.round(event);
    this.validateWeight();
  }

  validateWeight() {
    if (this.weightRuler < 10 || this.weightRuler > 300) {
      this.weightError = 'יש להזין ערך בין 10 ל - 300';
      return false;
    }
    this.weightError = '';
    return true;
  }

  onClose() {
    this.croppedImage = null;
    this.showCropper = false;
  }

  getLastImage() {
    var weightImages = this.product.fileUploads?.filter(
      (x) => x.fileUploadTypeId == FileUploadType.weight
    );
    this.lastImage = weightImages[weightImages.length - 1]?.path;
  }

  updateWeight() {
    let weightHistory = new PatientWeightHistory();
    weightHistory.isGoal = false;
    weightHistory.patientWeightHistoryId = 0;
    weightHistory.patientId = this.product.patientId;
    weightHistory.value = this.weightRuler;
    weightHistory.dateUpdated = moment().format('yyyy-MM-DD');
    this.store.dispatch(
      new UpdateCurrentWeight({ currentWeight: this.weightRuler })
    );
    this.weightError$.subscribe((error) => {
      if (error) {
        this.weightServerError = error;
      }
    });
    this.selectUpdateWeightSuccess$.subscribe((res) => {
      if (res) {
        this._snackBar.open('משקל נוכחי עודכן בהצלחה', 'סגור', {
          direction: 'rtl',
          duration: 2000,
          panelClass: 'update-success',
        });
      }
    });
    //this.store.dispatch(new LoadProduct({ patientId: this.product.patientId }));
  }

  uploadImage() {
    var imgArray = this.croppedImage.split(',');
    this.productsService
      .UploadWeightImage(this.productId, imgArray)
      .subscribe((data) => {
        this.croppedImage = null;
        this.showCropper = false;
        this.lastImage = data.filePath;
        this.store.dispatch(
          new LoadProduct({ patientId: this.patient.patientId })
        );
      });
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    //console.log(event, base64ToFile(event.base64));
  }

  imageLoaded() {
    this.showCropper = true;
    //console.log('Image loaded');
  }

  cropperReady(sourceImageDimensions: Dimensions) {
    // console.log('Cropper ready', sourceImageDimensions);
  }

  loadImageFailed() {
    // console.log('Load failed');
  }
}
