import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ErrorMessage } from '../data/ErrorMessage';
import { RegularUser } from '../data/RegularUser';
import { UserInfoResetInfo } from '../data/UserInfoResetInfo';

@Injectable({
  providedIn: 'root',
})
export class PersonalProfileService {
  constructor(private http: HttpClient) {}

  uploadProfileImg(productId: number, profileImage) {
    return this.http.post<{ filePath: string }>(
      `${environment.baseUrl}Products/UploadProfileImageBase64/${productId}`,
      profileImage
    );
  }

  getProfileImg(productId: number) {
    return this.http.get<any>(
      `${environment.baseUrl}Products/GetProfileImage/${productId}`
    );
  }

  sendMessageForReset(resetType: number, regularUserId: number) {
    return this.http.post<{ filePath: string }>(
      `${environment.baseUrl}RegularUsers/SendMessageForReset/${regularUserId}`,
      resetType
    );
  }

  validateVerificationCode(regularUserId: number, verificationCode: number) {
    return this.http.get<any>(
      `${environment.baseUrl}RegularUsers/ValidateVerificationCode/${regularUserId}/${verificationCode}`
    );
  }

  ChangeParamWithVerificationCode(userInfo: UserInfoResetInfo) {
    return this.http.post<ErrorMessage | RegularUser>(
      `${environment.baseUrl}RegularUsers/ChangeParamWithVerificationCode`,
      userInfo
    );
  }
}
