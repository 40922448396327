import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Product } from 'src/app/data/Product';
import {
  EnteredBarcodeInnerStatus,
  ProductStatusEnum,
} from 'src/app/Enum/StatusesEnum';
import { selectProduct } from 'src/app/Redux/selectors/product.selectors';

export interface statusBarItem {
  id: number[];
  title: string;
  title2?: string;
  title3?: string;
  isComplete: boolean;
}

@Component({
  selector: 'app-status-bar',
  templateUrl: './status-bar.component.html',
  styleUrls: ['./status-bar.component.scss'],
})
export class StatusBarComponent implements OnInit, OnChanges {
  ProductStatusEnum = ProductStatusEnum;
  @Input() readyToPickup: boolean;
  @Input() enteredBarcodeInnerStatus: number;
  @Input() fillDetailsStatus: boolean;
  status: number;
  currentStatus: statusBarItem;
  product$: Observable<Product> = this.store.select(selectProduct);

  barStatuses: statusBarItem[] = [
    {
      id: [-1, 93, 62, 63, 8, 194, 126],
      title: 'השלמת פרטים',
      isComplete: false,
    }, // FilledDetails
    { id: [9, 127], title: 'הערכה בדרך', isComplete: false }, // SentToCustomer || ResendToPatient
    { id: [61], title: 'סריקת המבחנה', isComplete: false }, // ArrivedToCostumer
    {
      id: [64],
      title: 'ביצוע הבדיקה',
      title2: 'תיאום איסוף הערכה',
      title3: 'אישור כתובת',
      isComplete: false,
    }, // EnteredBarcode
    { id: [7], title: 'תיאום איסוף הערכה', isComplete: false }, // WaitingToPickUp
  ];

  /* @ViewChild('coloredBar1') coloredBar1: ElementRef;
  @ViewChild('coloredBar2') coloredBar2: ElementRef;
  @ViewChild('coloredBar3') coloredBar3: ElementRef;

  @ViewChild('loadBar1') loadBar1: ElementRef;
  @ViewChild('loadBar2') loadBar2: ElementRef;
  @ViewChild('loadBar3') loadBar3: ElementRef;

  @ViewChild('indicator1') indicator1: ElementRef;
  @ViewChild('indicator2') indicator2: ElementRef;
  @ViewChild('indicator3') indicator3: ElementRef;
  @ViewChild('indicator4') indicator4: ElementRef; */
  product: Product;

  constructor(private renderer: Renderer2, private store: Store) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      this.enteredBarcodeInnerStatus == EnteredBarcodeInnerStatus.AfterSpitting
    ) {
      this.barStatuses[3].isComplete = true;
    } else if (this.fillDetailsStatus) {
      this.barStatuses[1].isComplete = true;
    }
    //this.setAnimation(currentValue);
  }

  ngOnInit(): void {
    this.product$.subscribe((res) => {
      if (res) {
        this.product = res;
        this.getProductStatus();
      }
    });
  }

  isActiveStep(statusIds: number[]) {
    if (statusIds.includes(this.status)) {
      return true;
    } else if (
      statusIds[0] == this.ProductStatusEnum.WaitingToPickUp &&
      this.enteredBarcodeInnerStatus == EnteredBarcodeInnerStatus.AfterSpitting
    ) {
      return true;
    }
  }

  getStatusTitle() {
    const item = this.barStatuses.find((x) => x.id.includes(this.status));
    if (
      item?.id.includes(64) &&
      this.enteredBarcodeInnerStatus == EnteredBarcodeInnerStatus.AfterSpitting
    ) {
      return item?.title2;
    }
    if (
      item?.id.includes(64) &&
      (this.enteredBarcodeInnerStatus ==
        EnteredBarcodeInnerStatus.ConfirmAdress ||
        this.enteredBarcodeInnerStatus ==
          EnteredBarcodeInnerStatus.ChangeAdress)
    ) {
      return item?.title3;
    }
    if (this.fillDetailsStatus) {
      return 'השלמת פרטים';
    }
    return item?.title;
  }

  setCompleteStatuses() {
    if (this.product.patient.filledDetails) {
      //this.mapCompleteStatuses(ProductStatusEnum.FilledDetails);
      this.barStatuses[0].isComplete = true;
    }
    if (this.status == ProductStatusEnum.ArrivedToCostumer) {
      this.barStatuses[1].isComplete = true;
    }
    if (this.status == ProductStatusEnum.EnteredBarcode) {
      this.barStatuses[1].isComplete = true;
      this.barStatuses[2].isComplete = true;
    }

    if (this.status == ProductStatusEnum.WaitingToPickUp) {
      this.barStatuses[1].isComplete = true;
      this.barStatuses[2].isComplete = true;
      this.barStatuses[3].isComplete = true;
      this.barStatuses[4].isComplete = true;
    }
  }

  getProductStatus() {
    if (!this.product || !this.product.patient) return;
    if (!this.product.patient.filledDetails) {
      this.status = -1;
    } else if (this.product.statusId == ProductStatusEnum.ResendToPatient) {
      this.status = ProductStatusEnum.SentToCustomer;
    } else if (this.product.statusId == ProductStatusEnum.RetestOnLab) {
      this.status = ProductStatusEnum.LaboratoryProcess;
    } else {
      this.status = this.product.statusId;
    }
    //this.status = 7;
    this.setCompleteStatuses();
  }

  statusInc(item: statusBarItem) {
    const inc = item.id.includes(this.product.statusId);
  }

  setProductStatus() {
    switch (this.product.statusId) {
      case ProductStatusEnum.FilledDetails:
        return 'השלמת פרטים';
      case 64:
        return 'הגיעה הבדיקה';
      case 7:
        return 'איסוף הבדיקה';
      case 62:
      case 8:
      case 194:
        return 'הבדיקה במעבדה';
      case 63:
        return 'התקבלו תוצאות';
    }
  }
  statusToShowInTitle(status: number) {
    switch (status) {
      case -1:
        return 'השלמת פרטים';
      case 64:
        return 'הגיעה הבדיקה';
      case 7:
        return 'איסוף הבדיקה';
      case 62:
      case 8:
      case 194:
        return 'הבדיקה במעבדה';
      case 63:
        return 'התקבלו תוצאות';
      default:
    }
  }

  /* setAnimation(status: number) {
    setTimeout(() => {
      var coloredRef1 = this.coloredBar1.nativeElement;
      var coloredRef2 = this.coloredBar2.nativeElement;
      var coloredRef3 = this.coloredBar3.nativeElement;

      var loadRef1 = this.loadBar1.nativeElement;
      var loadRef2 = this.loadBar2.nativeElement;
      var loadRef3 = this.loadBar3.nativeElement;

      var indicatorRef1 = this.indicator1.nativeElement;
      var indicatorRef2 = this.indicator2.nativeElement;
      var indicatorRef3 = this.indicator3.nativeElement;
      var indicatorRef4 = this.indicator4.nativeElement;

      if (
        status == ProductStatusEnum.EnteredBarcode ||
        status == ProductStatusEnum.WaitingToPickUp
      ) {
        setTimeout(() => {
          this.renderer.addClass(indicatorRef2, 'active');
        }, 2000);
        setTimeout(() => {
          this.renderer.addClass(loadRef1, 'active');
        }, 1000);
      } else if (
        status == ProductStatusEnum.ArrivedToLab ||
        status == ProductStatusEnum.ArrivedToOffice
      ) {
        this.renderer.addClass(coloredRef1, 'colored');
        setTimeout(() => {
          this.renderer.addClass(indicatorRef3, 'active');
        }, 2000);
        setTimeout(() => {
          this.renderer.addClass(loadRef2, 'active');
        }, 1000);
      } else if (
        status == ProductStatusEnum.LaboratoryProcess ||
        status == ProductStatusEnum.ResultAccepted ||
        status == ProductStatusEnum.RetestOnLab
      ) {
        this.renderer.addClass(coloredRef1, 'background-colored');
        this.renderer.addClass(coloredRef2, 'background-colored');
        setTimeout(() => {
          this.renderer.addClass(indicatorRef3, 'active');
        }, 2000);
        setTimeout(() => {
          this.renderer.addClass(loadRef3, 'active');
        }, 1000);
      } else if (status == ProductStatusEnum.Completed) {
        this.renderer.addClass(coloredRef1, 'background-colored');
        this.renderer.addClass(coloredRef2, 'background-colored');
        this.renderer.addClass(coloredRef3, 'background-colored');
        setTimeout(() => {
          this.renderer.addClass(indicatorRef4, 'active');
        }, 1000);
      }
    }, 100);
  } */
}
