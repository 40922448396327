import { Component, OnInit, ViewChild } from '@angular/core';
import { SignaturePad } from 'angular2-signaturepad-ios-fix';

@Component({
  selector: 'app-signature-thumb',
  templateUrl: './signature-thumb.component.html',
  styleUrls: ['./signature-thumb.component.scss'],
})
export class SignatureThumbComponent implements OnInit {
  @ViewChild(SignaturePad) signaturePad: SignaturePad;
  constructor() {}

  ngOnInit(): void {}
  private signaturePadOptions: Object = {
    // passed through to szimek/signature_pad constructor
    minWidth: 5,
    canvasWidth: 500,
    canvasHeight: 300,
  };

  ngAfterViewInit() {
    // this.signaturePad is now available
    this.signaturePad.set('minWidth', 100); // set szimek/signature_pad options at runtime
    this.signaturePad.set('minHeight', 50);
    this.signaturePad.clear(); // invoke functions from szimek/signature_pad API
  }

  drawComplete() {
    // will be notified of szimek/signature_pad's onEnd event
    //console.log(this.signaturePad.toDataURL());
  }

  drawStart() {
    // will be notified of szimek/signature_pad's onBegin event
    //console.log('begin drawing');
  }
}
