<div class="container-back-btn">
  <div (click)="backBtn()" class="btn"><button mat-button>
      < חזרה</button>
  </div>
</div>
<img class="landscape" src="../../../assets/soonBg-lnadscape.png" style="width: 100%;max-width: 100%; height: auto" />
<img class="portrait" src="../../../assets/soonBg-portrait.png" style="width: 100%;max-width: 100%; height: auto" />
<!--
sfgdsdfg
<div
  style="
    width: 100%;
    height: auto;
    background-image: url('../../../assets/soonBg.png');
    background-size: 100% 100%;
  "
></div>
-->