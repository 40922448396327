import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  Renderer2,
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { RegularUsersService } from 'src/app/Services/regular-users.service';
import { Router, ActivatedRoute } from '@angular/router';
import { RegularUser } from 'src/app/data/RegularUser';
import { isChrome } from 'src/app/Utils/userAgent';
import {
  passwordValidator,
  matchOtherValidator,
} from 'src/app/Utils/validatorsUtils';
import { Store } from '@ngrx/store';
import { ResetPasswordFromSms } from 'src/app/Redux/Actions/regular-user.actions';
import { environment } from 'src/environments/environment';
import { CodeInputComponent } from 'angular-code-input';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { GoogleCaptchaService } from 'src/app/Services/google-captcha.service';
import { GoogleCaptcha } from 'src/app/data/GoogleRecaptcha';

@Component({
  selector: 'app-register-page',
  templateUrl: './register-page.component.html',
  styleUrls: ['./register-page.component.scss'],
})
export class RegisterPageComponent implements OnInit {
  registerForm: FormGroup;
  smsCodeForm: FormGroup;
  resetPassForm: FormGroup;

  formState: number = 1;

  smsError: boolean = false;
  smsResponseError: boolean = false;
  secondSendSms: boolean = false;
  confirmPasswordNotMatch: boolean = false;
  resetPasswordError: boolean = false;
  isLoading: boolean = false;
  user: RegularUser;

  hide = true;
  hideVerify = true;

  @ViewChild('verificationCode') verificationCode: ElementRef;
  @ViewChild('codeInput') codeInput!: CodeInputComponent;
  paramsIdNum: any;
  paramsPhone: any;
  isSubmited: boolean;
  passwordNotValid: boolean;
  constructor(
    private regularUsersService: RegularUsersService,
    private router: Router,
    private renderer: Renderer2,
    private route: ActivatedRoute,
    private store: Store,
    private recaptchaV3Service: ReCaptchaV3Service,
    private googleCaptchaService: GoogleCaptchaService
  ) {
    /*  this.regularUsersService.currentUser.subscribe((user) => {
      if (user) this.router.navigate(['']);
    }); */
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.paramsIdNum = params['idNum'] ? params['idNum'] : '';
      this.paramsPhone = params['phone'] ? params['phone'] : '';
      this.createFormGroup();
    });
  }
  setTitle() {
    if (this.router.url.indexOf('register') != -1) {
      if (this.formState == 1) {
        return 'בוא נתחיל ברישום!';
      } else if (this.formState == 2) {
        return 'שלחנו קוד אימות למספר הטלפון הנייד שלך';
      } else if (this.formState == 3) {
        return 'בחר סיסמה';
      }
    } else {
      return 'איפוס סיסמה';
    }
  }

  createFormGroup() {
    if (this.formState == 1) {
      this.registerForm = new FormGroup({
        idNum: new FormControl(this.paramsIdNum, [Validators.required]),
        phone: new FormControl(this.paramsPhone, [Validators.required]),
      });
    } else if (this.formState == 2) {
      this.smsCodeForm = new FormGroup({
        smsCode: new FormControl(null, [Validators.required]),
      });
    } else if (this.formState == 3) {
      this.resetPassForm = new FormGroup({
        password: new FormControl(null, [
          Validators.required,
          Validators.minLength(8),
          passwordValidator,
        ]),
        confirmPassword: new FormControl(null, [
          Validators.required,
          Validators.minLength(8),
          matchOtherValidator('password'),
        ]),
      });
    }
  }

  onCodeChanged(event) {}
  onCodeCompleted(event) {
    this.smsCodeForm.get('smsCode').setValue(event);
    this.handelSmsResponse();
  }

  doRegistration(numSend = 1) {
    if (!this.registerForm.valid) return;

    this.isLoading = true;
    var idNum = this.registerForm.value.idNum;
    var phone = this.registerForm.value.phone;

    this.regularUsersService.sendSmsForPasswordReset(idNum, phone).subscribe(
      (data) => {
        this.isLoading = false;
        this.formState = 2;
        if (numSend == 2) {
          this.secondSendSms = true;
        }
        this.createFormGroup();
      },
      (error) => {
        this.isLoading = false;
        this.smsError = true;
      }
    );
  }

  handelPasswordReset() {
    this.isSubmited = true;
    if (!this.resetPassForm.valid) {
      if (!this.resetPassForm.controls['password'].valid) {
        this.passwordNotValid = true;
      }
      return;
    }

    this.isLoading = true;
    this.resetPasswordError = false;
    var password = this.resetPassForm.value.password;
    this.user.password = password;

    this.store.dispatch(ResetPasswordFromSms({ user: this.user }));

    /* this.regularUsersService.resetPassFromSms(this.user).subscribe(
      (data) => {
        this.isLoading = false;
        this.regularUsersService
          .validateFromLogin(this.user)
          .subscribe((data) => {
            if ((data as RegularUser).firstName) {
              this.router.navigate(['home']);
            }
          });
      },
      (error) => {
        this.isLoading = false;
        this.resetPasswordError = true;
      }
    ); */
  }

  handelSmsResponse() {
    if (!this.smsCodeForm.valid) return;
    this.isLoading = true;

    var smsCode = this.smsCodeForm.value.smsCode;
    var idNum = this.registerForm.value.idNum;
    var phone = this.registerForm.value.phone;

    this.regularUsersService
      .getResetParmasFromSms(idNum, phone, smsCode)
      .subscribe(
        (data) => {
          this.isLoading = false;
          this.user = data;
          this.formState = 3;
          this.createFormGroup();
        },
        (error) => {
          this.smsResponseError = true;
          this.codeInput.reset();
          this.isLoading = false;
          //console.log(error);
        }
      );
  }

  checkPasswordMatchAndConditions(e) {
    if (
      this.password.value !== null &&
      this.confirmPassword.value !== null &&
      this.password.value !== this.confirmPassword.value
    ) {
      this.confirmPasswordNotMatch = true;
    } else if (this.password.value === this.confirmPassword.value) {
      this.confirmPasswordNotMatch = false;
    }
    if (!this.hasLetters() && !this.is8Length() && !this.hasNumbers()) {
      this.passwordNotValid = true;
    } else {
      this.passwordNotValid = false;
    }
  }

  sendCaptchaToVerify(type: 'reset' | 'register') {
    this.recaptchaV3Service
      .execute('importantAction')
      .subscribe((token: string) => {
        const captcha = new GoogleCaptcha();
        captcha.response = token;
        this.googleCaptchaService
          .VerifyingGoogleCaptchaResponse(captcha)
          .subscribe((data) => {
            if (type === 'register') {
              this.doRegistration();
            } else if (type === 'reset') {
              this.handelPasswordReset();
            }
          });
        //console.debug(`Token [${token}] generated`);
      });
  }

  isChrome() {
    return isChrome();
  }
  is8Length() {
    return this.password.value && this.password.value.length >= 8;
  }
  hasLetters() {
    return (
      this.password.value &&
      /[a-z]/.test(this.password.value) &&
      /[A-Z]/.test(this.password.value)
    );
  }
  hasNumbers() {
    return this.password.value && /[0-9]/.test(this.password.value);
  }
  get idNum() {
    return this.registerForm.get('idNum');
  }
  get phone() {
    return this.registerForm.get('phone');
  }
  get smsCode() {
    return this.smsCodeForm.get('smsCode');
  }
  get password() {
    return this.resetPassForm.get('password');
  }
  get confirmPassword() {
    return this.resetPassForm.get('confirmPassword');
  }

  get isApp() {
    return environment.isApp;
  }
}
