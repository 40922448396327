import { Status } from './Status';

export class GuidedQuestion {
  guidedQuestionId: number;
  answerTxt: string;
  nextQuestionTxt: string;
  parentQuestionId: number | null;
  parentQuestion: GuidedQuestion;
  actionId: number | null;
  action: Status;
  answers: GuidedQuestion[];
}
